import { companyId as cookiesCompanyId } from '@/services/cookies';

/*
 * Обязательный interceptor запросов, для установки X-Company-Id заголовка из cookies
 */
async function companyId(config) {
  const id = cookiesCompanyId.get();

  if (id) {
    const headers = { 'X-Company-Id': id };

    Object.assign(config.headers, headers);
  }

  return config;
}

export default companyId;
