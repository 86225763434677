import { createLogger, Plugin } from 'vuex';
import { RootState } from './types';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

const plugins: Plugin<RootState>[] = [];

if (!IS_PRODUCTION) {
  plugins.push(
    createLogger({ collapsed: true }),
  );
}

export default plugins;
