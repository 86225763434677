import {
  SET_COMPANIES,
  SET_LICENSES,
  SET_PROFILE,
  SET_CHIEFS,
  SET_TEAMS,
} from '@/plugins/vuex/modules/profile/mutationTypes';
import {
  EntitiesProfileShow,
  EntitiesProfileApps,
  CompanyShow,
  EntitiesProfileChiefs,
  EntitiesProfileTeamsWithPeople,
} from '@/services/api/profile';
import { companyId } from '@/services/cookies';
import { ProfileState } from './state';

export default {
  [SET_PROFILE](state: ProfileState, payload: EntitiesProfileShow) {
    state.profile = payload;
  },
  [SET_COMPANIES](state: ProfileState, payload: CompanyShow[]) {
    state.companies = payload || [];
    state.company = payload.find((company) => company.id === companyId.get()) as CompanyShow;
  },
  [SET_LICENSES](state: ProfileState, payload: EntitiesProfileApps[]) {
    state.licenses = (payload || []).filter((app) => !!app.appName).map((app) => app.appName) as string[];
  },
  [SET_CHIEFS](state: ProfileState, payload: EntitiesProfileChiefs[]) {
    state.chiefs = payload || [];
  },
  [SET_TEAMS](state: ProfileState, payload: EntitiesProfileTeamsWithPeople[]) {
    state.teams = payload || [];
  },
};
