// TODO: fix ts
// @ts-nocheck

// Components
import VNode from 'vue';

import Toggleable from 'vuetify/lib/mixins/toggleable';
import { factory as PositionableFactory } from 'vuetify/lib/mixins/positionable';

// Utilities
import mixins from 'vuetify/lib/util/mixins';
import { convertToUnit } from 'vuetify/lib/util/helpers';

import TTAlert from '../TTAlert/TTAlert';

// Types

export default mixins(
  TTAlert,
  Toggleable,
  PositionableFactory([
    'absolute',
    'bottom',
    'left',
    'right',
    'top',
  ]),
  /* @vue/component */
).extend({
  name: 'TTNotify',

  props: {
    dataTest: {
      type: String,
      default: 'tt-notify',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    app: Boolean,
    centered: Boolean,
    timeout: {
      type: [Number, String],
      default: 5000,
    },
  },

  data: () => ({
    activeTimeout: -1,
  }),

  computed: {
    classes(): object {
      return {
        'v-snack--absolute': this.absolute,
        'v-snack--active': this.isActive,
        'v-snack--bottom': this.bottom || !this.top,
        'v-snack--centered': this.centered,
        'v-snack--left': this.left,
        'v-snack--right': this.right,
        'v-snack--top': this.top,
      };
    },

    styles(): object {
      // Styles are not needed when
      // using the absolute prop.
      if (this.absolute) return {};

      const {
        bar,
        bottom,
        footer,
        insetFooter,
        left,
        right,
        top,
      } = this.$vuetify.application;

      // Should always move for y-axis
      // applicationable components.
      return {
        paddingBottom: convertToUnit(bottom + footer + insetFooter),
        paddingLeft: !this.app ? undefined : convertToUnit(left),
        paddingRight: !this.app ? undefined : convertToUnit(right),
        paddingTop: convertToUnit(bar + top),
      };
    },
  },

  watch: {
    isActive: 'setTimeout',
    timeout: 'setTimeout',
  },

  mounted() {
    if (this.isActive) this.setTimeout();
  },

  methods: {
    genContent() {
      const self = this;
      const props = {
        dismissible: true,
        notify: true,
        icon: this.icon,
        type: this.type,
        title: this.title,
        content: this.content,
        'link-internal': this.linkInternal,
        'link-external': this.linkExternal,
      };
      return this.$createElement(TTAlert, {
        props,
        on: {
          close() {
            self.isActive = false;
          },
        },
      }, this.$slots.default);
    },
    genWrapper() {
      return this.$createElement('div', {
        staticClass: 'v-snack__wrapper',
        directives: [{
          name: 'show',
          value: this.isActive,
        }],
        on: {
          mouseenter: () => window.clearTimeout(this.activeTimeout),
          mouseleave: this.setTimeout,
        },
      }, [
        this.genContent(),
      ]);
    },
    setTimeout() {
      window.clearTimeout(this.activeTimeout);

      const timeout = Number(this.timeout);

      if (
        !this.isActive
        // TODO: remove 0 in v3
        || [0, -1].includes(timeout)
      ) {
        return;
      }

      this.activeTimeout = window.setTimeout(() => {
        this.isActive = false;
      }, timeout);
    },
  },

  render(h): VNode {
    return h('div', {
      staticClass: 'v-snack',
      attrs: {
        'data-test': this.dataTest,
        'data-test-label': this.dataTestLabel,
        'data-test-value': this.dataTestValue,
      },
      class: this.classes,
      style: this.styles,
    }, [this.genWrapper()]);
  },
});
