<template>
  <VNavigationDrawer
    :value="value"
    :width="width"
    :app="app"
    :clipped="clipped"
  >
    <slot />
    <template #append>
      <slot name="append" />
    </template>
    <template #prepend>
      <slot name="prepend" />
    </template>
  </VNavigationDrawer>
</template>

<script>
export default {
  name: 'TTNavigationDrawer',
  props: {
    value: Boolean,
    app: Boolean,
    clipped: Boolean,
    width: {
      type: [Number, String],
      default: 250,
    },
  },
};
</script>

<style scoped lang="scss">
</style>
