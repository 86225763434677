import { V1EntitiesSurveysPublicShow } from '@/services/api/tsxass';
import { AxiosResponse } from 'axios';

export interface SurveyState {
  meta: V1EntitiesSurveysPublicShow | null;
  metaLoading: boolean;
  metaRequest: Promise<AxiosResponse<V1EntitiesSurveysPublicShow, any>> | null;
}

export default (): SurveyState => ({
  meta: null,
  metaLoading: false,
  metaRequest: null,
});
