// eslint-disable-next-line import/extensions,import/no-unresolved
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';
import VuetifyColors from 'vuetify/lib/util/colors';
import TTColors from './tt.colors';

const theme: VuetifyThemeVariant = {
  // TODO: удалить VuetifyColors
  ...(VuetifyColors as unknown as VuetifyThemeVariant),
  ...TTColors,

  primary: TTColors['tt-light-blue'],
  secondary: TTColors['tt-light-mono-8'],
  alt: TTColors['tt-light-mono-8'],
  accent: TTColors['tt-light-blue'],
  error: TTColors['tt-light-red'],
  danger: TTColors['tt-light-red'],
  warning: TTColors['tt-light-yellow'],
  info: TTColors['tt-light-blue'],
  success: TTColors['tt-light-green'],
  // NOTE: не поддерживает ParsedThemeItem, только String :"(
  anchor: TTColors['tt-light-blue'].base,

  white: TTColors['tt-light-mono-0'],

  'tt-primary': TTColors['tt-light-blue'],
  'tt-secondary': TTColors['tt-light-mono-8'],
  'tt-danger': TTColors['tt-light-red'],
  'tt-ghost': TTColors['tt-light-blue'],
  'tt-unactive': TTColors['tt-light-mono-8'],
};

export default theme;
