import { EngagementAbility } from '@/helpers/constants';
import { V1EntitiesUsersPublicMeUser } from '@/services/api/tsxass';

export interface EngagementInfoState {
  abilities: EngagementAbility[];
  user: V1EntitiesUsersPublicMeUser | null,
}

export default (): EngagementInfoState => ({
  abilities: [],
  user: null,
});
