import { EngagementAbility } from '@/helpers/constants';
import { SET_ABILITIES, SET_USER } from '@/plugins/vuex/modules/engagementInfo/mutationTypes';
import { V1EntitiesUsersPublicMeUser } from '@/services/api/tsxass';
import { EngagementInfoState } from './state';

export default {
  [SET_ABILITIES](state: EngagementInfoState, payload: EngagementAbility[]) {
    state.abilities = [...state.abilities, ...payload];
  },
  [SET_USER](state: EngagementInfoState, user: V1EntitiesUsersPublicMeUser) {
    state.user = user;
  },
};
