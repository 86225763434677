import { Module } from 'vuex';
import { RootState } from '../../types';

enum AccessControlList {
  GUEST = 'guest',
  MANAGER = 'manager',
  ADMIN = 'admin'
}

interface AccessControlListState {
  user: {},
  role: AccessControlList,
  ACL: AccessControlList[],
}

const aclModule: Module<AccessControlListState, RootState> = {
  namespaced: true,

  state() {
    return {
      user: {},
      role: AccessControlList.GUEST,
      ACL: [AccessControlList.GUEST, AccessControlList.MANAGER, AccessControlList.ADMIN],
    };
  },

  getters: {
    can: (state) => (role: AccessControlList) => state.ACL.indexOf(state.role) >= state.ACL.indexOf(role),
  },

  mutations: {},

  actions: {},
};

export default aclModule;
