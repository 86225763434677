<template>
  <div
    class="tt-input tt-text-field "
    :class="classesList"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
  >
    <div
      v-if="!!label || !!counter || $slots.label"
      :class="['label d-flex w-100',
               {'justify-space-between' : (!!label || $slots.label) && !!counter },
               {'justify-end' : !label & !$slots.label && !!counter}
      ] "
    >
      <label v-if="!!label || $slots.label"><slot name="label">{{ label }}</slot></label>
      <span
        v-if="!!counter"
        class="tt-text-body-2 tt-light-mono-100--text"
      >{{ value.length }}{{ `/${maxlength ? maxlength : ''}` }}</span>
    </div>
    <VTextField
      :append-icon="appendIcon"
      :hide-details="hideDetails"
      :label="''"
      :maxlength="maxlength"
      :type="type"
      v-bind="{...$attrs, ...$props}"
      :data-test="`${dataTest}--input`"
      :data-test-label="dataTestLabel"
      :data-test-value="dataTestValue"
      v-on="$listeners"
    >
      <template #append>
        <slot name="append" />
      </template>

      <template #append-outer>
        <slot name="append-outer" />
      </template>

      <template #default>
        <slot name="default" />
      </template>

      <template
        v-if="$scopedSlots.message"
        #message="props"
      >
        <slot
          name="message"
          v-bind="props"
        />
      </template>

      <template #prepend-inner>
        <slot name="prepend-inner" />
      </template>

      <template #progress>
        <slot name="progress" />
      </template>
    </VTextField>
  </div>
</template>

<script>

export default {
  name: 'TTTextField',
  props: {
    appendIcon: {
      type: String,
      default: '',
    },
    clearable: Boolean,
    color: {
      type: String,
      default: 'tt-light-mono-16',
    },
    // eslint-disable-next-line vue/require-default-prop
    counter: [Boolean, Number, String],
    // eslint-disable-next-line vue/require-default-prop
    counterValue: {
      type: Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      default: 'tt-text-field',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: undefined,
    },
    error: Boolean,
    errorMessages: {
      type: [Array, String],
      default: () => [],
    },
    hint: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: [String, Boolean],
      default: 'auto',
    },
    label: {
      type: String,
      default: '',
    },
    messages: {
      type: [Array, String],
      default: () => [],
    },
    maxlength: {
      type: [Boolean, Number, String],
      default: false,
    },
    outlined: Boolean,
    persistentHint: Boolean,
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'text',
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
    value: {
      type: undefined,
      default: undefined,
    },
    large: Boolean,
    small: Boolean,
    xSmall: Boolean,
    xLarge: Boolean,
  },
  computed: {
    classesList() {
      return {
        'v-size--x-large': this.xLarge,
        'v-size--large': this.large,
        'v-size--small': this.small,
        'v-size--x-small': this.xSmall,
        'v-size--default': !this.xLarge && !this.large && !this.xSmall && !this.small,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../TTInput/TTInput";
@import "TTTextField";
</style>
