
import Vue from 'vue';
import { VRadioGroup } from 'vuetify/lib';

export default Vue.extend({
  name: 'TTRadioGroup',
  mixins: [VRadioGroup],
  props: {
    dataTest: {
      type: String,
      default: 'tt-radiogroup',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
  },
});
