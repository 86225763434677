import Vue, { PluginFunction } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import objectSupport from 'dayjs/plugin/objectSupport';
import minmax from 'dayjs/plugin/minMax';
import DayOfYear from 'dayjs/plugin/dayOfYear';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(quarterOfYear);
dayjs.extend(objectSupport);
dayjs.extend(minmax);
dayjs.extend(DayOfYear);
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const { VUE_APP_I18N_LOCALE: primary, VUE_APP_I18N_FALLBACK_LOCALE: fallback = 'ru' } = process.env;
const locale = primary || fallback;
dayjs.locale(locale);

const install: PluginFunction<Vue> = (vue) => {
  Object.defineProperty(vue.prototype, '$dayjs', {
    get() {
      return dayjs;
    },
  });
};

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(install);
}

export default dayjs;
