import state, { ProfileState } from './state';
import mutations from './mutations';
import actions from './actions';

export const getStoreConfig = ({ initialState = {} }: { initialState?: Partial<ProfileState> } = {}) => ({
  namespaced: true,
  state: { ...state(), ...initialState },
  mutations,
  actions,
});

export default getStoreConfig();
