import toSnakeCase from '@/utils/transformers/toSnakeCase';

const toSnakeCaseRequestTransformer = (data: any) => {
  if (data instanceof FormData) return data;
  if (typeof data === 'string') {
    try {
      return toSnakeCase(JSON.parse(data));
    } catch {
      return data;
    }
  }

  return toSnakeCase(data);
};

export default toSnakeCaseRequestTransformer;
