<template>
  <VSnackbar
    absolute
    app
    :value="value"
    :dismissible="dismissible"
    :color="color"
    :width="width"
    :height="height"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :min-height="minHeight"
    :min-width="minWidth"
    :light="light"
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
    :timeout="timeout"
    @input="(e) => $emit('input',e)"
  >
    <slot name="prepend">
      <VIcon
        v-if="hasType"
        :color="iconType.color"
        class="mr-2"
      >
        {{ iconType.icon }}
      </VIcon>
    </slot>
    <slot />
    <template #action="{attrs}">
      <slot
        name="action"
        :attrs="{...attrs}"
      />
    </template>
  </VSnackbar>
</template>

<script>
/* eslint-disable vue/require-default-prop */

const icons = {
  success: {
    icon: 'fa fa-check-circle',
    color: 'success',
  },
  info: {
    icon: 'fa fa-info-circle',
    color: 'info',
  },
  warning: {
    icon: 'fa fa-exclamation-circle',
    color: 'warning',
  },
  error: {
    icon: 'fa fa-exclamation-circle',
    color: 'error',
  },
};

export default {
  name: 'TTSnackbar',
  props: {
    value: Boolean,
    dismissible: Boolean,
    maxWidth: [Number, String],
    minWidth: [Number, String],
    maxHeight: [Number, String],
    minHeight: [Number, String],
    height: [Number, String],
    width: [Number, String],
    light: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    top: { type: Boolean, default: true },
    left: Boolean,
    right: Boolean,
    bottom: Boolean,
    timeout: { type: [String, Number], default: 5000 },
    color: {
      type: String,
      default: 'white',
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    iconType() {
      return icons[this.type];
    },
    hasType() {
      return this.type && this.type !== 'default';
    },
  },
};
</script>

<style scoped lang="scss">

.elevation-1 {
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08) !important;
}

.v-icon.v-icon {
  font-size: 19px;
}
</style>
