import state, { SurveyState } from './state';
import mutations from './mutations';
import getters from './getters';

export const getStoreConfig = ({ initialState = {} }: { initialState?: Partial<SurveyState> } = {}) => ({
  namespaced: true,
  state: { ...state(), ...initialState },
  mutations,
  getters,
  actions: {},
});

export default getStoreConfig();
