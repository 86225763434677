/* eslint-disable import/prefer-default-export */
import { companyId } from '@/services/cookies';
import RouteNames from '@/plugins/vue-router/routeNames';

const { VUE_APP_GATEKEEPER_ADDRESS } = process.env;

/*
 * Обязательный guard для выполнения перед всеми переходами
 */
function hasCompany(to, from, next) {
  const id = companyId.get();

  if (to.name === RouteNames.R_APP_EXTERNALS_REGISTRATION) {
    return next();
  }

  if (!id) {
    companyId.remove();

    // IMPORTANT: редиректим в гейткипер с сылкой на текущую страницу
    const url = new URL(`/?back=${window.location.href}`, VUE_APP_GATEKEEPER_ADDRESS);
    window.location.replace(url.href);

    return next(false);
  }

  return next();
}

export { hasCompany };
