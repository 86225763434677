import Router, { RouterOptions } from 'vue-router';

import yandexMetrica from './guards/yandexMetrica';
import { hasToken } from './guards/hasToken';
import { hasCompany } from './guards/hasCompany';

function createRouter({ routes, base, ...options }: RouterOptions) {
  const router = new Router({
    mode: 'history',
    base,
    // Note: отображается во вкладке маршрутов в devtools@^5.0.1
    routes,
    ...options,
  });

  router.beforeEach(hasToken);
  router.beforeEach(hasCompany);
  router.afterEach(yandexMetrica);

  return router;
}

export default createRouter;
