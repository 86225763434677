/* @see: https://developer.mozilla.org/ru/docs/Web/HTTP/Methods */
export const HTTP_GET = 'GET';
export const HTTP_HEAD = 'HEAD';
export const HTTP_POST = 'POST';
export const HTTP_PUT = 'PUT';
export const HTTP_DELETE = 'DELETE';
export const HTTP_CONNECT = 'CONNECT';
export const HTTP_OPTIONS = 'OPTIONS';
export const HTTP_TRACE = 'TRACE';
export const HTTP_PATCH = 'PATCH';
