<template>
  <TTTooltip
    v-bind="props"
    :disabled="disabled"
    :data-test="`${dataTest}--tooltip`"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
    v-on="$listeners"
  >
    <span>{{ text }}</span>
    <template v-slot:activator="{ on }">
      <div
        ref="text"
        v-resize.quiet="onResizeDirective"
        class="line-clamp"
        :class="[ classes, $vnode.data.staticClass ]"
        :style="styles"
        :data-test="dataTest"
        :data-test-label="dataTestLabel"
        :data-test-value="dataTestValue"
        v-on="on"
      >
        <slot>
          {{ text }}
        </slot>
      </div>
    </template>
  </TTTooltip>
</template>

<script>
import TTTooltip from '../TTTooltip/TTTooltip.vue';

// Компонент пока не тестируется, применение на свой страх и риск
export default {
  name: 'TTPrompt',

  components: { TTTooltip },

  props: {
    dataTest: {
      type: String,
      default: 'tt-prompt',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },

    line: {
      type: Number,
      default: 1,
    },
    text: {
      type: String,
      required: true,
    },
    // это пропс включает ResizeObserver - компонент реагирует на изменение собственного размера
    observing: {
      type: Boolean,
      default: true,
    },
    sameWidth: {
      type: Boolean,
      default: false,
    },

    // delay
    openDelay: {
      type: [Number, String],
      default: 0,
    },
    closeDelay: {
      type: [Number, String],
      default: 0,
    },

    // position
    top: {
      type: Boolean,
    },
    bottom: {
      type: Boolean,
      default: true,
    },
    left: {
      type: Boolean,
    },
    right: {
      type: Boolean,
    },

    // content
    // class
    contentClass: {
      type: String,
      default: undefined,
    },
    // color
    color: {
      type: String,
      default: undefined,
    },
    // size
    maxWidth: {
      type: [Number, String],
      default: 450,
    },
    minWidth: {
      type: [Number, String],
      default: 450,
    },
    // overflow flip
    offsetOverflow: {
      type: Boolean,
    },
    // nudge
    nudgeBottom: {
      type: [Number, String],
      default: 0,
    },
    nudgeLeft: {
      type: [Number, String],
      default: 0,
    },
    nudgeRight: {
      type: [Number, String],
      default: 0,
    },
    nudgeTop: {
      type: [Number, String],
      default: 0,
    },
    nudgeWidth: {
      type: [Number, String],
      default: 0,
    },

    zIndex: {
      type: [Number, String],
      default: undefined,
    },
    transition: {
      type: [String],
      default: undefined,
    },
  },
  data() {
    return {
      disabled: true,
      observer: null,
      width: null,
    };
  },
  computed: {
    styles() {
      return {
        '-webkit-line-clamp': this.line,
      };
    },
    classes() {
      return {
        'cursor-pointer': !this.disabled,
      };
    },
    props() {
      if (this.sameWidth && this.width) {
        return { ...this.$props, maxWidth: this.width, minWidth: this.width };
      }
      return { ...this.$props };
    },
  },
  watch: {
    line: 'onResizeDirective',
    text: 'onResizeDirective',
    observing(val) {
      if (val) {
        this.initObserver();
      } else {
        this.destroyObserver();
      }
    },
  },
  beforeDestroy() {
    this.destroyObserver();
  },
  mounted() {
    if (this.observing) {
      this.initObserver();
    } else {
      this.onResizeDirective();
    }
  },
  methods: {
    onResizeDirective() {
      if (!this.observing) {
        this.onResize();
      }
    },
    onResize() {
      this.$nextTick(() => {
        const el = this.$refs.text;
        this.width = this.sameWidth ? el.clientWidth : null;
        this.disabled = el.scrollHeight <= el.clientHeight;
      });
    },
    initObserver() {
      if (!this.observer) {
        const observer = new ResizeObserver(this.onResize);
        observer.observe(this.$refs.text);
        this.observer = observer;
      }
    },
    destroyObserver() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    },
  },
};
</script>

<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cursor-pointer{
  cursor: pointer;
}
</style>
