<template>
  <VBtn
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"

    :class="TTBtnClasses"

    v-bind="$props"
    v-on="$listeners"
  >
    <slot />
  </VBtn>
</template>

<script>
export default {
  name: 'TTBtn',

  props: {
    /* New */
    dataTest: {
      type: String,
      default: 'tt-btn',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },

    square: {
      type: Boolean,
      default: false,
    },

    /* Vuetify */
    absolute: {
      type: Boolean,
    },
    activeClass: {
      type: String,
      default: '',
    },
    append: {
      type: Boolean,
    },
    block: {
      type: Boolean,
    },
    bottom: {
      type: Boolean,
    },
    color: {
      type: String,
      default: 'tt-primary',
    },
    dark: {
      type: Boolean,
    },
    depressed: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    elevation: {
      type: [Number, String],
      default: undefined,
    },
    exact: {
      type: Boolean,
    },
    exactActiveClass: {
      type: String,
      default: undefined,
    },
    fab: {
      type: Boolean,
    },
    fixed: {
      type: Boolean,
    },
    height: {
      type: [Number, String],
      default: undefined,
    },
    href: {
      type: [String, Object],
      default: undefined,
    },
    icon: {
      type: Boolean,
    },
    inputValue: {
      type: undefined,
      default: undefined,
    },
    large: {
      type: Boolean,
    },
    left: {
      type: Boolean,
    },
    light: {
      type: Boolean,
    },
    link: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    maxHeight: {
      type: [Number, String],
      default: undefined,
    },
    maxWidth: {
      type: [Number, String],
      default: undefined,
    },
    minHeight: {
      type: [Number, String],
      default: undefined,
    },
    minWidth: {
      type: [Number, String],
      default: undefined,
    },
    nuxt: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    plain: {
      type: Boolean,
    },
    replace: {
      type: Boolean,
    },
    retainFocusOnClick: {
      type: Boolean,
    },
    right: {
      type: Boolean,
    },
    ripple: {
      type: [Boolean, Object],
      default: false,
    },
    rounded: {
      type: Boolean,
    },
    shaped: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
    tag: {
      type: String,
      default: 'button',
    },
    target: {
      type: String,
      default: undefined,
    },
    text: {
      type: Boolean,
    },
    tile: {
      type: Boolean,
    },
    to: {
      type: [String, Object],
      default: undefined,
    },
    top: {
      type: Boolean,
    },
    type: {
      type: String,
      default: 'button',
    },
    value: {
      type: undefined,
      default: undefined,
    },
    width: {
      type: [Number, String],
      default: undefined,
    },
    xLarge: {
      type: Boolean,
    },
    xSmall: {
      type: Boolean,
    },
  },

  computed: {
    TTBtnClasses() {
      return {
        'tt-btn--square': this.square,
      };
    },
  },
};
</script>

<style lang="scss" src="./TTBtn.scss"></style>
