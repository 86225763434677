import { Module } from 'vuex';
import { RootState } from '@/plugins/vuex/types';
import { LOAD_FEATURE_FLAGS } from '@/plugins/vuex/modules/featureFlags/actionTypes';
import { SET_FEATURE_FLAGS } from '@/plugins/vuex/modules/featureFlags/mutationTypes';
import { FeatureFlags } from '@/helpers/constants';
import { featureFlagsApi } from '@/services/api';

interface FeatureFlagsState {
  featureFlags: FeatureFlags[];
}

interface GitLabStrategy {
  name: String;
  parameters: any;
}

interface GitLabFlag {
  description: String;
  enabled: Boolean;
  name: String;
  strategies: GitLabStrategy[];
}

interface GitLabFeatureFlag {
  features: GitLabFlag[];
  version: Number;
}

const featureFlagsStateModule: Module<FeatureFlagsState, RootState> = {
  namespaced: true,

  state: {
    featureFlags: [],
  } as FeatureFlagsState,

  getters: {
    hasFlag: (state) => (flag: FeatureFlags) => state.featureFlags.includes(flag),
  },

  actions: {
    async [LOAD_FEATURE_FLAGS]({ commit }) {
      const flagsData: GitLabFeatureFlag = await featureFlagsApi.featureFlagsGet();
      commit(
        SET_FEATURE_FLAGS,
        flagsData.features.filter((ff) => ff.enabled).map((ff) => ff.name),
      );
    },
  },

  mutations: {
    [SET_FEATURE_FLAGS](state, payload: FeatureFlags[]) {
      state.featureFlags = payload;
    },
  },
};

export default featureFlagsStateModule;
