var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VNavigationDrawer',{ref:"menu",staticClass:"tt-module-menu",attrs:{"app":"","clipped":"","width":_vm.minWidth,"data-test":_vm.dataTest,"data-test-label":_vm.dataTestLabel,"data-test-value":_vm.dataTestValue,"mobile-breakpoint":"0"},scopedSlots:_vm._u([(_vm.appendItems.length > 0)?{key:"append",fn:function(){return [_c('div',{staticClass:"tt-module-menu-groups"},[_vm._l((_vm.appendItems),function(groups,i){return [_c('div',{key:_vm.generateId(`group-append-${i}`),staticClass:"tt-module-menu-group"},_vm._l((groups),function(item){return _c('TTTooltip',{key:_vm.generateId(`group-append-${item.title}`),attrs:{"content-class":"tt-module-menu-item-tooltip","nudge-right":"4","right":"","close-delay":300,"open-delay":300,"disabled":_vm.drawer || !!item.isParent || !(item.tooltip || item.title),"transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on : tooltip, attrs : tooltipAttrs }){return [_c('VBtn',_vm._g(_vm._b({staticClass:"tt-module-menu-item",class:{
                  'v-btn--active' : _vm.getActiveValue(item),
                  ...item.class
                },attrs:{"text":"","to":item.to,"href":item.href,"target":item.target,"exact":item.exact,"data-test":"tt-module-menu-btn","data-test-value":item.testValue,"data-test-label":item.testLabel,"disabled":item.disabled,"block":"","ripple":false},on:{"click":function($event){return _vm.$emit('click', item)}}},'VBtn',{ ...tooltipAttrs },false),{ ...tooltip, ...item.handlers }),[_c('BadgeMenu',{attrs:{"badge":item.badge}},[_c('VIcon',[_vm._v(_vm._s(item.icon))])],1),(_vm.drawer)?_c('span',{staticClass:"tt-text-caption ml-3 text-left"},[_c('div',{class:item.textClass},[_vm._v(" "+_vm._s(item.tooltip || item.title)+" ")])]):_vm._e(),_vm._t("item-append",null,null,item)],2)]}}],null,true)},[_c('span',{staticClass:"tt-text-caption"},[_vm._v(_vm._s(item.tooltip || item.title))])])}),1)]})],2)]},proxy:true}:null],null,true)},[_c('div',{staticClass:"tt-module-menu-groups"},[_vm._l((Object.keys(_vm.groupedItems)),function(group,i){return [(Object.keys(_vm.groupedItems).length > 1)?_c('div',{key:_vm.generateId(`group-title-${i}`),staticClass:"tt-text-body-2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.menuGroupTitle[group])+" ")]):_vm._e(),_c('div',{key:_vm.generateId(`group-${i}`),staticClass:"tt-module-menu-group"},_vm._l((_vm.groupedItems[group]),function(item){return _c('TTTooltip',{key:_vm.generateId(`group-${item.title}`),attrs:{"content-class":"tt-module-menu-item-tooltip","nudge-right":"4","right":"","close-delay":300,"open-delay":300,"disabled":_vm.drawer || !!item.isParent || !(item.tooltip || item.title),"transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on : tooltip, attrs : tooltipAttrs }){return [_c('VBtn',_vm._g(_vm._b({staticClass:"tt-module-menu-item",class:{
                'v-btn--active' : _vm.getActiveValue(item),
                ...item.class
              },attrs:{"text":"","to":item.to,"href":item.href,"target":item.target,"exact":item.exact,"data-test":"tt-module-menu-btn","data-test-value":item.testValue,"data-test-label":item.testLabel,"disabled":item.disabled,"block":"","ripple":false},on:{"click":function($event){return _vm.$emit('click', item)}}},'VBtn',{ ...tooltipAttrs },false),{ ...tooltip }),[_c('BadgeMenu',{attrs:{"badge":item.badge}},[_c('VIcon',[_vm._v(_vm._s(item.icon))])],1),_c('span',{staticClass:"tt-text-caption ml-3 two-lines-text text-left"},[_c('div',{class:item.textClass},[_vm._v(" "+_vm._s(item.tooltip || item.title)+" ")])]),(item.is360)?_c('div',{staticClass:"tt-light-mono-64--text tt-text-footnote ml-1 align-self-start"},[_c('sup',[_vm._v("360")])]):_vm._e(),_vm._t("item-append",null,null,item)],2)]}}],null,true)},[_c('span',{staticClass:"tt-text-caption"},[_vm._v(_vm._s(item.tooltip || item.title))])])}),1)]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }