<template>
  <VAppBar
    :app="app"
    :absolute="absolute"
    :height="height"
    :clipped-left="clippedLeft"
    :clipped-right="clippedRight"
    flat
  >
    <slot />
  </VAppBar>
</template>

<script>
export default {
  name: 'TTAppBar',
  props: {
    app: Boolean,
    clippedLeft: Boolean,
    clippedRight: {
      type: Boolean,
      default: false,
    },
    absolute: Boolean,
    height: {
      type: [String, Number],
      default: 46,
    },
  },
};
</script>

<style scoped lang="scss">

.theme--light.v-sheet {
  border-color: map-deep-get($tt-light-mono-16, 'base');
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

::v-deep .v-toolbar__content, .v-toolbar__extension {
  padding: 0 28px;
}
</style>
