export enum SurveyStageKind {
  CHOOSING_EXPERTS = 'choosing_experts',
  REVIEW_EXPERTS = 'review_experts',
  PASSAGE = 'passage',
}

export enum SurveyStageType {
  NEXT = 'next',
  PREV = 'prev',
  CURRENT = 'current',
}
