<template>
  <VNavigationDrawer
    ref="menu"
    app
    clipped
    class="tt-module-menu"
    :width="minWidth"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
    mobile-breakpoint="0"
  >
    <div class="tt-module-menu-groups">
      <template v-for="(group, i) in Object.keys(groupedItems)">
        <div
          v-if="Object.keys(groupedItems).length > 1"
          :key="generateId(`group-title-${i}`)"
          class="tt-text-body-2 font-weight-medium"
        >
          {{ menuGroupTitle[group] }}
        </div>
        <div
          :key="generateId(`group-${i}`)"
          class="tt-module-menu-group"
        >
          <TTTooltip
            v-for="item in groupedItems[group]"
            :key="generateId(`group-${item.title}`)"
            content-class="tt-module-menu-item-tooltip"
            nudge-right="4"
            right
            :close-delay="300"
            :open-delay="300"
            :disabled="drawer || !!item.isParent || !(item.tooltip || item.title)"
            transition="fade-transition"
          >
            <template v-slot:activator="{ on : tooltip, attrs : tooltipAttrs }">
              <VBtn
                class="tt-module-menu-item"
                :class="{
                  'v-btn--active' : getActiveValue(item),
                  ...item.class
                }"
                v-bind="{ ...tooltipAttrs }"
                text
                :to="item.to"
                :href="item.href"
                :target="item.target"
                :exact="item.exact"
                data-test="tt-module-menu-btn"
                :data-test-value="item.testValue"
                :data-test-label="item.testLabel"
                :disabled="item.disabled"
                block
                :ripple="false"
                v-on="{ ...tooltip }"
                @click="$emit('click', item)"
              >
                <BadgeMenu :badge="item.badge">
                  <VIcon>{{ item.icon }}</VIcon>
                </BadgeMenu>
                <span
                  class="tt-text-caption ml-3 two-lines-text text-left"
                >
                  <div :class="item.textClass">
                    {{ item.tooltip || item.title }}
                  </div>
                </span>
                <div
                  v-if="item.is360"
                  class="tt-light-mono-64--text tt-text-footnote ml-1 align-self-start"
                >
                  <sup>360</sup>
                </div>
                <slot
                  name="item-append"
                  v-bind="item"
                />
              </VBtn>
            </template>
            <span class="tt-text-caption">{{ item.tooltip || item.title }}</span>
          </TTTooltip>
        </div>
      </template>
    </div>
    <template
      v-if="appendItems.length > 0"
      #append
    >
      <div class="tt-module-menu-groups">
        <template v-for="(groups, i) in appendItems">
          <div
            :key="generateId(`group-append-${i}`)"
            class="tt-module-menu-group"
          >
            <TTTooltip
              v-for="item in groups"
              :key="generateId(`group-append-${item.title}`)"
              content-class="tt-module-menu-item-tooltip"
              nudge-right="4"
              right
              :close-delay="300"
              :open-delay="300"
              :disabled="drawer || !!item.isParent || !(item.tooltip || item.title)"
              transition="fade-transition"
            >
              <template v-slot:activator="{ on : tooltip, attrs : tooltipAttrs }">
                <VBtn
                  class="tt-module-menu-item"
                  :class="{
                    'v-btn--active' : getActiveValue(item),
                    ...item.class
                  }"
                  v-bind="{ ...tooltipAttrs }"
                  text
                  :to="item.to"
                  :href="item.href"
                  :target="item.target"
                  :exact="item.exact"
                  data-test="tt-module-menu-btn"
                  :data-test-value="item.testValue"
                  :data-test-label="item.testLabel"
                  :disabled="item.disabled"
                  block
                  :ripple="false"
                  v-on="{ ...tooltip, ...item.handlers }"
                  @click="$emit('click', item)"
                >
                  <BadgeMenu :badge="item.badge">
                    <VIcon>{{ item.icon }}</VIcon>
                  </BadgeMenu>
                  <span
                    v-if="drawer"
                    class="tt-text-caption ml-3 text-left"
                  >
                    <div :class="item.textClass">
                      {{ item.tooltip || item.title }}
                    </div>
                  </span>
                  <slot
                    name="item-append"
                    v-bind="item"
                  />
                </VBtn>
              </template>
              <span class="tt-text-caption">{{ item.tooltip || item.title }}</span>
            </TTTooltip>
          </div>
        </template>
      </div>
    </template>
  </VNavigationDrawer>
</template>

<script>
import BadgeMenu from './components/BadgeMenu.vue';
import TTTooltip from '../TTTooltip/TTTooltip.vue';

export default {
  name: 'TTModuleMenuV2',
  components: {
    BadgeMenu,
    TTTooltip,
  },
  props: {
    itemsTree: {
      type: Array,
      default: () => [],
    },
    userName: {
      type: String,
      default: '',
    },
    hideSignOut: {
      type: Boolean,
      default: false,
    },
    signOutText: {
      type: String,
      default: 'Выйти',
    },
    dataTest: {
      type: String,
      default: 'tt-module-menu',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 200,
    },
    menuGroupTitle: {
      type: Object,
      default: () => ({
        main: 'Основное',
        assessment: 'Оценка',
        other: 'Прочее',
      }),
    },
  },
  data() {
    return {
      drawer: true,
      minWidth: 210,
      childrenMenuIsShow: false,
    };
  },
  computed: {
    menuItems() {
      return this.itemsTree.map((item) => ({
        ...item,
        badge: item.badge ?? {
          value: item.children && item.children.some((child) => Boolean(child.badge)),
        },
      }));
    },

    groupedItems() {
      return this.menuItems.reduce((acc, item) => {
        const type = (Object.keys(this.menuGroupTitle).includes(item.group) && item.group) || 'other';
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(item);
        return acc;
      }, {});
    },

    appendItems() {
      const append = [];

      if (!this.hideSignOut) {
        append.push([
          {
            title: this.signOutText,
            handlers: {
              click: this.onClickSignOutHandler,
            },
            icon: 'fal fa-sign-out',
            testValue: 'sign-out',
            class: {
              'tt-module-menu-sign-out': true,
            },
            badge: false,
          },
        ]);
      }

      return append;
    },
  },

  methods: {
    generateId(v) {
      return Symbol(v);
    },

    getActiveValue(item, value) {
      return item.isActive ?? value;
    },

    onClickSignOutHandler() {
      this.$emit('sign-out');
    },
  },
};
</script>

<style lang="scss">
.tt-module-menu-item-tooltip {
  padding: 8px 12px;
  font-size: 12px;
  line-height: 100%;
  &:before {
    content: '';
    position: absolute;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    border: 4px solid;
    border-color: transparent rgba(map-get($tt-light-mono-100, 'base'), 0.9) transparent transparent;
  }
  .tt-text-caption {
    line-height: 100%;
  }
}
</style>

<style lang="scss" src="./TTModuleMenuV2.scss" scoped />
