import { SET_ABILITIES, SET_USER } from '@/plugins/vuex/modules/engagementInfo/mutationTypes';
import { LOAD_ENGAGEMENT_INFO } from '@/plugins/vuex/modules/engagementInfo/actionTypes';
import { adminApi, tsxassApi } from '@/services/api';

export default {
  async [LOAD_ENGAGEMENT_INFO]({ commit }: { commit: any, dispatch: any }) {
    const adminMe = await adminApi.getAdminMe();
    commit(SET_ABILITIES, adminMe.data.abilities);
    const v1Me = await tsxassApi.getV1Me();
    commit(SET_ABILITIES, v1Me.data.abilities);
    commit(SET_USER, v1Me.data.user);
  },
};
