import Vue from 'vue';

import TTAppBar from '@uikit/components/TTAppBar.vue';
import TTBtn from '@uikit/components/TTBtn/TTBtn.vue';
import TTDataTable from '@uikit/components/TTDataTable/TTDataTable.vue';
import TTNavigationDrawer from '@uikit/components/TTNavigationDrawer.vue';
import TTToolbarTitle from '@uikit/components/TTToolbarTitle.vue';
import TTSelect from '@uikit/components/TTTSelect/TTSelect.vue';
import TTTextField from '@uikit/components/TTTextField/TTTextField.vue';
import TTTooltip from '@uikit/components/TTTooltip/TTTooltip.vue';
// @ts-ignore
import TTAlert from '@uikit/components/TTAlert/TTAlert';
// @ts-ignore
import TTCheckbox from '@uikit/components/TTCheckbox/TTCheckbox';
// @ts-ignore
import TTRadio from '@uikit/components/TTRadio/TTRadio';
import TTRadioGroup from '@uikit/components/TTRadioGroup/TTRadioGroup.vue';
import TTTextarea from '@uikit/components/TTTextarea/TTTextarea.vue';
import TTModuleMenu from '@uikit/components/TTModuleMenu/TTModuleMenu.vue';
import TTModuleMenuV2 from '@uikit/components/TTModuleMenuV2/TTModuleMenuV2.vue';
import TTChip from '@uikit/components/TTChip/TTChip.vue';
import TTChipGroup from '@uikit/components/TTChipGroup/TTChipGroup.vue';
// @ts-ignore
import TTNotify from '@uikit/components/TTNotify/TTNotify';
import TTAutocomplete from '@uikit/components/TTAutocomplete/TTAutocomplete.vue';
import TTAvatar from '@uikit/components/TTAvatar/TTAvatar.vue';
import TTSnackbar from '@uikit/components/TTSnackbar.vue';
import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';

import App from '@/App.vue';

import {
  i18n, setupRouter, store, vuetify, dayjs,
} from '@/plugins';

import '@/plugins/sentry';

import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css';
import 'quill-emoji/dist/quill-emoji.css'; // quill-emoji

Vue.component('TTAppBar', TTAppBar);
Vue.component('TTBtn', TTBtn);
Vue.component('TTDataTable', TTDataTable);
Vue.component('TTNavigationDrawer', TTNavigationDrawer);
Vue.component('TTToolbarTitle', TTToolbarTitle);
Vue.component('TTSelect', TTSelect);
Vue.component('TTTextField', TTTextField);
Vue.component('TTCheckbox', TTCheckbox);
Vue.component('TTRadio', TTRadio);
Vue.component('TTRadioGroup', TTRadioGroup);
Vue.component('TTTextarea', TTTextarea);
Vue.component('TTModuleMenu', TTModuleMenu);
Vue.component('TTModuleMenuV2', TTModuleMenuV2);
Vue.component('TTAlert', TTAlert);
Vue.component('TTChip', TTChip);
Vue.component('TTChipGroup', TTChipGroup);
Vue.component('TTNotify', TTNotify);
Vue.component('TTAutocomplete', TTAutocomplete);
Vue.component('TTAvatar', TTAvatar);
Vue.component('TTTooltip', TTTooltip);
Vue.component('TTSnackbar', TTSnackbar);
Vue.component('TTPrompt', TTPrompt);

Vue.config.productionTip = false;

new Vue({
  name: 'TalentTech',
  i18n,
  router: setupRouter(Vue),
  store,
  vuetify,
  // @ts-ignore
  dayjs,
  render: (h) => h(App),
}).$mount('#app');
