import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/es5/locale/ru';
import TTLightTheme from '@uikit/themes/tt.light.theme';
import TTDarkTheme from '@uikit/themes/tt.dark.theme';
import icons from '@uikit/icons';
import breakpoint from '@uikit/themes/tt.breakpoint';

Vue.use(Vuetify);

const options = {
  theme: {
    default: 'light',
    themes: {
      light: TTLightTheme,
      dark: TTDarkTheme,
    },
    options: {
      // Note: IE does not support CSS Custom Properties
      customProperties: false,
    },
  },

  icons: {
    iconfont: icons.iconfont,
    values: {
      ...icons.values,
      /**
       * Note: Для того чтобы добавить дополнительные иконки в проект воспользуйтесь этим свойством
       * @example complete: 'fal fa-check',
       */
      bookLight: 'fal fa-book',
      cog: 'fal fa-cog',
      listAltLight: 'fal fa-list-alt',
      puzzleLight: 'fal fa-puzzle-piece',
      trash: 'fal fa-trash-alt',
      plus: 'fal fa-plus',
    },
  },

  lang: {
    locales: { ru },
    current: 'ru',
  },

  breakpoint,
};

export default new Vuetify(options);
