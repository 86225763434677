import { GetterTree } from 'vuex';
import { RootState } from '@/plugins/vuex/types';
import {
  V1EntitiesSurveysPublicShowSetting,
  V1EntitiesSurveysPublicShowStage,
} from '@/services/api/tsxass';
import SurveyType from '@/constants/surveyType';
import { SurveyStageType, SurveyStageKind } from '@/constants/surveyStage';
import { SurveySettingNames, SurveySettingValueType } from '@/constants/SurveySettings';
import { SurveyState } from './state';

export type SurveySettingsMap = Partial<Record<SurveySettingNames, any>>

const parseSettingValue = (setting: V1EntitiesSurveysPublicShowSetting) => {
  switch (setting.type) {
    case SurveySettingValueType.BOOLEAN:
      return [true, 'true'].includes(setting.value);
    case SurveySettingValueType.INTEGER:
      return parseInt(setting.value, 10);
    default:
      return setting.value;
  }
};

const surveyGetters: GetterTree<SurveyState, RootState> = {
  getSurveySetting: (state: SurveyState) => (settingKey: SurveySettingNames) => state
    .meta?.settings.find((item) => item.key === settingKey),

  surveySettings: (state: SurveyState): V1EntitiesSurveysPublicShowSetting[] => state.meta?.settings || [],

  // eslint-disable-next-line arrow-body-style
  surveySettingsMap: (_state, getters): SurveySettingsMap => {
    return Object.fromEntries(
      getters.surveySettings.map(
        (setting: V1EntitiesSurveysPublicShowSetting) => ([
          setting.key as SurveySettingNames,
          parseSettingValue(setting),
        ]),
      ),
    );
  },

  getSurveySettingValue: (_state, getters) => (settingKey: SurveySettingNames): any => getters
    .surveySettingsMap[settingKey] || null,

  currentSurveyStage: (state: SurveyState): V1EntitiesSurveysPublicShowStage | null => {
    if (!state.meta) {
      return null;
    }

    return state.meta.stages.find((stage) => stage.type === SurveyStageType.CURRENT) || null;
  },

  // Этап, отображаемый при открытии опроса
  currentDisplayedSurveyStage: (state: SurveyState, getters: any): V1EntitiesSurveysPublicShowStage | null => {
    if (!state.meta) {
      return null;
    }

    const prev = state.meta.stages.find((stage) => stage.type === SurveyStageType.PREV);
    const next = state.meta.stages.find((stage) => stage.type === SurveyStageType.NEXT);

    return getters.currentSurveyStage || next || prev || null;
  },

  surveyStageChoosingExperts: (state: SurveyState): V1EntitiesSurveysPublicShowStage | null => {
    if (!state.meta) {
      return null;
    }

    return state.meta.stages.find((stage) => stage.kind === SurveyStageKind.CHOOSING_EXPERTS) || null;
  },

  surveyStageReviewExperts: (state: SurveyState): V1EntitiesSurveysPublicShowStage | null => {
    if (!state.meta) {
      return null;
    }

    return state.meta.stages.find((stage) => stage.kind === SurveyStageKind.REVIEW_EXPERTS) || null;
  },

  surveyStagePassage: (state: SurveyState): V1EntitiesSurveysPublicShowStage | null => {
    if (!state.meta) {
      return null;
    }

    return state.meta.stages.find((stage) => stage.kind === SurveyStageKind.PASSAGE) || null;
  },

  // eslint-disable-next-line arrow-body-style
  isContinuousFeedbackSurvey: (_state: SurveyState, getters: any): boolean => {
    return !!getters.getSurveySettingValue('use_favorite');
  },

  is360Survey: (_state: SurveyState, getters: any): boolean => !getters.isContinuousFeedbackSurvey,

  // eslint-disable-next-line arrow-body-style
  surveyType: (_state: SurveyState, getters: any): SurveyType => {
    return getters.isContinuousFeedbackSurvey
      ? SurveyType.SURVEY_CONTINUOUS_FEEDBACK
      : SurveyType.SURVEY_360;
  },
};

export default surveyGetters;
