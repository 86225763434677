import { render, staticRenderFns } from "./TTModuleMenuV2.vue?vue&type=template&id=3e89f67b&scoped=true&"
import script from "./TTModuleMenuV2.vue?vue&type=script&lang=js&"
export * from "./TTModuleMenuV2.vue?vue&type=script&lang=js&"
import style0 from "./TTModuleMenuV2.vue?vue&type=style&index=0&id=3e89f67b&prod&lang=scss&"
import style1 from "./TTModuleMenuV2.scss?vue&type=style&index=1&id=3e89f67b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e89f67b",
  null
  
)

export default component.exports