import RouteNames from '@/plugins/vue-router/routeNames';

export default {
  name: 'RouteNamesProvider',

  provide: {
    // Note: удаляет Symbol.ESModule, vue-devtools тогда не думает что Names это ES модуль.
    RouteNames: { ...RouteNames },
  },

  render() {
    return this.$slots.default;
  },
};
