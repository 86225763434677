// eslint-disable-next-line import/extensions,import/no-unresolved
import { BreakpointOptions } from 'vuetify/types/services/breakpoint';

const breakpoint: BreakpointOptions = {
  thresholds: {
    xs: 600,
    sm: 1264,
    md: 1440,
    lg: 1920,
  },
  scrollBarWidth: 16,
};

export default breakpoint;
