<template>
  <VToolbarTitle>
    <slot />
  </VToolbarTitle>
</template>
<script>
export default {
  name: 'TTToolbarTitle',
};
</script>

<style scoped lang="scss">

</style>
