// TODO: ts
// @ts-nocheck

// Styles
import './TTAlert.scss';

// Types
import Vue from 'vue';
// TODO: fix lint
// eslint-disable-next-line import/extensions,import/no-unresolved
import { VNode } from 'vue/types';

import { VAlert, VIcon } from 'vuetify/lib';

import Themeable from 'vuetify/lib/mixins/themeable';
import TTBtn from '../TTBtn/TTBtn.vue';

const base = Vue.extend({ name: 'TTAlert', extends: VAlert });
interface options extends InstanceType<typeof base> {}

export default base.extend<options>().extend({
  props: {
    dataTest: {
      type: String,
      default: 'tt-alert',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
    // text есть в VAlert, по-этому content
    content: {
      type: String,
      default: null,
    },
    linkExternal: {
      type: Object,
      default: null,
    },
    linkInternal: {
      type: Object,
      default: null,
    },
    actions: Boolean,
    actionsSettings: {
      type: Array,
      default: () => [],
    },
    dismissible: Boolean,
    type: {
      type: String,
      default: 'info',
      validator(val: string) {
        return ['info', 'error', 'success', 'warning'].includes(val);
      },
    },
    icon: {
      default: () => '',
      type: [String, Boolean],
      validator(val: boolean | string) {
        return typeof val === 'string' || !val;
      },
    },
    notify: Boolean,
    closeIcon: {
      type: String,
      default: '$close',
    },
  },
  computed: {
    cachedIcon(): VNode | null {
      if (!this.computedIcon) return null;

      return this.$createElement(VIcon, {
        staticClass: 'v-alert__icon',
        props: { color: this.iconColor },
      }, this.computedIcon);
    },
    computedIcon(): string | boolean {
      if (this.icon === false) return false;
      if (typeof this.icon === 'string' && this.icon) return this.icon;
      if (!['error', 'info', 'success', 'warning'].includes(this.type)) return false;

      return `$${this.type}Alert`;
    },
    cachedDismissible(): VNode | null {
      if (!this.dismissible || this.actions) return null;

      const color = 'tt-light-mono-100';

      return this.$createElement(
        'TTBtn',
        {
          staticClass: 'tt-alert__dismissible',
          props: {
            color,
            icon: true,
          },
          attrs: {
            'aria-label': this.$vuetify.lang.t(this.closeLabel),
          },
          on: {
            click: () => {
              if (this.notify) {
                this.$emit('close');
              } else {
                this.isActive = false;
              }
            },
          },
        },
        [
          this.$createElement(
            VIcon,
            {
              props: { color },
            },
            this.closeIcon,
          ),
        ],
      );
    },
    cacheActions(): VNode | null {
      if (!this.actions) return null;

      let actions = [];

      if (this.actionsSettings.length !== 0) {
        actions = this.actionsSettings;
      } else if (this.type === 'error') {
        actions = [
          {
            id: 'cancel',
            text: this.$vuetify.lang.t('$vuetify.TTAlert.actions.cancel'),
            props: { color: 'tt-secondary-danger' },
          },
          {
            id: 'confirm',
            text: this.$vuetify.lang.t('$vuetify.TTAlert.actions.delete'),
            props: { color: 'tt-danger' },
          },
        ];
      } else {
        actions = [
          {
            id: 'cancel',
            text: this.$vuetify.lang.t('$vuetify.TTAlert.actions.cancel'),
            props: {
              color: 'tt-ghost', plain: true,
            },
          },
          { id: 'confirm', text: this.$vuetify.lang.t('$vuetify.TTAlert.actions.save') },
        ];
      }

      return this.$createElement(
        'div',
        {
          staticClass: 'd-flex justify-end mt-3',
        },
        actions.map((e) => this.$createElement(
          TTBtn,
          {
            props: e.props,
            staticClass: 'ml-4',
            on: {
              click: () => {
                this.$emit(e.id);
                this.toggle();
              },
            },
          },
          e.text,
        )),
      );
    },
    computedColor(): string {
      return this.color;
    },
    iconColor(): string | undefined {
      if (this.type === 'success') return 'tt-light-green vibrant';
      return this.type ? this.type : undefined;
    },
    isDark(): boolean {
      return Themeable.options.computed.isDark.call(this);
    },
    link(): object | null {
      return this.linkExternal || this.linkInternal;
    },
  },
  methods: {
    genWrapper(): VNode {
      const children = [
        this.$slots.prepend || this.cachedIcon,
        this.genContent(),
        this.$slots.append,
        this.$scopedSlots.close ? this.$scopedSlots.close({ toggle: this.toggle }) : this.cachedDismissible,
      ];

      return this.$createElement('div', {
        staticClass: 'v-alert__wrapper',
      }, children);
    },
    genContent(): VNode {
      return this.$createElement(
        'div',
        {
          staticClass: 'v-alert__content',
        },
        [this.$slots.default || this.genContentFromProps(), this.genActions()],
      );
    },
    genContentFromProps(): Array<VNode | null> {
      return [this.genTitleEl(), this.genContentEl(), this.genLinkEl()];
    },
    genTitleEl(): VNode | null {
      return this.title
        ? this.$createElement('span', { staticClass: 'tt-text-body-1 font-weight-medium' }, this.title)
        : null;
    },
    genContentEl(): VNode | null {
      return this.content
        ? this.$createElement(
          'span',
          { staticClass: `tt-text-body-2 ${this.title ? '' : 'font-weight-medium'}` },
          this.content,
        )
        : null;
    },
    genLinkEl(): VNode | null {
      if (!this.link) return null;
      const staticClass = 'tt-light-blue--text tt-text-body-2 pt-2';
      if (this.linkExternal) {
        return this.$createElement(
          'a',
          {
            staticClass,
            on: {
              click: this.toggle,
            },
            attrs: {
              target: this.link.target || '_blank',
              href: this.link.to,
            },
          },
          this.link.text,
        );
      }
      return this.$createElement(
        'router-link',
        {
          staticClass,
          attrs: {
            to: this.link.to,
            role: 'link',
          },
        },
        [
          this.$createElement(
            'span',
            {
              on: {
                click: () => {
                  if (this.notify) {
                    this.$emit('close');
                  } else {
                    this.isActive = false;
                  }
                },
              },
            },
            this.link.text,
          ),
        ],
      );
    },
    genActions(): VNode | null {
      return this.$scopedSlots.actions ? this.$scopedSlots.actions({ toggle: this.toggle }) : this.cacheActions;
    },
    genAlert(): VNode {
      return this.$createElement('div', {
        staticClass: 'tt-alert v-alert',
        attrs: {
          role: 'alert',
          'data-test': this.dataTest,
          'data-test-label': this.dataTestLabel,
          'data-test-value': this.dataTestValue,
        },
        on: this.listeners$,
        class: this.classes,
        style: this.styles,
        directives: [
          {
            name: 'show',
            value: this.isActive,
          },
        ],
      }, [this.genWrapper()]);
    },
  },
  render(h): VNode {
    const render = this.genAlert();

    if (!this.transition) return render;

    return h('transition', {
      props: {
        name: this.transition,
        origin: this.origin,
        mode: this.mode,
      },
    }, [render]);
  },
});
