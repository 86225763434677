import { V1EntitiesSurveysPublicShow } from '@/services/api/tsxass';
import { AxiosResponse } from 'axios';
import { SurveyState } from './state';
import * as types from './mutationTypes';

export default {
  [types.SET_SURVEY_META](state: SurveyState, payload: V1EntitiesSurveysPublicShow) {
    state.meta = payload;
  },
  [types.SET_SURVEY_META_LOADING](state: SurveyState, isLoading: boolean) {
    state.metaLoading = isLoading;
  },
  [types.SET_SURVEY_META_REQUEST](
    state: SurveyState,
    request: Promise<AxiosResponse<V1EntitiesSurveysPublicShow, any>>,
  ) {
    state.metaRequest = request;
  },
};
