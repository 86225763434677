import { RouteConfig } from 'vue-router';
import { SurveyStageKind } from '@/constants/surveyStage';
import RouteNames from './routeNames';

const routes: RouteConfig[] = [
  {
    path: '/',
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        redirect: { name: RouteNames.R_APP_HOME },
      },
      {
        path: 'errors/401',
        name: RouteNames.R_ERROR_401,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error401.vue'),
      },
      {
        path: 'errors/403',
        name: RouteNames.R_ERROR_403,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error403.vue'),
      },
      {
        path: 'errors/404',
        name: RouteNames.R_ERROR_404,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error404.vue'),
      },
      {
        path: 'errors/500',
        name: RouteNames.R_ERROR_500,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error500.vue'),
      },
    ],
  },

  {
    path: '/external-expert-registration',
    name: RouteNames.R_APP_EXTERNALS_REGISTRATION,
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/ExpertRegistration.vue'),
  },

  {
    path: '/app',
    name: RouteNames.R_APP,
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/PlatformLayout.vue'),
    children: [
      {
        path: 'home',
        name: RouteNames.R_APP_HOME,
        components: {
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Home.vue'),
        },
        meta: {
          hideBackButton: true,
          fixedPadding: true,
        },
      },
      {
        path: 'surveys/:surveyId',
        name: RouteNames.R_APP_SURVEY_ROOT,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavSurvey.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Assessment/SurveyRoot.vue'),
          footer: () => import(/* webpackChunkName "[request]" */ '@/views/app/Assessment/AppInfo.vue'),
        },
        meta: {
          hideBackButton: true,
          fixedPadding: true,
        },
        children: [
          {
            // Страница оценки без выбранного оцениваемого пользователя
            path: 'assessment',
            name: RouteNames.R_APP_ASSESSMENT,
            components: {
              default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Assessment/SurveyeeAssessment.vue'),
            },
            meta: {
              surveyStageKind: SurveyStageKind.PASSAGE,
              hideBackButton: true,
            },
          },
          {
            path: 'review_experts',
            name: RouteNames.R_APP_ASSESSMENT_REVIEW_EXPERTS,
            components: {
              default: () => import(/* webpackChunkName "[request]" */
                '@/views/app/Assessment/AssessmentReviewExperts.vue'
              ),
            },
            meta: {
              surveyStageKind: SurveyStageKind.REVIEW_EXPERTS,
              hideBackButton: true,
            },
          },
          {
            path: 'review_experts/:surveyeeId',
            name: RouteNames.R_APP_ASSESSMENT_REVIEW_EXPERT,
            components: {
              default: () => import(/* webpackChunkName "[request]" */
                '@/views/app/Assessment/AssessmentReviewExpertList.vue'
              ),
            },
            meta: {
              surveyStageKind: SurveyStageKind.REVIEW_EXPERTS,
              hideBackButton: true,
            },
          },
          {
            path: 'couples',
            name: RouteNames.R_APP_ASSESSMENT_COUPLES,
            components: {
              default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Assessment/AssessmentCouples.vue'),
            },
            meta: {
              surveyStageKind: SurveyStageKind.PASSAGE,
              hideBackButton: true,
              isStageFirstPage: true,
            },
          },
          {
            path: 'couples/favorites',
            name: RouteNames.R_APP_ASSESSMENT_COUPLES_FAVORITES,
            components: {
              default: () => import(/* webpackChunkName "[request]" */
                '@/views/app/Assessment/AssessmentCouplesFavorites.vue'
              ),
            },
            meta: {
              surveyStageKind: SurveyStageKind.PASSAGE,
              hideBackButton: true,
              isStageFirstPage: true,
            },
          },
          {
            path: 'couples/experts',
            name: RouteNames.R_APP_ASSESSMENT_COUPLES_EXPERTS,
            components: {
              default: () => import(/* webpackChunkName "[request]" */
                '@/views/app/Assessment/AssessmentExpertSelection.vue'
              ),
            },
            meta: {
              surveyStageKind: SurveyStageKind.CHOOSING_EXPERTS,
              hideBackButton: true,
              isStageFirstPage: true,
            },
          },
          {
            // Страница оценки выбранного оцениваемого пользователя
            path: 'couples/:coupleId/assessment',
            name: RouteNames.R_APP_SURVEYEE_ASSESSMENT,
            components: {
              default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Assessment/SurveyeeAssessment.vue'),
            },
            meta: {
              surveyStageKind: SurveyStageKind.PASSAGE,
              hideBackButton: true,
            },
          },
          {
            path: 'request_has_sent',
            name: RouteNames.R_APP_REQUEST_HAS_SENT,
            components: {
              default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Assessment/RequestHasSent.vue'),
            },
            meta: {
              hideBackButton: true,
            },
          },
          {
            path: 'ask_for_assessment',
            name: RouteNames.R_APP_ASK_FOR_ASSESSMENT,
            components: {
              default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Assessment/AskForAssessment.vue'),
            },
            meta: {
              hideBackButton: true,
            },
          },
        ],
      },
      {
        path: 'surveys/:surveyId/statistics',
        name: RouteNames.R_APP_SURVEY_STATISTICS,
        components: {
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Statistics/SurveyStatistics.vue'),
        },
        meta: {
          fixedPadding: true,
        },
      },
      {
        path: 'user_analytics',
        name: RouteNames.R_APP_USER_ANALYTICS,
        redirect: { name: RouteNames.R_APP_USER_ANALYTICS_ME },
        components: {
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/AnalyticsUser/UserAnalytics.vue'),
        },
        children: [
          {
            path: 'me',
            name: RouteNames.R_APP_USER_ANALYTICS_ME,
          },
          {
            path: 'employee',
            name: RouteNames.R_APP_USER_ANALYTICS_EMPLOYEE,
            redirect: { name: RouteNames.R_APP_USER_ANALYTICS_EMPLOYEE_PIVOT_TABLE },
            children: [
              {
                path: 'pivot_table',
                name: RouteNames.R_APP_USER_ANALYTICS_EMPLOYEE_PIVOT_TABLE,
              },
              {
                path: 'competencies',
                name: RouteNames.R_APP_USER_ANALYTICS_EMPLOYEE_COMPETENCY_COMPARISON,
              },
              {
                path: 'dashboards',
                name: RouteNames.R_APP_USER_ANALYTICS_EMPLOYEE_DASHBOARDS,
              },
            ],
          },
        ],
        meta: {
          fixedPadding: true,
        },
      },
      {
        path: 'analytics/:surveyId',
        name: RouteNames.R_APP_SURVEY_ANALYTICS,
        components: {
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Analytics/SurveyAnalytics.vue'),
        },
        meta: {
          fixedPadding: true,
        },
      },
      {
        path: 'yandex-gpt/:surveyId',
        name: RouteNames.R_APP_GPT_ANALYTICS,
        components: {
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/YandexGpt/YandexGpt.vue'),
        },
        meta: {
          fixedPadding: true,
        },
      },
      {
        path: 'feedback_from_colleagues',
        name: RouteNames.R_APP_FEEDBACK_FROM_COLLEAGUES,
        components: {
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          default: () => import(
            /* webpackChunkName "[request]" */
            '@/views/app/AnalyticsFeedbackFromColleagues/FeedbackFromColleagues.vue'
          ),
        },
        meta: {
          fixedPadding: true,
        },
      },
      {
        path: 'comments_feed',
        name: RouteNames.R_APP_COMMENTS_FEED,
        components: {
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/CommentsFeed.vue'),
        },
        meta: {
          fixedPadding: true,
        },
      },
      {
        path: 'external-expert-letter-view',
        name: RouteNames.R_APP_EXTERNAL_EXPERT_LETTER_VIEW,
        components: {
          default: () => import(
            /* webpackChunkName "[request]" */ '@/views/app/Assessment/ExternalExpertLetterView.vue'
          ),
        },
        meta: {
          hideBackButton: true,
        },
      },
    ],
  },

  {
    path: '/app/analytics/:surveyId/export',
    name: RouteNames.R_APP_SURVEY_ANALYTICS_EXPORT,
    components: {
      default: () => import(/* webpackChunkName "[request]" */ '@/views/app/AnalyticsExport/ExportPdfLayout.vue'),
    },
  },

  {
    path: '/app/analytics/:surveyId/export_v2',
    name: RouteNames.R_APP_SURVEY_ANALYTICS_EXPORT_V2,
    components: {
      default: () => import(/* webpackChunkName "[request]" */ '@/views/app/AnalyticsExport/ExportPdfLayoutV2.vue'),
    },
  },

  {
    path: '*',
    redirect: { name: RouteNames.R_ERROR_404 },
  },
];

export default routes;
