import { AxiosInstance } from 'axios';
import { createAxiosTransport } from '@/services/api/_utils/createAxiosTransport';
import { ProfileApiFactory, Configuration, ProfileApi } from '@/services/api/profile';
import { AdminApiFactory, AdminApi } from '@/services/api/admin';
import { DefaultApiFactory as TsxassApiFactory, DefaultApi as TsxassApi } from '@/services/api/tsxass';
import featureFlagsApi from '@/services/api/feature-flags';

type ApiFactory = (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => any;

function createApi(apiAddressEnvVarName: string, apiFactory: ApiFactory) {
  const apiAddress = process.env[apiAddressEnvVarName] || 'http://localhost:3000';
  const baseURL = apiAddress.replace(/\/$/, '');
  const transport = createAxiosTransport({ baseURL });

  return apiFactory(undefined, baseURL, transport);
}

const profileApi = createApi('VUE_APP_PROFILE_API_ADDRESS', ProfileApiFactory) as ProfileApi;
const adminApi = createApi('VUE_APP_ENGAGEMENT_API_ADDRESS', AdminApiFactory) as AdminApi;
const tsxassApi = createApi('VUE_APP_TSXASS_API_ADDRESS', TsxassApiFactory) as TsxassApi;

export {
  profileApi, adminApi, tsxassApi, featureFlagsApi,
};
