<template>
  <VNavigationDrawer
    ref="menu"
    app
    clipped
    class="tt-module-menu"
    :class="{ 'tt-module-menu--is-mouseover' : drawer }"
    :width="minWidth"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
    mobile-breakpoint="0"
    :style="menuWidth"
    @mouseenter.native="mouseOut = false"
    @mouseleave.native="mouseOut = true"
  >
    <VFadeTransition>
      <div
        v-show="!drawer"
        class="tt-module-menu__expand-menu-arrow"
        data-test="expand-menu"
        @mouseenter="drawer = true"
      >
        <VIcon class="arrow-right tt-light-mono-46--text">
          fal fa-angle-right
        </VIcon>
      </div>
    </VFadeTransition>

    <div class="tt-module-menu-groups">
      <template v-for="(groups, i) in items">
        <VDivider
          v-if="i !== 0"
          :key="generateId(`group-divider-${i}`)"
          class="mx-1"
        />
        <div
          :key="generateId(`group-${i}`)"
          class="tt-module-menu-group"
        >
          <TTTooltip
            v-for="item in groups"
            :key="generateId(`group-${item.title}`)"
            content-class="tt-module-menu-item-tooltip"
            nudge-right="4"
            right
            :close-delay="300"
            :open-delay="300"
            :disabled="drawer || !!item.isParent || !(item.tooltip || item.title)"
            transition="fade-transition"
          >
            <template v-slot:activator="{ on : tooltip, attrs : tooltipAttrs }">
              <VMenu
                right
                open-on-hover
                open-on-click
                offset-x
                nudge-top="4"
                :disabled="!item.isParent"
                close-on-content-click
                transition="fade-transition"
                content-class="tt-module-menu-item-children__wrapper"
                @input="childrenMenuShowing"
              >
                <template v-slot:activator="{ on : menu, attrs : menuAttrs, value }">
                  <VBtn
                    class="tt-module-menu-item"
                    :class="{
                      'tt-module-menu-item__parent' : item.isParent,
                      'v-btn--active' : getActiveValue(item, value),
                      ...item.class
                    }"
                    v-bind="{ ...tooltipAttrs, ...menuAttrs }"
                    text
                    height="48"
                    :to="item.to"
                    :href="item.href"
                    :target="item.target"
                    :exact="item.exact"
                    data-test="tt-module-menu-btn"
                    :data-test-value="item.testValue"
                    :data-test-label="item.testLabel"
                    :disabled="item.disabled"
                    block
                    :ripple="false"
                    v-on="{ ...tooltip, ...menu }"
                    @click="$emit('click', item)"
                  >
                    <BadgeMenu :badge="item.badge">
                      <VIcon>{{ item.icon }}</VIcon>
                    </BadgeMenu>
                    <span
                      v-if="drawer"
                      class="tt-text-caption ml-3 text-truncate text-left"
                      :style="titleWidth"
                    ><div
                       v-if="item.additionalTitle"
                       class="text-truncate"
                     >
                       {{ item.additionalTitle }}
                     </div>
                      <div :class="item.textClass">
                        {{ item.tooltip || item.title }}
                      </div></span>
                    <VIcon
                      v-if="item.isParent"
                      class="arrow-right tt-light-mono-46--text"
                    >
                      fal fa-angle-right
                    </VIcon>
                    <slot
                      name="item-append"
                      v-bind="item"
                    />
                  </VBtn>
                </template>
                <div class="tt-module-menu-item-children__list">
                  <VBtn
                    v-for="children in item.children"
                    :key="generateId(`children-${children.title}`)"
                    class="tt-module-menu-item"
                    :class="children.class"
                    text
                    height="48"
                    :to="children.to"
                    :href="children.href"
                    :target="children.target"
                    :exact="children.exact"
                    :ripple="false"
                    data-test="tt-module-menu-btn-children"
                    :data-test-value="children.testValue"
                    :data-test-label="children.testLabel"
                    @click="$emit('click', children)"
                  >
                    <BadgeMenu :badge="children.badge">
                      <VIcon>
                        {{ children.icon }}
                      </VIcon>
                    </BadgeMenu>

                    <span class="pl-3 text-caption">{{ children.tooltip || children.title }}</span>
                  </VBtn>
                </div>
              </VMenu>
            </template>
            <span class="tt-text-caption">{{ item.tooltip || item.title }}</span>
          </TTTooltip>
        </div>
      </template>
    </div>

    <template
      v-if="prependItems.length > 0"
      #prepend
    >
      <div class="tt-module-menu-groups">
        <template v-for="(groups, i) in prependItems">
          <VDivider
            v-if="i !== 0"
            :key="generateId(`group-prepend-divider-${i}`)"
            class="mx-1"
          />
          <div
            :key="generateId(`group-prepend-${i}`)"
            class="tt-module-menu-group"
          >
            <TTTooltip
              v-for="item in groups"
              :key="generateId(`group-prepend-${item.title}`)"
              content-class="tt-module-menu-item-tooltip"
              nudge-right="4"
              right
              :close-delay="300"
              :open-delay="300"
              :disabled="drawer || !!item.isParent || !(item.tooltip || item.title)"
              transition="fade-transition"
            >
              <template v-slot:activator="{ on : tooltip, attrs : tooltipAttrs }">
                <VMenu
                  right
                  open-on-hover
                  open-on-click
                  offset-x
                  nudge-top="4"
                  :disabled="!item.isParent"
                  close-on-content-click
                  transition="fade-transition"
                  content-class="tt-module-menu-item-children__wrapper"
                  @input="childrenMenuShowing"
                >
                  <template v-slot:activator="{ on : menu, attrs : menuAttrs, value }">
                    <VBtn
                      class="tt-module-menu-item"
                      :class="{
                        'tt-module-menu-item__parent' : item.isParent,
                        'v-btn--active' : getActiveValue(item, value),
                        ...item.class
                      }"
                      v-bind="{ ...tooltipAttrs, ...menuAttrs }"
                      text
                      height="48"
                      :to="item.to"
                      :href="item.href"
                      :target="item.target"
                      :exact="item.exact"
                      data-test="tt-module-menu-btn"
                      :data-test-value="item.testValue"
                      :data-test-label="item.testLabel"
                      :disabled="item.disabled"
                      block
                      :ripple="false"
                      v-on="{ ...tooltip, ...menu }"
                      @click="$emit('click', item)"
                    >
                      <BadgeMenu :badge="item.badge">
                        <VIcon>{{ item.icon }}</VIcon>
                      </BadgeMenu>
                      <span
                        v-if="drawer"
                        class="tt-text-caption ml-3 text-truncate text-left"
                        :style="titleWidth"
                      >
                        <div
                          v-if="item.additionalTitle"
                          class="text-truncate"
                        >
                          {{ item.additionalTitle }}
                        </div>
                        <div :class="item.textClass">
                          {{ item.tooltip || item.title }}
                        </div>
                      </span>
                      <VIcon
                        v-if="item.isParent"
                        class="arrow-right tt-light-mono-46--text"
                      >
                        fal fa-angle-right
                      </VIcon>
                      <slot
                        name="item-append"
                        v-bind="item"
                      />
                    </VBtn>
                  </template>
                  <div class="tt-module-menu-item-children__list">
                    <VBtn
                      v-for="children in item.children"
                      :key="generateId(`children-${children.title}`)"
                      class="tt-module-menu-item"
                      :class="children.class"
                      text
                      height="48"
                      :to="children.to"
                      :href="children.href"
                      :target="children.target"
                      :exact="children.exact"
                      :ripple="false"
                      data-test="tt-module-menu-btn-children"
                      :data-test-value="children.testValue"
                      :data-test-label="children.testLabel"
                      @click="$emit('click', children)"
                    >
                      <BadgeMenu :badge="children.badge">
                        <VIcon>
                          {{ children.icon }}
                        </VIcon>
                      </BadgeMenu>

                      <span class="pl-3 text-caption">{{ children.tooltip || children.title }}</span>
                    </VBtn>
                  </div>
                </VMenu>
              </template>
              <span class="tt-text-caption">{{ item.tooltip || item.title }}</span>
            </TTTooltip>
          </div>
        </template>
      </div>
    </template>

    <template
      v-if="appendItems.length > 0"
      #append
    >
      <div class="tt-module-menu-groups">
        <template v-for="(groups, i) in appendItems">
          <VDivider
            v-if="i !== 0"
            :key="generateId(`group-append-divider-${i}`)"
            class="mx-1"
          />
          <div
            :key="generateId(`group-append-${i}`)"
            class="tt-module-menu-group"
          >
            <TTTooltip
              v-for="item in groups"
              :key="generateId(`group-append-${item.title}`)"
              content-class="tt-module-menu-item-tooltip"
              nudge-right="4"
              right
              :close-delay="300"
              :open-delay="300"
              :disabled="drawer || !!item.isParent || !(item.tooltip || item.title)"
              transition="fade-transition"
            >
              <template v-slot:activator="{ on : tooltip, attrs : tooltipAttrs }">
                <VMenu
                  right
                  open-on-hover
                  open-on-click
                  offset-x
                  nudge-top="4"
                  :disabled="!item.isParent"
                  close-on-content-click
                  transition="fade-transition"
                  content-class="tt-module-menu-item-children__wrapper"
                  @input="childrenMenuShowing"
                >
                  <template v-slot:activator="{ on : menu, attrs : menuAttrs, value }">
                    <VBtn
                      class="tt-module-menu-item"
                      :class="{
                        'tt-module-menu-item__parent' : item.isParent,
                        'v-btn--active' : getActiveValue(item, value),
                        ...item.class
                      }"
                      v-bind="{ ...tooltipAttrs, ...menuAttrs }"
                      text
                      height="48"
                      :to="item.to"
                      :href="item.href"
                      :target="item.target"
                      :exact="item.exact"
                      data-test="tt-module-menu-btn"
                      :data-test-value="item.testValue"
                      :data-test-label="item.testLabel"
                      :disabled="item.disabled"
                      block
                      :ripple="false"
                      v-on="{ ...tooltip, ...menu, ...item.handlers }"
                      @click="$emit('click', item)"
                    >
                      <BadgeMenu :badge="item.badge">
                        <VIcon>{{ item.icon }}</VIcon>
                      </BadgeMenu>
                      <span
                        v-if="drawer"
                        class="tt-text-caption ml-3 text-truncate text-left"
                        :style="titleWidth"
                      >
                        <div
                          v-if="item.additionalTitle"
                          class="text-truncate"
                        >
                          {{ item.additionalTitle }}
                        </div>
                        <div :class="item.textClass">
                          {{ item.tooltip || item.title }}
                        </div>
                      </span>
                      <VIcon
                        v-if="item.isParent"
                        class="arrow-right tt-light-mono-46--text"
                      >
                        fal fa-angle-right
                      </VIcon>
                      <slot
                        name="item-append"
                        v-bind="item"
                      />
                    </VBtn>
                  </template>
                  <div class="tt-module-menu-item-children__list">
                    <VBtn
                      v-for="children in item.children"
                      :key="generateId(`children-${children.title}`)"
                      class="tt-module-menu-item"
                      :class="children.class"
                      text
                      height="48"
                      :to="children.to"
                      :href="children.href"
                      :target="children.target"
                      :exact="children.exact"
                      :ripple="false"
                      data-test="tt-module-menu-btn-children"
                      :data-test-value="children.testValue"
                      :data-test-label="children.testLabel"
                      @click="$emit('click', children)"
                    >
                      <BadgeMenu :badge="children.badge">
                        <VIcon>
                          {{ children.icon }}
                        </VIcon>
                      </BadgeMenu>

                      <span class="pl-3 text-caption">{{ children.tooltip || children.title }}</span>
                    </VBtn>
                  </div>
                </VMenu>
              </template>
              <span class="tt-text-caption">{{ item.tooltip || item.title }}</span>
            </TTTooltip>
          </div>
        </template>
      </div>
    </template>
  </VNavigationDrawer>
</template>

<script>
import BadgeMenu from './components/BadgeMenu.vue';
import TTTooltip from '../TTTooltip/TTTooltip.vue';

const isPrepend = (item) => item.position === 'prepend';
const isDefault = (item) => typeof item.position === 'undefined' || item.position === 'default';
const isAppend = (item) => item.position === 'append';
const groupReducer = (predicat) => (acc, group) => {
  const newGroup = group.filter(predicat);

  if (newGroup.length > 0) {
    acc.push(newGroup);
  }

  return acc;
};

export default {
  name: 'TTModuleMenu',
  components: {
    BadgeMenu,
    TTTooltip,
  },
  props: {
    itemsTree: {
      type: Array,
      default: () => [],
    },
    userName: {
      type: String,
      default: '',
    },
    hideSignOut: {
      type: Boolean,
      default: false,
    },
    signOutText: {
      type: String,
      default: 'Выйти',
    },
    dataTest: {
      type: String,
      default: 'tt-module-menu',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 217,
    },
    // TODO: добавить проп isOpened для постоянно открытого меню.
    // Поможет при тестировании вместо `await wrapper.setData(...);`
  },
  data() {
    return {
      drawer: false,
      minWidth: 81,
      childrenMenuIsShow: false,
      mouseOut: true,
    };
  },
  CLOSE_TIMER: null,
  CLOSE_DELAY: 100,
  computed: {
    menuItems() {
      return this.itemsTree.map((group) => [
        ...group.map((item) => ({
          ...item,
          isParent: item.children && item.children.length > 0,
          badge: item.badge ?? {
            value: item.children && item.children.some((child) => Boolean(child.badge)),
          },
        })),
      ]);
    },

    prependItems() {
      return this.menuItems.reduce(groupReducer(isPrepend), []);
    },

    items() {
      return this.menuItems.reduce(groupReducer(isDefault), []);
    },

    appendItems() {
      const append = this.menuItems.reduce(groupReducer(isAppend), []);

      if (!this.hideSignOut) {
        append.push([
          {
            title: this.signOutText,
            handlers: {
              click: this.onClickSignOutHandler,
            },
            icon: 'fal fa-sign-out-alt',
            testValue: 'sign-out',
            class: {
              'tt-module-menu-sign-out': true,
            },
            additionalTitle: this.userName,
            textClass: {
              'tt-light-red--text': true,
            },
            isParent: false,
            badge: false,
          },
        ]);
      }

      return append;
    },

    menuWidth() {
      const width = this.drawer ? this.maxWidth : this.minWidth;
      return `width: ${width}px;`;
    },

    titleWidth() {
      const width = this.maxWidth - 93;
      return `width: ${width}px;`;
    },

    mouseOver() {
      return !this.mouseOut || this.childrenMenuIsShow;
    },
  },
  watch: {
    mouseOver(v) {
      if (this.drawer) {
        this.setCloseTimer(v);
      }
    },
  },

  methods: {
    generateId(v) {
      return Symbol(v);
    },

    childrenMenuShowing(e) {
      this.childrenMenuIsShow = e;
    },

    setCloseTimer(v) {
      let timer = this.$options.CLOSE_TIMER;
      if (v) {
        clearTimeout(timer);
      } else {
        timer = setTimeout(() => {
          this.drawer = false;
        }, this.$options.CLOSE_DELAY);
      }
    },

    getActiveValue(item, value) {
      return item.isActive ?? value;
    },

    onClickSignOutHandler() {
      this.$emit('sign-out');
    },
  },
};
</script>

<style lang="scss">
.tt-module-menu-item-tooltip {
  padding: 8px 12px;
  font-size: 12px;
  line-height: 100%;
  &:before {
    content: '';
    position: absolute;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    border: 4px solid;
    border-color: transparent rgba(map-get($tt-light-mono-100, 'base'), 0.9) transparent transparent;
  }
  .tt-text-caption {
    line-height: 100%;
  }
}
</style>

<style lang="scss" src="./TTModuleMenu.scss" scoped />
