import { accessToken } from '@/services/cookies';

/*
 * Обязательный interceptor запросов, для установки Authorization заголовка из cookies
 */
async function authorization(config) {
  const token = accessToken.get();

  if (token) {
    const headers = { Authorization: token };

    Object.assign(config.headers, headers);
  }

  return config;
}

export default authorization;
