import acl from './acl';
import profile from './profile';
import survey from './survey';
import featureFlags from './featureFlags';
import engagementInfo from './engagementInfo';

const modules = {
  acl,
  profile,
  survey,
  featureFlags,
  engagementInfo,
};

export default modules;
