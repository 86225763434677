import { helpdeskeddy, usedesk } from '@/plugins/usedesk';
import { LOAD_PROFILE } from '@/plugins/vuex/modules/profile/actionTypes';
import { profileApi } from '@/services/api';
import {
  SET_COMPANIES,
  SET_LICENSES,
  SET_PROFILE,
  SET_CHIEFS,
  SET_TEAMS,
} from '@/plugins/vuex/modules/profile/mutationTypes';
import { FeatureFlags } from '@/helpers/constants';
import { ProfileState } from './state';

type LoadProfilePayload = {
  disableHelpdesk?: boolean
}

let promise: Promise<unknown> | null;

export default {
  [LOAD_PROFILE]({
    state,
    commit,
    rootGetters,
  }: {
    state: ProfileState;
    commit: any;
    rootGetters: any;
  }, payload: LoadProfilePayload = {}): Promise<void> {
    return new Promise((resolve) => {
      if (!state.profile) {
        promise = promise
          || Promise.all([
            profileApi.getProfile(),
            profileApi.getProfileApps(),
            profileApi.getProfileCompanies(),
            profileApi.getProfileChiefs(),
            profileApi.getProfileTeamsWithPeople(),
          ])
            .then(
              ([
                { data: profile },
                { data: apps },
                {
                  data: { companies },
                },
                { data: chiefs },
                {
                  data: { teamsWithPeople },
                },
              ]) => {
                commit(SET_PROFILE, profile);
                commit(SET_LICENSES, apps);
                commit(SET_COMPANIES, companies);
                commit(SET_CHIEFS, chiefs);
                commit(SET_TEAMS, teamsWithPeople);
                if (rootGetters['featureFlags/hasFlag'](FeatureFlags.USE_HELPDESKEDDY)) {
                  if (!payload.disableHelpdesk) { helpdeskeddy(profile); }
                } else if (!payload.disableHelpdesk) { usedesk(profile); }
              },
            )
            .catch(() => { promise = null; })
            .finally(resolve);
      }
      resolve();
    });
  },
};
