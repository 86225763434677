<template>
  <div
    class="tt-input tt-text-field tt-select"
    :class="classesList"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
  >
    <div
      v-if="!!label"
      class="label"
    >
      <label>{{ label }}</label>
    </div>
    <VSelect
      :label="''"
      :menu-props="menuPropsComputed"
      :multiple="multiple"
      v-bind="$props"
      :data-test="`${dataTest}--input`"
      :data-test-label="dataTestLabel"
      :data-test-value="dataTestValue"
      v-on="$listeners"
    >
      <template #append>
        <slot name="append" />
      </template>

      <template #append-item>
        <slot name="append-item" />
      </template>

      <template #append-outer>
        <slot name="append-outer" />
      </template>

      <template #counter="props">
        <slot
          name="counter"
          v-bind="props"
        />
      </template>

      <template #default>
        <slot name="default" />
      </template>

      <template
        v-if="$scopedSlots.item"
        #item="props"
      >
        <slot
          name="item"
          v-bind="props"
        />
      </template>

      <template
        v-if="$scopedSlots.message"
        #message="props"
      >
        <slot
          name="message"
          v-bind="props"
        />
      </template>

      <template #no-data>
        <slot name="no-data" />
      </template>

      <template #prepend>
        <slot name="prepend" />
      </template>

      <template #prepend-inner>
        <slot name="prepend-inner" />
      </template>

      <template #prepend-item>
        <slot name="prepend-item" />
      </template>

      <template #progress>
        <slot name="progress" />
      </template>

      <template
        v-if="$scopedSlots.selection"
        #selection="props"
      >
        <slot
          name="selection"
          v-bind="props"
        />
      </template>
    </VSelect>
  </div>
</template>

<script>
export default {
  name: 'TTSelect',
  props: {
    appendIcon: {
      type: String,
      default: '$dropdown',
    },
    attach: {
      type: [Boolean, String, Object],
      default: true,
    },
    clearable: Boolean,
    cacheItems: Boolean,
    chips: Boolean,
    color: {
      type: String,
      default: 'tt-light-mono-16',
    },
    deletableChips: Boolean,
    disableLookup: Boolean,
    eager: Boolean,
    hideSelected: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      default: 'tt-select',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: undefined,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: [String, Array, Function],
      default: 'text',

    },
    itemValue: {
      type: [String, Array, Function],
      default: 'value',
    },
    error: Boolean,
    errorMessages: {
      type: [Array, String],
      default: () => [],
    },
    hint: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: [String, Boolean],
      default: 'auto',
    },
    label: {
      type: String,
      default: '',
    },
    messages: {
      type: [Array, String],
      default: () => [],
    },
    multiple: Boolean,
    menuProps: {
      type: [Array, String, Object],
      default: () => ({}),
    },
    outlined: Boolean,
    persistentHint: Boolean,
    placeholder: {
      type: String,
      default: '',
    },
    returnObject: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'text',
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
    value: {
      type: undefined,
      default: undefined,
    },
    xSmall: Boolean,
    small: Boolean,
    large: Boolean,
    xLarge: Boolean,
  },
  computed: {
    classesList() {
      return {
        'v-size--x-large': this.xLarge,
        'v-size--large': this.large,
        'v-size--small': this.small,
        'v-size--x-small': this.xSmall,
        'v-size--default': !this.xLarge && !this.large && !this.xSmall && !this.small,
        'with-checkboxes': this.multiple,
      };
    },
    contentClass() {
      let cls = 'tt-select';
      if (this.multiple) cls += ' with-checkboxes';
      return cls;
    },
    menuPropsComputed() {
      return {
        'offset-y': true,
        'content-class': this.contentClass,
        ...this.menuProps,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../TTInput/TTInput";
@import "../TTTextField/TTTextField";
@import "TTSelect";
</style>
