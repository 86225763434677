<template>
  <div id="App">
    <VThemeProvider>
      <RouteNamesProvider>
        <RouterView />
      </RouteNamesProvider>
    </VThemeProvider>
  </div>
</template>

<script>
import RouteNamesProvider from '@/components/providers/RouteNamesProvider';

export default {
  name: 'App',

  components: {
    RouteNamesProvider,
  },

  mounted() {
    document.title = this.$t('appTitle');
  },
};
</script>

<style src="@fortawesome/fontawesome-pro/css/all.css"></style>
<style lang="scss" src="@/styles/main.scss"></style>
