/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import toSnakeCaseRequestTransformer from '@/services/api/_utils/transformers/toSnakeCaseRequestTransformer';
import toCamelCaseResponseTransformer from '@/services/api/_utils/transformers/toCamelCaseResponseTransformer';
import authorization from '@/services/api/_utils/interceptors/authorization';
import companyId from '@/services/api/_utils/interceptors/companyId';
import { onError } from '@/services/api/_utils/interceptors/onError';

/*
 * @see: https://github.com/axios/axios#request-config
 */
function createAxiosTransport({ baseURL = 'http://localhost:3000', ...options } = {}) {
  const transport = axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    // @ts-ignore
    responseEncoding: 'utf8',
    ...options,
  });

  // IMPORTANT: стандартный трансформер запросов конвертит данные в строки,
  // поэтому сперва используй наш трансформер запросов для массивов и объектов
  if (Array.isArray(transport.defaults.transformRequest)) {
    transport.defaults.transformRequest.unshift(toSnakeCaseRequestTransformer);
  }
  if (Array.isArray(transport.defaults.transformResponse)) {
    transport.defaults.transformResponse.push(toCamelCaseResponseTransformer);
  }

  // IMPORTANT: порядок для колбеков ОБРАТНЫЙ !!!
  transport.interceptors.request.use(authorization);
  transport.interceptors.request.use(companyId);

  // IMPORTANT: порядок для коллбеков ПРЯМОЙ !!!
  transport.interceptors.response.use(undefined, onError);

  return transport;
}

export { createAxiosTransport };
