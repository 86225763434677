<template>
  <VBadge
    width="8"
    :value="isActive"
    :color="color"
    class="tt-module-menu-item__badge"
  >
    <slot />
  </VBadge>
</template>

<script>
export default {
  name: 'BadgeMenu',
  props: {
    badge: {
      type: [Boolean, Object],
      default: null,
    },
  },
  computed: {
    isActive() {
      if (typeof this.badge === 'object' && this.badge !== null) return !!this.badge.value;
      return this.badge;
    },
    color() {
      return this.badge?.color ? this.badge?.color : 'tt-light-red vibrant';
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-badge__badge {
  padding: 4px;
  min-width: 8px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
</style>
