import type { VueConstructor } from 'vue';
import VueRouter from 'vue-router';
import createRouter from './router';
import routes from './routes';

function setupRouter(localVue: VueConstructor) {
  localVue.use(VueRouter);

  const router = createRouter({
    base: process.env.BASE_URL,
    routes,
  });

  return router;
}

export { setupRouter };
export type { default as RouteNames } from './routeNames';
