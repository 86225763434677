// @ts-nocheck
// Styles
import './TTRadio.scss';

import Vue from 'vue';
import { VRadio, VIcon } from 'vuetify/lib';

const base = Vue.extend({ name: 'TTRadio', mixins: [VRadio] });
interface options extends InstanceType<typeof base> {}

// TODO: fix ts
// TODO: тесты!!!
export default base.extend<options>().extend({
  props: {
    dataTest: {
      type: String,
      default: 'tt-radio',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    onIcon: {
      type: String,
      default: '$ttRadioOn',
    },
  },

  computed: {
    computedIcon(): string {
      return this.isActive
        ? this.onIcon
        : '';
    },
    computedIconColor() {
      if (this.isDisabled) return 'tt-light-mono-24';
      if (this.color) return this.color;
      if (this.isDark || this.appIsDark) return 'tt-light-mono-0';
      return 'tt-light-blue';
    },
    computedLabelColor() {
      if (this.isDisabled) return 'tt-light-mono-46';
      if (this.isDark || this.appIsDark) return 'tt-light-mono-0';
      return 'tt-light-mono-100';
    },
  },

  mounted() {
    // HACK: VRadio вставляется в слот VInput, от сюда нет другого способа установить
    // атрибуты рут элементу. Поэтому ждем маунта и устанавливаем атрибуты.
    // Смотри дополнительно TTCheckbox.
    this.$el.setAttribute('data-test', this.dataTest);
    this.$el.setAttribute('data-test-label', this.dataTestLabel);
    this.$el.setAttribute('data-test-value', this.dataTestValue);
  },

  methods: {
    genRadio() {
      const classes = {
        ...this.setTextColor(this.computedIconColor).class,
        'align-self-baseline': true,
      };

      return this.$createElement('div', {
        staticClass: 'tt-radio v-input--selection-controls__input',
        class: classes,
      }, [
        this.$createElement(VIcon, this.setTextColor(this.computedIconColor, {
          props: {
            size: 8,
          },
        }), this.computedIcon),
        this.genInput({
          name: this.computedName,
          value: this.value,
          ...this.attrs$,
        }),
      ]);
    },
    genLabel() {
      if (!this.hasLabel) return null;

      return this.$createElement('span', this.setTextColor(this.computedLabelColor, {
        slot: 'label',
        staticClass: 'radio-label tt-text-body-2',
      }), this.$slots.label || this.label);
    },
  },
});
