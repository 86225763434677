/* tslint:disable */
/* eslint-disable */
/**
 * Admin endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Ability
 */
export interface Ability {
    /**
     * Наше название (уникальное)
     * @type {string}
     * @memberof Ability
     */
    title?: string;
    /**
     * Русское название
     * @type {string}
     * @memberof Ability
     */
    name?: string;
}
/**
 * Admin_Analytics_AnalyticsTemplates_Full model
 * @export
 * @interface AdminAnalyticsAnalyticsTemplatesFull
 */
export interface AdminAnalyticsAnalyticsTemplatesFull {
    /**
     * ID темплейта
     * @type {number}
     * @memberof AdminAnalyticsAnalyticsTemplatesFull
     */
    id?: number;
    /**
     * Slug темплейта
     * @type {string}
     * @memberof AdminAnalyticsAnalyticsTemplatesFull
     */
    slug?: string;
    /**
     * дефолтность темплейта
     * @type {boolean}
     * @memberof AdminAnalyticsAnalyticsTemplatesFull
     */
    default?: boolean;
    /**
     * Идентификатор опроса
     * @type {string}
     * @memberof AdminAnalyticsAnalyticsTemplatesFull
     */
    surveySlug?: string;
    /**
     * 
     * @type {AdminAnalyticsAnalyticsTemplatesTabsFull}
     * @memberof AdminAnalyticsAnalyticsTemplatesFull
     */
    tabs?: AdminAnalyticsAnalyticsTemplatesTabsFull;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsAnalyticsTemplatesTabsFull
 */
 export interface AdminAnalyticsAnalyticsTemplatesTabsFull {
    /**
     * Название вкладки
     * @type {string}
     * @memberof AdminAnalyticsAnalyticsTemplatesTabsFull
     */
    title?: string;
    /**
     * Идентификатор вкладки
     * @type {string}
     * @memberof AdminAnalyticsAnalyticsTemplatesTabsFull
     */
    slug?: string;
    /**
     * Порядковый номер вкладки
     * @type {number}
     * @memberof AdminAnalyticsAnalyticsTemplatesTabsFull
     */
    ord?: number;
    /**
     * Видимость вкладки
     * @type {boolean}
     * @memberof AdminAnalyticsAnalyticsTemplatesTabsFull
     */
    visible?: boolean;
    /**
     * Тип визуализации
     * @type {string}
     * @memberof AdminAnalyticsAnalyticsTemplatesTabsFull
     */
    visualizationType?: string;
    /**
     * Настройки визуализации
     * @type {string}
     * @memberof AdminAnalyticsAnalyticsTemplatesTabsFull
     */
    visualizationSettings?: string;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsAnswersDistribution
 */
export interface AdminAnalyticsFlexibleDatasetsAnswersDistribution {
    /**
     * легенда
     * @type {Array<string>}
     * @memberof AdminAnalyticsFlexibleDatasetsAnswersDistribution
     */
    legend?: Array<string>;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsAnswersDistributionQuestions}
     * @memberof AdminAnalyticsFlexibleDatasetsAnswersDistribution
     */
    questions?: AdminAnalyticsFlexibleDatasetsAnswersDistributionQuestions;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsAnswersDistributionQuestions
 */
export interface AdminAnalyticsFlexibleDatasetsAnswersDistributionQuestions {
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsLibTripleValues}
     * @memberof AdminAnalyticsFlexibleDatasetsAnswersDistributionQuestions
     */
    answers?: AdminAnalyticsFlexibleDatasetsLibTripleValues;
    /**
     * Подсказка
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsAnswersDistributionQuestions
     */
    hint?: string;
    /**
     * Главное значение, иногда нужно показывать перед диаграммой
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsAnswersDistributionQuestions
     */
    value?: number;
    /**
     * Текст вопроса
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsAnswersDistributionQuestions
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsFactorComparison
 */
export interface AdminAnalyticsFlexibleDatasetsFactorComparison {
    /**
     * название
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsFactorComparison
     */
    title?: string;
    /**
     * легенда
     * @type {Array<string>}
     * @memberof AdminAnalyticsFlexibleDatasetsFactorComparison
     */
    legend?: Array<string>;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsFactorComparisonGroups}
     * @memberof AdminAnalyticsFlexibleDatasetsFactorComparison
     */
    groups?: AdminAnalyticsFlexibleDatasetsFactorComparisonGroups;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsFactorComparisonGroups
 */
export interface AdminAnalyticsFlexibleDatasetsFactorComparisonGroups {
    /**
     * массив значений в строгом соответствии с легендой
     * @type {Array<number>}
     * @memberof AdminAnalyticsFlexibleDatasetsFactorComparisonGroups
     */
    values?: Array<number>;
    /**
     * Название группы
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsFactorComparisonGroups
     */
    title?: string;
    /**
     * Предыдущее значение
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsFactorComparisonGroups
     */
    parentValue?: number;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsHeatmap
 */
export interface AdminAnalyticsFlexibleDatasetsHeatmap {
    /**
     * Заголовки
     * @type {Array<string>}
     * @memberof AdminAnalyticsFlexibleDatasetsHeatmap
     */
    headers?: Array<string>;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsHeatmapBody}
     * @memberof AdminAnalyticsFlexibleDatasetsHeatmap
     */
    body?: AdminAnalyticsFlexibleDatasetsHeatmapBody;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsHeatmapBody
 */
export interface AdminAnalyticsFlexibleDatasetsHeatmapBody {
    /**
     * Массив значений в строгом соответствии с заголовками
     * @type {Array<string>}
     * @memberof AdminAnalyticsFlexibleDatasetsHeatmapBody
     */
    values?: Array<string>;
    /**
     * Название
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsHeatmapBody
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsIndexComparison
 */
export interface AdminAnalyticsFlexibleDatasetsIndexComparison {
    /**
     * легенда
     * @type {Array<string>}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexComparison
     */
    legend?: Array<string>;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexComparisonGroups}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexComparison
     */
    groups?: AdminAnalyticsFlexibleDatasetsIndexComparisonGroups;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsIndexComparisonGroups
 */
export interface AdminAnalyticsFlexibleDatasetsIndexComparisonGroups {
    /**
     * массив значений в строгом соответствии с легендой
     * @type {Array<number>}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexComparisonGroups
     */
    values?: Array<number>;
    /**
     * Название группы
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexComparisonGroups
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsIndexDynamics
 */
export interface AdminAnalyticsFlexibleDatasetsIndexDynamics {
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexDynamicsSurveys}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexDynamics
     */
    surveys?: AdminAnalyticsFlexibleDatasetsIndexDynamicsSurveys;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexDynamicsPagination}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexDynamics
     */
    pagination?: AdminAnalyticsFlexibleDatasetsIndexDynamicsPagination;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsIndexDynamicsPagination
 */
export interface AdminAnalyticsFlexibleDatasetsIndexDynamicsPagination {
    /**
     * 
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexDynamicsPagination
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsIndexDynamicsSurveys
 */
export interface AdminAnalyticsFlexibleDatasetsIndexDynamicsSurveys {
    /**
     * Идентификатор опроса
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexDynamicsSurveys
     */
    slug?: string;
    /**
     * Дата начала опроса
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexDynamicsSurveys
     */
    startAt?: string;
    /**
     * Название опроса
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexDynamicsSurveys
     */
    title?: string;
    /**
     * Значение по опросу в долях единицы
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexDynamicsSurveys
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsIndexStructure
 */
export interface AdminAnalyticsFlexibleDatasetsIndexStructure {
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsLibTripleValues}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexStructure
     */
    values?: AdminAnalyticsFlexibleDatasetsLibTripleValues;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsIndexValue
 */
export interface AdminAnalyticsFlexibleDatasetsIndexValue {
    /**
     * Изменение индекса относительно предыдущего периода
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexValue
     */
    previousPeriodDiff?: number;
    /**
     * Значение индекса
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexValue
     */
    value?: number;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexValueParentBenchmark}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexValue
     */
    parentBenchmark?: AdminAnalyticsFlexibleDatasetsIndexValueParentBenchmark;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsIndexValueParentBenchmark
 */
export interface AdminAnalyticsFlexibleDatasetsIndexValueParentBenchmark {
    /**
     * Название родительского бенчмарка
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexValueParentBenchmark
     */
    title?: string;
    /**
     * Значение родительского бенчмарка
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsIndexValueParentBenchmark
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsLibTripleValues
 */
export interface AdminAnalyticsFlexibleDatasetsLibTripleValues {
    /**
     * Абсолютное значение
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsLibTripleValues
     */
    absoluteCount?: number;
    /**
     * Название
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsLibTripleValues
     */
    name?: string;
    /**
     * Значение в долях единицы
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsLibTripleValues
     */
    percentCount?: number;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsStatisticsUserSurvey
 */
export interface AdminAnalyticsFlexibleDatasetsStatisticsUserSurvey {
    /**
     * Общее количество
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsStatisticsUserSurvey
     */
    totalCount?: number;
    /**
     * Абсолютное количество
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsStatisticsUserSurvey
     */
    absoluteCount?: number;
    /**
     * Процент участвующих
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsStatisticsUserSurvey
     */
    percentCount?: number;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleDatasetsUserSurveySectionData
 */
export interface AdminAnalyticsFlexibleDatasetsUserSurveySectionData {
    /**
     * Тайтл подразделения
     * @type {string}
     * @memberof AdminAnalyticsFlexibleDatasetsUserSurveySectionData
     */
    title?: string;
    /**
     * Количество ответивших
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsUserSurveySectionData
     */
    answeredCount?: number;
    /**
     * Процент распределения
     * @type {number}
     * @memberof AdminAnalyticsFlexibleDatasetsUserSurveySectionData
     */
    distribution?: number;
}
/**
 * Admin_Analytics_Flexible_Tabs model
 * @export
 * @interface AdminAnalyticsFlexibleTabs
 */
export interface AdminAnalyticsFlexibleTabs {
    /**
     * Название вкладки
     * @type {string}
     * @memberof AdminAnalyticsFlexibleTabs
     */
    title?: string;
    /**
     * Идентификатор вкладки
     * @type {string}
     * @memberof AdminAnalyticsFlexibleTabs
     */
    slug?: string;
    /**
     * Порядковый номер вкладки
     * @type {number}
     * @memberof AdminAnalyticsFlexibleTabs
     */
    ord?: number;
    /**
     * Видимость вкладки
     * @type {boolean}
     * @memberof AdminAnalyticsFlexibleTabs
     */
    visible?: boolean;
    /**
     * Тип визуализации
     * @type {string}
     * @memberof AdminAnalyticsFlexibleTabs
     */
    visualizationType?: string;
}
/**
 * Admin_Analytics_Flexible_Tabs_AdditionalQuestions_Comparison model
 * @export
 * @interface AdminAnalyticsFlexibleTabsAdditionalQuestionsComparison
 */
export interface AdminAnalyticsFlexibleTabsAdditionalQuestionsComparison {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonVisualizationSettings}
     * @memberof AdminAnalyticsFlexibleTabsAdditionalQuestionsComparison
     */
    visualizationSettings?: AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonVisualizationSettings;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonDataset}
     * @memberof AdminAnalyticsFlexibleTabsAdditionalQuestionsComparison
     */
    dataset?: AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonDataset;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonDataset
 */
export interface AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonDataset {
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsHeatmap}
     * @memberof AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonDataset
     */
    heatmap?: AdminAnalyticsFlexibleDatasetsHeatmap;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonVisualizationSettings
 */
export interface AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonVisualizationSettings {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonVisualizationSettingsWidgets}
     * @memberof AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonVisualizationSettings
     */
    widgets?: AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonVisualizationSettingsWidgets;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonVisualizationSettingsWidgets
 */
export interface AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonVisualizationSettingsWidgets {
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsHeatmap}
     * @memberof AdminAnalyticsFlexibleTabsAdditionalQuestionsComparisonVisualizationSettingsWidgets
     */
    heatmap?: AdminAnalyticsFlexibleWidgetsHeatmap;
}
/**
 * Admin_Analytics_Flexible_Tabs_AdditionalQuestions_Main model
 * @export
 * @interface AdminAnalyticsFlexibleTabsAdditionalQuestionsMain
 */
export interface AdminAnalyticsFlexibleTabsAdditionalQuestionsMain {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsAdditionalQuestionsMainVisualizationSettings}
     * @memberof AdminAnalyticsFlexibleTabsAdditionalQuestionsMain
     */
    visualizationSettings?: AdminAnalyticsFlexibleTabsAdditionalQuestionsMainVisualizationSettings;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsAdditionalQuestionsMainDataset}
     * @memberof AdminAnalyticsFlexibleTabsAdditionalQuestionsMain
     */
    dataset?: AdminAnalyticsFlexibleTabsAdditionalQuestionsMainDataset;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsAdditionalQuestionsMainDataset
 */
export interface AdminAnalyticsFlexibleTabsAdditionalQuestionsMainDataset {
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsAnswersDistribution}
     * @memberof AdminAnalyticsFlexibleTabsAdditionalQuestionsMainDataset
     */
    answersDistribution?: AdminAnalyticsFlexibleDatasetsAnswersDistribution;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsAdditionalQuestionsMainVisualizationSettings
 */
export interface AdminAnalyticsFlexibleTabsAdditionalQuestionsMainVisualizationSettings {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsAdditionalQuestionsMainVisualizationSettingsWidgets}
     * @memberof AdminAnalyticsFlexibleTabsAdditionalQuestionsMainVisualizationSettings
     */
    widgets?: AdminAnalyticsFlexibleTabsAdditionalQuestionsMainVisualizationSettingsWidgets;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsAdditionalQuestionsMainVisualizationSettingsWidgets
 */
export interface AdminAnalyticsFlexibleTabsAdditionalQuestionsMainVisualizationSettingsWidgets {
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsAnswersDistribution}
     * @memberof AdminAnalyticsFlexibleTabsAdditionalQuestionsMainVisualizationSettingsWidgets
     */
    answersDistribution?: AdminAnalyticsFlexibleWidgetsAnswersDistribution;
}
/**
 * Admin_Analytics_Flexible_Tabs_MainResults_Main model
 * @export
 * @interface AdminAnalyticsFlexibleTabsMainResultsMain
 */
export interface AdminAnalyticsFlexibleTabsMainResultsMain {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsMainResultsMainVisualizationSettings}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMain
     */
    visualizationSettings?: AdminAnalyticsFlexibleTabsMainResultsMainVisualizationSettings;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsMainResultsMainDataset}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMain
     */
    dataset?: AdminAnalyticsFlexibleTabsMainResultsMainDataset;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsMainResultsMainDataset
 */
export interface AdminAnalyticsFlexibleTabsMainResultsMainDataset {
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexValue}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMainDataset
     */
    satisfactionIndexValue?: AdminAnalyticsFlexibleDatasetsIndexValue;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexValue}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMainDataset
     */
    safetyCultureIndexValue?: AdminAnalyticsFlexibleDatasetsIndexValue;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexValue}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMainDataset
     */
    npsIndexValue?: AdminAnalyticsFlexibleDatasetsIndexValue;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexValue}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMainDataset
     */
    enpsCompanyIndexValue?: AdminAnalyticsFlexibleDatasetsIndexValue;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexValue}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMainDataset
     */
    wellbeingIndexValue?: AdminAnalyticsFlexibleDatasetsIndexValue;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexValue}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMainDataset
     */
    engagementIndexValue?: AdminAnalyticsFlexibleDatasetsIndexValue;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexValue}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMainDataset
     */
    happinessIndexValue?: AdminAnalyticsFlexibleDatasetsIndexValue;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsFactorComparison}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMainDataset
     */
    factorsComparisonsGroup?: AdminAnalyticsFlexibleDatasetsFactorComparison;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsMainResultsMainVisualizationSettings
 */
export interface AdminAnalyticsFlexibleTabsMainResultsMainVisualizationSettings {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsMainResultsMainVisualizationSettingsWidgets}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMainVisualizationSettings
     */
    widgets?: AdminAnalyticsFlexibleTabsMainResultsMainVisualizationSettingsWidgets;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsMainResultsMainVisualizationSettingsWidgets
 */
export interface AdminAnalyticsFlexibleTabsMainResultsMainVisualizationSettingsWidgets {
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsIndexValue}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMainVisualizationSettingsWidgets
     */
    indexValuesGroup?: AdminAnalyticsFlexibleWidgetsIndexValue;
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsFactorComparison}
     * @memberof AdminAnalyticsFlexibleTabsMainResultsMainVisualizationSettingsWidgets
     */
    factorsComparisonsGroup?: AdminAnalyticsFlexibleWidgetsFactorComparison;
}
/**
 * Admin_Analytics_Flexible_Tabs_Respondents_Anonymous model
 * @export
 * @interface AdminAnalyticsFlexibleTabsRespondentsAnonymous
 */
export interface AdminAnalyticsFlexibleTabsRespondentsAnonymous {
    /**
     * Является ли результат анонимным?
     * @type {boolean}
     * @memberof AdminAnalyticsFlexibleTabsRespondentsAnonymous
     */
    isAnonymous?: boolean;
}
/**
 * Admin_Analytics_Flexible_Tabs_SingleIndex_Comparison model
 * @export
 * @interface AdminAnalyticsFlexibleTabsSingleIndexComparison
 */
export interface AdminAnalyticsFlexibleTabsSingleIndexComparison {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSingleIndexComparisonVisualizationSettings}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexComparison
     */
    visualizationSettings?: AdminAnalyticsFlexibleTabsSingleIndexComparisonVisualizationSettings;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSingleIndexComparisonDataset}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexComparison
     */
    dataset?: AdminAnalyticsFlexibleTabsSingleIndexComparisonDataset;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsSingleIndexComparisonDataset
 */
export interface AdminAnalyticsFlexibleTabsSingleIndexComparisonDataset {
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexComparison}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexComparisonDataset
     */
    indexComparison?: AdminAnalyticsFlexibleDatasetsIndexComparison;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsHeatmap}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexComparisonDataset
     */
    heatmap?: AdminAnalyticsFlexibleDatasetsHeatmap;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsSingleIndexComparisonVisualizationSettings
 */
export interface AdminAnalyticsFlexibleTabsSingleIndexComparisonVisualizationSettings {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSingleIndexComparisonVisualizationSettingsWidgets}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexComparisonVisualizationSettings
     */
    widgets?: AdminAnalyticsFlexibleTabsSingleIndexComparisonVisualizationSettingsWidgets;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsSingleIndexComparisonVisualizationSettingsWidgets
 */
export interface AdminAnalyticsFlexibleTabsSingleIndexComparisonVisualizationSettingsWidgets {
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsIndexComparison}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexComparisonVisualizationSettingsWidgets
     */
    indexComparison?: AdminAnalyticsFlexibleWidgetsIndexComparison;
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsHeatmap}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexComparisonVisualizationSettingsWidgets
     */
    heatmap?: AdminAnalyticsFlexibleWidgetsHeatmap;
}
/**
 * Admin_Analytics_Flexible_Tabs_SingleIndex_Main model
 * @export
 * @interface AdminAnalyticsFlexibleTabsSingleIndexMain
 */
export interface AdminAnalyticsFlexibleTabsSingleIndexMain {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettings}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexMain
     */
    visualizationSettings?: AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettings;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSingleIndexMainDataset}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexMain
     */
    dataset?: AdminAnalyticsFlexibleTabsSingleIndexMainDataset;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsSingleIndexMainDataset
 */
export interface AdminAnalyticsFlexibleTabsSingleIndexMainDataset {
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexValue}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexMainDataset
     */
    indexValue?: AdminAnalyticsFlexibleDatasetsIndexValue;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexDynamics}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexMainDataset
     */
    indexDynamics?: AdminAnalyticsFlexibleDatasetsIndexDynamics;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsIndexStructure}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexMainDataset
     */
    indexStructure?: AdminAnalyticsFlexibleDatasetsIndexStructure;
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsAnswersDistribution}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexMainDataset
     */
    answersDistribution?: AdminAnalyticsFlexibleDatasetsAnswersDistribution;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettings
 */
export interface AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettings {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettingsWidgets}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettings
     */
    widgets?: AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettingsWidgets;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettingsWidgets
 */
export interface AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettingsWidgets {
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsIndexValue}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettingsWidgets
     */
    indexValue?: AdminAnalyticsFlexibleWidgetsIndexValue;
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsIndexDynamics}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettingsWidgets
     */
    indexDynamics?: AdminAnalyticsFlexibleWidgetsIndexDynamics;
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsIndexStructure}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettingsWidgets
     */
    indexStructure?: AdminAnalyticsFlexibleWidgetsIndexStructure;
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsAnswersDistribution}
     * @memberof AdminAnalyticsFlexibleTabsSingleIndexMainVisualizationSettingsWidgets
     */
    answersDistribution?: AdminAnalyticsFlexibleWidgetsAnswersDistribution;
}
/**
 * Admin_Analytics_Flexible_Tabs_SurveyPartipicants_Main model
 * @export
 * @interface AdminAnalyticsFlexibleTabsSurveyPartipicantsMain
 */
export interface AdminAnalyticsFlexibleTabsSurveyPartipicantsMain {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSurveyPartipicantsMainVisualizationSettings}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMain
     */
    visualizationSettings?: AdminAnalyticsFlexibleTabsSurveyPartipicantsMainVisualizationSettings;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDataset}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMain
     */
    dataset?: AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDataset;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDataset
 */
export interface AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDataset {
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsStatisticsUserSurvey}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDataset
     */
    statisticsUserSurvey?: AdminAnalyticsFlexibleDatasetsStatisticsUserSurvey;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDataset
     */
    jobAgeSection?: AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDataset
     */
    jobDurationSection?: AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDataset
     */
    jobFunctionSection?: AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDataset
     */
    jobPositionSection?: AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDataset
     */
    childrenCommandSection?: AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection
 */
export interface AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection {
    /**
     * 
     * @type {Array<AdminAnalyticsFlexibleDatasetsUserSurveySectionData>}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection
     */
    data?: Array<AdminAnalyticsFlexibleDatasetsUserSurveySectionData>;
    /**
     * Общее количество участников
     * @type {number}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMainDatasetJobAgeSection
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsSurveyPartipicantsMainVisualizationSettings
 */
export interface AdminAnalyticsFlexibleTabsSurveyPartipicantsMainVisualizationSettings {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsSurveyPartipicantsMainVisualizationSettingsWidgets}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMainVisualizationSettings
     */
    widgets?: AdminAnalyticsFlexibleTabsSurveyPartipicantsMainVisualizationSettingsWidgets;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsSurveyPartipicantsMainVisualizationSettingsWidgets
 */
export interface AdminAnalyticsFlexibleTabsSurveyPartipicantsMainVisualizationSettingsWidgets {
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsStatisticsUserSurvey}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMainVisualizationSettingsWidgets
     */
    statisticsUserSurvey?: AdminAnalyticsFlexibleWidgetsStatisticsUserSurvey;
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsUserSurveySection}
     * @memberof AdminAnalyticsFlexibleTabsSurveyPartipicantsMainVisualizationSettingsWidgets
     */
    userSurveySections?: AdminAnalyticsFlexibleWidgetsUserSurveySection;
}
/**
 * Admin_Analytics_Flexible_Tabs_VerticalFactors_Comparison model
 * @export
 * @interface AdminAnalyticsFlexibleTabsVerticalFactorsComparison
 */
export interface AdminAnalyticsFlexibleTabsVerticalFactorsComparison {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsVerticalFactorsComparisonVisualizationSettings}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsComparison
     */
    visualizationSettings?: AdminAnalyticsFlexibleTabsVerticalFactorsComparisonVisualizationSettings;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsVerticalFactorsComparisonDataset}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsComparison
     */
    dataset?: AdminAnalyticsFlexibleTabsVerticalFactorsComparisonDataset;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsVerticalFactorsComparisonDataset
 */
export interface AdminAnalyticsFlexibleTabsVerticalFactorsComparisonDataset {
    /**
     * 
     * @type {AdminAnalyticsFlexibleDatasetsHeatmap}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsComparisonDataset
     */
    heatmap?: AdminAnalyticsFlexibleDatasetsHeatmap;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsVerticalFactorsComparisonVisualizationSettings
 */
export interface AdminAnalyticsFlexibleTabsVerticalFactorsComparisonVisualizationSettings {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsVerticalFactorsComparisonVisualizationSettingsWidgets}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsComparisonVisualizationSettings
     */
    widgets?: AdminAnalyticsFlexibleTabsVerticalFactorsComparisonVisualizationSettingsWidgets;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsVerticalFactorsComparisonVisualizationSettingsWidgets
 */
export interface AdminAnalyticsFlexibleTabsVerticalFactorsComparisonVisualizationSettingsWidgets {
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsHeatmap}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsComparisonVisualizationSettingsWidgets
     */
    heatmap?: AdminAnalyticsFlexibleWidgetsHeatmap;
}
/**
 * Admin_Analytics_Flexible_Tabs_VerticalFactors_Main model
 * @export
 * @interface AdminAnalyticsFlexibleTabsVerticalFactorsMain
 */
export interface AdminAnalyticsFlexibleTabsVerticalFactorsMain {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsVerticalFactorsMainVisualizationSettings}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsMain
     */
    visualizationSettings?: AdminAnalyticsFlexibleTabsVerticalFactorsMainVisualizationSettings;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsVerticalFactorsMainDataset}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsMain
     */
    dataset?: AdminAnalyticsFlexibleTabsVerticalFactorsMainDataset;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsVerticalFactorsMainDataset
 */
export interface AdminAnalyticsFlexibleTabsVerticalFactorsMainDataset {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsIndexComparison}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsMainDataset
     */
    indexComparison?: AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsIndexComparison;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsGroups
 */
export interface AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsGroups {
    /**
     * массив значений в строгом соответствии с легендой
     * @type {Array<number>}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsGroups
     */
    values?: Array<number>;
    /**
     * Название группы
     * @type {string}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsGroups
     */
    title?: string;
    /**
     * Поле group из ответа topmind
     * @type {string}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsGroups
     */
    group?: string;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsIndexComparison
 */
export interface AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsIndexComparison {
    /**
     * легенда
     * @type {Array<string>}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsIndexComparison
     */
    legend?: Array<string>;
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsGroups}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsIndexComparison
     */
    groups?: AdminAnalyticsFlexibleTabsVerticalFactorsMainDatasetsGroups;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsVerticalFactorsMainVisualizationSettings
 */
export interface AdminAnalyticsFlexibleTabsVerticalFactorsMainVisualizationSettings {
    /**
     * 
     * @type {AdminAnalyticsFlexibleTabsVerticalFactorsMainVisualizationSettingsWidgets}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsMainVisualizationSettings
     */
    widgets?: AdminAnalyticsFlexibleTabsVerticalFactorsMainVisualizationSettingsWidgets;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleTabsVerticalFactorsMainVisualizationSettingsWidgets
 */
export interface AdminAnalyticsFlexibleTabsVerticalFactorsMainVisualizationSettingsWidgets {
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsIndexComparison}
     * @memberof AdminAnalyticsFlexibleTabsVerticalFactorsMainVisualizationSettingsWidgets
     */
    indexComparison?: AdminAnalyticsFlexibleWidgetsIndexComparison;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleWidgetsAnswersDistribution
 */
export interface AdminAnalyticsFlexibleWidgetsAnswersDistribution {
    /**
     * в легенде красим от красного к зелёному цвету или наоборот
     * @type {boolean}
     * @memberof AdminAnalyticsFlexibleWidgetsAnswersDistribution
     */
    areValuesInverted?: boolean;
    /**
     * показывать ли иконку с подсказкой
     * @type {boolean}
     * @memberof AdminAnalyticsFlexibleWidgetsAnswersDistribution
     */
    isHintVisible?: boolean;
    /**
     * рисовать ли все значения со значком \"%\"; растягивать ли прямоугольники на всю ширину
     * @type {boolean}
     * @memberof AdminAnalyticsFlexibleWidgetsAnswersDistribution
     */
    isPercentage?: boolean;
    /**
     * Заголовок
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsAnswersDistribution
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleWidgetsFactorComparison
 */
export interface AdminAnalyticsFlexibleWidgetsFactorComparison {
    /**
     * Заголовок
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsFactorComparison
     */
    title?: string;
    /**
     * Диапазон значений, `от`
     * @type {number}
     * @memberof AdminAnalyticsFlexibleWidgetsFactorComparison
     */
    rangeFrom?: number;
    /**
     * Диапазон значений, `до`
     * @type {number}
     * @memberof AdminAnalyticsFlexibleWidgetsFactorComparison
     */
    rangeTo?: number;
    /**
     * Порядок сортировки
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsFactorComparison
     */
    order?: string;
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsLibZones}
     * @memberof AdminAnalyticsFlexibleWidgetsFactorComparison
     */
    zones?: AdminAnalyticsFlexibleWidgetsLibZones;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleWidgetsHeatmap
 */
export interface AdminAnalyticsFlexibleWidgetsHeatmap {
    /**
     * в легенде красим от красного к зелёному цвету или наоборот
     * @type {boolean}
     * @memberof AdminAnalyticsFlexibleWidgetsHeatmap
     */
    areValuesInverted?: boolean;
    /**
     * Заголовок
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsHeatmap
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleWidgetsIndexComparison
 */
export interface AdminAnalyticsFlexibleWidgetsIndexComparison {
    /**
     * Заголовок
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexComparison
     */
    title?: string;
    /**
     * Диапазон значений, `от`
     * @type {number}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexComparison
     */
    rangeFrom?: number;
    /**
     * Диапазон значений, `до`
     * @type {number}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexComparison
     */
    rangeTo?: number;
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsLibZones}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexComparison
     */
    zones?: AdminAnalyticsFlexibleWidgetsLibZones;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleWidgetsIndexDynamics
 */
export interface AdminAnalyticsFlexibleWidgetsIndexDynamics {
    /**
     * Заголовок
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexDynamics
     */
    title?: string;
    /**
     * Диапазон значений, `от`
     * @type {number}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexDynamics
     */
    rangeFrom?: number;
    /**
     * Диапазон значений, `до`
     * @type {number}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexDynamics
     */
    rangeTo?: number;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleWidgetsIndexStructure
 */
export interface AdminAnalyticsFlexibleWidgetsIndexStructure {
    /**
     * красим от красного к зелёному цвету или наоборот
     * @type {boolean}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexStructure
     */
    areValuesInverted?: boolean;
    /**
     * Заголовок
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexStructure
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleWidgetsIndexValue
 */
export interface AdminAnalyticsFlexibleWidgetsIndexValue {
    /**
     * диапазон зон (от красного к зелёному) считается от конца диапазона к началу или наоборот
     * @type {boolean}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexValue
     */
    areZonesInverted?: boolean;
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsLibBenchmarks}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexValue
     */
    benchmarks?: AdminAnalyticsFlexibleWidgetsLibBenchmarks;
    /**
     * для engagement должен использоваться кастомный хинт
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexValue
     */
    hasEngagementLikeHint?: string;
    /**
     * Подсказка
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexValue
     */
    hint?: string;
    /**
     * Диапазон значений, `от`
     * @type {number}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexValue
     */
    rangeFrom?: number;
    /**
     * Диапазон значений, `до`
     * @type {number}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexValue
     */
    rangeTo?: number;
    /**
     * Заголовок
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexValue
     */
    title?: string;
    /**
     * Имя индекса
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexValue
     */
    indexName?: string;
    /**
     * 
     * @type {AdminAnalyticsFlexibleWidgetsLibZones}
     * @memberof AdminAnalyticsFlexibleWidgetsIndexValue
     */
    zones?: AdminAnalyticsFlexibleWidgetsLibZones;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleWidgetsLibBenchmarks
 */
export interface AdminAnalyticsFlexibleWidgetsLibBenchmarks {
    /**
     * Заголовок бенчмарка
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsLibBenchmarks
     */
    title?: string;
    /**
     * Значение бенчмарка
     * @type {number}
     * @memberof AdminAnalyticsFlexibleWidgetsLibBenchmarks
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleWidgetsLibZones
 */
export interface AdminAnalyticsFlexibleWidgetsLibZones {
    /**
     * Заголовок зоны
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsLibZones
     */
    title?: string;
    /**
     * Диапазон значений зоны
     * @type {Array<number>}
     * @memberof AdminAnalyticsFlexibleWidgetsLibZones
     */
    values?: Array<number>;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleWidgetsStatisticsUserSurvey
 */
export interface AdminAnalyticsFlexibleWidgetsStatisticsUserSurvey {
    /**
     * Заглушка в настоящее время виджет не имеет настроек
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsStatisticsUserSurvey
     */
    stub?: string;
}
/**
 * 
 * @export
 * @interface AdminAnalyticsFlexibleWidgetsUserSurveySection
 */
export interface AdminAnalyticsFlexibleWidgetsUserSurveySection {
    /**
     * Заголовок
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsUserSurveySection
     */
    title?: string;
    /**
     * Имя индекса
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsUserSurveySection
     */
    indexName?: string;
    /**
     * Имя таблицы
     * @type {string}
     * @memberof AdminAnalyticsFlexibleWidgetsUserSurveySection
     */
    tableTitle?: string;
}
/**
 * 
 * @export
 * @interface AdminAreaShortSurveyInfo
 */
export interface AdminAreaShortSurveyInfo {
    /**
     * ID опроса
     * @type {number}
     * @memberof AdminAreaShortSurveyInfo
     */
    id?: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof AdminAreaShortSurveyInfo
     */
    name?: string;
    /**
     * Дата старта опроса
     * @type {string}
     * @memberof AdminAreaShortSurveyInfo
     */
    startAt?: string;
    /**
     * Дата окончания опроса
     * @type {string}
     * @memberof AdminAreaShortSurveyInfo
     */
    finishAt?: string;
    /**
     * Процент завершения
     * @type {number}
     * @memberof AdminAreaShortSurveyInfo
     */
    finishedPercent?: number;
    /**
     * Кол-во дней до завершения опроса
     * @type {number}
     * @memberof AdminAreaShortSurveyInfo
     */
    daysBeforeFinish?: number;
    /**
     * SLUG опроса
     * @type {string}
     * @memberof AdminAreaShortSurveyInfo
     */
    slug?: string;
    /**
     * Тип аналитики
     * @type {string}
     * @memberof AdminAreaShortSurveyInfo
     */
    analyticsKind?: string;
    /**
     * Тип права на просмотр
     * @type {string}
     * @memberof AdminAreaShortSurveyInfo
     */
    viewingRights?: string;
    /**
     * Тип ссылки для прохождения
     * @type {string}
     * @memberof AdminAreaShortSurveyInfo
     */
    userSurveySlugSettings?: string;
    /**
     * ID автора
     * @type {string}
     * @memberof AdminAreaShortSurveyInfo
     */
    authorId?: string;
}
/**
 * AdminArea_SurveysIndex model
 * @export
 * @interface AdminAreaSurveysIndex
 */
export interface AdminAreaSurveysIndex {
    /**
     * 
     * @type {AdminAreaShortSurveyInfo}
     * @memberof AdminAreaSurveysIndex
     */
    drafts?: AdminAreaShortSurveyInfo;
    /**
     * 
     * @type {AdminAreaShortSurveyInfo}
     * @memberof AdminAreaSurveysIndex
     */
    published?: AdminAreaShortSurveyInfo;
    /**
     * 
     * @type {AdminAreaShortSurveyInfo}
     * @memberof AdminAreaSurveysIndex
     */
    active?: AdminAreaShortSurveyInfo;
    /**
     * 
     * @type {AdminAreaShortSurveyInfo}
     * @memberof AdminAreaSurveysIndex
     */
    finished?: AdminAreaShortSurveyInfo;
}
/**
 * Admin_ExternalSurveys_Full model
 * @export
 * @interface AdminExternalSurveysFull
 */
export interface AdminExternalSurveysFull {
    /**
     * ID внешнего опроса
     * @type {number}
     * @memberof AdminExternalSurveysFull
     */
    id?: number;
    /**
     * Дата старта внешнего опроса
     * @type {string}
     * @memberof AdminExternalSurveysFull
     */
    startAt?: string;
    /**
     * Дата завершения внешнего опроса
     * @type {string}
     * @memberof AdminExternalSurveysFull
     */
    finishAt?: string;
    /**
     * Дата и время создания внешнего опроса
     * @type {string}
     * @memberof AdminExternalSurveysFull
     */
    createdAt?: string;
    /**
     * Статус загрузки
     * @type {string}
     * @memberof AdminExternalSurveysFull
     */
    dataUploadingStatus?: string;
    /**
     * Название внешнего опроса
     * @type {string}
     * @memberof AdminExternalSurveysFull
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface AdminSurveysFinalPageBlocks
 */
export interface AdminSurveysFinalPageBlocks {
    /**
     * 
     * @type {string}
     * @memberof AdminSurveysFinalPageBlocks
     */
    body?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminSurveysFinalPageBlocks
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminSurveysFinalPageBlocks
     */
    imageUrl?: string;
    /**
     * Туловище блока на выбранном языке
     * @type {string}
     * @memberof AdminSurveysFinalPageBlocks
     */
    localizedBody?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminSurveysFinalPageBlocks
     */
    ord?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminSurveysFinalPageBlocks
     */
    renderType?: string;
}
/**
 * Admin_Surveys_FinalPages model
 * @export
 * @interface AdminSurveysFinalPages
 */
export interface AdminSurveysFinalPages {
    /**
     * Выбранный язык перевода
     * @type {string}
     * @memberof AdminSurveysFinalPages
     */
    language?: string;
    /**
     * 
     * @type {AdminSurveysFinalPageBlocks}
     * @memberof AdminSurveysFinalPages
     */
    blocks?: AdminSurveysFinalPageBlocks;
}
/**
 * Admin_Surveys_OrgstruturePageSettings model
 * @export
 * @interface AdminSurveysOrgstruturePageSettings
 */
export interface AdminSurveysOrgstruturePageSettings {
    /**
     * 
     * @type {boolean}
     * @memberof AdminSurveysOrgstruturePageSettings
     */
    searchByCode?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminSurveysOrgstruturePageSettings
     */
    searchByLead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminSurveysOrgstruturePageSettings
     */
    onlyFinalNodes?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminSurveysOrgstruturePageSettings
     */
    pageOrder?: number;
}
/**
 * 
 * @export
 * @interface AnalyticsAnswer
 */
export interface AnalyticsAnswer {
    /**
     * Title ответа
     * @type {string}
     * @memberof AnalyticsAnswer
     */
    title?: string;
    /**
     * Название группы ответа
     * @type {string}
     * @memberof AnalyticsAnswer
     */
    name?: string;
    /**
     * Кол-во ответов в абсолюте
     * @type {number}
     * @memberof AnalyticsAnswer
     */
    absoluteCount?: number;
    /**
     * Кол-во ответах в %
     * @type {number}
     * @memberof AnalyticsAnswer
     */
    percentCount?: number;
}
/**
 * 
 * @export
 * @interface AnalyticsBurnoutAnswer
 */
export interface AnalyticsBurnoutAnswer {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsBurnoutAnswer
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsBurnoutAnswer
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsBurnoutAnswer
     */
    percentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsBurnoutAnswer
     */
    absoluteValue?: number;
}
/**
 * 
 * @export
 * @interface AnalyticsBurnoutAspect
 */
export interface AnalyticsBurnoutAspect {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsBurnoutAspect
     */
    value?: number;
    /**
     * 
     * @type {AnalyticsBurnoutQuestion}
     * @memberof AnalyticsBurnoutAspect
     */
    questions?: AnalyticsBurnoutQuestion;
}
/**
 * Analytics_BurnoutData model
 * @export
 * @interface AnalyticsBurnoutData
 */
export interface AnalyticsBurnoutData {
    /**
     * Индекс эмоционального истощения
     * @type {number}
     * @memberof AnalyticsBurnoutData
     */
    psychoemotionalExhaustionIndex?: number;
    /**
     * 
     * @type {AnalyticsExhaustionGroup}
     * @memberof AnalyticsBurnoutData
     */
    notBurned?: AnalyticsExhaustionGroup;
    /**
     * 
     * @type {AnalyticsExhaustionGroup}
     * @memberof AnalyticsBurnoutData
     */
    lowBurned?: AnalyticsExhaustionGroup;
    /**
     * 
     * @type {AnalyticsExhaustionGroup}
     * @memberof AnalyticsBurnoutData
     */
    mediumBurned?: AnalyticsExhaustionGroup;
    /**
     * 
     * @type {AnalyticsExhaustionGroup}
     * @memberof AnalyticsBurnoutData
     */
    wellDone?: AnalyticsExhaustionGroup;
    /**
     * 
     * @type {AnalyticsBurnoutAspect}
     * @memberof AnalyticsBurnoutData
     */
    psychoemotionalExhaustionAspect?: AnalyticsBurnoutAspect;
    /**
     * 
     * @type {AnalyticsBurnoutAspect}
     * @memberof AnalyticsBurnoutData
     */
    personalDistanceAspect?: AnalyticsBurnoutAspect;
    /**
     * 
     * @type {AnalyticsBurnoutAspect}
     * @memberof AnalyticsBurnoutData
     */
    professionalDemotivationAspect?: AnalyticsBurnoutAspect;
    /**
     * 
     * @type {AnalyticsBurnoutQuestion}
     * @memberof AnalyticsBurnoutData
     */
    questionWithoutFactors?: AnalyticsBurnoutQuestion;
    /**
     * 
     * @type {AnalyticsOpenQuestion}
     * @memberof AnalyticsBurnoutData
     */
    openQuestions?: AnalyticsOpenQuestion;
}
/**
 * 
 * @export
 * @interface AnalyticsBurnoutQuestion
 */
export interface AnalyticsBurnoutQuestion {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsBurnoutQuestion
     */
    title?: string;
    /**
     * 
     * @type {AnalyticsBurnoutAnswer}
     * @memberof AnalyticsBurnoutQuestion
     */
    answers?: AnalyticsBurnoutAnswer;
}
/**
 * 
 * @export
 * @interface AnalyticsConstructorAnswerData
 */
export interface AnalyticsConstructorAnswerData {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorAnswerData
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorAnswerData
     */
    percentValue?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorAnswerData
     */
    absoluteValue?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorAnswerData
     */
    score?: string;
}
/**
 * Analytics_ConstructorBlockData model
 * @export
 * @interface AnalyticsConstructorBlockData
 */
export interface AnalyticsConstructorBlockData {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorBlockData
     */
    kind?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorBlockData
     */
    text?: string;
    /**
     * 
     * @type {AnalyticsConstructorQuestionData}
     * @memberof AnalyticsConstructorBlockData
     */
    questions?: AnalyticsConstructorQuestionData;
}
/**
 * 
 * @export
 * @interface AnalyticsConstructorQuestionData
 */
export interface AnalyticsConstructorQuestionData {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorQuestionData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorQuestionData
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorQuestionData
     */
    kind?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorQuestionData
     */
    maxAnswers?: string;
    /**
     * 
     * @type {AnalyticsConstructorQuestionData}
     * @memberof AnalyticsConstructorQuestionData
     */
    subquestions?: AnalyticsConstructorQuestionData;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorQuestionData
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorQuestionData
     */
    otherAnswers?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorQuestionData
     */
    openAnswers?: string;
    /**
     * 
     * @type {AnalyticsConstructorAnswerData}
     * @memberof AnalyticsConstructorQuestionData
     */
    answers?: AnalyticsConstructorAnswerData;
}
/**
 * Analytics_ConstructorTextData model
 * @export
 * @interface AnalyticsConstructorTextData
 */
export interface AnalyticsConstructorTextData {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorTextData
     */
    items?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConstructorTextData
     */
    pagination?: string;
}
/**
 * 
 * @export
 * @interface AnalyticsCsiArea
 */
export interface AnalyticsCsiArea {
    /**
     * Название
     * @type {string}
     * @memberof AnalyticsCsiArea
     */
    title?: string;
    /**
     * Количество ответов
     * @type {number}
     * @memberof AnalyticsCsiArea
     */
    peoplesCount?: number;
    /**
     * 
     * @type {AnalyticsCsiScore}
     * @memberof AnalyticsCsiArea
     */
    score?: AnalyticsCsiScore;
    /**
     * 
     * @type {AnalyticsCsiFactor}
     * @memberof AnalyticsCsiArea
     */
    factors?: AnalyticsCsiFactor;
    /**
     * Комментарии
     * @type {Array<string>}
     * @memberof AnalyticsCsiArea
     */
    comments?: Array<string>;
    /**
     * 
     * @type {AnalyticsCsiProcess}
     * @memberof AnalyticsCsiArea
     */
    processes?: AnalyticsCsiProcess;
}
/**
 * 
 * @export
 * @interface AnalyticsCsiAssesmentValue
 */
export interface AnalyticsCsiAssesmentValue {
    /**
     * Виртуальный идентификатор
     * @type {string}
     * @memberof AnalyticsCsiAssesmentValue
     */
    id?: string;
    /**
     * Название
     * @type {string}
     * @memberof AnalyticsCsiAssesmentValue
     */
    title?: string;
    /**
     * 
     * @type {AnalyticsCsiAssesmentValue}
     * @memberof AnalyticsCsiAssesmentValue
     */
    children?: AnalyticsCsiAssesmentValue;
}
/**
 * Analytics_CsiData model
 * @export
 * @interface AnalyticsCsiData
 */
export interface AnalyticsCsiData {
    /**
     * 
     * @type {AnalyticsCsiFullScore}
     * @memberof AnalyticsCsiData
     */
    score?: AnalyticsCsiFullScore;
    /**
     * 
     * @type {AnalyticsCsiFunction}
     * @memberof AnalyticsCsiData
     */
    functions?: AnalyticsCsiFunction;
    /**
     * 
     * @type {AnalyticsCsiAssesmentValue}
     * @memberof AnalyticsCsiData
     */
    assesmentValues?: AnalyticsCsiAssesmentValue;
}
/**
 * 
 * @export
 * @interface AnalyticsCsiFactor
 */
export interface AnalyticsCsiFactor {
    /**
     * Название
     * @type {string}
     * @memberof AnalyticsCsiFactor
     */
    title?: string;
    /**
     * Значение интегрального расчета
     * @type {number}
     * @memberof AnalyticsCsiFactor
     */
    integral?: number;
    /**
     * Значение индексного расчета
     * @type {number}
     * @memberof AnalyticsCsiFactor
     */
    index?: number;
}
/**
 * 
 * @export
 * @interface AnalyticsCsiFullScore
 */
export interface AnalyticsCsiFullScore {
    /**
     * Значение интегрального расчета среднего балла
     * @type {number}
     * @memberof AnalyticsCsiFullScore
     */
    integral?: number;
    /**
     * Значение индексного расчета среднего балла
     * @type {number}
     * @memberof AnalyticsCsiFullScore
     */
    index?: number;
    /**
     * Стартовое значение шкалы
     * @type {number}
     * @memberof AnalyticsCsiFullScore
     */
    scaleStart?: number;
    /**
     * Конечное значение шкалы
     * @type {number}
     * @memberof AnalyticsCsiFullScore
     */
    scaleFinish?: number;
    /**
     * 
     * @type {AnalyticsCsiFactor}
     * @memberof AnalyticsCsiFullScore
     */
    factors?: AnalyticsCsiFactor;
}
/**
 * 
 * @export
 * @interface AnalyticsCsiFunction
 */
export interface AnalyticsCsiFunction {
    /**
     * Виртуальный ID
     * @type {string}
     * @memberof AnalyticsCsiFunction
     */
    id?: string;
    /**
     * Название
     * @type {string}
     * @memberof AnalyticsCsiFunction
     */
    title?: string;
    /**
     * Количество ответов
     * @type {number}
     * @memberof AnalyticsCsiFunction
     */
    peoplesCount?: number;
    /**
     * 
     * @type {AnalyticsCsiScore}
     * @memberof AnalyticsCsiFunction
     */
    score?: AnalyticsCsiScore;
    /**
     * 
     * @type {AnalyticsCsiFactor}
     * @memberof AnalyticsCsiFunction
     */
    factors?: AnalyticsCsiFactor;
    /**
     * Комментарии
     * @type {Array<string>}
     * @memberof AnalyticsCsiFunction
     */
    comments?: Array<string>;
    /**
     * 
     * @type {AnalyticsCsiArea}
     * @memberof AnalyticsCsiFunction
     */
    areas?: AnalyticsCsiArea;
}
/**
 * 
 * @export
 * @interface AnalyticsCsiProcess
 */
export interface AnalyticsCsiProcess {
    /**
     * Название
     * @type {string}
     * @memberof AnalyticsCsiProcess
     */
    title?: string;
    /**
     * Количество ответов
     * @type {number}
     * @memberof AnalyticsCsiProcess
     */
    peoplesCount?: number;
    /**
     * 
     * @type {AnalyticsCsiScore}
     * @memberof AnalyticsCsiProcess
     */
    score?: AnalyticsCsiScore;
    /**
     * 
     * @type {AnalyticsCsiFactor}
     * @memberof AnalyticsCsiProcess
     */
    factors?: AnalyticsCsiFactor;
    /**
     * Комментарии
     * @type {Array<string>}
     * @memberof AnalyticsCsiProcess
     */
    comments?: Array<string>;
}
/**
 * 
 * @export
 * @interface AnalyticsCsiScore
 */
export interface AnalyticsCsiScore {
    /**
     * Значение интегрального расчета
     * @type {number}
     * @memberof AnalyticsCsiScore
     */
    integral?: number;
    /**
     * Значение индексного расчета
     * @type {number}
     * @memberof AnalyticsCsiScore
     */
    index?: number;
}
/**
 * Analytics_Dashboard model
 * @export
 * @interface AnalyticsDashboard
 */
export interface AnalyticsDashboard {
    /**
     * Суммарное кол-во человек, для которых запущен опрос
     * @type {number}
     * @memberof AnalyticsDashboard
     */
    totalUsers?: number;
    /**
     * Суммарное кол-во человек, которые заполнили опрос
     * @type {number}
     * @memberof AnalyticsDashboard
     */
    totalUserSurveys?: number;
    /**
     * 
     * @type {Array<AnalyticsTeam>}
     * @memberof AnalyticsDashboard
     */
    teams?: Array<AnalyticsTeam>;
    /**
     * Key-value объект для получения локализации answer value
     * @type {object}
     * @memberof AnalyticsDashboard
     */
    answerTitles?: object;
    /**
     * 
     * @type {Survey}
     * @memberof AnalyticsDashboard
     */
    survey?: Survey;
    /**
     * 
     * @type {Array<StringKeyValue>}
     * @memberof AnalyticsDashboard
     */
    jobFunctions?: Array<StringKeyValue>;
    /**
     * 
     * @type {Array<StringKeyValue>}
     * @memberof AnalyticsDashboard
     */
    jobPositions?: Array<StringKeyValue>;
    /**
     * 
     * @type {Array<StringKeyValue>}
     * @memberof AnalyticsDashboard
     */
    jobDurations?: Array<StringKeyValue>;
    /**
     * 
     * @type {AnalyticsTeam}
     * @memberof AnalyticsDashboard
     */
    rootTeam?: AnalyticsTeam;
}
/**
 * 
 * @export
 * @interface AnalyticsExhaustionGroup
 */
export interface AnalyticsExhaustionGroup {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsExhaustionGroup
     */
    percentCount?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsExhaustionGroup
     */
    absoluteCount?: number;
}
/**
 * 
 * @export
 * @interface AnalyticsFactor
 */
export interface AnalyticsFactor {
    /**
     * Название фактора
     * @type {string}
     * @memberof AnalyticsFactor
     */
    name?: string;
    /**
     * Название фактора (title, переведенный в зависимости от локали)
     * @type {string}
     * @memberof AnalyticsFactor
     */
    title?: string;
    /**
     * Вычисленное значение фактора
     * @type {number}
     * @memberof AnalyticsFactor
     */
    score?: number;
    /**
     * Вычисленное значение фактора из предыдущего опроса
     * @type {number}
     * @memberof AnalyticsFactor
     */
    previousScore?: number;
}
/**
 * Analytics_FactorByTeam model
 * @export
 * @interface AnalyticsFactorByTeam
 */
export interface AnalyticsFactorByTeam {
    /**
     * 
     * @type {OrgstructureTeam}
     * @memberof AnalyticsFactorByTeam
     */
    team?: OrgstructureTeam;
    /**
     * 
     * @type {AnalyticsFactor}
     * @memberof AnalyticsFactorByTeam
     */
    factor?: AnalyticsFactor;
}
/**
 * Analytics_FactorDynamic model
 * @export
 * @interface AnalyticsFactorDynamic
 */
export interface AnalyticsFactorDynamic {
    /**
     * 
     * @type {AnalyticsFactor}
     * @memberof AnalyticsFactorDynamic
     */
    factor?: AnalyticsFactor;
    /**
     * 
     * @type {Survey}
     * @memberof AnalyticsFactorDynamic
     */
    survey?: Survey;
}
/**
 * Analytics_FactorGroup model
 * @export
 * @interface AnalyticsFactorGroup
 */
export interface AnalyticsFactorGroup {
    /**
     * Title группы
     * @type {string}
     * @memberof AnalyticsFactorGroup
     */
    title?: string;
    /**
     * Название группы
     * @type {string}
     * @memberof AnalyticsFactorGroup
     */
    name?: string;
    /**
     * Кол-во в абсолюте
     * @type {number}
     * @memberof AnalyticsFactorGroup
     */
    absoluteCount?: number;
    /**
     * Кол-во в %
     * @type {number}
     * @memberof AnalyticsFactorGroup
     */
    percentCount?: number;
}
/**
 * Analytics_Factors model
 * @export
 * @interface AnalyticsFactors
 */
export interface AnalyticsFactors {
    /**
     * Общее кол-во пользователей
     * @type {number}
     * @memberof AnalyticsFactors
     */
    usersCount?: number;
    /**
     * Общее кол-во пользовательских анкет
     * @type {number}
     * @memberof AnalyticsFactors
     */
    usersSurveyCount?: number;
    /**
     * 
     * @type {AnalyticsFactor}
     * @memberof AnalyticsFactors
     */
    factors?: AnalyticsFactor;
}
/**
 * Analytics_FilterValues model
 * @export
 * @interface AnalyticsFilterValues
 */
export interface AnalyticsFilterValues {
    /**
     * 
     * @type {StringKeyValue}
     * @memberof AnalyticsFilterValues
     */
    jobFunctions?: StringKeyValue;
    /**
     * 
     * @type {StringKeyValue}
     * @memberof AnalyticsFilterValues
     */
    jobPositions?: StringKeyValue;
    /**
     * 
     * @type {StringKeyValue}
     * @memberof AnalyticsFilterValues
     */
    jobDurations?: StringKeyValue;
    /**
     * 
     * @type {StringKeyValue}
     * @memberof AnalyticsFilterValues
     */
    factorGroups?: StringKeyValue;
}
/**
 * AnalyticsKind model
 * @export
 * @interface AnalyticsKind
 */
export interface AnalyticsKind {
    /**
     * Английское название
     * @type {string}
     * @memberof AnalyticsKind
     */
    enTitle?: string;
    /**
     * Русское название
     * @type {string}
     * @memberof AnalyticsKind
     */
    ruTitle?: string;
}
/**
 * 
 * @export
 * @interface AnalyticsMetaFilterQuestion
 */
export interface AnalyticsMetaFilterQuestion {
    /**
     * Порядок вопроса
     * @type {number}
     * @memberof AnalyticsMetaFilterQuestion
     */
    ord?: number;
    /**
     * Meta вопроса
     * @type {string}
     * @memberof AnalyticsMetaFilterQuestion
     */
    metakey?: string;
    /**
     * Перевод metakey
     * @type {string}
     * @memberof AnalyticsMetaFilterQuestion
     */
    metakeyI18n?: string;
    /**
     * Доступные значения
     * @type {Array<string>}
     * @memberof AnalyticsMetaFilterQuestion
     */
    values?: Array<string>;
}
/**
 * Analytics_MetaFiltersList model
 * @export
 * @interface AnalyticsMetaFiltersList
 */
export interface AnalyticsMetaFiltersList {
    /**
     * 
     * @type {AnalyticsMetaFilterQuestion}
     * @memberof AnalyticsMetaFiltersList
     */
    filters?: AnalyticsMetaFilterQuestion;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsMetaFiltersList
     */
    metafilters?: string;
}
/**
 * 
 * @export
 * @interface AnalyticsOpenQuestion
 */
export interface AnalyticsOpenQuestion {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsOpenQuestion
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsOpenQuestion
     */
    answers?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsOpenQuestion
     */
    questionId?: string;
}
/**
 * 
 * @export
 * @interface AnalyticsPriorityFactor
 */
export interface AnalyticsPriorityFactor {
    /**
     * Название фактора
     * @type {string}
     * @memberof AnalyticsPriorityFactor
     */
    name?: string;
    /**
     * Название фатора (русское)
     * @type {string}
     * @memberof AnalyticsPriorityFactor
     */
    title?: string;
    /**
     * Значение фактора
     * @type {number}
     * @memberof AnalyticsPriorityFactor
     */
    score?: number;
    /**
     * Дельта факторов (для сортировки)
     * @type {number}
     * @memberof AnalyticsPriorityFactor
     */
    delta?: number;
    /**
     * 
     * @type {HrPracticeShort}
     * @memberof AnalyticsPriorityFactor
     */
    practices?: HrPracticeShort;
}
/**
 * Analytics_PriorityFactorsData model
 * @export
 * @interface AnalyticsPriorityFactorsData
 */
export interface AnalyticsPriorityFactorsData {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsPriorityFactorsData
     */
    usersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsPriorityFactorsData
     */
    usersSurveyCount?: number;
    /**
     * 
     * @type {AnalyticsPriorityFactor}
     * @memberof AnalyticsPriorityFactorsData
     */
    priorityFactors?: AnalyticsPriorityFactor;
}
/**
 * Analytics_Question model
 * @export
 * @interface AnalyticsQuestion
 */
export interface AnalyticsQuestion {
    /**
     * ID вопроса
     * @type {number}
     * @memberof AnalyticsQuestion
     */
    id?: number;
    /**
     * Заголовок вопроса
     * @type {string}
     * @memberof AnalyticsQuestion
     */
    title?: string;
    /**
     * Левый заголовок вопроса
     * @type {string}
     * @memberof AnalyticsQuestion
     */
    leftTitle?: string;
    /**
     * Правый заголовок вопроса
     * @type {string}
     * @memberof AnalyticsQuestion
     */
    rightTitle?: string;
    /**
     * Тип вопроса
     * @type {string}
     * @memberof AnalyticsQuestion
     */
    questionType?: AnalyticsQuestionQuestionType;
    /**
     * 
     * @type {Array<AnalyticsAnswer>}
     * @memberof AnalyticsQuestion
     */
    answers?: Array<AnalyticsAnswer>;
    /**
     * Кол-во ответов на вопрос
     * @type {number}
     * @memberof AnalyticsQuestion
     */
    answersCount?: number;
}

export const AnalyticsQuestionQuestionType = {
    DICHOTOMY_5: 'dichotomy_5',
    DICHOTOMY_7: 'dichotomy_7',
    DICHOTOMY_WITH_POLAR_AFFIRMATION: 'dichotomy_with_polar_affirmation',
    LIKERT_6: 'likert_6',
    LIKERT_7: 'likert_7',
    OPEN: 'open',
    ORG_STRUCTURE: 'org_structure',
    NPS: 'nps',
    UPDATE_USER_SURVEY: 'update_user_survey',
    MONO: 'mono',
    POLY: 'poly',
    SCALE: 'scale',
    LIST: 'list',
    HORIZONTAL_BLOCK: 'horizontal_block',
    MATRIX: 'matrix'
} as const;

export type AnalyticsQuestionQuestionType = typeof AnalyticsQuestionQuestionType[keyof typeof AnalyticsQuestionQuestionType];

/**
 * Analytics_Questions_Answers model
 * @export
 * @interface AnalyticsQuestionsAnswers
 */
 export interface AnalyticsQuestionsAnswers {
    /**
     * Тема
     * @type {string}
     * @memberof AnalyticsQuestionsAnswers
     */
    theme?: string;
    /**
     * Кол-во отфильтрованных ответов на указанный вопрос
     * @type {number}
     * @memberof AnalyticsQuestionsAnswers
     */
    absoluteCount?: number;
    /**
     * 
     * @type {Array<AnalyticsQuestionsComment>}
     * @memberof AnalyticsQuestionsAnswers
     */
    comments?: Array<AnalyticsQuestionsComment>;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsQuestionsAnswers
     */
    pagination?: string;
}
/**
 * Analytics_Questions_Clustering model
 * @export
 * @interface AnalyticsQuestionsClustering
 */
export interface AnalyticsQuestionsClustering {
    /**
     * Тема
     * @type {string}
     * @memberof AnalyticsQuestionsClustering
     */
    theme?: string;
    /**
     * 
     * @type {Array<AnalyticsQuestionsSentiment>}
     * @memberof AnalyticsQuestionsClustering
     */
    sentiments?: Array<AnalyticsQuestionsSentiment>;
}
/**
 * 
 * @export
 * @interface AnalyticsQuestionsComment
 */
export interface AnalyticsQuestionsComment {
    /**
     * Ответ на вопрос
     * @type {string}
     * @memberof AnalyticsQuestionsComment
     */
    body?: string;
    /**
     * ID user_answer\'a
     * @type {number}
     * @memberof AnalyticsQuestionsComment
     */
    id?: number;
    /**
     * Сентимент ответа
     * @type {string}
     * @memberof AnalyticsQuestionsComment
     */
    sentiment?: string;
    /**
     * Старт сентимента
     * @type {number}
     * @memberof AnalyticsQuestionsComment
     */
    start?: number;
    /**
     * Финиш сентимента
     * @type {number}
     * @memberof AnalyticsQuestionsComment
     */
    finish?: number;
}
/**
 * Analytics_Questions_Comments model
 * @export
 * @interface AnalyticsQuestionsComments
 */
export interface AnalyticsQuestionsComments {
    /**
     * Общее кол-во комментариев по вопросу
     * @type {number}
     * @memberof AnalyticsQuestionsComments
     */
    totalCommentsCount?: number;
    /**
     * 
     * @type {Array<AnalyticsQuestionsSentiment>}
     * @memberof AnalyticsQuestionsComments
     */
    sentiments?: Array<AnalyticsQuestionsSentiment>;
}
/**
 * 
 * @export
 * @interface AnalyticsQuestionsSentiment
 */
export interface AnalyticsQuestionsSentiment {
    /**
     * Сентимент ответа
     * @type {string}
     * @memberof AnalyticsQuestionsSentiment
     */
    title?: string;
    /**
     * Локализированный сентимент ответа
     * @type {string}
     * @memberof AnalyticsQuestionsSentiment
     */
    titleLocalized?: string;
    /**
     * Кол-во ответах указанного сентимента в %
     * @type {number}
     * @memberof AnalyticsQuestionsSentiment
     */
    percent?: number;
    /**
     * Кол-во ответов указанного сентимента в абсолюте
     * @type {number}
     * @memberof AnalyticsQuestionsSentiment
     */
    absoluteCount?: number;
}
/**
 * Analytics_Surveys_RawDataReports_Small model
 * @export
 * @interface AnalyticsSurveysRawDataReportsSmall
 */
export interface AnalyticsSurveysRawDataReportsSmall {
    /**
     * ID отчета
     * @type {number}
     * @memberof AnalyticsSurveysRawDataReportsSmall
     */
    id?: number;
    /**
     * Статус отчета
     * @type {string}
     * @memberof AnalyticsSurveysRawDataReportsSmall
     */
    status?: string;
    /**
     * Ссылка для скачивания файла
     * @type {string}
     * @memberof AnalyticsSurveysRawDataReportsSmall
     */
    downloadLink?: string;
}
/**
 * Analytics_Team model
 * @export
 * @interface AnalyticsTeam
 */
export interface AnalyticsTeam {
    /**
     * ID команды
     * @type {string}
     * @memberof AnalyticsTeam
     */
    id?: string;
    /**
     * Название команды
     * @type {string}
     * @memberof AnalyticsTeam
     */
    title?: string;
    /**
     * Кол-во штатных должностей в команде
     * @type {number}
     * @memberof AnalyticsTeam
     */
    totalUsers?: number;
    /**
     * Кол-во людей в команде
     * @type {number}
     * @memberof AnalyticsTeam
     */
    totalPeople?: number;
    /**
     * Кол-во людей заполнивших опрос
     * @type {number}
     * @memberof AnalyticsTeam
     */
    totalUserSurveys?: number;
    /**
     * 
     * @type {Array<AnalyticsFactor>}
     * @memberof AnalyticsTeam
     */
    factors?: Array<AnalyticsFactor>;
    /**
     * 
     * @type {Array<AnalyticsTiming>}
     * @memberof AnalyticsTeam
     */
    timings?: Array<AnalyticsTiming>;
    /**
     * Есть ли у команды дети
     * @type {boolean}
     * @memberof AnalyticsTeam
     */
    hasChildren?: boolean;
    /**
     * Название команды родителя
     * @type {string}
     * @memberof AnalyticsTeam
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsTeam
     */
    fakesData?: string;
}
/**
 * 
 * @export
 * @interface AnalyticsTiming
 */
export interface AnalyticsTiming {
    /**
     * Дата за которую были заполнены опросы
     * @type {string}
     * @memberof AnalyticsTiming
     */
    date?: string;
    /**
     * Кол-во заполненных опросов за конкретную дату (из поля date)
     * @type {number}
     * @memberof AnalyticsTiming
     */
    count?: number;
}
/**
 * Analytics_TimingsByTeam model
 * @export
 * @interface AnalyticsTimingsByTeam
 */
export interface AnalyticsTimingsByTeam {
    /**
     * 
     * @type {OrgstructureTeam}
     * @memberof AnalyticsTimingsByTeam
     */
    team?: OrgstructureTeam;
    /**
     * 
     * @type {AnalyticsTiming}
     * @memberof AnalyticsTimingsByTeam
     */
    timings?: AnalyticsTiming;
}
/**
 * Answer model
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     * ID ответа
     * @type {number}
     * @memberof Answer
     */
    id?: number;
    /**
     * Текст ответа
     * @type {string}
     * @memberof Answer
     */
    text?: string;
    /**
     * Локализованный текст ответа
     * @type {string}
     * @memberof Answer
     */
    localizedText?: string;
    /**
     * Оценка ответа
     * @type {number}
     * @memberof Answer
     */
    score?: number;
    /**
     * Порядок ответа (для сортировки)
     * @type {number}
     * @memberof Answer
     */
    ord?: number;
    /**
     * ID следующей страницы при ветвлении
     * @type {number}
     * @memberof Answer
     */
    nextPageId?: number;
}
/**
 * 
 * @export
 * @interface AnswerTemplate
 */
export interface AnswerTemplate {
    /**
     * ID ответа
     * @type {number}
     * @memberof AnswerTemplate
     */
    id?: number;
    /**
     * Текст ответа
     * @type {string}
     * @memberof AnswerTemplate
     */
    text?: string;
    /**
     * Оценка ответа
     * @type {number}
     * @memberof AnswerTemplate
     */
    score?: number;
    /**
     * Порядок ответа (для сортировки)
     * @type {number}
     * @memberof AnswerTemplate
     */
    ord?: number;
}
/**
 * Block model
 * @export
 * @interface Block
 */
export interface Block {
    /**
     * ID блока
     * @type {number}
     * @memberof Block
     */
    id?: number;
    /**
     * Порядок
     * @type {number}
     * @memberof Block
     */
    ord?: number;
    /**
     * Тип блока
     * @type {string}
     * @memberof Block
     */
    renderType?: string;
    /**
     * Идентификатор страницы
     * @type {number}
     * @memberof Block
     */
    pageId?: number;
    /**
     * Текстовый контент
     * @type {string}
     * @memberof Block
     */
    body?: string;
    /**
     * Локализованный текстовый контент
     * @type {string}
     * @memberof Block
     */
    localizedBody?: string;
    /**
     * Заголовок блока
     * @type {string}
     * @memberof Block
     */
    title?: string;
    /**
     * Локализованный заголовок
     * @type {string}
     * @memberof Block
     */
    localizedTitle?: string;
    /**
     * УРЛ картинки
     * @type {string}
     * @memberof Block
     */
    imageUrl?: string;
    /**
     * 
     * @type {Question}
     * @memberof Block
     */
    questions?: Question;
}
/**
 * CalculatedCompanyHistoricalDatum model
 * @export
 * @interface CalculatedCompanyHistoricalDatum
 */
export interface CalculatedCompanyHistoricalDatum {
    /**
     * Название фактора
     * @type {string}
     * @memberof CalculatedCompanyHistoricalDatum
     */
    factorName?: string;
    /**
     * Минимальное значение
     * @type {number}
     * @memberof CalculatedCompanyHistoricalDatum
     */
    min?: number;
    /**
     * Максимальное значение
     * @type {number}
     * @memberof CalculatedCompanyHistoricalDatum
     */
    max?: number;
    /**
     * Среднее значение
     * @type {number}
     * @memberof CalculatedCompanyHistoricalDatum
     */
    avg?: number;
}
/**
 * ComplexReport model
 * @export
 * @interface ComplexReport
 */
export interface ComplexReport {
    /**
     * ID отчета
     * @type {number}
     * @memberof ComplexReport
     */
    id?: number;
    /**
     * Название отчета
     * @type {string}
     * @memberof ComplexReport
     */
    title?: string;
    /**
     * Название отчета
     * @type {string}
     * @memberof ComplexReport
     */
    analyticsKind?: string;
    /**
     * Русское название типа аналитики отчета
     * @type {string}
     * @memberof ComplexReport
     */
    ruAnalyticsKind?: string;
    /**
     * Slug отчета
     * @type {string}
     * @memberof ComplexReport
     */
    slug?: string;
    /**
     * Описание отчета
     * @type {string}
     * @memberof ComplexReport
     */
    description?: string;
    /**
     * UUID компании
     * @type {string}
     * @memberof ComplexReport
     */
    companyUuid?: string;
    /**
     * Дата создания отчета
     * @type {string}
     * @memberof ComplexReport
     */
    createdAt?: string;
}
/**
 * ComplexReportFull model
 * @export
 * @interface ComplexReportFull
 */
export interface ComplexReportFull {
    /**
     * ID отчета
     * @type {number}
     * @memberof ComplexReportFull
     */
    id?: number;
    /**
     * Название отчета
     * @type {string}
     * @memberof ComplexReportFull
     */
    title?: string;
    /**
     * Название типа аналитики отчета
     * @type {string}
     * @memberof ComplexReportFull
     */
    analyticsKind?: string;
    /**
     * Русское название типа аналитики отчета
     * @type {string}
     * @memberof ComplexReportFull
     */
    ruAnalyticsKind?: string;
    /**
     * Slug отчета
     * @type {string}
     * @memberof ComplexReportFull
     */
    slug?: string;
    /**
     * Описание отчета
     * @type {string}
     * @memberof ComplexReportFull
     */
    description?: string;
    /**
     * UUID компании
     * @type {string}
     * @memberof ComplexReportFull
     */
    companyUuid?: string;
    /**
     * Дата создания отчета
     * @type {string}
     * @memberof ComplexReportFull
     */
    createdAt?: string;
    /**
     * 
     * @type {User}
     * @memberof ComplexReportFull
     */
    users?: User;
    /**
     * 
     * @type {SurveyShort}
     * @memberof ComplexReportFull
     */
    surveys?: SurveyShort;
}
/**
 * EmailTemplate model
 * @export
 * @interface EmailTemplate
 */
export interface EmailTemplate {
    /**
     * ID шаблона
     * @type {number}
     * @memberof EmailTemplate
     */
    id?: number;
    /**
     * Заголовок
     * @type {string}
     * @memberof EmailTemplate
     */
    header?: string;
    /**
     * Тело письма
     * @type {string}
     * @memberof EmailTemplate
     */
    body?: string;
    /**
     * Тема письма
     * @type {string}
     * @memberof EmailTemplate
     */
    subject?: string;
    /**
     * Название шаблона
     * @type {string}
     * @memberof EmailTemplate
     */
    templateName?: string;
    /**
     * Тип шаблона
     * @type {string}
     * @memberof EmailTemplate
     */
    templateType?: string;
    /**
     * URL картинки для изображения (может быть null)
     * @type {string}
     * @memberof EmailTemplate
     */
    imageUrl?: string;
}
/**
 * ExpressSurveys_MainResultsEntity_BusinessUnitsEntity model
 * @export
 * @interface ExpressSurveysMainResultsEntityBusinessUnitsEntity
 */
export interface ExpressSurveysMainResultsEntityBusinessUnitsEntity {
    /**
     * Выбранный уровень
     * @type {string}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntity
     */
    level?: string;
    /**
     * 
     * @type {ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexes}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntity
     */
    mainIndexes?: ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexes;
    /**
     * 
     * @type {ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestions}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntity
     */
    indexesQuestions?: ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestions;
}
/**
 * Подраздел \"Вопросы индексов\"
 * @export
 * @interface ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestions
 */
export interface ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestions {
    /**
     * Заголовок раздела
     * @type {string}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestions
     */
    title?: string;
    /**
     * 
     * @type {ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexes}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestions
     */
    indexes?: ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexes;
}
/**
 * Основные индексы
 * @export
 * @interface ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexes
 */
export interface ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexes {
    /**
     * Название индекса
     * @type {string}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexes
     */
    title?: string;
    /**
     * Названия факторов индекса
     * @type {Array<string>}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexes
     */
    factorNames?: Array<string>;
    /**
     * 
     * @type {ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeams}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexes
     */
    teams?: ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeams;
}
/**
 * Команды, соответствующие выбранному уровню; отсортированы по названию
 * @export
 * @interface ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeams
 */
export interface ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeams {
    /**
     * Название команды
     * @type {string}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeams
     */
    title?: string;
    /**
     * UUID команды
     * @type {string}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeams
     */
    externalId?: string;
    /**
     * 
     * @type {ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeamsFactors}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeams
     */
    factors?: ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeamsFactors;
}
/**
 * Факторы индекса
 * @export
 * @interface ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeamsFactors
 */
export interface ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeamsFactors {
    /**
     * Название фактора
     * @type {string}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeamsFactors
     */
    title?: string;
    /**
     * Значение фактора для команды
     * @type {number}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityIndexesQuestionsIndexesTeamsFactors
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexes
 */
export interface ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexes {
    /**
     * Заголовок раздела
     * @type {string}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexes
     */
    title?: string;
    /**
     * 
     * @type {ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexes}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexes
     */
    indexes?: ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexes;
}
/**
 * Основные индексы
 * @export
 * @interface ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexes
 */
export interface ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexes {
    /**
     * Название индекса
     * @type {string}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexes
     */
    title?: string;
    /**
     * Диапазон значений
     * @type {number}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexes
     */
    range?: number;
    /**
     * 
     * @type {ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexesTeams}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexes
     */
    teams?: ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexesTeams;
}
/**
 * Команды, соответствующие выбранному уровню; отсортированы по значению индекса от большего к меньшему
 * @export
 * @interface ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexesTeams
 */
export interface ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexesTeams {
    /**
     * Название команды
     * @type {string}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexesTeams
     */
    title?: string;
    /**
     * UUID команды
     * @type {string}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexesTeams
     */
    externalId?: string;
    /**
     * Значение индекса для команды
     * @type {number}
     * @memberof ExpressSurveysMainResultsEntityBusinessUnitsEntityMainIndexesIndexesTeams
     */
    value?: number;
}
/**
 * Factor model
 * @export
 * @interface Factor
 */
export interface Factor {
    /**
     * ID фактора
     * @type {number}
     * @memberof Factor
     */
    id?: number;
    /**
     * Английское название
     * @type {string}
     * @memberof Factor
     */
    name?: string;
    /**
     * Русское название
     * @type {string}
     * @memberof Factor
     */
    title?: string;
    /**
     * Доступные типы вопросов
     * @type {Array<string>}
     * @memberof Factor
     */
    availableQuestionTypes?: Array<string>;
    /**
     * Доступное максимальное количество ответов
     * @type {number}
     * @memberof Factor
     */
    maxAnswers?: number;
    /**
     * Настройки обязательности ответа
     * @type {boolean}
     * @memberof Factor
     */
    questionRequired?: boolean;
    /**
     * Название группы факторов
     * @type {string}
     * @memberof Factor
     */
    factorsGroupTitle?: string;
    /**
     * 
     * @type {QuestionTemplate}
     * @memberof Factor
     */
    questionTemplates?: QuestionTemplate;
}
/**
 * 
 * @export
 * @interface FactorShort
 */
export interface FactorShort {
    /**
     * ID фактора
     * @type {number}
     * @memberof FactorShort
     */
    id?: number;
    /**
     * UUID компании
     * @type {string}
     * @memberof FactorShort
     */
    companyId?: string;
    /**
     * Русское название
     * @type {string}
     * @memberof FactorShort
     */
    title?: string;
    /**
     * Название иконки фактора
     * @type {string}
     * @memberof FactorShort
     */
    icon?: string;
    /**
     * Формулировка вопроса
     * @type {string}
     * @memberof FactorShort
     */
    questionText?: string;
    /**
     * Можно ли удалять фактор
     * @type {boolean}
     * @memberof FactorShort
     */
    deletable?: boolean;
}
/**
 * FactorsGroup model
 * @export
 * @interface FactorsGroup
 */
export interface FactorsGroup {
    /**
     * ID группы
     * @type {number}
     * @memberof FactorsGroup
     */
    id?: number;
    /**
     * UUID компании
     * @type {string}
     * @memberof FactorsGroup
     */
    companyId?: string;
    /**
     * Название группы
     * @type {string}
     * @memberof FactorsGroup
     */
    title?: string;
    /**
     * 
     * @type {FactorShort}
     * @memberof FactorsGroup
     */
    factors?: FactorShort;
}
/**
 * FactorsGroupAsIndex model
 * @export
 * @interface FactorsGroupAsIndex
 */
export interface FactorsGroupAsIndex {
    /**
     * Название
     * @type {string}
     * @memberof FactorsGroupAsIndex
     */
    title?: string;
    /**
     * Кол-во вопросов в индексе
     * @type {number}
     * @memberof FactorsGroupAsIndex
     */
    questionsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof FactorsGroupAsIndex
     */
    factors?: string;
}
/**
 * Feature model
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * Название
     * @type {string}
     * @memberof Feature
     */
    name?: string;
    /**
     * Активная?
     * @type {boolean}
     * @memberof Feature
     */
    active?: boolean;
}
/**
 * FullSurvey model
 * @export
 * @interface FullSurvey
 */
export interface FullSurvey {
    /**
     * ID опроса
     * @type {number}
     * @memberof FullSurvey
     */
    id?: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof FullSurvey
     */
    surveyName?: string;
    /**
     * Языки опроса
     * @type {Array<string>}
     * @memberof FullSurvey
     */
    languages?: Array<string>;
    /**
     * Базовый язык опроса
     * @type {string}
     * @memberof FullSurvey
     */
    defaultLanguage?: string;
    /**
     * Статус опроса
     * @type {string}
     * @memberof FullSurvey
     */
    status?: string;
    /**
     * Идентификатор опроса
     * @type {string}
     * @memberof FullSurvey
     */
    slug?: string;
    /**
     * UUID команд, для которых запущен опрос
     * @type {Array<string>}
     * @memberof FullSurvey
     */
    teamUids?: Array<string>;
    /**
     * Дата-время старта опроса
     * @type {string}
     * @memberof FullSurvey
     */
    startAt?: string;
    /**
     * Дата-время окончания опроса
     * @type {string}
     * @memberof FullSurvey
     */
    finishAt?: string;
    /**
     * Дата-время создания опроса
     * @type {string}
     * @memberof FullSurvey
     */
    createdAt?: string;
    /**
     * Дата-время обновления опроса
     * @type {string}
     * @memberof FullSurvey
     */
    updatedAt?: string;
    /**
     * Является ли опрос анонимным
     * @type {boolean}
     * @memberof FullSurvey
     */
    anonymous?: boolean;
    /**
     * Численность из мета
     * @type {boolean}
     * @memberof FullSurvey
     */
    useMetaTeamMembersCount?: boolean;
    /**
     * Работа с некачественными анкетами
     * @type {boolean}
     * @memberof FullSurvey
     */
    detectFakes?: boolean;
    /**
     * Описание опроса
     * @type {string}
     * @memberof FullSurvey
     */
    description?: string;
    /**
     * УРЛ картинки опроса
     * @type {string}
     * @memberof FullSurvey
     */
    imageUrl?: string;
    /**
     * Идентификатор предыдущего опроса
     * @type {string}
     * @memberof FullSurvey
     */
    previousSurveySlug?: string;
    /**
     * Настройки ссылки для прохождения
     * @type {string}
     * @memberof FullSurvey
     */
    userSurveySlugSettings?: string;
    /**
     * 
     * @type {Page}
     * @memberof FullSurvey
     */
    pages?: Page;
    /**
     * 
     * @type {SurveyNotificationSetting}
     * @memberof FullSurvey
     */
    notificationSettings?: SurveyNotificationSetting;
    /**
     * Периодичность повторов
     * @type {string}
     * @memberof FullSurvey
     */
    repeatPeriod?: string;
    /**
     * Количество повторов
     * @type {number}
     * @memberof FullSurvey
     */
    repeatTimes?: number;
    /**
     * 
     * @type {SurveyRepeat}
     * @memberof FullSurvey
     */
    repeats?: SurveyRepeat;
    /**
     * 
     * @type {SurveyTimelineItem}
     * @memberof FullSurvey
     */
    timelineItems?: SurveyTimelineItem;
    /**
     * 
     * @type {EmailTemplate}
     * @memberof FullSurvey
     */
    emailTemplates?: EmailTemplate;
    /**
     * Тип шаблона для старте опроса
     * @type {string}
     * @memberof FullSurvey
     */
    startTemplateType?: string;
    /**
     * Тип шаблона для напоминания об опросе
     * @type {string}
     * @memberof FullSurvey
     */
    reminderTemplateType?: string;
    /**
     * Тип шаблона для напоминания об опросе(анонимный)
     * @type {string}
     * @memberof FullSurvey
     */
    unsubscribeReminderTemplateType?: string;
    /**
     * 
     * @type {string}
     * @memberof FullSurvey
     */
    contentEditable?: string;
    /**
     * 
     * @type {string}
     * @memberof FullSurvey
     */
    teams?: string;
    /**
     * 
     * @type {OrgstructurePage}
     * @memberof FullSurvey
     */
    orgstructurePage?: OrgstructurePage;
    /**
     * Настройка прав просмтра аналитики
     * @type {string}
     * @memberof FullSurvey
     */
    viewingRights?: string;
    /**
     * 
     * @type {SurveyMetaQuestion}
     * @memberof FullSurvey
     */
    surveyMetaQuestions?: SurveyMetaQuestion;
    /**
     * UUID автора опроса
     * @type {string}
     * @memberof FullSurvey
     */
    authorId?: string;
}
/**
 * 
 * @export
 * @interface GetAdminTeamsIdsMergeRequest
 */
export interface GetAdminTeamsIdsMergeRequest {
    /**
     * ID команд
     * @type {Array<string>}
     * @memberof GetAdminTeamsIdsMergeRequest
     */
    'team_ids': Array<string>;
}
/**
  * Heatmap model
 * @export
 * @interface Heatmap
 */
export interface Heatmap {
    /**
     * ID тепловой карты
     * @type {number}
     * @memberof Heatmap
     */
    id?: number;
    /**
     * Статус тепловой карты
     * @type {string}
     * @memberof Heatmap
     */
    status?: string;
    /**
     * ID команд
     * @type {string}
     * @memberof Heatmap
     */
    teamUids?: string;
    /**
     * Названия команд
     * @type {string}
     * @memberof Heatmap
     */
    teamNames?: string;
    /**
     * 
     * @type {HeatmapRow}
     * @memberof Heatmap
     */
    rows?: HeatmapRow;
}
/**
 * 
 * @export
 * @interface HeatmapCell
 */
export interface HeatmapCell {
    /**
     * ID
     * @type {number}
     * @memberof HeatmapCell
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HeatmapCell
     */
    teamId?: string;
    /**
     * 
     * @type {string}
     * @memberof HeatmapCell
     */
    teamName?: string;
    /**
     * 
     * @type {string}
     * @memberof HeatmapCell
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface HeatmapRow
 */
export interface HeatmapRow {
    /**
     * Порядок
     * @type {number}
     * @memberof HeatmapRow
     */
    ord?: number;
    /**
     * Заголовок строки
     * @type {string}
     * @memberof HeatmapRow
     */
    title?: string;
    /**
     * 
     * @type {HeatmapCell}
     * @memberof HeatmapRow
     */
    values?: HeatmapCell;
    /**
     * 
     * @type {HeatmapRow}
     * @memberof HeatmapRow
     */
    children?: HeatmapRow;
}
/**
 * HistoricalDataForSurvey model
 * @export
 * @interface HistoricalDataForSurvey
 */
export interface HistoricalDataForSurvey {
    /**
     * 
     * @type {string}
     * @memberof HistoricalDataForSurvey
     */
    pagination?: string;
    /**
     * 
     * @type {HistoricalSurvey}
     * @memberof HistoricalDataForSurvey
     */
    surveys?: HistoricalSurvey;
}
/**
 * 
 * @export
 * @interface HistoricalSurvey
 */
export interface HistoricalSurvey {
    /**
     * Название опроса
     * @type {string}
     * @memberof HistoricalSurvey
     */
    name?: string;
    /**
     * Идентификатор опроса
     * @type {string}
     * @memberof HistoricalSurvey
     */
    slug?: string;
    /**
     * Дата-время старта опроса
     * @type {string}
     * @memberof HistoricalSurvey
     */
    startAt?: string;
    /**
     * Дата-время окончания опроса
     * @type {string}
     * @memberof HistoricalSurvey
     */
    finishAt?: string;
    /**
     * 
     * @type {AnalyticsFactor}
     * @memberof HistoricalSurvey
     */
    factors?: AnalyticsFactor;
}
/**
 * HrPracticeFull model
 * @export
 * @interface HrPracticeFull
 */
export interface HrPracticeFull {
    /**
     * ID практики
     * @type {number}
     * @memberof HrPracticeFull
     */
    id?: number;
    /**
     * Заголовок
     * @type {string}
     * @memberof HrPracticeFull
     */
    title?: string;
    /**
     * Краткое описание
     * @type {string}
     * @memberof HrPracticeFull
     */
    shortDescription?: string;
    /**
     * Oписание
     * @type {string}
     * @memberof HrPracticeFull
     */
    description?: string;
    /**
     * URL картинки для изображения (может быть null)
     * @type {string}
     * @memberof HrPracticeFull
     */
    imageUrl?: string;
    /**
     * Ресурсы необходимые для реализации
     * @type {string}
     * @memberof HrPracticeFull
     */
    cost?: string;
    /**
     * Ответственные
     * @type {string}
     * @memberof HrPracticeFull
     */
    responsible?: string;
    /**
     * На кого направлено
     * @type {string}
     * @memberof HrPracticeFull
     */
    recipients?: string;
    /**
     * Где можно применять
     * @type {string}
     * @memberof HrPracticeFull
     */
    applicationScope?: string;
    /**
     * Периодичность
     * @type {string}
     * @memberof HrPracticeFull
     */
    period?: string;
    /**
     * Важно
     * @type {string}
     * @memberof HrPracticeFull
     */
    notes?: string;
    /**
     * 
     * @type {HrPracticeShort}
     * @memberof HrPracticeFull
     */
    commonPractices?: HrPracticeShort;
    /**
     * Списко тегов
     * @type {Array<string>}
     * @memberof HrPracticeFull
     */
    tags?: Array<string>;
    /**
     * Практика видна для компании?
     * @type {boolean}
     * @memberof HrPracticeFull
     */
    visible?: boolean;
    /**
     * ID автора практики
     * @type {string}
     * @memberof HrPracticeFull
     */
    authorId?: string;
    /**
     * ID последнего редактирующего юзера
     * @type {string}
     * @memberof HrPracticeFull
     */
    lastUpdatedBy?: string;
}
/**
 * 
 * @export
 * @interface HrPracticeShort
 */
export interface HrPracticeShort {
    /**
     * ID практики
     * @type {number}
     * @memberof HrPracticeShort
     */
    id?: number;
    /**
     * Заголовок
     * @type {string}
     * @memberof HrPracticeShort
     */
    title?: string;
    /**
     * Краткое описание
     * @type {string}
     * @memberof HrPracticeShort
     */
    shortDescription?: string;
    /**
     * URL картинки для изображения (может быть null)
     * @type {string}
     * @memberof HrPracticeShort
     */
    imageUrl?: string;
    /**
     * Список тегов
     * @type {Array<string>}
     * @memberof HrPracticeShort
     */
    tags?: Array<string>;
    /**
     * Практика видна для компании?
     * @type {boolean}
     * @memberof HrPracticeShort
     */
    visible?: boolean;
    /**
     * Источник практики
     * @type {string}
     * @memberof HrPracticeShort
     */
    source?: string;
}
/**
 * ImageTemplate model
 * @export
 * @interface ImageTemplate
 */
export interface ImageTemplate {
    /**
     * ID шаблона
     * @type {number}
     * @memberof ImageTemplate
     */
    id?: number;
    /**
     * УРЛ картинки
     * @type {string}
     * @memberof ImageTemplate
     */
    imageUrl?: string;
}
/**
 * Me model
 * @export
 * @interface Me
 */
export interface Me {
    /**
     * ID пользователя
     * @type {number}
     * @memberof Me
     */
    id?: number;
    /**
     * Внешний UUID пользователя
     * @type {string}
     * @memberof Me
     */
    userExternalId?: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof Me
     */
    firstName?: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof Me
     */
    middleName?: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof Me
     */
    lastName?: string;
    /**
     * URL аватарки пользователя
     * @type {string}
     * @memberof Me
     */
    avatarUrl?: string;
    /**
     * Должности пользователя
     * @type {Array<string>}
     * @memberof Me
     */
    staffPositions?: Array<string>;
    /**
     * Команды пользователя
     * @type {Array<string>}
     * @memberof Me
     */
    teams?: Array<string>;
    /**
     * Абилки пользователя
     * @type {Array<string>}
     * @memberof Me
     */
    abilities?: Array<string>;
    /**
     * Роли пользователя
     * @type {Array<string>}
     * @memberof Me
     */
    roles?: Array<string>;
}
/**
 * MetaInfo model
 * @export
 * @interface MetaInfo
 */
export interface MetaInfo {
    /**
     * Название (en)
     * @type {string}
     * @memberof MetaInfo
     */
    key?: string;
    /**
     * Название (ru)
     * @type {string}
     * @memberof MetaInfo
     */
    value?: string;
}
/**
 * Error model
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    message?: string;
}
/**
 * NotificationReminder model
 * @export
 * @interface NotificationReminder
 */
export interface NotificationReminder {
    /**
     * ID нотификации
     * @type {number}
     * @memberof NotificationReminder
     */
    id?: number;
    /**
     * ID компании
     * @type {string}
     * @memberof NotificationReminder
     */
    surveyId?: string;
    /**
     * ID команды
     * @type {string}
     * @memberof NotificationReminder
     */
    teamId?: string;
    /**
     * Scope уведомления
     * @type {string}
     * @memberof NotificationReminder
     */
    scope?: string;
    /**
     * Дата-время создания
     * @type {string}
     * @memberof NotificationReminder
     */
    createdAt?: string;
    /**
     * Дата-время обновления
     * @type {string}
     * @memberof NotificationReminder
     */
    updatedAt?: string;
}
/**
 * OrgstructureBlock model
 * @export
 * @interface OrgstructureBlock
 */
export interface OrgstructureBlock {
    /**
     * ID блока
     * @type {number}
     * @memberof OrgstructureBlock
     */
    id?: number;
    /**
     * Заголовок вопроса
     * @type {string}
     * @memberof OrgstructureBlock
     */
    questionTitle?: string;
    /**
     * Локализованный заголовок вопроса
     * @type {string}
     * @memberof OrgstructureBlock
     */
    questionTitleLocalized?: string;
    /**
     * Подсказка вопроса
     * @type {string}
     * @memberof OrgstructureBlock
     */
    questionHint?: string;
    /**
     * Локализованная подсказка вопроса
     * @type {string}
     * @memberof OrgstructureBlock
     */
    questionHintLocalized?: string;
    /**
     * Плейсхолдер селекта
     * @type {string}
     * @memberof OrgstructureBlock
     */
    selectPlaceholder?: string;
    /**
     * Локализованный плейсхолдер селекта
     * @type {string}
     * @memberof OrgstructureBlock
     */
    selectPlaceholderLocalized?: string;
    /**
     * Подсказка селекта
     * @type {string}
     * @memberof OrgstructureBlock
     */
    selectHint?: string;
    /**
     * Локализованная подсказка селекта
     * @type {string}
     * @memberof OrgstructureBlock
     */
    selectHintLocalized?: string;
}
/**
 * OrgstructurePage model
 * @export
 * @interface OrgstructurePage
 */
export interface OrgstructurePage {
    /**
     * Выбранный язык
     * @type {string}
     * @memberof OrgstructurePage
     */
    language?: string;
    /**
     * Идентификатор предыдущего опроса
     * @type {string}
     * @memberof OrgstructurePage
     */
    surveySlug?: string;
    /**
     * 
     * @type {OrgstructureBlock}
     * @memberof OrgstructurePage
     */
    blocks?: OrgstructureBlock;
}
/**
 * Orgstructure_Parent model
 * @export
 * @interface OrgstructureParent
 */
export interface OrgstructureParent {
    /**
     * ID родителя
     * @type {string}
     * @memberof OrgstructureParent
     */
    parentId?: string;
}
/**
 * Orgstructure_Parents model
 * @export
 * @interface OrgstructureParents
 */
export interface OrgstructureParents {
    /**
     * Имя команды
     * @type {string}
     * @memberof OrgstructureParents
     */
    name?: string;
    /**
     * 
     * @type {OrgstructureParents}
     * @memberof OrgstructureParents
     */
    parent?: OrgstructureParents;
}
/**
 * Orgstructure_ShortTeam model
 * @export
 * @interface OrgstructureShortTeam
 */
export interface OrgstructureShortTeam {
    /**
     * ID команды
     * @type {string}
     * @memberof OrgstructureShortTeam
     */
    id?: string;
    /**
     * Имя команды
     * @type {string}
     * @memberof OrgstructureShortTeam
     */
    name?: string;
}
/**
 * Orgstructure_Team model
 * @export
 * @interface OrgstructureTeam
 */
export interface OrgstructureTeam {
    /**
     * ID команды
     * @type {string}
     * @memberof OrgstructureTeam
     */
    id?: string;
    /**
     * Имя команды
     * @type {string}
     * @memberof OrgstructureTeam
     */
    name?: string;
    /**
     * Имя команды для отображения
     * @type {string}
     * @memberof OrgstructureTeam
     */
    displayName?: string;
    /**
     * Тим команды
     * @type {string}
     * @memberof OrgstructureTeam
     */
    teamType?: string;
    /**
     * ID компании
     * @type {string}
     * @memberof OrgstructureTeam
     */
    companyId?: string;
    /**
     * Внешний ID команды
     * @type {string}
     * @memberof OrgstructureTeam
     */
    externalId?: string;
    /**
     * Путь команды до родителя
     * @type {Array<string>}
     * @memberof OrgstructureTeam
     */
    path?: Array<string>;
    /**
     * Кол-во человек в команде
     * @type {number}
     * @memberof OrgstructureTeam
     */
    staffPositionsCounter?: number;
    /**
     * Кол-во человек в команде с учетом потомков
     * @type {number}
     * @memberof OrgstructureTeam
     */
    staffPositionsWithDescendantsCounter?: number;
    /**
     * Есть ли дети у укоманды
     * @type {boolean}
     * @memberof OrgstructureTeam
     */
    hasChildren?: boolean;
    /**
     * Имя родителя
     * @type {string}
     * @memberof OrgstructureTeam
     */
    parentName?: string;
    /**
     * Имя родителя для отображения
     * @type {string}
     * @memberof OrgstructureTeam
     */
    parentDisplayName?: string;
    /**
     * ID родителя
     * @type {string}
     * @memberof OrgstructureTeam
     */
    parentId?: string;
    /**
     * Дочерние команды
     * @type {Array<OrgstructureTeam>}
     * @memberof OrgstructureTeam
     */
    children?: Array<OrgstructureTeam>;
    /**
     * 
     * @type {string}
     * @memberof OrgstructureTeam
     */
    fakesData?: string;
}
/**
 * Page model
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * ID страницы
     * @type {number}
     * @memberof Page
     */
    id?: number;
    /**
     * Текстовый контент
     * @type {string}
     * @memberof Page
     */
    title?: string;
    /**
     * Локализованный текстовый контент
     * @type {string}
     * @memberof Page
     */
    localizedTitle?: string;
    /**
     * Порядок
     * @type {number}
     * @memberof Page
     */
    ord?: number;
    /**
     * Идентификатор опроса
     * @type {number}
     * @memberof Page
     */
    surveyId?: number;
    /**
     * ID следующей страницы при прохождении
     * @type {number}
     * @memberof Page
     */
    nextPageId?: number;
    /**
     * Статус ветвления на странице
     * @type {string}
     * @memberof Page
     */
    branchingStatus?: string;
    /**
     * 
     * @type {Block}
     * @memberof Page
     */
    blocks?: Block;
}
/**
 * 
 * @export
 * @interface PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest
 */
export interface PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest {
    /**
     * ID команд
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest
     */
    teamIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface PostAdminAnalyticsSurveysRawDataReportsExportRequest
 */
export interface PostAdminAnalyticsSurveysRawDataReportsExportRequest {
    /**
     * IDs опросов для которых генерируется отчет; не менее 1 и не более 100
     * @type {Array<number>}
     * @memberof PostAdminAnalyticsSurveysRawDataReportsExportRequest
     */
    surveyIds: Array<number>;
}
/**
 * 
 * @export
 * @interface PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest
 */
export interface PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest {
    /**
     * ID команд
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest
     */
    teamIds?: Array<string>;
    /**
     * Рабочие функции
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest
     */
    jobFunctions?: Array<string>;
    /**
     * Рабочие должности
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest
     */
    jobPositions?: Array<string>;
    /**
     * Сроки работы
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest
     */
    jobDurations?: Array<string>;
    /**
     * Рабочие функции
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest
     */
    jobFunction?: Array<string>;
    /**
     * Рабочие должности
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest
     */
    jobPosition?: Array<string>;
    /**
     * Сроки работы
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest
     */
    jobDuration?: Array<string>;
}
/**
 * 
 * @export
 * @interface PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest
 */
export interface PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest {
    /**
     * ID команд
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest
     */
    teamIds: Array<string>;
    /**
     * Рабочие функции
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest
     */
    jobFunctions?: Array<string>;
    /**
     * Рабочие должности
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest
     */
    jobPositions?: Array<string>;
    /**
     * Сроки работы
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest
     */
    jobDurations?: Array<string>;
    /**
     * Рабочие функции
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest
     */
    jobFunction?: Array<string>;
    /**
     * Рабочие должности
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest
     */
    jobPosition?: Array<string>;
    /**
     * Сроки работы
     * @type {Array<string>}
     * @memberof PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest
     */
    jobDuration?: Array<string>;
}
/**
 * 
 * @export
 * @interface PostAdminBlocksRequest
 */
export interface PostAdminBlocksRequest {
    /**
     * ID страницы
     * @type {number}
     * @memberof PostAdminBlocksRequest
     */
    pageId: number;
    /**
     * Тип блока
     * @type {string}
     * @memberof PostAdminBlocksRequest
     */
    renderType: PostAdminBlocksRequestRenderType;
}

export const PostAdminBlocksRequestRenderType = {
    PLAIN: 'plain',
    MATRIX: 'matrix',
    QUESTION: 'question',
    IMAGE: 'image',
    TEXT: 'text'
} as const;

export type PostAdminBlocksRequestRenderType = typeof PostAdminBlocksRequestRenderType[keyof typeof PostAdminBlocksRequestRenderType];

/**
 * 
 * @export
 * @interface PostAdminBranchingSettingsRequest
 */
export interface PostAdminBranchingSettingsRequest {
    /**
     * ID вопроса
     * @type {number}
     * @memberof PostAdminBranchingSettingsRequest
     */
    questionId: number;
    /**
     * Обязательность вопроса
     * @type {boolean}
     * @memberof PostAdminBranchingSettingsRequest
     */
    questionRequired: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostAdminBranchingSettingsRequest
     */
    'answers[id]'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostAdminBranchingSettingsRequest
     */
    'answers[nextPageId]'?: Array<number>;
    /**
     * ID следующей страницы (для страницы опроса)
     * @type {number}
     * @memberof PostAdminBranchingSettingsRequest
     */
    nextPageId?: number;
}
/**
 * 
 * @export
 * @interface PostAdminCompaniesImportRequest
 */
export interface PostAdminCompaniesImportRequest {
    /**
     * UUID компании
     * @type {string}
     * @memberof PostAdminCompaniesImportRequest
     */
    companyId: string;
    /**
     * Принудительно засинкать (даже если последние 24 часа уже был синк)
     * @type {boolean}
     * @memberof PostAdminCompaniesImportRequest
     */
    force?: boolean;
}
/**
 * POST /admin/complex_reports
 * @export
 * @interface PostAdminComplexReports
 */
 export interface PostAdminComplexReports {
    /**
     * Название отчета
     * @type {string}
     * @memberof PostAdminComplexReports
     */
    title: string;
    /**
     * Тип аналитики отчета
     * @type {string}
     * @memberof PostAdminComplexReports
     */
    analyticsKind: PostAdminComplexReportsAnalyticsKind;
    /**
     * Описание отчета
     * @type {string}
     * @memberof PostAdminComplexReports
     */
    description?: string;
}

export const PostAdminComplexReportsAnalyticsKind = {
    CSI: 'csi',
    OCCUPATIONAL_BURNOUT: 'occupational_burnout',
    EXPRESS_SVS: 'express_svs'
} as const;

export type PostAdminComplexReportsAnalyticsKind = typeof PostAdminComplexReportsAnalyticsKind[keyof typeof PostAdminComplexReportsAnalyticsKind];

/**
 * POST /admin/email_templates/{id}/test_email_message
 * @export
 * @interface PostAdminEmailTemplatesIdTestEmailMessage
 */
export interface PostAdminEmailTemplatesIdTestEmailMessage {
    /**
     * Массив email-ов для теста
     * @type {Array<string>}
     * @memberof PostAdminEmailTemplatesIdTestEmailMessage
     */
     emails: Array<string>;
    /**
     * Slug опроса
     * @type {string}
     * @memberof PostAdminEmailTemplatesIdTestEmailMessage
     */
     surveySlug: string;
}
/**
 * 
 * @export
 * @interface PostAdminEmailTemplatesRequest
 */
export interface PostAdminEmailTemplatesRequest {
    /**
     * Хеддер шаблона
     * @type {string}
     * @memberof PostAdminEmailTemplatesRequest
     */
    header: string;
    /**
     * Тело письма
     * @type {string}
     * @memberof PostAdminEmailTemplatesRequest
     */
    body: string;
    /**
     * Тема письма
     * @type {string}
     * @memberof PostAdminEmailTemplatesRequest
     */
    subject: string;
    /**
     * Название html шаблона
     * @type {string}
     * @memberof PostAdminEmailTemplatesRequest
     */
    templateName: string;
    /**
     * Картинка шаблона
     * @type {any}
     * @memberof PostAdminEmailTemplatesRequest
     */
    image?: any;
    /**
     * Slug опроса
     * @type {string}
     * @memberof PostAdminEmailTemplatesRequest
     */
    surveySlug: string;
}
/**
 * POST /admin/external_surveys
 * @export
 * @interface PostAdminExternalSurveys
 */
export interface PostAdminExternalSurveys {
    /**
     * 
     * @type {PostAdminExternalSurveysExternalSurvey}
     * @memberof PostAdminExternalSurveys
     */
     externalSurvey: PostAdminExternalSurveysExternalSurvey;
}
/**
 * Параметры для создания внешнего опроса
 * @export
 * @interface PostAdminExternalSurveysExternalSurvey
 */
export interface PostAdminExternalSurveysExternalSurvey {
    /**
     * Название внешнего опроса
     * @type {string}
     * @memberof PostAdminExternalSurveysExternalSurvey
     */
     name: string;
     /**
      * Дата старта внешнего опроса
      * @type {string}
      * @memberof PostAdminExternalSurveysExternalSurvey
      */
     startAt: string;
     /**
      * Дата завершения внешнего опроса
      * @type {string}
      * @memberof PostAdminExternalSurveysExternalSurvey
      */
     finishAt: string;
}
/**
 * POST /admin/factors
 * @export
 * @interface PostAdminFactors
 */
export interface PostAdminFactors {
    /**
     * Название
     * @type {string}
     * @memberof PostAdminFactors
     */
     title: string;
     /**
      * ID группы факторов
      * @type {number}
      * @memberof PostAdminFactors
      */
     factorGroupId: number;
     /**
      * Название иконки
      * @type {string}
      * @memberof PostAdminFactors
      */
     icon?: string;
     /**
      * Формулировка вопроса
      * @type {string}
      * @memberof PostAdminFactors
      */
     questionText?: string;
}
/**
 * 
 * @export
 * @interface PostAdminFactorsGroupsIdAddFactorsRequest
 */
export interface PostAdminFactorsGroupsIdAddFactorsRequest {
    /**
     * Массив ID факторово этой группы
     * @type {Array<number>}
     * @memberof PostAdminFactorsGroupsIdAddFactorsRequest
     */
    'factorIds': Array<number>;
}
/**
 * 
 * @export
 * @interface PostAdminHeatmapsRequest
 */
export interface PostAdminHeatmapsRequest {
    /**
     * Slug опроса по которому строится тепловая карта
     * @type {string}
     * @memberof PostAdminHeatmapsRequest
     */
    'surveySlug': string;
    /**
     * UUIDs команд
     * @type {Array<string>}
     * @memberof PostAdminHeatmapsRequest
     */
    'teamUids'?: Array<string>;
    /**
     * Какие нужны индексы
     * @type {Array<string>}
     * @memberof PostAdminHeatmapsRequest
     */
    'indices': Array<PostAdminHeatmapsRequestIndices>;
    /**
     * Какие нужны базовые факторы
     * @type {Array<string>}
     * @memberof PostAdminHeatmapsRequest
     */
    'baseFactors': Array<PostAdminHeatmapsRequestBaseFactors>;
    /**
     * Какие нужны базовые факторы
     * @type {Array<string>}
     * @memberof PostAdminHeatmapsRequest
     */
    'enchancingFactors': Array<PostAdminHeatmapsRequestEnchancingFactors>;
    /**
     * Фильтр функция
     * @type {Array<string>}
     * @memberof PostAdminHeatmapsRequest
     */
    'jobFunctions'?: Array<string>;
    /**
     * Фильтр позиция
     * @type {Array<string>}
     * @memberof PostAdminHeatmapsRequest
     */
    'jobPositions'?: Array<string>;
    /**
     * Фильтр стаж
     * @type {Array<string>}
     * @memberof PostAdminHeatmapsRequest
     */
    'jobDurations'?: Array<string>;
}

export const PostAdminHeatmapsRequestIndices = {
    ENGAGEMENT: 'engagement',
    SATISFACTION: 'satisfaction',
    ENPS_COMPANY: 'enps_company'
} as const;

export type PostAdminHeatmapsRequestIndices = typeof PostAdminHeatmapsRequestIndices[keyof typeof PostAdminHeatmapsRequestIndices];
export const PostAdminHeatmapsRequestBaseFactors = {
    STABILITY: 'stability',
    STRESS_LEVEL: 'stress_level',
    WORK_AND_LIFE_BALANCE: 'work_and_life_balance',
    WORKFLOW: 'workflow',
    AWARENESS_LEVEL: 'awareness_level',
    WORK_CONTENT: 'work_content',
    SALARY: 'salary',
    SOCIAL_BENEFITS: 'social_benefits',
    WORKING_CONDITIONS: 'working_conditions',
    RESOURCES_AND_TOOLS: 'resources_and_tools'
} as const;

export type PostAdminHeatmapsRequestBaseFactors = typeof PostAdminHeatmapsRequestBaseFactors[keyof typeof PostAdminHeatmapsRequestBaseFactors];
export const PostAdminHeatmapsRequestEnchancingFactors = {
    WORK: 'me_and_work',
    COMPANY: 'me_and_company',
    HEAD: 'me_and_head',
    CLIENT: 'me_and_client',
    TEAM: 'me_and_team'
} as const;

export type PostAdminHeatmapsRequestEnchancingFactors = typeof PostAdminHeatmapsRequestEnchancingFactors[keyof typeof PostAdminHeatmapsRequestEnchancingFactors];

/**
 * 
 * @export
 * @interface PostAdminHrPracticesRequest
 */
export interface PostAdminHrPracticesRequest {
    /**
     * Заголовок
     * @type {string}
     * @memberof PostAdminHrPracticesRequest
     */
    'title': string;
    /**
     * Краткое описание
     * @type {string}
     * @memberof PostAdminHrPracticesRequest
     */
    'shortDescription': string;
    /**
     * Oписание
     * @type {string}
     * @memberof PostAdminHrPracticesRequest
     */
    'description'?: string;
    /**
     * Ресурсы необходимые для реализации
     * @type {string}
     * @memberof PostAdminHrPracticesRequest
     */
    'cost'?: string;
    /**
     * Ответственные
     * @type {string}
     * @memberof PostAdminHrPracticesRequest
     */
    'responsible'?: string;
    /**
     * На кого направлено
     * @type {string}
     * @memberof PostAdminHrPracticesRequest
     */
    'recipients'?: string;
    /**
     * Где можно применять
     * @type {string}
     * @memberof PostAdminHrPracticesRequest
     */
    'applicationScope'?: string;
    /**
     * Периодичность
     * @type {string}
     * @memberof PostAdminHrPracticesRequest
     */
    'period'?: string;
    /**
     * Важно
     * @type {string}
     * @memberof PostAdminHrPracticesRequest
     */
    'notes'?: string;
    /**
     * Списко тегов
     * @type {string}
     * @memberof PostAdminHrPracticesRequest
     */
    'tags'?: string;
    /**
     * Картинка
     * @type {any}
     * @memberof PostAdminHrPracticesRequest
     */
    'image'?: any;
    /**
     * Адрес картинки для загрузки
     * @type {string}
     * @memberof PostAdminHrPracticesRequest
     */
    'imageUrl'?: string;
}
/**
 * 
 * @export
 * @interface PostAdminImageTemplatesRequest
 */
export interface PostAdminImageTemplatesRequest {
    /**
     * Файл изображения
     * @type {any}
     * @memberof PostAdminImageTemplatesRequest
     */
    'image': any;
}
/**
 * 
 * @export
 * @interface PostAdminPagesRequest
 */
export interface PostAdminPagesRequest {
    /**
     * Slug опроса
     * @type {string}
     * @memberof PostAdminPagesRequest
     */
    'surveySlug': string;
}
/**
 * 
 * @export
 * @interface PostAdminQuestionsRequest
 */
export interface PostAdminQuestionsRequest {
    /**
     * Порядковый номер ответа
     * @type {Array<number>}
     * @memberof PostAdminQuestionsRequest
     */
    'answers[ord]'?: Array<number>;
    /**
     * Score ответа
     * @type {Array<number>}
     * @memberof PostAdminQuestionsRequest
     */
    'answers[score]'?: Array<number>;
    /**
     * Текст ответа
     * @type {Array<string>}
     * @memberof PostAdminQuestionsRequest
     */
    'answers[text]'?: Array<string>;
    /**
     * ID блока куда добавляется вопрос
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    'blockId'?: string;
    /**
     * Тело опроса
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    'body'?: string;
    /**
     * Добавить комментарий
     * @type {boolean}
     * @memberof PostAdminQuestionsRequest
     */
    'commentable'?: boolean;
    /**
     * IDs факторов которые надо добавить к вопросу
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    'factorIds'?: string;
    /**
     * IDs факторов которые надо добавить к вопросу
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    '[Integer]'?: string;
    /**
     * Названия факторов которые надо добавить к вопросу
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    'factorNames'?: string;
    /**
     * Названия факторов которые надо добавить к вопросу
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    '[String]'?: string;
    /**
     * Добавить вариант не могу оценить
     * @type {boolean}
     * @memberof PostAdminQuestionsRequest
     */
    'includeIfNotAnswered'?: boolean;
    /**
     * Анализировать тему ответов с помощью ИИ
     * @type {boolean}
     * @memberof PostAdminQuestionsRequest
     */
    'includeAiAnalysis'?: boolean;
    /**
     * Левый заголовок (только для дихотомии)
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    'leftTitle'?: string;
    /**
     * Сколько ответов можно выбрать
     * @type {number}
     * @memberof PostAdminQuestionsRequest
     */
    'maxAnswers'?: number;
    /**
     * Формулировка опции \"Другое\"
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    'otherStatement'?: string;
    /**
     * ID страницы куда добавляется вопрос
     * @type {number}
     * @memberof PostAdminQuestionsRequest
     */
    'pageId'?: number;
    /**
     * Картинка вопроса
     * @type {any}
     * @memberof PostAdminQuestionsRequest
     */
    'picture'?: any;
    /**
     * ID шаблонов вопроса
     * @type {Array<number>}
     * @memberof PostAdminQuestionsRequest
     */
    'questionTemplateIds'?: Array<number>;
    /**
     * Тип вопроса
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    'questionType'?: PostAdminQuestionsRequestQuestionType;
    /**
     * ID подвопроса матрицы
     * @type {Array<number>}
     * @memberof PostAdminQuestionsRequest
     */
    'questions[id]'?: Array<number>;
    /**
     * Порядковый номер подвопроса матрицы
     * @type {Array<number>}
     * @memberof PostAdminQuestionsRequest
     */
    'questions[ord]'?: Array<number>;
    /**
     * Заголовок подвопроса матрицы
     * @type {Array<string>}
     * @memberof PostAdminQuestionsRequest
     */
    'questions[title]'?: Array<string>;
    /**
     * Перемешивать ответы при прохождении
     * @type {boolean}
     * @memberof PostAdminQuestionsRequest
     */
    'randomizeAnswers'?: boolean;
    /**
     * Обязательный?
     * @type {boolean}
     * @memberof PostAdminQuestionsRequest
     */
    'required'?: boolean;
    /**
     * Правый заголовок (только для дихотомии)
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    'rightTitle'?: string;
    /**
     * Заголовок вопроса
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    'title'?: string;
    /**
     * Визуализация вопроса горизонтальный блок с иконками
     * @type {string}
     * @memberof PostAdminQuestionsRequest
     */
    'visualizationType'?: PostAdminQuestionsRequestVisualizationType;
}

export const PostAdminQuestionsRequestQuestionType = {
    DICHOTOMY_5: 'dichotomy_5',
    DICHOTOMY_7: 'dichotomy_7',
    DICHOTOMY_WITH_POLAR_AFFIRMATION: 'dichotomy_with_polar_affirmation',
    LIKERT_6: 'likert_6',
    LIKERT_7: 'likert_7',
    OPEN: 'open',
    ORG_STRUCTURE: 'org_structure',
    NPS: 'nps',
    UPDATE_USER_SURVEY: 'update_user_survey',
    MONO: 'mono',
    POLY: 'poly',
    SCALE: 'scale',
    LIST: 'list',
    HORIZONTAL_BLOCK: 'horizontal_block',
    MATRIX: 'matrix'
} as const;

export type PostAdminQuestionsRequestQuestionType = typeof PostAdminQuestionsRequestQuestionType[keyof typeof PostAdminQuestionsRequestQuestionType];
export const PostAdminQuestionsRequestVisualizationType = {
    SMILES: 'smiles',
    DOTS: 'dots',
    DIGITS: 'digits'
} as const;

export type PostAdminQuestionsRequestVisualizationType = typeof PostAdminQuestionsRequestVisualizationType[keyof typeof PostAdminQuestionsRequestVisualizationType];

/**
 * POST /admin/sudo_actions/add_to_sudo_admins
 * @export
 * @interface PostAdminSudoActionsAddToSudoAdmins
 */
export interface PostAdminSudoActionsAddToSudoAdmins {
    /**
     * UUID пользователя
     * @type {string}
     * @memberof PostAdminSudoActionsAddToSudoAdmins
     */
    'userUuid': string;
    /**
     * UUID компании
     * @type {string}
     * @memberof PostAdminSudoActionsAddToSudoAdmins
     */
    'companyId': string;
}
/**
 * POST /admin/survey_meta_questions
 * @export
 * @interface PostAdminSurveyMetaQuestions
 */
export interface PostAdminSurveyMetaQuestions {
    /**
     * Slug опроса
     * @type {string}
     * @memberof PostAdminSurveyMetaQuestions
     */
    'surveySlug': string;
    /**
     * Вопросы паспортички
     * @type {Array<PostAdminSurveyMetaQuestionsQuestionsInner>}
     * @memberof PostAdminSurveyMetaQuestions
     */
    'questions': Array<PostAdminSurveyMetaQuestionsQuestionsInner>;
}
/**
 * 
 * @export
 * @interface PostAdminSurveyMetaQuestionsQuestionsInner
 */
export interface PostAdminSurveyMetaQuestionsQuestionsInner {
    /**
     * Порядок
     * @type {number}
     * @memberof PostAdminSurveyMetaQuestionsQuestionsInner
     */
    'ord': number;
    /**
     * Метаполе
     * @type {string}
     * @memberof PostAdminSurveyMetaQuestionsQuestionsInner
     */
    'metakey': string;
    /**
     * Текст вопроса
     * @type {string}
     * @memberof PostAdminSurveyMetaQuestionsQuestionsInner
     */
    'title': string;
    /**
     * Множественный выбор
     * @type {boolean}
     * @memberof PostAdminSurveyMetaQuestionsQuestionsInner
     */
    'multiselect': boolean;
}
/**
 * 
 * @export
 * @interface PostAdminSurveyRequestsRequest
 */
export interface PostAdminSurveyRequestsRequest {
    /**
     * 
     * @type {string}
     * @memberof PostAdminSurveyRequestsRequest
     */
    'body': string;
}
/**
 * 
 * @export
 * @interface PostAdminSurveysActiveNotificationRemindersRequest
 */
export interface PostAdminSurveysActiveNotificationRemindersRequest {
    /**
     * UUID команды
     * @type {string}
     * @memberof PostAdminSurveysActiveNotificationRemindersRequest
     */
    'teamId'?: string;
}
/**
 * 
 * @export
 * @interface PostAdminSurveysBackToDraftRequest
 */
export interface PostAdminSurveysBackToDraftRequest {
    /**
     * Массив slug опросов
     * @type {Array<string>}
     * @memberof PostAdminSurveysBackToDraftRequest
     */
    'surveySlugs': Array<string>;
}
/**
 * 
 * @export
 * @interface PostAdminSurveysRequest
 */
export interface PostAdminSurveysRequest {
    /**
     * Название опроса
     * @type {string}
     * @memberof PostAdminSurveysRequest
     */
    'name'?: string;
    /**
     * Описание опроса
     * @type {string}
     * @memberof PostAdminSurveysRequest
     */
    'description'?: string;
    /**
     * Картинка опроса
     * @type {any}
     * @memberof PostAdminSurveysRequest
     */
    'image'?: any;
    /**
     * ID шаблона
     * @type {number}
     * @memberof PostAdminSurveysRequest
     */
    'surveyTemplateId'?: number;
    /**
     * Дефолтный язык опроса
     * @type {string}
     * @memberof PostAdminSurveysRequest
     */
    'defaultLanguage'?: string;
    /**
     * Название языковой версии
     * @type {Array<string>}
     * @memberof PostAdminSurveysRequest
     */
    'languages[languageName]': Array<string>;
    /**
     * Перевод названия опроса
     * @type {Array<string>}
     * @memberof PostAdminSurveysRequest
     */
    'languages[name]': Array<string>;
    /**
     * Перевод описания опроса
     * @type {Array<string>}
     * @memberof PostAdminSurveysRequest
     */
    'languages[description]': Array<string>;
}
/**
 * 
 * @export
 * @interface PostAdminTemplatesRequest
 */
export interface PostAdminTemplatesRequest {
    /**
     * ID опроса на основе которого создается шаблон
     * @type {number}
     * @memberof PostAdminTemplatesRequest
     */
    'surveyId': number;
    /**
     * Название шаблона
     * @type {string}
     * @memberof PostAdminTemplatesRequest
     */
    'name': string;
    /**
     * Описание шаблона
     * @type {string}
     * @memberof PostAdminTemplatesRequest
     */
    'description'?: string;
    /**
     * Картинка шаблона
     * @type {any}
     * @memberof PostAdminTemplatesRequest
     */
    'image'?: any;
}
/**
 * POST /admin/topmind_query/upsert
 * @export
 * @interface PostAdminTopmindQueryUpsert
 */
export interface PostAdminTopmindQueryUpsert {
    /**
     * Название Topmind запроса
     * @type {string}
     * @memberof PostAdminTopmindQueryUpsert
     */
    'name': string;
    /**
     * Тело Topmind запроса
     * @type {string}
     * @memberof PostAdminTopmindQueryUpsert
     */
    'query'?: string;
}
/**
 * POST /admin/user_groups
 * @export
 * @interface PostAdminUserGroups
 */
export interface PostAdminUserGroups {
    /**
     * Название группы
     * @type {string}
     * @memberof PostAdminUserGroups
     */
    'title': string;
    /**
     * Названия ролей для группы
     * @type {Array<string>}
     * @memberof PostAdminUserGroups
     */
    'roleTitles': Array<string>;
    /**
     * Описание группы
     * @type {string}
     * @memberof PostAdminUserGroups
     */
    'description'?: string;
}
/**
 * Pptx model
 * @export
 * @interface Pptx
 */
export interface Pptx {
    /**
     * ID презентации
     * @type {number}
     * @memberof Pptx
     */
    'id'?: number;
    /**
     * Статус презентации
     * @type {string}
     * @memberof Pptx
     */
    'status'?: string;
    /**
     * Ссылка для скачивания файла
     * @type {string}
     * @memberof Pptx
     */
    'link'?: string;
}
/**
 * 
 * @export
 * @interface PutAdminAdminAreaSurveysSurveySlugRequest
 */
export interface PutAdminAdminAreaSurveysSurveySlugRequest {
    /**
     * 
     * @type {string}
     * @memberof PutAdminAdminAreaSurveysSurveySlugRequest
     */
    'survey[finishAt]': string;
}
/**
 * PUT /admin/analytics/surveys/raw_data_reports/:report_id/update_status
 * @export
 * @interface PutAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus
 */
export interface PutAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus {
    /**
     * Статус отчета
     * @type {string}
     * @memberof PutAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface PutAdminAnswersIdRequest
 */
export interface PutAdminAnswersIdRequest {
    /**
     * ID следующей страницы (для ветвления)
     * @type {number}
     * @memberof PutAdminAnswersIdRequest
     */
    'answer[nextPageId]'?: number;
}
/**
 * 
 * @export
 * @interface PutAdminBlocksIdRequest
 */
export interface PutAdminBlocksIdRequest {
    /**
     * Текстовый контент
     * @type {string}
     * @memberof PutAdminBlocksIdRequest
     */
    'block[body]'?: string;
    /**
     * Картинка
     * @type {any}
     * @memberof PutAdminBlocksIdRequest
     */
    'block[image]'?: any;
}
/**
 * PUT /admin/complex_reports/{id}
 * @export
 * @interface PutAdminComplexReports
 */
export interface PutAdminComplexReports {
    /**
     * Название отчета
     * @type {string}
     * @memberof PutAdminComplexReports
     */
     title?: string;
     /**
      * Описание отчета
      * @type {string}
      * @memberof PutAdminComplexReports
      */
     description?: string;
     /**
      * IDs юзеров которые имеют доступ к результату отчета
      * @type {Array<number>}
      * @memberof PutAdminComplexReports
      */
     userIds?: Array<number>;
     /**
      * IDs опросов включенных в отчет
      * @type {Array<number>}
      * @memberof PutAdminComplexReports
      */
     surveyIds?: Array<number>;
}
/**
 * PUT /admin/email_templates/{id}
 * @export
 * @interface PutAdminEmailTemplates
 */
export interface PutAdminEmailTemplates {
    /**
     * Параметры для изменения
     * @type {object}
     * @memberof PutAdminEmailTemplates
     */
    emailTemplate: object;
}
/**
 * PUT /admin/external_surveys/:id
 * @export
 * @interface PutAdminExternalSurveys
 */
export interface PutAdminExternalSurveys {
    /**
     * 
     * @type {PutAdminExternalSurveysExternalSurvey}
     * @memberof PutAdminExternalSurveys
     */
    'externalSurvey': PutAdminExternalSurveysExternalSurvey;
}
/**
 * Параметры для создания внешнего опроса
 * @export
 * @interface PutAdminExternalSurveysExternalSurvey
 */
export interface PutAdminExternalSurveysExternalSurvey {
    /**
     * Название внешнего опроса
     * @type {string}
     * @memberof PutAdminExternalSurveysExternalSurvey
     */
     name?: string;
     /**
      * Дата старта внешнего опроса
      * @type {string}
      * @memberof PutAdminExternalSurveysExternalSurvey
      */
     startAt?: string;
     /**
      * Дата завершения внешнего опроса
      * @type {string}
      * @memberof PutAdminExternalSurveysExternalSurvey
      */
     finishAt?: string;
}
/**
 * PUT /admin/factors/{id}
 * @export
 * @interface PutAdminFactors
 */
export interface PutAdminFactors {
    /**
     * 
     * @type {PutAdminFactorsFactor}
     * @memberof PutAdminFactors
     */
    'factor': PutAdminFactorsFactor;
}
/**
 * Параметры для изменения
 * @export
 * @interface PutAdminFactorsFactor
 */
export interface PutAdminFactorsFactor {
    /**
     * Название
     * @type {string}
     * @memberof PutAdminFactorsFactor
     */
    'title'?: string;
    /**
     * Название иконки
     * @type {string}
     * @memberof PutAdminFactorsFactor
     */
    'icon'?: string;
    /**
     * Формулировка вопроса
     * @type {string}
     * @memberof PutAdminFactorsFactor
     */
    'questionText'?: string;
    /**
     * Варианты ответа
     * @type {Array<PutAdminFactorsFactorAnswersVariantsInner>}
     * @memberof PutAdminFactorsFactor
     */
    'answersVariants'?: Array<PutAdminFactorsFactorAnswersVariantsInner>;
}
/**
 * 
 * @export
 * @interface PutAdminFactorsFactorAnswersVariantsInner
 */
export interface PutAdminFactorsFactorAnswersVariantsInner {
    /**
     * Текст ответа
     * @type {string}
     * @memberof PutAdminFactorsFactorAnswersVariantsInner
     */
    'text'?: string;
    /**
     * Порядок ответа
     * @type {number}
     * @memberof PutAdminFactorsFactorAnswersVariantsInner
     */
    'ord'?: number;
    /**
     * Score ответа
     * @type {number}
     * @memberof PutAdminFactorsFactorAnswersVariantsInner
     */
    'score'?: number;
}
/**
 * PUT /admin/hr_practices/{id}
 * @export
 * @interface PutAdminHrPractices
 */
export interface PutAdminHrPractices {
    /**
     * Параметры для изменения
     * @type {object}
     * @memberof PutAdminHrPractices
     */
    'practice': object;
}
/**
 * 
 * @export
 * @interface PutAdminPagesIdRequest
 */
export interface PutAdminPagesIdRequest {
    /**
     * Новый тайтл страницы
     * @type {string}
     * @memberof PutAdminPagesIdRequest
     */
    'page[title]'?: string;
    /**
     * Hint
     * @type {string}
     * @memberof PutAdminPagesIdRequest
     */
    'page[hint]'?: string;
    /**
     * Help
     * @type {string}
     * @memberof PutAdminPagesIdRequest
     */
    'page[help]'?: string;
    /**
     * Новый порядковый номер страницы
     * @type {number}
     * @memberof PutAdminPagesIdRequest
     */
    'page[ord]'?: number;
    /**
     * ID следующей страницы (для ветвления)
     * @type {number}
     * @memberof PutAdminPagesIdRequest
     */
    'page[nextPageId]'?: number;
}
/**
 * 
 * @export
 * @interface PutAdminQuestionsIdRequest
 */
export interface PutAdminQuestionsIdRequest {
    /**
     * Порядковый номер ответа
     * @type {Array<number>}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[answers][ord]'?: Array<number>;
    /**
     * Score ответа
     * @type {Array<number>}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[answers][score]'?: Array<number>;
    /**
     * Текст ответа
     * @type {Array<string>}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[answers][text]'?: Array<string>;
    /**
     * Тело опроса
     * @type {string}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[body]'?: string;
    /**
     * Добавить комментарий
     * @type {boolean}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[commentable]'?: boolean;
    /**
     * IDs факторов которые надо добавить к вопросу
     * @type {string}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[factorIds]'?: string;
    /**
     * IDs факторов которые надо добавить к вопросу
     * @type {string}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[[Integer]]'?: string;
    /**
     * Адрес картинки для загрузки
     * @type {string}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[imageUrl]'?: string;
    /**
     * Анализировать тему ответов с помощью ИИ
     * @type {boolean}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[includeAiAnalysis]'?: boolean;
    /**
     * Добавить вариант не могу оценить
     * @type {boolean}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[includeIfNotAnswered]'?: boolean;
    /**
     * Левый заголовок (только для дихотомии)
     * @type {string}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[leftTitle]'?: string;
    /**
     * Сколько ответов можно выбрать
     * @type {number}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[maxAnswers]'?: number;
    /**
     * Порядковый номер вопроса
     * @type {number}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[ord]'?: number;
    /**
     * Формулировка опции \"Другое\"
     * @type {string}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[otherStatement]'?: string;
    /**
     * Картинка вопроса
     * @type {any}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[picture]'?: any;
    /**
     * Тип вопроса
     * @type {string}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[questionType]'?: PutAdminQuestionsIdRequestQuestionQuestionType;
    /**
     * ID подвопроса матрицы
     * @type {Array<number>}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[questions][id]'?: Array<number>;
    /**
     * Порядковый номер подвопроса матрицы
     * @type {Array<number>}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[questions][ord]'?: Array<number>;
    /**
     * Заголовок подвопроса матрицы
     * @type {Array<string>}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[questions][title]'?: Array<string>;
    /**
     * Перемешивать ответы при прохождении
     * @type {boolean}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[randomizeAnswers]'?: boolean;
    /**
     * Обязательный?
     * @type {boolean}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[required]'?: boolean;
    /**
     * Правый заголовок (только для дихотомии)
     * @type {string}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[rightTitle]'?: string;
    /**
     * Заголовок вопроса
     * @type {string}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[title]'?: string;
    /**
     * Визуализация вопроса горизонтальный блок с иконками
     * @type {string}
     * @memberof PutAdminQuestionsIdRequest
     */
    'question[visualizationType]'?: PutAdminQuestionsIdRequestQuestionVisualizationType;
}

export const PutAdminQuestionsIdRequestQuestionQuestionType = {
    DICHOTOMY_5: 'dichotomy_5',
    DICHOTOMY_7: 'dichotomy_7',
    DICHOTOMY_WITH_POLAR_AFFIRMATION: 'dichotomy_with_polar_affirmation',
    LIKERT_6: 'likert_6',
    LIKERT_7: 'likert_7',
    OPEN: 'open',
    ORG_STRUCTURE: 'org_structure',
    NPS: 'nps',
    UPDATE_USER_SURVEY: 'update_user_survey',
    MONO: 'mono',
    POLY: 'poly',
    SCALE: 'scale',
    LIST: 'list',
    HORIZONTAL_BLOCK: 'horizontal_block',
    MATRIX: 'matrix'
} as const;

export type PutAdminQuestionsIdRequestQuestionQuestionType = typeof PutAdminQuestionsIdRequestQuestionQuestionType[keyof typeof PutAdminQuestionsIdRequestQuestionQuestionType];
export const PutAdminQuestionsIdRequestQuestionVisualizationType = {
    SMILES: 'smiles',
    DOTS: 'dots',
    DIGITS: 'digits'
} as const;

export type PutAdminQuestionsIdRequestQuestionVisualizationType = typeof PutAdminQuestionsIdRequestQuestionVisualizationType[keyof typeof PutAdminQuestionsIdRequestQuestionVisualizationType];

/**
 * PUT /admin/surveys/{survey_slug}
 * @export
 * @interface PutAdminSurveys
 */
export interface PutAdminSurveys {
    /**
     * 
     * @type {PutAdminSurveysSurvey}
     * @memberof PutAdminSurveys
     */
    'survey': PutAdminSurveysSurvey;
}
/**
 * Параметры для изменения
 * @export
 * @interface PutAdminSurveysSurvey
 */
export interface PutAdminSurveysSurvey {
    /**
     * Тип аналитики
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    analyticsKind?: string;
    /**
     * Является ли опрос анонимным
     * @type {boolean}
     * @memberof PutAdminSurveysSurvey
     */
    anonymous?: boolean;
    /**
     * Базовый язык опроса
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    defaultLanguage?: string;
    /**
     * Включена ли готовая страница
     * @type {boolean}
     * @memberof PutAdminSurveysSurvey
     */
    finishPageEnabled?: boolean;
    /**
     * Картинка опроса
     * @type {object}
     * @memberof PutAdminSurveysSurvey
     */
    image?: object;
    /**
     * Языки опроса
     * @type {Array<string>}
     * @memberof PutAdminSurveysSurvey
     */
    languages?: Array<string>;
    /**
     * Периодичность повторов
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    repeatPeriod?: string;
    /**
     * Количество повторов
     * @type {number}
     * @memberof PutAdminSurveysSurvey
     */
    repeatTimes?: number;
    /**
     * Дата-время старта опроса
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    startAt?: string;
    /**
     * Дата-время окончания опроса
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    finishAt?: string;
    /**
     * UUID команд, для которых запущен опрос
     * @type {Array<string>}
     * @memberof PutAdminSurveysSurvey
     */
    teamUids?: Array<string>;
    /**
     * Численность из мета
     * @type {boolean}
     * @memberof PutAdminSurveysSurvey
     */
    useMetaTeamMembersCount?: boolean;
    /**
     * Работа с некачественными анкетами
     * @type {boolean}
     * @memberof PutAdminSurveysSurvey
     */
    detectFakes?: boolean;
    /**
     * Настройки ссылки для прохождения
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    userSurveySlugSettings?: string;
    /**
     * Настройка прав просмтра аналитики
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    viewingRights?: string;
    /**
     * Страница приветствия включена
     * @type {boolean}
     * @memberof PutAdminSurveysSurvey
     */
    welcomePageEnabled?: boolean;
    /**
     * UUID автора опроса
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    authorId?: string;
    /**
     * UUID компании
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    companyId?: string;
    /**
     * UUID корневой команды
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    rootTeamId?: string;
    /**
     * Тип источника
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    sourceKind?: string;
    /**
     * Описание
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    description?: string;
    /**
     * Тело последней страницы
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    finishPageBody?: string;
    /**
     * Загаловок последней страницы
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    finishPageTitle?: string;
    /**
     * Тело страницы инструкции
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    instructionPageBody?: string;
    /**
     * Загаловок страницы инструкции
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    instructionPageTitle?: string;
    /**
     * Название
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    name?: string;
    /**
     * Тело страницы приветствия
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    welcomePageBody?: string;
    /**
     * Загаловок страницы приветствия
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    welcomePageTitle?: string;
    /**
     * Настройки нотификации
     * @type {Array<string>}
     * @memberof PutAdminSurveysSurvey
     */
    notificationSettings?: Array<string>;
    /**
     * Повторы
     * @type {Array<string>}
     * @memberof PutAdminSurveysSurvey
     */
    repeats?: Array<string>;
    /**
     * Тип шаблона для старте опроса
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    startTemplateType?: string;
    /**
     * Тип шаблона для напоминания об опросе
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    reminderTemplateType?: string;
    /**
     * Тип шаблона для напоминания об опросе(анонимный)
     * @type {string}
     * @memberof PutAdminSurveysSurvey
     */
    unsubscribeReminderTemplateType?: string;
}
/**
 * PUT /admin/surveys/:survey_slug/orgstructure_pages
 * @export
 * @interface PutAdminSurveysSurveySlugOrgstructurePages
 */
export interface PutAdminSurveysSurveySlugOrgstructurePages {
    /**
     * 
     * @type {PutAdminSurveysSurveySlugOrgstructurePagesSettings}
     * @memberof PutAdminSurveysSurveySlugOrgstructurePages
     */
    'settings': PutAdminSurveysSurveySlugOrgstructurePagesSettings;
}
/**
 * 
 * @export
 * @interface PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest
 */
export interface PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest {
    /**
     * ID блока
     * @type {Array<number>}
     * @memberof PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest
     */
    'blocks[id]': Array<number>;
    /**
     * Заголовок вопроса 
     * @type {Array<string>}
     * @memberof PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest
     */
    'blocks[questionTitle]'?: Array<string>;
    /**
     * Подсказка селекта
     * @type {Array<string>}
     * @memberof PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest
     */
    'blocks[selectHint]'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PutAdminSurveysSurveySlugOrgstructurePagesSettings
 */
export interface PutAdminSurveysSurveySlugOrgstructurePagesSettings {
    /**
     * Включить поиск по коду
     * @type {boolean}
     * @memberof PutAdminSurveysSurveySlugOrgstructurePagesSettings
     */
     searchByCode?: boolean;
     /**
      * Включить поиск по руководителю
      * @type {boolean}
      * @memberof PutAdminSurveysSurveySlugOrgstructurePagesSettings
      */
     searchByLead?: boolean;
     /**
      * Запретить выбор промежуточных вариантов
      * @type {boolean}
      * @memberof PutAdminSurveysSurveySlugOrgstructurePagesSettings
      */
     onlyFinalNodes?: boolean;
     /**
      * Порядковый номер страницы
      * @type {number}
      * @memberof PutAdminSurveysSurveySlugOrgstructurePagesSettings
      */
     pageOrder?: number;
}
/**
 * PUT /admin/top_mind/export
 * @export
 * @interface PutAdminTopMindExport
 */
export interface PutAdminTopMindExport {
    /**
     * Модель для экспорта в TopMind
     * @type {string}
     * @memberof PutAdminTopMindExport
     */
    'model': string;
}
/**
 * PUT /admin/translations/blocks/{id}
 * @export
 * @interface PutAdminTranslationsBlocks
 */
export interface PutAdminTranslationsBlocks {
    /**
     * Язык на который будет переведен блок
     * @type {string}
     * @memberof PutAdminTranslationsBlocks
     */
    'language': string;
    /**
     * Тип отображения блока
     * @type {string}
     * @memberof PutAdminTranslationsBlocks
     */
    'renderType': PutAdminTranslationsBlocksRenderType;
    /**
     * Переведенное туловище блока
     * @type {string}
     * @memberof PutAdminTranslationsBlocks
     */
    'body'?: string;
    /**
     * Переведенный заголовок блока
     * @type {string}
     * @memberof PutAdminTranslationsBlocks
     */
    'title'?: string;
    /**
     * Вопрос(ы) блока
     * @type {Array<PutAdminTranslationsBlocksQuestionsInner>}
     * @memberof PutAdminTranslationsBlocks
     */
    'questions'?: Array<PutAdminTranslationsBlocksQuestionsInner>;
}

export const PutAdminTranslationsBlocksRenderType = {
    QUESTION: 'question',
    TEXT: 'text',
    MATRIX: 'matrix'
} as const;

export type PutAdminTranslationsBlocksRenderType = typeof PutAdminTranslationsBlocksRenderType[keyof typeof PutAdminTranslationsBlocksRenderType];

/**
 * 
 * @export
 * @interface PutAdminTranslationsBlocksQuestionsInner
 */
export interface PutAdminTranslationsBlocksQuestionsInner {
    /**
     * Идентификатор вопроса
     * @type {number}
     * @memberof PutAdminTranslationsBlocksQuestionsInner
     */
    'id': number;
    /**
     * Перевод заголовка вопроса
     * @type {string}
     * @memberof PutAdminTranslationsBlocksQuestionsInner
     */
    'title': string;
    /**
     * Перевод варианта ответа \'Другое\'
     * @type {string}
     * @memberof PutAdminTranslationsBlocksQuestionsInner
     */
    'otherStatement'?: string;
    /**
     * Ответы на обычный вопрос
     * @type {Array<PutAdminTranslationsBlocksQuestionsInnerAnswersInner>}
     * @memberof PutAdminTranslationsBlocksQuestionsInner
     */
    'answers'?: Array<PutAdminTranslationsBlocksQuestionsInnerAnswersInner>;
    /**
     * Подвопросы матрицы
     * @type {Array<PutAdminTranslationsBlocksQuestionsInnerQuestionsInner>}
     * @memberof PutAdminTranslationsBlocksQuestionsInner
     */
    'questions'?: Array<PutAdminTranslationsBlocksQuestionsInnerQuestionsInner>;
}
/**
 * 
 * @export
 * @interface PutAdminTranslationsBlocksQuestionsInnerAnswersInner
 */
export interface PutAdminTranslationsBlocksQuestionsInnerAnswersInner {
    /**
     * Идентификатор ответа на обычный вопрос
     * @type {number}
     * @memberof PutAdminTranslationsBlocksQuestionsInnerAnswersInner
     */
    'id': number;
    /**
     * Перевод текста ответа на обычный вопрос
     * @type {string}
     * @memberof PutAdminTranslationsBlocksQuestionsInnerAnswersInner
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface PutAdminTranslationsBlocksQuestionsInnerQuestionsInner
 */
export interface PutAdminTranslationsBlocksQuestionsInnerQuestionsInner {
    /**
     * Идентификатор подвопроса матрицы
     * @type {number}
     * @memberof PutAdminTranslationsBlocksQuestionsInnerQuestionsInner
     */
    'id': number;
    /**
     * Перевод заголовка подвопроса матрицы
     * @type {string}
     * @memberof PutAdminTranslationsBlocksQuestionsInnerQuestionsInner
     */
    'title': string;
    /**
     * Ответы на подвопрос матрицы
     * @type {Array<PutAdminTranslationsBlocksQuestionsInnerQuestionsInnerAnswersInner>}
     * @memberof PutAdminTranslationsBlocksQuestionsInnerQuestionsInner
     */
    'answers'?: Array<PutAdminTranslationsBlocksQuestionsInnerQuestionsInnerAnswersInner>;
}
/**
 * 
 * @export
 * @interface PutAdminTranslationsBlocksQuestionsInnerQuestionsInnerAnswersInner
 */
export interface PutAdminTranslationsBlocksQuestionsInnerQuestionsInnerAnswersInner {
    /**
     * Идентификатор ответа
     * @type {number}
     * @memberof PutAdminTranslationsBlocksQuestionsInnerQuestionsInnerAnswersInner
     */
    'id': number;
    /**
     * Перевод текста ответа на подвопрос матрицы
     * @type {string}
     * @memberof PutAdminTranslationsBlocksQuestionsInnerQuestionsInnerAnswersInner
     */
    'text': string;
}
/**
 * PUT /admin/translations/surveys/{survey_slug}
 * @export
 * @interface PutAdminTranslationsSurveys
 */
export interface PutAdminTranslationsSurveys {
    /**
     * Дефолтный язык опроса
     * @type {string}
     * @memberof PutAdminTranslationsSurveys
     */
    'defaultLanguage'?: string;
    /**
     * 
     * @type {Array<PutAdminTranslationsSurveysLanguagesInner>}
     * @memberof PutAdminTranslationsSurveys
     */
    'languages'?: Array<PutAdminTranslationsSurveysLanguagesInner>;
}
/**
 * 
 * @export
 * @interface PutAdminTranslationsSurveysLanguagesInner
 */
export interface PutAdminTranslationsSurveysLanguagesInner {
    /**
     * Название языковой версии
     * @type {string}
     * @memberof PutAdminTranslationsSurveysLanguagesInner
     */
    'languageName': string;
    /**
     * Перевод названия опроса
     * @type {string}
     * @memberof PutAdminTranslationsSurveysLanguagesInner
     */
    'name': string;
    /**
     * Перевод описания опроса
     * @type {string}
     * @memberof PutAdminTranslationsSurveysLanguagesInner
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface PutAdminTranslationsSurveysSurveySlugFinalPageRequest
 */
export interface PutAdminTranslationsSurveysSurveySlugFinalPageRequest {
    /**
     * Язык перевода
     * @type {string}
     * @memberof PutAdminTranslationsSurveysSurveySlugFinalPageRequest
     */
    'finalPage[language]': string;
    /**
     * Переведенное туловище блока
     * @type {Array<string>}
     * @memberof PutAdminTranslationsSurveysSurveySlugFinalPageRequest
     */
    'finalPage[blocks][body]': Array<string>;
    /**
     * ID переводимого блока
     * @type {Array<number>}
     * @memberof PutAdminTranslationsSurveysSurveySlugFinalPageRequest
     */
    'finalPage[blocks][id]'?: Array<number>;
    /**
     * Порядковый номер переводимого блока
     * @type {Array<number>}
     * @memberof PutAdminTranslationsSurveysSurveySlugFinalPageRequest
     */
    'finalPage[blocks][ord]'?: Array<number>;
}
/**
 * PUT /admin/translations/surveys/:survey_slug/orgstructure_pages
 * @export
 * @interface PutAdminTranslationsSurveysSurveySlugOrgstructurePages
 */
export interface PutAdminTranslationsSurveysSurveySlugOrgstructurePages {
    /**
     * Язык опроса (перевода)
     * @type {string}
     * @memberof PutAdminTranslationsSurveysSurveySlugOrgstructurePages
     */
    'language': string;
    /**
     * 
     * @type {Array<PutAdminTranslationsSurveysSurveySlugOrgstructurePagesBlocksInner>}
     * @memberof PutAdminTranslationsSurveysSurveySlugOrgstructurePages
     */
    'blocks': Array<PutAdminTranslationsSurveysSurveySlugOrgstructurePagesBlocksInner>;
}
/**
 * 
 * @export
 * @interface PutAdminTranslationsSurveysSurveySlugOrgstructurePagesBlocksInner
 */
export interface PutAdminTranslationsSurveysSurveySlugOrgstructurePagesBlocksInner {
    /**
     * ID блока
     * @type {number}
     * @memberof PutAdminTranslationsSurveysSurveySlugOrgstructurePagesBlocksInner
     */
    'id': number;
    /**
     * Заголовок вопроса
     * @type {string}
     * @memberof PutAdminTranslationsSurveysSurveySlugOrgstructurePagesBlocksInner
     */
    'questionTitle'?: string;
    /**
     * Подсказка вопроса
     * @type {string}
     * @memberof PutAdminTranslationsSurveysSurveySlugOrgstructurePagesBlocksInner
     */
    'questionHint'?: string;
    /**
     * Плейсхолдер селекта
     * @type {string}
     * @memberof PutAdminTranslationsSurveysSurveySlugOrgstructurePagesBlocksInner
     */
    'selectPlaceholder'?: string;
    /**
     * Подсказка селекта
     * @type {string}
     * @memberof PutAdminTranslationsSurveysSurveySlugOrgstructurePagesBlocksInner
     */
    'selectHint'?: string;
}
/**
 * PUT /admin/user_groups/:id
 * @export
 * @interface PutAdminUserGroups
 */
 export interface PutAdminUserGroups {
    /**
     * Название группы
     * @type {string}
     * @memberof PutAdminUserGroups
     */
    title: string;
    /**
     * Названия ролей для группы
     * @type {Array<string>}
     * @memberof PutAdminUserGroups
     */
    roleTitles: Array<string>;
    /**
     * Описание группы
     * @type {string}
     * @memberof PutAdminUserGroups
     */
    description?: string;
    /**
     * ID юзеров группы
     * @type {Array<number>}
     * @memberof PutAdminUserGroups
     */
    userIds?: Array<number>;
}
/**
 * Question model
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * ID вопроса
     * @type {number}
     * @memberof Question
     */
    id?: number;
    /**
     * Тип вопроса
     * @type {string}
     * @memberof Question
     */
    questionType?: string;
    /**
     * Заголовок вопроса
     * @type {string}
     * @memberof Question
     */
    title?: string;
    /**
     * Локализованный заголовок вопроса
     * @type {string}
     * @memberof Question
     */
    localizedTitle?: string;
    /**
     * Совет по вопросу
     * @type {string}
     * @memberof Question
     */
    hint?: string;
    /**
     * Локализованный совет по вопросу
     * @type {string}
     * @memberof Question
     */
    localizedHint?: string;
    /**
     * Текст опроса
     * @type {string}
     * @memberof Question
     */
    body?: string;
    /**
     * Локализованный текст опроса
     * @type {string}
     * @memberof Question
     */
    localizedBody?: string;
    /**
     * Формулировка опции \"Другое\"
     * @type {string}
     * @memberof Question
     */
    otherStatement?: string;
    /**
     * Локализованная формулировка опции \"Другое\"
     * @type {string}
     * @memberof Question
     */
    localizedOtherStatement?: string;
    /**
     * Левый заголовок (только для дихотомии)
     * @type {string}
     * @memberof Question
     */
    leftTitle?: string;
    /**
     * Правый заголовок (только для дихотомии)
     * @type {string}
     * @memberof Question
     */
    rightTitle?: string;
    /**
     * URL картинки для изображения (может быть null)
     * @type {string}
     * @memberof Question
     */
    imageUrl?: string;
    /**
     * 
     * @type {Array<Answer>}
     * @memberof Question
     */
    answers?: Array<Answer>;
    /**
     * Порядок вопроса
     * @type {number}
     * @memberof Question
     */
    ord?: number;
    /**
     * Обязательный?
     * @type {boolean}
     * @memberof Question
     */
    required?: boolean;
    /**
     * Не могу оценить
     * @type {boolean}
     * @memberof Question
     */
    includeIfNotAnswered?: boolean;
    /**
     * Поле для комментария к ответу
     * @type {boolean}
     * @memberof Question
     */
    commentable?: boolean;
    /**
     * Визуализация ответов для горизонтального блока (смайлы, точки, цифры
     * @type {string}
     * @memberof Question
     */
    visualizationType?: string;
    /**
     * Сколько ответов можно выбрать
     * @type {number}
     * @memberof Question
     */
    maxAnswers?: number;
    /**
     * Перемешивать ответы при прохождении
     * @type {boolean}
     * @memberof Question
     */
    randomizeAnswers?: boolean;
    /**
     * Добавить ответ \"Другое\"
     * @type {boolean}
     * @memberof Question
     */
    includeOther?: boolean;
    /**
     * Анализировать тему ответов с помощью ИИ
     * @type {boolean}
     * @memberof Question
     */
    includeAiAnalysis?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    factors?: string;
}
/**
 * 
 * @export
 * @interface QuestionTemplate
 */
export interface QuestionTemplate {
    /**
     * ID вопроса
     * @type {number}
     * @memberof QuestionTemplate
     */
    id?: number;
    /**
     * Тип вопроса
     * @type {string}
     * @memberof QuestionTemplate
     */
    questionType?: string;
    /**
     * Заголовок вопроса
     * @type {string}
     * @memberof QuestionTemplate
     */
    title?: string;
    /**
     * Совет по вопросу
     * @type {string}
     * @memberof QuestionTemplate
     */
    hint?: string;
    /**
     * Текст опроса
     * @type {string}
     * @memberof QuestionTemplate
     */
    body?: string;
    /**
     * Левый заголовок (только для дихотомии)
     * @type {string}
     * @memberof QuestionTemplate
     */
    leftTitle?: string;
    /**
     * Правый заголовок (только для дихотомии)
     * @type {string}
     * @memberof QuestionTemplate
     */
    rightTitle?: string;
    /**
     * URL картинки для изображения (может быть null)
     * @type {string}
     * @memberof QuestionTemplate
     */
    imageUrl?: string;
    /**
     * 
     * @type {Array<AnswerTemplate>}
     * @memberof QuestionTemplate
     */
    answerTemplates?: Array<AnswerTemplate>;
    /**
     * Порядок вопроса
     * @type {number}
     * @memberof QuestionTemplate
     */
    ord?: number;
    /**
     * Обязательный?
     * @type {boolean}
     * @memberof QuestionTemplate
     */
    required?: boolean;
    /**
     * Не могу оценить
     * @type {boolean}
     * @memberof QuestionTemplate
     */
    includeIfNotAnswered?: boolean;
    /**
     * Поле для комментария к ответу
     * @type {boolean}
     * @memberof QuestionTemplate
     */
    commentable?: boolean;
    /**
     * Визуализация ответов для горизонтального блока (смайлы, точки, цифры
     * @type {string}
     * @memberof QuestionTemplate
     */
    visualizationType?: string;
    /**
     * Сколько ответов можно выбрать
     * @type {number}
     * @memberof QuestionTemplate
     */
    maxAnswers?: number;
    /**
     * Перемешивать ответы при прохождении
     * @type {boolean}
     * @memberof QuestionTemplate
     */
    randomizeAnswers?: boolean;
    /**
     * Формулировка опции \"Другое\"
     * @type {string}
     * @memberof QuestionTemplate
     */
    otherStatement?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof QuestionTemplate
     */
    tags?: Array<Tag>;
}
/**
 * Role model
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * Наше название (уникальное)
     * @type {string}
     * @memberof Role
     */
    title?: string;
    /**
     * Русское название
     * @type {string}
     * @memberof Role
     */
    name?: string;
    /**
     * 
     * @type {Ability}
     * @memberof Role
     */
    abilities?: Ability;
}
/**
 * ShortPage model
 * @export
 * @interface ShortPage
 */
export interface ShortPage {
    /**
     * ID страницы
     * @type {number}
     * @memberof ShortPage
     */
    id?: number;
    /**
     * Название
     * @type {string}
     * @memberof ShortPage
     */
    title?: string;
    /**
     * Порядок страницы в опросе (без учета ветвления)
     * @type {number}
     * @memberof ShortPage
     */
    ord?: number;
    /**
     * ID следующей страницы при прохождении
     * @type {number}
     * @memberof ShortPage
     */
    nextPageId?: number;
    /**
     * Статус ветвления на странице
     * @type {string}
     * @memberof ShortPage
     */
    branchingStatus?: string;
    /**
     * 
     * @type {ShortQuestion}
     * @memberof ShortPage
     */
    questions?: ShortQuestion;
}
/**
 * 
 * @export
 * @interface ShortQuestion
 */
export interface ShortQuestion {
    /**
     * ID вопроса
     * @type {number}
     * @memberof ShortQuestion
     */
    id?: number;
    /**
     * Тип вопроса
     * @type {string}
     * @memberof ShortQuestion
     */
    questionType?: ShortQuestionQuestionType;
    /**
     * Заголовок вопроса
     * @type {string}
     * @memberof ShortQuestion
     */
    title?: string;
    /**
     * 
     * @type {Array<Answer>}
     * @memberof ShortQuestion
     */
    answers?: Array<Answer>;
    /**
     * Максимально допустимое количество ответов
     * @type {number}
     * @memberof ShortQuestion
     */
    maxAnswers?: number;
}

export const ShortQuestionQuestionType = {
    DICHOTOMY_5: 'dichotomy_5',
    DICHOTOMY_7: 'dichotomy_7',
    DICHOTOMY_WITH_POLAR_AFFIRMATION: 'dichotomy_with_polar_affirmation',
    LIKERT_6: 'likert_6',
    LIKERT_7: 'likert_7',
    OPEN: 'open',
    ORG_STRUCTURE: 'org_structure',
    NPS: 'nps',
    UPDATE_USER_SURVEY: 'update_user_survey',
    MONO: 'mono',
    POLY: 'poly',
    SCALE: 'scale',
    LIST: 'list',
    HORIZONTAL_BLOCK: 'horizontal_block',
    MATRIX: 'matrix'
} as const;

export type ShortQuestionQuestionType = typeof ShortQuestionQuestionType[keyof typeof ShortQuestionQuestionType];

/**
 * 
 * @export
 * @interface ShortSurvey
 */
 export interface ShortSurvey {
    /**
     * 
     * @type {string}
     * @memberof ShortSurvey
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurvey
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurvey
     */
    questionsCount?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurvey
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurvey
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurvey
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurvey
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurvey
     */
    authorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurvey
     */
    analyticsKind?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurvey
     */
    canStart?: string;
}
/**
 * ShortSurveyTemplate model
 * @export
 * @interface ShortSurveyTemplate
 */
export interface ShortSurveyTemplate {
    /**
     * 
     * @type {string}
     * @memberof ShortSurveyTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurveyTemplate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurveyTemplate
     */
    questionsCount?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurveyTemplate
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurveyTemplate
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurveyTemplate
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurveyTemplate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurveyTemplate
     */
    authorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurveyTemplate
     */
    analyticsKind?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortSurveyTemplate
     */
    canStart?: string;
}
/**
 * 
 * @export
 * @interface StringKeyValue
 */
export interface StringKeyValue {
    /**
     * Ключ
     * @type {string}
     * @memberof StringKeyValue
     */
    key?: string;
    /**
     * Значение
     * @type {string}
     * @memberof StringKeyValue
     */
    value?: string;
}
/**
 * Survey model
 * @export
 * @interface Survey
 */
export interface Survey {
    /**
     * ID опроса
     * @type {number}
     * @memberof Survey
     */
    id?: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof Survey
     */
    surveyName?: string;
    /**
     * Языки опроса
     * @type {Array<string>}
     * @memberof Survey
     */
    languages?: Array<string>;
    /**
     * Статус опроса
     * @type {string}
     * @memberof Survey
     */
    status?: string;
    /**
     * Идентификатор опроса
     * @type {string}
     * @memberof Survey
     */
    slug?: string;
    /**
     * UUID команд, для которых запущен опрос
     * @type {Array<string>}
     * @memberof Survey
     */
    teamUids?: Array<string>;
    /**
     * Дата-время старта опроса
     * @type {string}
     * @memberof Survey
     */
    startAt?: string;
    /**
     * Дата-время окончания опроса
     * @type {string}
     * @memberof Survey
     */
    finishAt?: string;
    /**
     * Дата-время создания опроса
     * @type {string}
     * @memberof Survey
     */
    createdAt?: string;
    /**
     * Дата-время обновления опроса
     * @type {string}
     * @memberof Survey
     */
    updatedAt?: string;
    /**
     * Является ли опрос анонимным
     * @type {boolean}
     * @memberof Survey
     */
    anonymous?: boolean;
    /**
     * Общее кол-во анкет
     * @type {number}
     * @memberof Survey
     */
    totalUserSurveys?: number;
    /**
     * Кол-во завершенных анкет
     * @type {number}
     * @memberof Survey
     */
    finishedUserSurveys?: number;
    /**
     * Идентификатор предыдущего опроса
     * @type {string}
     * @memberof Survey
     */
    previousSurveySlug?: string;
    /**
     * Отсутствие напоминаний
     * @type {string}
     * @memberof Survey
     */
    notificationsOff?: string;
    /**
     * Название опроса
     * @type {string}
     * @memberof Survey
     */
    name?: string;
    /**
     * Описание опроса
     * @type {string}
     * @memberof Survey
     */
    description?: string;
    /**
     * УРЛ картинки опроса
     * @type {string}
     * @memberof Survey
     */
    imageUrl?: string;
    /**
     * Происхождение опроса
     * @type {string}
     * @memberof Survey
     */
    sourceKind?: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    teams?: string;
    /**
     * За дополнительный вопрос мы считаем любой вопрос опроса, у которого нет фактора и тип != открытый
     * @type {boolean}
     * @memberof Survey
     */
    additionalQuestionsPresent?: boolean;
}
/**
 * SurveyMetaQuestion model
 * @export
 * @interface SurveyMetaQuestion
 */
export interface SurveyMetaQuestion {
    /**
     * Порядок вопроса
     * @type {number}
     * @memberof SurveyMetaQuestion
     */
    ord?: number;
    /**
     * Meta вопроса
     * @type {string}
     * @memberof SurveyMetaQuestion
     */
    metakey?: string;
    /**
     * Заголовок вопроса
     * @type {string}
     * @memberof SurveyMetaQuestion
     */
    title?: string;
    /**
     * Множественный выбор
     * @type {boolean}
     * @memberof SurveyMetaQuestion
     */
    multiselect?: boolean;
}
/**
 * 
 * @export
 * @interface SurveyNotificationSetting
 */
export interface SurveyNotificationSetting {
    /**
     * Тип нотификации
     * @type {string}
     * @memberof SurveyNotificationSetting
     */
    kind?: string;
    /**
     * Периодичность
     * @type {string}
     * @memberof SurveyNotificationSetting
     */
    period?: string;
    /**
     * Условие
     * @type {string}
     * @memberof SurveyNotificationSetting
     */
    condition?: string;
    /**
     * Время нотификации
     * @type {string}
     * @memberof SurveyNotificationSetting
     */
    notificationTime?: string;
}
/**
 * 
 * @export
 * @interface SurveyRepeat
 */
export interface SurveyRepeat {
    /**
     * Время начала
     * @type {string}
     * @memberof SurveyRepeat
     */
    startAt?: string;
    /**
     * Время окончания
     * @type {string}
     * @memberof SurveyRepeat
     */
    finishAt?: string;
}
/**
 * SurveyShort model
 * @export
 * @interface SurveyShort
 */
export interface SurveyShort {
    /**
     * ID опроса
     * @type {number}
     * @memberof SurveyShort
     */
    id?: number;
    /**
     * Название опроса
     * @type {string}
     * @memberof SurveyShort
     */
    surveyName?: string;
    /**
     * Уникальный slug опроса
     * @type {string}
     * @memberof SurveyShort
     */
    slug?: string;
    /**
     * Дата старта опроса
     * @type {string}
     * @memberof SurveyShort
     */
    startAt?: string;
    /**
     * Дата окончания опроса
     * @type {string}
     * @memberof SurveyShort
     */
    finishAt?: string;
}
/**
 * SurveyTemplatesIndex model
 * @export
 * @interface SurveyTemplatesIndex
 */
export interface SurveyTemplatesIndex {
    /**
     * 
     * @type {string}
     * @memberof SurveyTemplatesIndex
     */
    templates?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyTemplatesIndex
     */
    pagination?: string;
}
/**
 * SurveyTimelineItem model
 * @export
 * @interface SurveyTimelineItem
 */
export interface SurveyTimelineItem {
    /**
     * Идентификатор повторения
     * @type {string}
     * @memberof SurveyTimelineItem
     */
    id?: string;
    /**
     * Название опроса
     * @type {string}
     * @memberof SurveyTimelineItem
     */
    title?: string;
    /**
     * Статус
     * @type {string}
     * @memberof SurveyTimelineItem
     */
    status?: string;
    /**
     * Время начала
     * @type {string}
     * @memberof SurveyTimelineItem
     */
    startAt?: string;
    /**
     * Время окончания
     * @type {string}
     * @memberof SurveyTimelineItem
     */
    finishAt?: string;
    /**
     * Текущий опрос?
     * @type {boolean}
     * @memberof SurveyTimelineItem
     */
    current?: boolean;
}
/**
 * SurveysListData model
 * @export
 * @interface SurveysListData
 */
export interface SurveysListData {
    /**
     * 
     * @type {ShortSurvey}
     * @memberof SurveysListData
     */
    surveys?: ShortSurvey;
    /**
     * 
     * @type {string}
     * @memberof SurveysListData
     */
    pagination?: string;
}
/**
 * SurveysTemplates model
 * @export
 * @interface SurveysTemplates
 */
export interface SurveysTemplates {
    /**
     * 
     * @type {ShortSurvey}
     * @memberof SurveysTemplates
     */
    surveys?: ShortSurvey;
    /**
     * 
     * @type {ShortSurveyTemplate}
     * @memberof SurveysTemplates
     */
    templates?: ShortSurveyTemplate;
    /**
     * 
     * @type {string}
     * @memberof SurveysTemplates
     */
    ttTemplates?: string;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * Тэг
     * @type {string}
     * @memberof Tag
     */
    title?: string;
}
/**
 * TagsHrPractices model
 * @export
 * @interface TagsHrPractices
 */
export interface TagsHrPractices {
    /**
     * 
     * @type {Tag}
     * @memberof TagsHrPractices
     */
    tags?: Tag;
    /**
     * 
     * @type {string}
     * @memberof TagsHrPractices
     */
    practices?: string;
    /**
     * 
     * @type {string}
     * @memberof TagsHrPractices
     */
    pagination?: string;
    /**
     * 
     * @type {string}
     * @memberof TagsHrPractices
     */
    counters?: string;
}
/**
 * TagsIndex model
 * @export
 * @interface TagsIndex
 */
export interface TagsIndex {
    /**
     * 
     * @type {Tag}
     * @memberof TagsIndex
     */
    tags?: Tag;
    /**
     * 
     * @type {string}
     * @memberof TagsIndex
     */
    pagination?: string;
}
/**
 * TagsQuestionTemplates model
 * @export
 * @interface TagsQuestionTemplates
 */
export interface TagsQuestionTemplates {
    /**
     * 
     * @type {Tag}
     * @memberof TagsQuestionTemplates
     */
    tags?: Tag;
    /**
     * 
     * @type {QuestionTemplate}
     * @memberof TagsQuestionTemplates
     */
    templates?: QuestionTemplate;
}
/**
 * Team model
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * ID команды
     * @type {string}
     * @memberof Team
     */
    id?: string;
    /**
     * Имя команды
     * @type {string}
     * @memberof Team
     */
    name?: string;
    /**
     * Имя команды для отображения
     * @type {string}
     * @memberof Team
     */
    displayName?: string;
    /**
     * ID компании
     * @type {string}
     * @memberof Team
     */
    companyId?: string;
    /**
     * Внешний ID команды
     * @type {string}
     * @memberof Team
     */
    externalId?: string;
    /**
     * Путь команды до родителя
     * @type {Array<string>}
     * @memberof Team
     */
    path?: Array<string>;
    /**
     * Кол-во человек в команде с учетом потомков
     * @type {number}
     * @memberof Team
     */
    staffPositionsWithDescendantsCounter?: number;
    /**
     * Есть ли дети у команды
     * @type {boolean}
     * @memberof Team
     */
    hasChildren?: boolean;
    /**
     * Имя родителя
     * @type {string}
     * @memberof Team
     */
    parentName?: string;
    /**
     * Имя родителя для отображения
     * @type {string}
     * @memberof Team
     */
    parentDisplayName?: string;
    /**
     * ID родителя
     * @type {string}
     * @memberof Team
     */
    parentId?: string;
    /**
     * Дочерние команды
     * @type {Array<Team>}
     * @memberof Team
     */
    children?: Array<Team>;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    fakesData?: string;
}
/**
 * TopmindQuery model
 * @export
 * @interface TopmindQuery
 */
export interface TopmindQuery {
    /**
     * ID Topmind запроса
     * @type {number}
     * @memberof TopmindQuery
     */
    id?: number;
    /**
     * Название Topmind запроса
     * @type {string}
     * @memberof TopmindQuery
     */
    name?: string;
    /**
     * Тело Topmind запроса
     * @type {string}
     * @memberof TopmindQuery
     */
    query?: string;
    /**
     * Поддельный ответ Topmind запроса
     * @type {string}
     * @memberof TopmindQuery
     */
    fakeResponse?: string;
    /**
     * Напоминалка
     * @type {string}
     * @memberof TopmindQuery
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * ID пользователя
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * ФИО пользователя
     * @type {string}
     * @memberof User
     */
    fullName?: string;
    /**
     * Должности пользователя
     * @type {string}
     * @memberof User
     */
    staffPositions?: string;
    /**
     * URL аватарки пользователя
     * @type {string}
     * @memberof User
     */
    avatarUrl?: string;
    /**
     * Команды пользователя
     * @type {string}
     * @memberof User
     */
    teams?: string;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * Отчество пользователя
     * @type {string}
     * @memberof User
     */
    middleName?: string;
    /**
     * Фамилия пользователя
     * @type {string}
     * @memberof User
     */
    lastName?: string;
}
/**
 * UserAnswer model
 * @export
 * @interface UserAnswer
 */
export interface UserAnswer {
    /**
     * 
     * @type {number}
     * @memberof UserAnswer
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAnswer
     */
    questionId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAnswer
     */
    answerId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAnswer
     */
    valueText?: string;
    /**
     * Вариант \"Не могу оценить\"
     * @type {boolean}
     * @memberof UserAnswer
     */
    notAnswered?: boolean;
    /**
     * Вариант ответа \"Другое\"
     * @type {boolean}
     * @memberof UserAnswer
     */
    otherAnswer?: boolean;
}
/**
 * UserGroup model
 * @export
 * @interface UserGroup
 */
export interface UserGroup {
    /**
     * ID группы
     * @type {number}
     * @memberof UserGroup
     */
    id?: number;
    /**
     * Название группы
     * @type {string}
     * @memberof UserGroup
     */
    title?: string;
    /**
     * Описание группы
     * @type {string}
     * @memberof UserGroup
     */
    description?: string;
    /**
     * 
     * @type {Role}
     * @memberof UserGroup
     */
    roles?: Role;
    /**
     * 
     * @type {string}
     * @memberof UserGroup
     */
    users?: string;
}
/**
 * UsersList model
 * @export
 * @interface UsersList
 */
export interface UsersList {
    /**
     * 
     * @type {User}
     * @memberof UsersList
     */
    users?: User;
    /**
     * 
     * @type {string}
     * @memberof UsersList
     */
    pagination?: string;
}
/**
 * XLSXReport model
 * @export
 * @interface XLSXReport
 */
export interface XLSXReport {
    /**
     * ID отчета
     * @type {number}
     * @memberof XLSXReport
     */
    id?: number;
    /**
     * Статус отчета
     * @type {string}
     * @memberof XLSXReport
     */
    status?: string;
    /**
     * Ссылка для скачивания файла
     * @type {string}
     * @memberof XLSXReport
     */
    link?: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * DELETE /admin/blocks/{id}
         * @summary Удаление блока
         * @param {number} id ID блока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminBlocksId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminBlocksId', 'id', id)
            const localVarPath = `/admin/blocks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/email_templates/{id}
         * @summary Удалить шаблон письма
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminEmailTemplatesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminEmailTemplatesId', 'id', id)
            const localVarPath = `/admin/email_templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/email_templates/{id}/trash_image
         * @summary Удалить картинку шаблона
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminEmailTemplatesIdTrashImage: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminEmailTemplatesIdTrashImage', 'id', id)
            const localVarPath = `/admin/email_templates/{id}/trash_image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/external_surveys/:id
         * @summary Удалить внешний опрос
         * @param {number} id ID внешнего опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminExternalSurveysId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminExternalSurveysId', 'id', id)
            const localVarPath = `/admin/external_surveys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/factors/{id}
         * @summary Удалить фактор
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminFactorsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminFactorsId', 'id', id)
            const localVarPath = `/admin/factors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/hr_practices/{id}
         * @summary Удалить практику
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminHrPracticesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminHrPracticesId', 'id', id)
            const localVarPath = `/admin/hr_practices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/hr_practices/{id}/trash_image
         * @summary Удалить картинку практики
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminHrPracticesIdTrashImage: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminHrPracticesIdTrashImage', 'id', id)
            const localVarPath = `/admin/hr_practices/{id}/trash_image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/pages/{id}
         * @summary Удаление страницы в опросе
         * @param {number} id ID страницы для удаления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminPagesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminPagesId', 'id', id)
            const localVarPath = `/admin/pages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/questions/{id}
         * @summary Удалить вопрос
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminQuestionsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminQuestionsId', 'id', id)
            const localVarPath = `/admin/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/questions/{id}/picture_trash
         * @summary Удалить картинку вопроса
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminQuestionsIdPictureTrash: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminQuestionsIdPictureTrash', 'id', id)
            const localVarPath = `/admin/questions/{id}/picture_trash`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/surveys
         * @summary Удалить несколько опросов насовсем
         * @param {Array<string>} surveySlugs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveys: async (surveySlugs: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlugs' is not null or undefined
            assertParamExists('deleteAdminSurveys', 'surveySlugs', surveySlugs)
            const localVarPath = `/admin/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (surveySlugs) {
                localVarFormParams.append('survey_slugs', surveySlugs.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}
         * @summary Удалить опрос насовсем
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlug: async (surveySlug: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('deleteAdminSurveysSurveySlug', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}/async
         * @summary Удалить опрос насовсем асинхронно
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugAsync: async (surveySlug: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('deleteAdminSurveysSurveySlugAsync', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/async`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/surveys/:survey_slug/fake_user_surveys
         * @summary “Удаление” некачественных анкет из аналитики
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugFakeUserSurveys: async (surveySlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('deleteAdminSurveysSurveySlugFakeUserSurveys', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/fake_user_surveys`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/surveys/:survey_slug/final_blocks/:id
         * @summary Удалить один из блоков страницы \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {string} id ID блока для удаления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugFinalBlocksId: async (surveySlug: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('deleteAdminSurveysSurveySlugFinalBlocksId', 'surveySlug', surveySlug)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminSurveysSurveySlugFinalBlocksId', 'id', id)
            const localVarPath = `/admin/surveys/{survey_slug}/final_blocks/{id}`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/surveys/:survey_slug/final_page
         * @summary Удалить страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugFinalPage: async (surveySlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('deleteAdminSurveysSurveySlugFinalPage', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/final_page`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}/image_trash
         * @summary Удалить картинку опроса
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugImageTrash: async (surveySlug: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('deleteAdminSurveysSurveySlugImageTrash', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/image_trash`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}/trash
         * @summary Удалить опрос в корзину
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugTrash: async (surveySlug: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('deleteAdminSurveysSurveySlugTrash', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/trash`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/templates/:id
         * @summary Удалить шаблона опроса компании
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminTemplatesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminTemplatesId', 'id', id)
            const localVarPath = `/admin/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DELETE /admin/user_groups/:id
         * @summary Обновить группу пользователей
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminUserGroupsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminUserGroupsId', 'id', id)
            const localVarPath = `/admin/user_groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/admin_area/surveys
         * @summary Получение списка опросов компании с разбивкой по статусам
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAdminAreaSurveys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/admin_area/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/analytics_templates
         * @summary Список шаблонов аналитики
         * @param {Array<string>} [slugs] Идентификаторы дефолтных темплейтов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsAnalyticsTemplates: async (slugs?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/analytics/analytics_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (slugs) {
                localVarFormParams.append('slugs', slugs.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/company/factor-dynamic
         * @summary Динамика фактора на основании завершенных опросов
         * @param {string} [factorName] Название фактора
         * @param {Array<string>} [factorNames] Массив названий факторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsCompanyFactorDynamic: async (factorName?: string, factorNames?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/analytics/company/factor-dynamic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (factorName !== undefined) {
                localVarQueryParameter['factor_name'] = factorName;
            }

            if (factorNames) {
                localVarQueryParameter['factor_names'] = factorNames.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/dashboard
         * @summary Дэшборд по аналитике для активного опроса в рамках компании
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsDashboard: async (teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/analytics/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/flexible/:slug/respondents/anonymous
         * @summary Проверка анонимности
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsFlexibleSlugRespondentsAnonymous: async (slug: string, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminAnalyticsFlexibleSlugRespondentsAnonymous', 'slug', slug)
            const localVarPath = `/admin/analytics/flexible/{slug}/respondents/anonymous`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /analytics/flexible/:slug/tabs
         * @summary Список вкладок для гибкой аналитики
         * @param {string} slug ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsFlexibleSlugTabs: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminAnalyticsFlexibleSlugTabs', 'slug', slug)
            const localVarPath = `/admin/analytics/flexible/{slug}/tabs`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/questions/{question_id}/answers
         * @summary Получение ответов на открытые вопросы
         * @param {number} questionId ID вопроса
         * @param {string} [theme] Тема
         * @param {string} [sentiment] Сентимент
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 40)
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsQuestionsQuestionIdAnswers: async (questionId: number, theme?: string, sentiment?: string, offset?: number, limit?: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('getAdminAnalyticsQuestionsQuestionIdAnswers', 'questionId', questionId)
            const localVarPath = `/admin/analytics/questions/{question_id}/answers`
                .replace(`{${"question_id"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (theme !== undefined) {
                localVarQueryParameter['theme'] = theme;
            }

            if (sentiment !== undefined) {
                localVarQueryParameter['sentiment'] = sentiment;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/questions/{question_id}/clustering
         * @summary Получение кластеризации открытого вопроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsQuestionsQuestionIdClustering: async (questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('getAdminAnalyticsQuestionsQuestionIdClustering', 'questionId', questionId)
            const localVarPath = `/admin/analytics/questions/{question_id}/clustering`
                .replace(`{${"question_id"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/questions/{question_id}/comments
         * @summary Получение сентимента открытого вопроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsQuestionsQuestionIdComments: async (questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('getAdminAnalyticsQuestionsQuestionIdComments', 'questionId', questionId)
            const localVarPath = `/admin/analytics/questions/{question_id}/comments`
                .replace(`{${"question_id"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/current
         * @summary Получить текущую активную команду для аналитики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysCurrent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/analytics/surveys/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/raw_data_reports/:report_id/download
         * @summary Возвращает `.zip` файл со сформированными `.xlsx` отчетами, по одному файлу на каждый опрос
         * @param {number} reportId ID отчёта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysRawDataReportsReportIdDownload: async (reportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysRawDataReportsReportIdDownload', 'reportId', reportId)
            const localVarPath = `/admin/analytics/surveys/raw_data_reports/{report_id}/download`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/raw_data_reports/:report_id/status
         * @summary Возвращает информацию о статусе отчета
         * @param {number} reportId ID отчёта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysRawDataReportsReportIdStatus: async (reportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysRawDataReportsReportIdStatus', 'reportId', reportId)
            const localVarPath = `/admin/analytics/surveys/raw_data_reports/{report_id}/status`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{report_id}/report_status
         * @summary Возвращает информацию о статусе отчета
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysReportIdReportStatus: async (reportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysReportIdReportStatus', 'reportId', reportId)
            const localVarPath = `/admin/analytics/surveys/{report_id}/report_status`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{slug}
         * @summary Получить опрос по slug
         * @param {string} slug Slug идентификатор опроса или комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSlug: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSlug', 'slug', slug)
            const localVarPath = `/admin/analytics/surveys/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * /admin/analytics/surveys/{slug}/burnout
         * @summary Возвращает данные для аналитики опроса выгорания
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSlugBurnout: async (slug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSlugBurnout', 'slug', slug)
            const localVarPath = `/admin/analytics/surveys/{slug}/burnout`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * /admin/analytics/surveys/{survey_slug}/csi
         * @summary Возвращает данные для аналитики опроса сsi
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSlugCsi: async (slug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSlugCsi', 'slug', slug)
            const localVarPath = `/admin/analytics/surveys/{slug}/csi`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /user_surveys/{slug}/meta-filters
         * @summary Получение списка мета вопросов опроса (продвинутая паспортичка)
         * @param {string} slug Slug идентификатор опроса или комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSlugMetaFilters: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSlugMetaFilters', 'slug', slug)
            const localVarPath = `/admin/analytics/surveys/{slug}/meta-filters`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/additional_questions
         * @summary Показывать ответы на вопросы, которых нет в нашем шаблоне
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugAdditionalQuestions: async (surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugAdditionalQuestions', 'surveySlug', surveySlug)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/additional_questions`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/comments
         * @summary Возвращает комментарии по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} teamIds ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugComments: async (surveySlug: string, questionId: number, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugComments', 'surveySlug', surveySlug)
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugComments', 'questionId', questionId)
            // verify required parameter 'teamIds' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugComments', 'teamIds', teamIds)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/comments`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['question_id'] = questionId;
            }

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * /admin/analytics/surveys/{survey_slug}/constructor
         * @summary Возвращает данные для аналитики опросов из конструктора
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugConstructor: async (surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugConstructor', 'surveySlug', surveySlug)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/constructor`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/constructor-open-question-answers
         * @summary Возвращает ответы на открытые вопросы для опроса из конструктора по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugConstructorOpenQuestionAnswers: async (surveySlug: string, questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugConstructorOpenQuestionAnswers', 'surveySlug', surveySlug)
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugConstructorOpenQuestionAnswers', 'questionId', questionId)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/constructor-open-question-answers`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['question_id'] = questionId;
            }

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factor-by-team
         * @summary Возвращает значение фактора в разрезе переданных команд
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {Array<string>} teamIds ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugFactorByTeam: async (surveySlug: string, factorName: string, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugFactorByTeam', 'surveySlug', surveySlug)
            // verify required parameter 'factorName' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugFactorByTeam', 'factorName', factorName)
            // verify required parameter 'teamIds' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugFactorByTeam', 'teamIds', teamIds)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/factor-by-team`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (factorName !== undefined) {
                localVarQueryParameter['factor_name'] = factorName;
            }

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factor-groups
         * @summary Получение кол-ва групп в разрезе фактора
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugFactorGroups: async (surveySlug: string, factorName: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugFactorGroups', 'surveySlug', surveySlug)
            // verify required parameter 'factorName' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugFactorGroups', 'factorName', factorName)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/factor-groups`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (factorName !== undefined) {
                localVarQueryParameter['factor_name'] = factorName;
            }

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factor-questions
         * @summary Возвращает вопросы для фактора и ответы на них
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugFactorQuestions: async (surveySlug: string, factorName: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugFactorQuestions', 'surveySlug', surveySlug)
            // verify required parameter 'factorName' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugFactorQuestions', 'factorName', factorName)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/factor-questions`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (factorName !== undefined) {
                localVarQueryParameter['factor_name'] = factorName;
            }

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }

            if (factorGroup !== undefined) {
                localVarQueryParameter['factor_group'] = factorGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factors
         * @summary Возвращает значение факторов по определенной команде (командам)
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugFactors: async (surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugFactors', 'surveySlug', surveySlug)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/factors`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }

            if (factorGroup !== undefined) {
                localVarQueryParameter['factor_group'] = factorGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/filter-values
         * @summary Получение доступных значений для фильтров
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugFilterValues: async (surveySlug: string, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugFilterValues', 'surveySlug', surveySlug)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/filter-values`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/open-question-answers
         * @summary Получение ответов на открытый вопрос
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugOpenQuestionAnswers: async (surveySlug: string, questionId: number, teamIds?: Array<string>, factorGroup?: string, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugOpenQuestionAnswers', 'surveySlug', surveySlug)
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugOpenQuestionAnswers', 'questionId', questionId)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/open-question-answers`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['question_id'] = questionId;
            }

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (factorGroup !== undefined) {
                localVarQueryParameter['factor_group'] = factorGroup;
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/open-questions
         * @summary Получение открытых вопросов
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {string} [factorName] Название фактора
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugOpenQuestions: async (surveySlug: string, teamIds?: Array<string>, factorName?: string, factorGroup?: string, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugOpenQuestions', 'surveySlug', surveySlug)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/open-questions`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (factorName !== undefined) {
                localVarQueryParameter['factor_name'] = factorName;
            }

            if (factorGroup !== undefined) {
                localVarQueryParameter['factor_group'] = factorGroup;
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/other-answers
         * @summary Возвращает варианты \"Другое\" по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} teamIds ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugOtherAnswers: async (surveySlug: string, questionId: number, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugOtherAnswers', 'surveySlug', surveySlug)
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugOtherAnswers', 'questionId', questionId)
            // verify required parameter 'teamIds' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugOtherAnswers', 'teamIds', teamIds)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/other-answers`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['question_id'] = questionId;
            }

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/priority_factors
         * @summary Возвращает топ факторов с которыми надо поработать
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugPriorityFactors: async (surveySlug: string, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugPriorityFactors', 'surveySlug', surveySlug)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/priority_factors`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/timings-by-team
         * @summary Возвращает статистику прохождения опроса в разрезе команды (команд)
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugTimingsByTeam: async (surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugTimingsByTeam', 'surveySlug', surveySlug)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/timings-by-team`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/xlsx-reports/open-questions
         * @summary Возвращает XLSX файл с ответами на открытые вопросы
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugXlsxReportsOpenQuestions: async (surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugXlsxReportsOpenQuestions', 'surveySlug', surveySlug)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/xlsx-reports/open-questions`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }

            if (factorGroup !== undefined) {
                localVarQueryParameter['factor_group'] = factorGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/xlsx_reports/{report_id}/download
         * @summary Возвращает XLSX файл с данными отчета
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} reportId ID отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugXlsxReportsReportIdDownload: async (surveySlug: string, reportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugXlsxReportsReportIdDownload', 'surveySlug', surveySlug)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getAdminAnalyticsSurveysSurveySlugXlsxReportsReportIdDownload', 'reportId', reportId)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/xlsx-reports/{report_id}/download`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)))
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/analytics/teams/tree
         * @summary Получение дерева команд компании
         * @param {string} [surveySlug] Slug опроса (для разделения прав при просмотре аналитики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsTeamsTree: async (surveySlug?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/analytics/teams/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveySlug !== undefined) {
                localVarQueryParameter['survey_slug'] = surveySlug;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/company-historical-data
         * @summary Получение исторических значений факторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCompanyHistoricalData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/company-historical-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/company-historical-data/{survey_slug}
         * @summary Получение исторических значений факторов
         * @param {number} surveySlug 
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {number} [offset] Оффсет
         * @param {number} [limit] Количество записей на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCompanyHistoricalDataSurveySlug: async (surveySlug: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminCompanyHistoricalDataSurveySlug', 'surveySlug', surveySlug)
            const localVarPath = `/admin/company-historical-data/{survey_slug}`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/complex_reports
         * @summary Создание комплексного отчета
         * @param {string} [q] Строка для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminComplexReports: async (q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/complex_reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/complex_reports/analytics_kinds
         * @summary Список доступных analytics_kind для комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminComplexReportsAnalyticsKinds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/complex_reports/analytics_kinds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/complex_reports/{id}
         * @summary Получение информации о комплексном отчете
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminComplexReportsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminComplexReportsId', 'id', id)
            const localVarPath = `/admin/complex_reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/complex_reports/{id}
         * @summary Получение списка опросов компании доступных для добавления в комплексный отчет
         * @param {number} id ID комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminComplexReportsIdSurveys: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminComplexReportsIdSurveys', 'id', id)
            const localVarPath = `/admin/complex_reports/{id}/surveys`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/email_templates/{id}
         * @summary Шаблон письма
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEmailTemplatesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminEmailTemplatesId', 'id', id)
            const localVarPath = `/admin/email_templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/factor_business_unit
         * @summary Сравнение фактора по бизнес-юнитам
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {number} level Уровень команды
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugFactorBusinessUnit: async (slug: string, level: number, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugFactorBusinessUnit', 'slug', slug)
            // verify required parameter 'level' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugFactorBusinessUnit', 'level', level)
            const localVarPath = `/admin/express_surveys/analytics/{slug}/factor_business_unit`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/filter_values
         * @summary Получение данных для блока \"Верхнеуровневый фильтр по командам\"
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugFilterValues: async (slug: string, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugFilterValues', 'slug', slug)
            const localVarPath = `/admin/express_surveys/analytics/{slug}/filter_values`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/main_questions
         * @summary Данные для вкладки \"Факторы\" экспресс-опросов
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] UUID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugMainQuestions: async (slug: string, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugMainQuestions', 'slug', slug)
            const localVarPath = `/admin/express_surveys/analytics/{slug}/main_questions`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/main_results
         * @summary Данные для вкладки \"Основные результаты\" экспресс-опросов
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] UUID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugMainResults: async (slug: string, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugMainResults', 'slug', slug)
            const localVarPath = `/admin/express_surveys/analytics/{slug}/main_results`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/main_results/business_units_comparison
         * @summary Данные для вкладки \"Основные результаты\" экспресс-опросов | Сравнение по бизнес-юнитам
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {number} level Выбранный уровень команд для сравнения по бизнес-юнитам
         * @param {Array<string>} [teamIds] UUIDs команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugMainResultsBusinessUnitsComparison: async (slug: string, level: number, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugMainResultsBusinessUnitsComparison', 'slug', slug)
            // verify required parameter 'level' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugMainResultsBusinessUnitsComparison', 'level', level)
            const localVarPath = `/admin/express_surveys/analytics/{slug}/main_results/business_units_comparison`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/nps_basic
         * @summary Получение статистики по eNPS/NPS
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugNpsBasic: async (slug: string, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugNpsBasic', 'slug', slug)
            const localVarPath = `/admin/express_surveys/analytics/{slug}/nps_basic`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/nps_business_unit
         * @summary Сравнение eNPS/NPS факторов по бизнес-юнитам
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {number} level Уровень команды
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugNpsBusinessUnit: async (slug: string, level: number, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugNpsBusinessUnit', 'slug', slug)
            // verify required parameter 'level' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugNpsBusinessUnit', 'level', level)
            const localVarPath = `/admin/express_surveys/analytics/{slug}/nps_business_unit`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/open_questions/not_nps
         * @summary Получение статистики по открытым вопросам(без NPS)
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugOpenQuestionsNotNps: async (slug: string, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugOpenQuestionsNotNps', 'slug', slug)
            const localVarPath = `/admin/express_surveys/analytics/{slug}/open_questions/not_nps`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/open_questions/nps
         * @summary Получение статистики по открытым вопросам(для NPS)
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugOpenQuestionsNps: async (slug: string, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAdminExpressSurveysAnalyticsSlugOpenQuestionsNps', 'slug', slug)
            const localVarPath = `/admin/express_surveys/analytics/{slug}/open_questions/nps`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/external_surveys
         * @summary Список внешних опросов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExternalSurveys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/external_surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/external_surveys/:id
         * @summary Получить внешний опрос
         * @param {number} id ID внешнего опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExternalSurveysId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminExternalSurveysId', 'id', id)
            const localVarPath = `/admin/external_surveys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/factors
         * @summary Список факторов
         * @param {string} [surveySlug] Slug опроса куда добавляем факторы
         * @param {string} [questionType] Тип вопроса для фильтра
         * @param {number} [maxAnswers] Количество ответов для фильтра
         * @param {boolean} [questionRequired] Обязательность вопроса для фильтра
         * @param {boolean} [onlyFactors] Вернуть только факторы которые не привязаны к индексам
         * @param {Array<string>} [factorsGroupsNames] Названия групп факторов для фильтра
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFactors: async (surveySlug?: string, questionType?: string, maxAnswers?: number, questionRequired?: boolean, onlyFactors?: boolean, factorsGroupsNames?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/factors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (surveySlug !== undefined) {
                localVarQueryParameter['survey_slug'] = surveySlug;
            }

            if (questionType !== undefined) {
                localVarQueryParameter['question_type'] = questionType;
            }

            if (maxAnswers !== undefined) {
                localVarQueryParameter['max_answers'] = maxAnswers;
            }

            if (questionRequired !== undefined) {
                localVarQueryParameter['question_required'] = questionRequired;
            }

            if (onlyFactors !== undefined) {
                localVarQueryParameter['only_factors'] = onlyFactors;
            }

            if (factorsGroupsNames) {
                localVarFormParams.append('factors_groups_names', factorsGroupsNames.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/factors_groups
         * @summary Список групп факторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFactorsGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/factors_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/factors/{id}
         * @summary Получить фактор
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFactorsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminFactorsId', 'id', id)
            const localVarPath = `/admin/factors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/features/status
         * @summary Получение состояния feature флагов
         * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFeaturesStatus: async (xAuthToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('getAdminFeaturesStatus', 'xAuthToken', xAuthToken)
            const localVarPath = `/admin/features/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['X-Auth-Token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/heatmaps/:id
         * @summary Получить статус тепловой карты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminHeatmapsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminHeatmapsId', 'id', id)
            const localVarPath = `/admin/heatmaps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/hr_practices
         * @summary Список HR практик
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {'all' | 'user' | 'tt'} [scope] Скоуп практик
         * @param {Array<string>} [tags] Тэги для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminHrPractices: async (offset?: number, limit?: number, scope?: 'all' | 'user' | 'tt', tags?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/hr_practices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (tags) {
                localVarFormParams.append('tags', tags.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/hr_practices/{id}
         * @summary Список HR практик
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminHrPracticesId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminHrPracticesId', 'id', id)
            const localVarPath = `/admin/hr_practices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/image_templates
         * @summary Список шаблонов картинок
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminImageTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/image_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/indicies
         * @summary Список индексов (групп факторов с пометкой индекс) для добавления в опрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminIndicies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/indicies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/me
         * @summary Информация о текущем пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/meta_info
         * @summary Получить доступную метаинфу о пользователях для компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminMetaInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/meta_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/pages
         * @summary Список страниц для данного опроса (с возможностью поиска)
         * @param {string} surveySlug Slug опроса
         * @param {string} [q] Шаблон для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPages: async (surveySlug: string, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminPages', 'surveySlug', surveySlug)
            const localVarPath = `/admin/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveySlug !== undefined) {
                localVarQueryParameter['survey_slug'] = surveySlug;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/question_templates
         * @summary Список шаблонов вопросов
         * @param {number} [page] Страница
         * @param {number} [perPage] Количество записей на странице
         * @param {string} [tag] Тэг для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminQuestionTemplates: async (page?: number, perPage?: number, tag?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/question_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/roles
         * @summary Список ролей и абилок
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/surveys
         * @summary Список опросов с шаблонами для личного кабинета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/surveys/active/notification-reminders
         * @summary Получение списка отправленных уведомлений в течении 24х часов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysActiveNotificationReminders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/surveys/active/notification-reminders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/surveys/drafts
         * @summary Список черновиков опросов для личного кабинета
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysDrafts: async (offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/surveys/drafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/surveys/{survey_slug}
         * @summary Получить опрос для редактирования
         * @param {string} surveySlug Идентификатор опроса
         * @param {string} [language] Язык опроса (перевода)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlug: async (surveySlug: string, language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminSurveysSurveySlug', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/surveys/:survey_slug/final_page
         * @summary Получить страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {string} [language] Язык перевода
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugFinalPage: async (surveySlug: string, language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminSurveysSurveySlugFinalPage', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/final_page`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/surveys/:survey_slug/orgstructure_pages
         * @summary Получить настройки оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugOrgstructurePages: async (surveySlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminSurveysSurveySlugOrgstructurePages', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/orgstructure_pages`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/surveys/{surveys_slug}/pptx/{pptx_id}/download
         * @summary Получение pptx опроса
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} pptxId ID презентации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugPptxPptxIdDownload: async (surveySlug: string, pptxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminSurveysSurveySlugPptxPptxIdDownload', 'surveySlug', surveySlug)
            // verify required parameter 'pptxId' is not null or undefined
            assertParamExists('getAdminSurveysSurveySlugPptxPptxIdDownload', 'pptxId', pptxId)
            const localVarPath = `/admin/surveys/{survey_slug}/pptx/{pptx_id}/download`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)))
                .replace(`{${"pptx_id"}}`, encodeURIComponent(String(pptxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/surveys/{surveys_slug}/pptx/{pptx_id}/status
         * @summary Возвращает информацию о статусе презентации
         * @param {number} surveySlug 
         * @param {number} pptxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugPptxPptxIdStatus: async (surveySlug: number, pptxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminSurveysSurveySlugPptxPptxIdStatus', 'surveySlug', surveySlug)
            // verify required parameter 'pptxId' is not null or undefined
            assertParamExists('getAdminSurveysSurveySlugPptxPptxIdStatus', 'pptxId', pptxId)
            const localVarPath = `/admin/surveys/{survey_slug}/pptx/{pptx_id}/status`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)))
                .replace(`{${"pptx_id"}}`, encodeURIComponent(String(pptxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/surveys/{survey_slug}/root-team
         * @summary Получить корневую команду для опроса
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugRootTeam: async (surveySlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminSurveysSurveySlugRootTeam', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/root-team`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/surveys/{survey_slug}/timeline
         * @summary Получить таймлайн опросов компании
         * @param {string} surveySlug Идентификатор опроса
         * @param {string} startDate Начальная дата интервала
         * @param {string} endDate Конечная дата интервала
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugTimeline: async (surveySlug: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getAdminSurveysSurveySlugTimeline', 'surveySlug', surveySlug)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAdminSurveysSurveySlugTimeline', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAdminSurveysSurveySlugTimeline', 'endDate', endDate)
            const localVarPath = `/admin/surveys/{survey_slug}/timeline`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/surveys/trashed
         * @summary Список опросов в корзине
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysTrashed: async (offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/surveys/trashed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/tags
         * @summary Получить список тегов с пагинацией, поиск по параметру q (если пустая строка в q, то отдаем все)
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 6)
         * @param {string} [q] Шаблон для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTags: async (offset?: number, limit?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/teams/
         * @summary Получение команд по совпадению имени (в будущем возможно по дефолту будет отдавать какие-то команды)
         * @param {string} name Совпадение, по которому искать команды
         * @param {string} [surveySlug] Slug опроса для аналитики которого ищем команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeams: async (name: string, surveySlug?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getAdminTeams', 'name', name)
            const localVarPath = `/admin/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (surveySlug !== undefined) {
                localVarQueryParameter['survey_slug'] = surveySlug;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/teams/children
         * @summary Получение детей для команд
         * @param {string} [surveySlug] 
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsChildren: async (surveySlug?: string, teamIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/teams/children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (surveySlug !== undefined) {
                localVarQueryParameter['survey_slug'] = surveySlug;
            }

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/teams/{id}
         * @summary Получение данный команды по ID
         * @param {string} id ID команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminTeamsId', 'id', id)
            const localVarPath = `/admin/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/teams/{id}/children
         * @summary Получение детей для команды
         * @param {string} id ID команды
         * @param {string} [surveySlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsIdChildren: async (id: string, surveySlug?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminTeamsIdChildren', 'id', id)
            const localVarPath = `/admin/teams/{id}/children`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveySlug !== undefined) {
                localVarQueryParameter['survey_slug'] = surveySlug;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/teams/{id}/parent
         * @summary Получение ID вышестоящей команды
         * @param {string} id ID команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsIdParent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminTeamsIdParent', 'id', id)
            const localVarPath = `/admin/teams/{id}/parent`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/teams/{id}/parents
         * @summary Получение дерево вышестоящих команд
         * @param {string} id ID команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsIdParents: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminTeamsIdParents', 'id', id)
            const localVarPath = `/admin/teams/{id}/parents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/teams/ids/merge
         * @summary Схлопывание team ids
         * @param {Array<string>} teamIds ID команд
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAdminTeamsIdsMerge: async (teamIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamIds' is not null or undefined
            assertParamExists('getAdminTeamsIdsMerge', 'teamIds', teamIds)
            const localVarPath = `/admin/teams/ids/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (teamIds) {
                localVarFormParams.append('team_ids', teamIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/teams/tree
         * @summary Получение дерева команд компании
         * @param {string} [surveySlug] Slug опроса (для разделения прав при просмотре аналитики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsTree: async (surveySlug?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/teams/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (surveySlug !== undefined) {
                localVarQueryParameter['survey_slug'] = surveySlug;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/templates
         * @summary Список шаблонов опросов для личного кабинета
         * @param {number} [offset] Оффсет
         * @param {number} [limit] Количество записей на странице
         * @param {'all' | 'user' | 'tt'} [scope] Скоуп шаблонов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTemplates: async (offset?: number, limit?: number, scope?: 'all' | 'user' | 'tt', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/user_groups
         * @summary Список групп пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/user_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /admin/user_groups/:id
         * @summary Полная информация о группе пользователей
         * @param {number} id ID группы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserGroupsId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminUserGroupsId', 'id', id)
            const localVarPath = `/admin/user_groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * /admin/users
         * @summary Список пользователей компании
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {string} [q] Шаблон поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUsers: async (offset?: number, limit?: number, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/analytics/analytics_templates/import
         * @summary Обновить шаблон аналитики
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {number} [templateId] ID импортируемого темплейта
         * @param {boolean} [_default] Является ли шаблон дефолтным
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsAnalyticsTemplatesImport: async (slug: string, templateId?: number, _default?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('postAdminAnalyticsAnalyticsTemplatesImport', 'slug', slug)
            const localVarPath = `/admin/analytics/analytics_templates/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (templateId !== undefined) {
                localVarQueryParameter['template_id'] = templateId;
            }

            if (_default !== undefined) {
                localVarQueryParameter['default'] = _default;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /analytics/flexible/:slug/additional_questions/comparison
         * @summary Сравнительные данные по индексу
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {string} [comparisonKind] Тип сравнения
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugAdditionalQuestionsComparison: async (slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugAdditionalQuestionsComparison', 'slug', slug)
            // verify required parameter 'tabSlug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugAdditionalQuestionsComparison', 'tabSlug', tabSlug)
            const localVarPath = `/admin/analytics/flexible/{slug}/additional_questions/comparison`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"tab_slug"}}`, encodeURIComponent(String(tabSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (comparisonKind !== undefined) {
                localVarQueryParameter['comparison_kind'] = comparisonKind;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /analytics/flexible/:slug/additional_questions/main
         * @summary Основные данные по дополнительным вопросам
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugAdditionalQuestionsMain: async (slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugAdditionalQuestionsMain', 'slug', slug)
            // verify required parameter 'tabSlug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugAdditionalQuestionsMain', 'tabSlug', tabSlug)
            const localVarPath = `/admin/analytics/flexible/{slug}/additional_questions/main`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"tab_slug"}}`, encodeURIComponent(String(tabSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /analytics/flexible/:slug/main_results/main
         * @summary Основные данные для основных результатов
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugMainResultsMain: async (slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugMainResultsMain', 'slug', slug)
            // verify required parameter 'tabSlug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugMainResultsMain', 'tabSlug', tabSlug)
            const localVarPath = `/admin/analytics/flexible/{slug}/main_results/main`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"tab_slug"}}`, encodeURIComponent(String(tabSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /analytics/flexible/:slug/single_index/comparison
         * @summary Сравнительные данные по индексу
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {string} [comparisonKind] Тип сравнения
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugSingleIndexComparison: async (slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugSingleIndexComparison', 'slug', slug)
            // verify required parameter 'tabSlug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugSingleIndexComparison', 'tabSlug', tabSlug)
            const localVarPath = `/admin/analytics/flexible/{slug}/single_index/comparison`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"tab_slug"}}`, encodeURIComponent(String(tabSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (comparisonKind !== undefined) {
                localVarQueryParameter['comparison_kind'] = comparisonKind;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /analytics/flexible/:slug/single_index/main
         * @summary Основные данные по индексу
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugSingleIndexMain: async (slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugSingleIndexMain', 'slug', slug)
            // verify required parameter 'tabSlug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugSingleIndexMain', 'tabSlug', tabSlug)
            const localVarPath = `/admin/analytics/flexible/{slug}/single_index/main`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"tab_slug"}}`, encodeURIComponent(String(tabSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /analytics/flexible/:slug/survey_participants/main
         * @summary Основные данные для участников исследования
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugSurveyParticipantsMain: async (slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugSurveyParticipantsMain', 'slug', slug)
            // verify required parameter 'tabSlug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugSurveyParticipantsMain', 'tabSlug', tabSlug)
            const localVarPath = `/admin/analytics/flexible/{slug}/survey_participants/main`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"tab_slug"}}`, encodeURIComponent(String(tabSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /analytics/flexible/:slug/vertical_factors/comparison
         * @summary Сравнительные данные для вертикальных факторов
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {string} [comparisonKind] Тип сравнения
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugVerticalFactorsComparison: async (slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugVerticalFactorsComparison', 'slug', slug)
            // verify required parameter 'tabSlug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugVerticalFactorsComparison', 'tabSlug', tabSlug)
            const localVarPath = `/admin/analytics/flexible/{slug}/vertical_factors/comparison`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"tab_slug"}}`, encodeURIComponent(String(tabSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (comparisonKind !== undefined) {
                localVarQueryParameter['comparison_kind'] = comparisonKind;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /analytics/flexible/:slug/vertical_factors/main
         * @summary Основные данные для вертикальных факторов
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugVerticalFactorsMain: async (slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugVerticalFactorsMain', 'slug', slug)
            // verify required parameter 'tabSlug' is not null or undefined
            assertParamExists('postAdminAnalyticsFlexibleSlugVerticalFactorsMain', 'tabSlug', tabSlug)
            const localVarPath = `/admin/analytics/flexible/{slug}/vertical_factors/main`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"tab_slug"}}`, encodeURIComponent(String(tabSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/analytics/surveys/raw_data_reports/export
         * @summary Создает запрос на выгрузку .zip архива с отчётами
         * @param {PostAdminAnalyticsSurveysRawDataReportsExportRequest} postAdminAnalyticsSurveysRawDataReportsExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysRawDataReportsExport: async (postAdminAnalyticsSurveysRawDataReportsExportRequest: PostAdminAnalyticsSurveysRawDataReportsExportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminAnalyticsSurveysRawDataReportsExportRequest' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysRawDataReportsExport', 'postAdminAnalyticsSurveysRawDataReportsExportRequest', postAdminAnalyticsSurveysRawDataReportsExportRequest)
            const localVarPath = `/admin/analytics/surveys/raw_data_reports/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsSurveysRawDataReportsExportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/additional_questions
         * @summary Показывать ответы на вопросы, которых нет в нашем шаблоне
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest} [postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysSurveySlugAdditionalQuestions: async (surveySlug: string, postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest?: PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugAdditionalQuestions', 'surveySlug', surveySlug)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/additional_questions`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/comments
         * @summary Возвращает комментарии по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysSurveySlugComments: async (surveySlug: string, questionId: number, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugComments', 'surveySlug', surveySlug)
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugComments', 'questionId', questionId)
            // verify required parameter 'postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugComments', 'postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest', postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/comments`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['question_id'] = questionId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/factor-by-team
         * @summary Возвращает значение фактора в разрезе переданных команд
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysSurveySlugFactorByTeam: async (surveySlug: string, factorName: string, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugFactorByTeam', 'surveySlug', surveySlug)
            // verify required parameter 'factorName' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugFactorByTeam', 'factorName', factorName)
            // verify required parameter 'postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugFactorByTeam', 'postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest', postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/factor-by-team`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (factorName !== undefined) {
                localVarQueryParameter['factor_name'] = factorName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/other-answers
         * @summary Возвращает варианты \"Другое\" по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysSurveySlugOtherAnswers: async (surveySlug: string, questionId: number, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugOtherAnswers', 'surveySlug', surveySlug)
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugOtherAnswers', 'questionId', questionId)
            // verify required parameter 'postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugOtherAnswers', 'postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest', postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/other-answers`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['question_id'] = questionId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/xlsx-reports-export
         * @summary Создает запрос на выгрузку xlsx файла
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {boolean} withChildren Вместе с дочерними подразделениями
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {Array<'index' | 'structure' | 'benchmarking' | 'answer_distribution'>} [engagement] Что показывать в вовлеченности
         * @param {Array<'index' | 'benchmarking' | 'answer_distribution'>} [satisfaction] Что показывать в удовлетворенности
         * @param {Array<'index' | 'groups'>} [enpsCompany] Что показывать в eNPS
         * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [baseFactors] Что показывать в базовых факторах
         * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [enchancingFactors] Что показывать в усиливающих факторах
         * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [employeeExperience] Что показывать в индексе опыта сотрудника
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysSurveySlugXlsxReportsExport: async (surveySlug: string, withChildren: boolean, teamIds?: Array<string>, jobPositions?: Array<string>, jobFunctions?: Array<string>, jobDurations?: Array<string>, jobPosition?: Array<string>, jobFunction?: Array<string>, jobDuration?: Array<string>, engagement?: Array<'index' | 'structure' | 'benchmarking' | 'answer_distribution'>, satisfaction?: Array<'index' | 'benchmarking' | 'answer_distribution'>, enpsCompany?: Array<'index' | 'groups'>, baseFactors?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, enchancingFactors?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, employeeExperience?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugXlsxReportsExport', 'surveySlug', surveySlug)
            // verify required parameter 'withChildren' is not null or undefined
            assertParamExists('postAdminAnalyticsSurveysSurveySlugXlsxReportsExport', 'withChildren', withChildren)
            const localVarPath = `/admin/analytics/surveys/{survey_slug}/xlsx-reports-export`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (withChildren !== undefined) {
                localVarQueryParameter['with_children'] = withChildren;
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }

            if (jobPosition) {
                localVarQueryParameter['job_position'] = jobPosition.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunction) {
                localVarQueryParameter['job_function'] = jobFunction.join(COLLECTION_FORMATS.csv);
            }

            if (jobDuration) {
                localVarQueryParameter['job_duration'] = jobDuration.join(COLLECTION_FORMATS.csv);
            }

            if (engagement) {
                localVarQueryParameter['engagement'] = engagement.join(COLLECTION_FORMATS.csv);
            }

            if (satisfaction) {
                localVarQueryParameter['satisfaction'] = satisfaction.join(COLLECTION_FORMATS.csv);
            }

            if (enpsCompany) {
                localVarQueryParameter['enps_company'] = enpsCompany.join(COLLECTION_FORMATS.csv);
            }

            if (baseFactors) {
                localVarQueryParameter['base_factors'] = baseFactors.join(COLLECTION_FORMATS.csv);
            }

            if (enchancingFactors) {
                localVarQueryParameter['enchancing_factors'] = enchancingFactors.join(COLLECTION_FORMATS.csv);
            }

            if (employeeExperience) {
                localVarQueryParameter['employee_experience'] = employeeExperience.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/blocks
         * @summary Создание блока в странице опроса
         * @param {PostAdminBlocksRequest} postAdminBlocksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminBlocks: async (postAdminBlocksRequest: PostAdminBlocksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminBlocksRequest' is not null or undefined
            assertParamExists('postAdminBlocks', 'postAdminBlocksRequest', postAdminBlocksRequest)
            const localVarPath = `/admin/blocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminBlocksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/blocks/{id}/copy
         * @summary Копирование блока
         * @param {number} id ID блока для копирования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminBlocksIdCopy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postAdminBlocksIdCopy', 'id', id)
            const localVarPath = `/admin/blocks/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/branching_settings
         * @summary Обновить настройки ветвления для вопроса
         * @param {PostAdminBranchingSettingsRequest} postAdminBranchingSettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminBranchingSettings: async (postAdminBranchingSettingsRequest: PostAdminBranchingSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminBranchingSettingsRequest' is not null or undefined
            assertParamExists('postAdminBranchingSettings', 'postAdminBranchingSettingsRequest', postAdminBranchingSettingsRequest)
            const localVarPath = `/admin/branching_settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminBranchingSettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/companies/import
         * @summary Импорт компании из оргструктуры
         * @param {PostAdminCompaniesImportRequest} postAdminCompaniesImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminCompaniesImport: async (postAdminCompaniesImportRequest: PostAdminCompaniesImportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminCompaniesImportRequest' is not null or undefined
            assertParamExists('postAdminCompaniesImport', 'postAdminCompaniesImportRequest', postAdminCompaniesImportRequest)
            const localVarPath = `/admin/companies/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminCompaniesImportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/complex_reports
         * @summary Создание комплексного отчета
         * @param {PostAdminComplexReports} adminComplexReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminComplexReports: async (adminComplexReports: PostAdminComplexReports, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminComplexReports' is not null or undefined
            assertParamExists('postAdminComplexReports', 'adminComplexReports', adminComplexReports)
            const localVarPath = `/admin/complex_reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminComplexReports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/email_templates
         * @summary Создание шаблона письма
         * @param {PostAdminEmailTemplatesRequest} postAdminEmailTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminEmailTemplates: async (postAdminEmailTemplatesRequest: PostAdminEmailTemplatesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminEmailTemplatesRequest' is not null or undefined
            assertParamExists('postAdminEmailTemplates', 'postAdminEmailTemplatesRequest', postAdminEmailTemplatesRequest)
            const localVarPath = `/admin/email_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminEmailTemplatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/email_templates/{id}/test_email_message
         * @summary Отправить тестовое письмо
         * @param {string} id Идентификатор шаблона
         * @param {PostAdminEmailTemplatesIdTestEmailMessage} adminEmailTemplatesIdTestEmailMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminEmailTemplatesIdTestEmailMessage: async (id: string, adminEmailTemplatesIdTestEmailMessage: PostAdminEmailTemplatesIdTestEmailMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postAdminEmailTemplatesIdTestEmailMessage', 'id', id)
            // verify required parameter 'adminEmailTemplatesIdTestEmailMessage' is not null or undefined
            assertParamExists('postAdminEmailTemplatesIdTestEmailMessage', 'adminEmailTemplatesIdTestEmailMessage', adminEmailTemplatesIdTestEmailMessage)
            const localVarPath = `/admin/email_templates/{id}/test_email_message`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEmailTemplatesIdTestEmailMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/external_surveys
         * @summary Создать внешний опрос
         * @param {PostAdminExternalSurveys} adminExternalSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminExternalSurveys: async (adminExternalSurveys: PostAdminExternalSurveys, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminExternalSurveys' is not null or undefined
            assertParamExists('postAdminExternalSurveys', 'adminExternalSurveys', adminExternalSurveys)
            const localVarPath = `/admin/external_surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminExternalSurveys, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/factors
         * @summary Создать фактор
         * @param {PostAdminFactors} adminFactors 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminFactors: async (adminFactors: PostAdminFactors, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminFactors' is not null or undefined
            assertParamExists('postAdminFactors', 'adminFactors', adminFactors)
            const localVarPath = `/admin/factors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFactors, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/factors_groups/:id/add_factors
         * @summary Список групп факторов
         * @param {number} id 
         * @param {PostAdminFactorsGroupsIdAddFactorsRequest} postAdminFactorsGroupsIdAddFactorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminFactorsGroupsIdAddFactors: async (id: number, postAdminFactorsGroupsIdAddFactorsRequest: PostAdminFactorsGroupsIdAddFactorsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postAdminFactorsGroupsIdAddFactors', 'id', id)
            // verify required parameter 'postAdminFactorsGroupsIdAddFactorsRequest' is not null or undefined
            assertParamExists('postAdminFactorsGroupsIdAddFactors', 'postAdminFactorsGroupsIdAddFactorsRequest', postAdminFactorsGroupsIdAddFactorsRequest)
            const localVarPath = `/admin/factors_groups/{id}/add_factors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminFactorsGroupsIdAddFactorsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/factors/import
         * @summary Обновление фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminFactorsImport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/factors/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/features/disable
         * @summary Выключение фичи
         * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
         * @param {string} featureName Название фичи
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminFeaturesDisable: async (xAuthToken: string, featureName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('postAdminFeaturesDisable', 'xAuthToken', xAuthToken)
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('postAdminFeaturesDisable', 'featureName', featureName)
            const localVarPath = `/admin/features/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (featureName !== undefined) {
                localVarQueryParameter['feature_name'] = featureName;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['X-Auth-Token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/features/enable
         * @summary Включение фичи
         * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
         * @param {string} featureName Название фичи
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminFeaturesEnable: async (xAuthToken: string, featureName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('postAdminFeaturesEnable', 'xAuthToken', xAuthToken)
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('postAdminFeaturesEnable', 'featureName', featureName)
            const localVarPath = `/admin/features/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (featureName !== undefined) {
                localVarQueryParameter['feature_name'] = featureName;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['X-Auth-Token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/heatmaps
         * @summary Создать тепловую карту
         * @param {PostAdminHeatmapsRequest} postAdminHeatmapsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminHeatmaps: async (postAdminHeatmapsRequest: PostAdminHeatmapsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminHeatmapsRequest' is not null or undefined
            assertParamExists('postAdminHeatmaps', 'postAdminHeatmapsRequest', postAdminHeatmapsRequest)
            const localVarPath = `/admin/heatmaps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminHeatmapsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/hr_practices
         * @summary Создать практику
         * @param {PostAdminHrPracticesRequest} postAdminHrPracticesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminHrPractices: async (postAdminHrPracticesRequest: PostAdminHrPracticesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminHrPracticesRequest' is not null or undefined
            assertParamExists('postAdminHrPractices', 'postAdminHrPracticesRequest', postAdminHrPracticesRequest)
            const localVarPath = `/admin/hr_practices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminHrPracticesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/hr_practices/{id}/set_invisible
         * @summary Сделать практику невидимой для компании
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminHrPracticesIdSetInvisible: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postAdminHrPracticesIdSetInvisible', 'id', id)
            const localVarPath = `/admin/hr_practices/{id}/set_invisible`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/hr_practices/{id}/set_visible
         * @summary Сделать практику видимой для компании
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminHrPracticesIdSetVisible: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postAdminHrPracticesIdSetVisible', 'id', id)
            const localVarPath = `/admin/hr_practices/{id}/set_visible`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/hr_practices/import
         * @summary Импортировать HR практики ТТ из файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminHrPracticesImport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/hr_practices/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/image_templates
         * @summary Добавление картинка в базу изображений
         * @param {PostAdminImageTemplatesRequest} postAdminImageTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminImageTemplates: async (postAdminImageTemplatesRequest: PostAdminImageTemplatesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminImageTemplatesRequest' is not null or undefined
            assertParamExists('postAdminImageTemplates', 'postAdminImageTemplatesRequest', postAdminImageTemplatesRequest)
            const localVarPath = `/admin/image_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminImageTemplatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/pages
         * @summary Создание страницы в опросе
         * @param {PostAdminPagesRequest} postAdminPagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminPages: async (postAdminPagesRequest: PostAdminPagesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminPagesRequest' is not null or undefined
            assertParamExists('postAdminPages', 'postAdminPagesRequest', postAdminPagesRequest)
            const localVarPath = `/admin/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminPagesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/pages/{id}/duplicate
         * @summary Дублирование страницы
         * @param {number} id ID страницы для дублирования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminPagesIdDuplicate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postAdminPagesIdDuplicate', 'id', id)
            const localVarPath = `/admin/pages/{id}/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/question_templates/import
         * @summary Импортировать шаблоны вопросов ТТ из файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminQuestionTemplatesImport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/question_templates/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/questions
         * @summary Создание вопроса
         * @param {PostAdminQuestionsRequest} [postAdminQuestionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminQuestions: async (postAdminQuestionsRequest?: PostAdminQuestionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminQuestionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/roles/import
         * @summary Импорт ролей и абилок из файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminRolesImport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/roles/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/sudo_actions/add_to_sudo_admins
         * @summary Сделать пользователя системным администратором
         * @param {string} xAuthToken JWT токен для авторизации супер администратора
         * @param {PostAdminSudoActionsAddToSudoAdmins} adminSudoActionsAddToSudoAdmins 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSudoActionsAddToSudoAdmins: async (xAuthToken: string, adminSudoActionsAddToSudoAdmins: PostAdminSudoActionsAddToSudoAdmins, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('postAdminSudoActionsAddToSudoAdmins', 'xAuthToken', xAuthToken)
            // verify required parameter 'adminSudoActionsAddToSudoAdmins' is not null or undefined
            assertParamExists('postAdminSudoActionsAddToSudoAdmins', 'adminSudoActionsAddToSudoAdmins', adminSudoActionsAddToSudoAdmins)
            const localVarPath = `/admin/sudo_actions/add_to_sudo_admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['X-Auth-Token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSudoActionsAddToSudoAdmins, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/survey_meta_questions
         * @summary Задать настройки вопросов паспортички для опроса
         * @param {PostAdminSurveyMetaQuestions} adminSurveyMetaQuestions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveyMetaQuestions: async (adminSurveyMetaQuestions: PostAdminSurveyMetaQuestions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminSurveyMetaQuestions' is not null or undefined
            assertParamExists('postAdminSurveyMetaQuestions', 'adminSurveyMetaQuestions', adminSurveyMetaQuestions)
            const localVarPath = `/admin/survey_meta_questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSurveyMetaQuestions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/survey_requests
         * @summary Запросить опрос
         * @param {PostAdminSurveyRequestsRequest} postAdminSurveyRequestsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveyRequests: async (postAdminSurveyRequestsRequest: PostAdminSurveyRequestsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminSurveyRequestsRequest' is not null or undefined
            assertParamExists('postAdminSurveyRequests', 'postAdminSurveyRequestsRequest', postAdminSurveyRequestsRequest)
            const localVarPath = `/admin/survey_requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminSurveyRequestsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys
         * @summary Создание опроса
         * @param {PostAdminSurveysRequest} postAdminSurveysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveys: async (postAdminSurveysRequest: PostAdminSurveysRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminSurveysRequest' is not null or undefined
            assertParamExists('postAdminSurveys', 'postAdminSurveysRequest', postAdminSurveysRequest)
            const localVarPath = `/admin/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminSurveysRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys/active/notification-reminders
         * @summary Нотификация конкретной команды о том, что нужно пройти опрос
         * @param {PostAdminSurveysActiveNotificationRemindersRequest} [postAdminSurveysActiveNotificationRemindersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysActiveNotificationReminders: async (postAdminSurveysActiveNotificationRemindersRequest?: PostAdminSurveysActiveNotificationRemindersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/surveys/active/notification-reminders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminSurveysActiveNotificationRemindersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys/back_to_draft
         * @summary Вернуть несколько опросов из trashed в черновик
         * @param {PostAdminSurveysBackToDraftRequest} postAdminSurveysBackToDraftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysBackToDraft: async (postAdminSurveysBackToDraftRequest: PostAdminSurveysBackToDraftRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminSurveysBackToDraftRequest' is not null or undefined
            assertParamExists('postAdminSurveysBackToDraft', 'postAdminSurveysBackToDraftRequest', postAdminSurveysBackToDraftRequest)
            const localVarPath = `/admin/surveys/back_to_draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminSurveysBackToDraftRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys/request
         * @summary Запросить опрос
         * @param {string} message Сообщение для создания опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysRequest: async (message: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'message' is not null or undefined
            assertParamExists('postAdminSurveysRequest', 'message', message)
            const localVarPath = `/admin/surveys/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys/{survey_slug}/back_to_draft
         * @summary Вернуть опрос (published/trashed -> draft) в статус черновик
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugBackToDraft: async (surveySlug: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminSurveysSurveySlugBackToDraft', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/back_to_draft`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys/{survey_slug}/duplicate
         * @summary Дублировать опрос
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugDuplicate: async (surveySlug: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminSurveysSurveySlugDuplicate', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/duplicate`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys/:survey_slug/fake_user_surveys
         * @summary “Восстановление” некачественных анкет из аналитики
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugFakeUserSurveys: async (surveySlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminSurveysSurveySlugFakeUserSurveys', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/fake_user_surveys`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys/:survey_slug/orgstructure_pages
         * @summary Создание настроек страницы оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugOrgstructurePages: async (surveySlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminSurveysSurveySlugOrgstructurePages', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/orgstructure_pages`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys/{surveys_slug}/pptx/pptx-export
         * @summary Создает запрос на выгрузку pptx файла
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugPptxPptxExport: async (surveySlug: string, teamIds?: Array<string>, jobPositions?: Array<string>, jobFunctions?: Array<string>, jobDurations?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminSurveysSurveySlugPptxPptxExport', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/pptx/pptx-export`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamIds) {
                localVarQueryParameter['team_ids'] = teamIds.join(COLLECTION_FORMATS.csv);
            }

            if (jobPositions) {
                localVarQueryParameter['job_positions'] = jobPositions.join(COLLECTION_FORMATS.csv);
            }

            if (jobFunctions) {
                localVarQueryParameter['job_functions'] = jobFunctions.join(COLLECTION_FORMATS.csv);
            }

            if (jobDurations) {
                localVarQueryParameter['job_durations'] = jobDurations.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys/{survey_slug}/publish
         * @summary Опубликовать опрос(draft -> published)
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugPublish: async (surveySlug: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminSurveysSurveySlugPublish', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/publish`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys/{survey_slug}/start_now
         * @summary Опубликовать и сразу запустить опрос (draft -> active)
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugStartNow: async (surveySlug: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminSurveysSurveySlugStartNow', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/start_now`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/surveys/{survey_slug}/stop_now
         * @summary Срочно остановить активный опрос
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugStopNow: async (surveySlug: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('postAdminSurveysSurveySlugStopNow', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/stop_now`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/teams/ids/merge
         * @summary Схлопывание team ids
         * @param {GetAdminTeamsIdsMergeRequest} getAdminTeamsIdsMergeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminTeamsIdsMerge: async (getAdminTeamsIdsMergeRequest: GetAdminTeamsIdsMergeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getAdminTeamsIdsMergeRequest' is not null or undefined
            assertParamExists('postAdminTeamsIdsMerge', 'getAdminTeamsIdsMergeRequest', getAdminTeamsIdsMergeRequest)
            const localVarPath = `/admin/teams/ids/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAdminTeamsIdsMergeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/teams/update_parents
         * @summary Обновление родительских связей в таблице teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminTeamsUpdateParents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/teams/update_parents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/templates
         * @summary Создать шаблон опроса
         * @param {PostAdminTemplatesRequest} postAdminTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminTemplates: async (postAdminTemplatesRequest: PostAdminTemplatesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postAdminTemplatesRequest' is not null or undefined
            assertParamExists('postAdminTemplates', 'postAdminTemplatesRequest', postAdminTemplatesRequest)
            const localVarPath = `/admin/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminTemplatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/templates/import_tt
         * @summary Импортнуть шаблоны ТТ из файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminTemplatesImportTt: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/templates/import_tt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/topmind_query/upsert
         * @summary Создает запрос на экспорт данных выбранной модели в TopMind
         * @param {PostAdminTopmindQueryUpsert} adminTopmindQueryUpsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminTopmindQueryUpsert: async (adminTopmindQueryUpsert: PostAdminTopmindQueryUpsert, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminTopmindQueryUpsert' is not null or undefined
            assertParamExists('postAdminTopmindQueryUpsert', 'adminTopmindQueryUpsert', adminTopmindQueryUpsert)
            const localVarPath = `/admin/topmind_query/upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTopmindQueryUpsert, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /admin/user_groups
         * @summary Создать группу пользователей
         * @param {PostAdminUserGroups} adminUserGroups 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminUserGroups: async (adminUserGroups: PostAdminUserGroups, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminUserGroups' is not null or undefined
            assertParamExists('postAdminUserGroups', 'adminUserGroups', adminUserGroups)
            const localVarPath = `/admin/user_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserGroups, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/admin_area/surveys/{survey_slug}
         * @summary Обновление любых (даже запущенных опросов)
         * @param {number} surveySlug 
         * @param {PutAdminAdminAreaSurveysSurveySlugRequest} putAdminAdminAreaSurveysSurveySlugRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAdminAreaSurveysSurveySlug: async (surveySlug: number, putAdminAdminAreaSurveysSurveySlugRequest: PutAdminAdminAreaSurveysSurveySlugRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('putAdminAdminAreaSurveysSurveySlug', 'surveySlug', surveySlug)
            // verify required parameter 'putAdminAdminAreaSurveysSurveySlugRequest' is not null or undefined
            assertParamExists('putAdminAdminAreaSurveysSurveySlug', 'putAdminAdminAreaSurveysSurveySlugRequest', putAdminAdminAreaSurveysSurveySlugRequest)
            const localVarPath = `/admin/admin_area/surveys/{survey_slug}`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAdminAdminAreaSurveysSurveySlugRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/analytics/surveys/raw_data_reports/:report_id/update_status
         * @summary Меняет статус отчета
         * @param {number} reportId ID отчёта
         * @param {PutAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus} adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus: async (reportId: number, adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus: PutAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('putAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus', 'reportId', reportId)
            // verify required parameter 'adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus' is not null or undefined
            assertParamExists('putAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus', 'adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus', adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus)
            const localVarPath = `/admin/analytics/surveys/raw_data_reports/{report_id}/update_status`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/answers/{id}
         * @summary Обновление ответов
         * @param {number} id 
         * @param {PutAdminAnswersIdRequest} [putAdminAnswersIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAnswersId: async (id: number, putAdminAnswersIdRequest?: PutAdminAnswersIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminAnswersId', 'id', id)
            const localVarPath = `/admin/answers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAdminAnswersIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/blocks/{id}
         * @summary Обновление блока
         * @param {number} id ID блока
         * @param {PutAdminBlocksIdRequest} [putAdminBlocksIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminBlocksId: async (id: number, putAdminBlocksIdRequest?: PutAdminBlocksIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminBlocksId', 'id', id)
            const localVarPath = `/admin/blocks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAdminBlocksIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/complex_reports/{id}
         * @summary Обновление комплексного отчета
         * @param {number} id 
         * @param {PutAdminComplexReports} adminComplexReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminComplexReportsId: async (id: number, adminComplexReports: PutAdminComplexReports, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminComplexReportsId', 'id', id)
            // verify required parameter 'adminComplexReports' is not null or undefined
            assertParamExists('putAdminComplexReportsId', 'adminComplexReports', adminComplexReports)
            const localVarPath = `/admin/complex_reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminComplexReports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/email_templates/{id}
         * @summary Обновление шаблона письма
         * @param {string} id Идентификатор шаблона
         * @param {PutAdminEmailTemplates} adminEmailTemplates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminEmailTemplatesId: async (id: string, adminEmailTemplates: PutAdminEmailTemplates, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminEmailTemplatesId', 'id', id)
            // verify required parameter 'adminEmailTemplates' is not null or undefined
            assertParamExists('putAdminEmailTemplatesId', 'adminEmailTemplates', adminEmailTemplates)
            const localVarPath = `/admin/email_templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEmailTemplates, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/external_surveys/:id
         * @summary Обновление внешнего опроса
         * @param {number} id ID внешнего опроса
         * @param {PutAdminExternalSurveys} adminExternalSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminExternalSurveysId: async (id: number, adminExternalSurveys: PutAdminExternalSurveys, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminExternalSurveysId', 'id', id)
            // verify required parameter 'adminExternalSurveys' is not null or undefined
            assertParamExists('putAdminExternalSurveysId', 'adminExternalSurveys', adminExternalSurveys)
            const localVarPath = `/admin/external_surveys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminExternalSurveys, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/factors/{id}
         * @summary Обновление фактора
         * @param {number} id Идентификатор фактора
         * @param {PutAdminFactors} adminFactors 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminFactorsId: async (id: number, adminFactors: PutAdminFactors, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminFactorsId', 'id', id)
            // verify required parameter 'adminFactors' is not null or undefined
            assertParamExists('putAdminFactorsId', 'adminFactors', adminFactors)
            const localVarPath = `/admin/factors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFactors, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/hr_practices/{id}
         * @summary Обновление практики
         * @param {string} id Идентификатор практики
         * @param {PutAdminHrPractices} adminHrPractices 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminHrPracticesId: async (id: string, adminHrPractices: PutAdminHrPractices, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminHrPracticesId', 'id', id)
            // verify required parameter 'adminHrPractices' is not null or undefined
            assertParamExists('putAdminHrPracticesId', 'adminHrPractices', adminHrPractices)
            const localVarPath = `/admin/hr_practices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminHrPractices, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/pages/{id}
         * @summary Обновление страницы в опросе
         * @param {number} id ID страницы для обновления
         * @param {PutAdminPagesIdRequest} [putAdminPagesIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminPagesId: async (id: number, putAdminPagesIdRequest?: PutAdminPagesIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminPagesId', 'id', id)
            const localVarPath = `/admin/pages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAdminPagesIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/questions/{id}
         * @summary Обновление вопроса
         * @param {number} id ID вопроса
         * @param {PutAdminQuestionsIdRequest} [putAdminQuestionsIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminQuestionsId: async (id: number, putAdminQuestionsIdRequest?: PutAdminQuestionsIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminQuestionsId', 'id', id)
            const localVarPath = `/admin/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAdminQuestionsIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/surveys/{survey_slug}
         * @summary Обновление опроса
         * @param {string} surveySlug Идентификатор опроса
         * @param {PutAdminSurveys} adminSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminSurveysSurveySlug: async (surveySlug: string, adminSurveys: PutAdminSurveys, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('putAdminSurveysSurveySlug', 'surveySlug', surveySlug)
            // verify required parameter 'adminSurveys' is not null or undefined
            assertParamExists('putAdminSurveysSurveySlug', 'adminSurveys', adminSurveys)
            const localVarPath = `/admin/surveys/{survey_slug}`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSurveys, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/surveys/:survey_slug/final_page
         * @summary Создать\\отредактировать страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminSurveysSurveySlugFinalPage: async (surveySlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('putAdminSurveysSurveySlugFinalPage', 'surveySlug', surveySlug)
            const localVarPath = `/admin/surveys/{survey_slug}/final_page`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/surveys/:survey_slug/orgstructure_pages
         * @summary Обновление настроек страницы оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {PutAdminSurveysSurveySlugOrgstructurePages} adminSurveysSurveySlugOrgstructurePages 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminSurveysSurveySlugOrgstructurePages: async (surveySlug: string, adminSurveysSurveySlugOrgstructurePages: PutAdminSurveysSurveySlugOrgstructurePages, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('putAdminSurveysSurveySlugOrgstructurePages', 'surveySlug', surveySlug)
            // verify required parameter 'adminSurveysSurveySlugOrgstructurePages' is not null or undefined
            assertParamExists('putAdminSurveysSurveySlugOrgstructurePages', 'adminSurveysSurveySlugOrgstructurePages', adminSurveysSurveySlugOrgstructurePages)
            const localVarPath = `/admin/surveys/{survey_slug}/orgstructure_pages`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSurveysSurveySlugOrgstructurePages, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/surveys/:survey_slug/orgstructure_pages/orgstructure_blocks
         * @summary Обновление блока оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest} putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocks: async (surveySlug: string, putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest: PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocks', 'surveySlug', surveySlug)
            // verify required parameter 'putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest' is not null or undefined
            assertParamExists('putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocks', 'putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest', putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest)
            const localVarPath = `/admin/surveys/{survey_slug}/orgstructure_pages/orgstructure_blocks`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/top_mind/export
         * @summary Создает запрос на экспорт данных выбранной модели в TopMind
         * @param {PutAdminTopMindExport} adminTopMindExport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminTopMindExport: async (adminTopMindExport: PutAdminTopMindExport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminTopMindExport' is not null or undefined
            assertParamExists('putAdminTopMindExport', 'adminTopMindExport', adminTopMindExport)
            const localVarPath = `/admin/top_mind/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTopMindExport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/translations/blocks/{id}
         * @summary Обновление одного из переводов блока
         * @param {string} id Идентификатор блока
         * @param {PutAdminTranslationsBlocks} adminTranslationsBlocks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminTranslationsBlocksId: async (id: string, adminTranslationsBlocks: PutAdminTranslationsBlocks, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminTranslationsBlocksId', 'id', id)
            // verify required parameter 'adminTranslationsBlocks' is not null or undefined
            assertParamExists('putAdminTranslationsBlocksId', 'adminTranslationsBlocks', adminTranslationsBlocks)
            const localVarPath = `/admin/translations/blocks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTranslationsBlocks, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/translations/surveys/{survey_slug}
         * @summary Обновление переводов опроса
         * @param {string} surveySlug Идентификатор опроса
         * @param {PutAdminTranslationsSurveys} adminTranslationsSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminTranslationsSurveysSurveySlug: async (surveySlug: string, adminTranslationsSurveys: PutAdminTranslationsSurveys, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('putAdminTranslationsSurveysSurveySlug', 'surveySlug', surveySlug)
            // verify required parameter 'adminTranslationsSurveys' is not null or undefined
            assertParamExists('putAdminTranslationsSurveysSurveySlug', 'adminTranslationsSurveys', adminTranslationsSurveys)
            const localVarPath = `/admin/translations/surveys/{survey_slug}`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTranslationsSurveys, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/translations/surveys/:survey_slug/final_page
         * @summary Перевести страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {PutAdminTranslationsSurveysSurveySlugFinalPageRequest} putAdminTranslationsSurveysSurveySlugFinalPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminTranslationsSurveysSurveySlugFinalPage: async (surveySlug: string, putAdminTranslationsSurveysSurveySlugFinalPageRequest: PutAdminTranslationsSurveysSurveySlugFinalPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('putAdminTranslationsSurveysSurveySlugFinalPage', 'surveySlug', surveySlug)
            // verify required parameter 'putAdminTranslationsSurveysSurveySlugFinalPageRequest' is not null or undefined
            assertParamExists('putAdminTranslationsSurveysSurveySlugFinalPage', 'putAdminTranslationsSurveysSurveySlugFinalPageRequest', putAdminTranslationsSurveysSurveySlugFinalPageRequest)
            const localVarPath = `/admin/translations/surveys/{survey_slug}/final_page`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAdminTranslationsSurveysSurveySlugFinalPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/translations/surveys/:survey_slug/orgstructure_pages
         * @summary Перевести страницы оргструктуры
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {PutAdminTranslationsSurveysSurveySlugOrgstructurePages} adminTranslationsSurveysSurveySlugOrgstructurePages 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminTranslationsSurveysSurveySlugOrgstructurePages: async (surveySlug: string, adminTranslationsSurveysSurveySlugOrgstructurePages: PutAdminTranslationsSurveysSurveySlugOrgstructurePages, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('putAdminTranslationsSurveysSurveySlugOrgstructurePages', 'surveySlug', surveySlug)
            // verify required parameter 'adminTranslationsSurveysSurveySlugOrgstructurePages' is not null or undefined
            assertParamExists('putAdminTranslationsSurveysSurveySlugOrgstructurePages', 'adminTranslationsSurveysSurveySlugOrgstructurePages', adminTranslationsSurveysSurveySlugOrgstructurePages)
            const localVarPath = `/admin/translations/surveys/{survey_slug}/orgstructure_pages`
                .replace(`{${"survey_slug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTranslationsSurveysSurveySlugOrgstructurePages, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /admin/user_groups/:id
         * @summary Обновить группу пользователей
         * @param {number} id ID группы
         * @param {PutAdminUserGroups} adminUserGroups 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminUserGroupsId: async (id: number, adminUserGroups: PutAdminUserGroups, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdminUserGroupsId', 'id', id)
            // verify required parameter 'adminUserGroups' is not null or undefined
            assertParamExists('putAdminUserGroupsId', 'adminUserGroups', adminUserGroups)
            const localVarPath = `/admin/user_groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserGroups, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * DELETE /admin/blocks/{id}
         * @summary Удаление блока
         * @param {number} id ID блока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminBlocksId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Block>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminBlocksId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/email_templates/{id}
         * @summary Удалить шаблон письма
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminEmailTemplatesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminEmailTemplatesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/email_templates/{id}/trash_image
         * @summary Удалить картинку шаблона
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminEmailTemplatesIdTrashImage(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminEmailTemplatesIdTrashImage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/external_surveys/:id
         * @summary Удалить внешний опрос
         * @param {number} id ID внешнего опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminExternalSurveysId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminExternalSurveysId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/factors/{id}
         * @summary Удалить фактор
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminFactorsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminFactorsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/hr_practices/{id}
         * @summary Удалить практику
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminHrPracticesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminHrPracticesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/hr_practices/{id}/trash_image
         * @summary Удалить картинку практики
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminHrPracticesIdTrashImage(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminHrPracticesIdTrashImage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/pages/{id}
         * @summary Удаление страницы в опросе
         * @param {number} id ID страницы для удаления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminPagesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminPagesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/questions/{id}
         * @summary Удалить вопрос
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminQuestionsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminQuestionsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/questions/{id}/picture_trash
         * @summary Удалить картинку вопроса
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminQuestionsIdPictureTrash(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminQuestionsIdPictureTrash(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/surveys
         * @summary Удалить несколько опросов насовсем
         * @param {Array<string>} surveySlugs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminSurveys(surveySlugs: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminSurveys(surveySlugs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}
         * @summary Удалить опрос насовсем
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminSurveysSurveySlug(surveySlug: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminSurveysSurveySlug(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}/async
         * @summary Удалить опрос насовсем асинхронно
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminSurveysSurveySlugAsync(surveySlug: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminSurveysSurveySlugAsync(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/surveys/:survey_slug/fake_user_surveys
         * @summary “Удаление” некачественных анкет из аналитики
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminSurveysSurveySlugFakeUserSurveys(surveySlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminSurveysSurveySlugFakeUserSurveys(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/surveys/:survey_slug/final_blocks/:id
         * @summary Удалить один из блоков страницы \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {string} id ID блока для удаления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminSurveysSurveySlugFinalBlocksId(surveySlug: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminSurveysSurveySlugFinalBlocksId(surveySlug, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/surveys/:survey_slug/final_page
         * @summary Удалить страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminSurveysSurveySlugFinalPage(surveySlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminSurveysSurveySlugFinalPage(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}/image_trash
         * @summary Удалить картинку опроса
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminSurveysSurveySlugImageTrash(surveySlug: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminSurveysSurveySlugImageTrash(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}/trash
         * @summary Удалить опрос в корзину
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminSurveysSurveySlugTrash(surveySlug: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminSurveysSurveySlugTrash(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/templates/:id
         * @summary Удалить шаблона опроса компании
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminTemplatesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminTemplatesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DELETE /admin/user_groups/:id
         * @summary Обновить группу пользователей
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminUserGroupsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminUserGroupsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/admin_area/surveys
         * @summary Получение списка опросов компании с разбивкой по статусам
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAdminAreaSurveys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAreaSurveysIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAdminAreaSurveys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/analytics_templates
         * @summary Список шаблонов аналитики
         * @param {Array<string>} [slugs] Идентификаторы дефолтных темплейтов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsAnalyticsTemplates(slugs?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAnalyticsAnalyticsTemplatesFull>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsAnalyticsTemplates(slugs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/company/factor-dynamic
         * @summary Динамика фактора на основании завершенных опросов
         * @param {string} [factorName] Название фактора
         * @param {Array<string>} [factorNames] Массив названий факторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsCompanyFactorDynamic(factorName?: string, factorNames?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsFactorDynamic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsCompanyFactorDynamic(factorName, factorNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/dashboard
         * @summary Дэшборд по аналитике для активного опроса в рамках компании
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsDashboard(teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsDashboard(teamIds, jobFunctions, jobPositions, jobDurations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/flexible/:slug/respondents/anonymous
         * @summary Проверка анонимности
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsFlexibleSlugRespondentsAnonymous(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAnalyticsFlexibleTabsRespondentsAnonymous>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsFlexibleSlugRespondentsAnonymous(slug, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /analytics/flexible/:slug/tabs
         * @summary Список вкладок для гибкой аналитики
         * @param {string} slug ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsFlexibleSlugTabs(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAnalyticsFlexibleTabs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsFlexibleSlugTabs(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/questions/{question_id}/answers
         * @summary Получение ответов на открытые вопросы
         * @param {number} questionId ID вопроса
         * @param {string} [theme] Тема
         * @param {string} [sentiment] Сентимент
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 40)
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsQuestionsQuestionIdAnswers(questionId: number, theme?: string, sentiment?: string, offset?: number, limit?: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsQuestionsAnswers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsQuestionsQuestionIdAnswers(questionId, theme, sentiment, offset, limit, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/questions/{question_id}/clustering
         * @summary Получение кластеризации открытого вопроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsQuestionsQuestionIdClustering(questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsQuestionsClustering>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsQuestionsQuestionIdClustering(questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/questions/{question_id}/comments
         * @summary Получение сентимента открытого вопроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsQuestionsQuestionIdComments(questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsQuestionsComments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsQuestionsQuestionIdComments(questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/current
         * @summary Получить текущую активную команду для аналитики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysCurrent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysCurrent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/raw_data_reports/:report_id/download
         * @summary Возвращает `.zip` файл со сформированными `.xlsx` отчетами, по одному файлу на каждый опрос
         * @param {number} reportId ID отчёта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysRawDataReportsReportIdDownload(reportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysRawDataReportsReportIdDownload(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/raw_data_reports/:report_id/status
         * @summary Возвращает информацию о статусе отчета
         * @param {number} reportId ID отчёта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysRawDataReportsReportIdStatus(reportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsSurveysRawDataReportsSmall>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysRawDataReportsReportIdStatus(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{report_id}/report_status
         * @summary Возвращает информацию о статусе отчета
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysReportIdReportStatus(reportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XLSXReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysReportIdReportStatus(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{slug}
         * @summary Получить опрос по slug
         * @param {string} slug Slug идентификатор опроса или комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSlug(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSlug(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * /admin/analytics/surveys/{slug}/burnout
         * @summary Возвращает данные для аналитики опроса выгорания
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSlugBurnout(slug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsBurnoutData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSlugBurnout(slug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * /admin/analytics/surveys/{survey_slug}/csi
         * @summary Возвращает данные для аналитики опроса сsi
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSlugCsi(slug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsCsiData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSlugCsi(slug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /user_surveys/{slug}/meta-filters
         * @summary Получение списка мета вопросов опроса (продвинутая паспортичка)
         * @param {string} slug Slug идентификатор опроса или комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSlugMetaFilters(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsMetaFiltersList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSlugMetaFilters(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/additional_questions
         * @summary Показывать ответы на вопросы, которых нет в нашем шаблоне
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsConstructorBlockData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/comments
         * @summary Возвращает комментарии по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} teamIds ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugComments(surveySlug: string, questionId: number, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsConstructorTextData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugComments(surveySlug, questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * /admin/analytics/surveys/{survey_slug}/constructor
         * @summary Возвращает данные для аналитики опросов из конструктора
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugConstructor(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsConstructorBlockData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugConstructor(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/constructor-open-question-answers
         * @summary Возвращает ответы на открытые вопросы для опроса из конструктора по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugConstructorOpenQuestionAnswers(surveySlug: string, questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsConstructorTextData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugConstructorOpenQuestionAnswers(surveySlug, questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factor-by-team
         * @summary Возвращает значение фактора в разрезе переданных команд
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {Array<string>} teamIds ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug: string, factorName: string, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsFactorByTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug, factorName, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factor-groups
         * @summary Получение кол-ва групп в разрезе фактора
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugFactorGroups(surveySlug: string, factorName: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsFactorGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugFactorGroups(surveySlug, factorName, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factor-questions
         * @summary Возвращает вопросы для фактора и ответы на них
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugFactorQuestions(surveySlug: string, factorName: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsQuestion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugFactorQuestions(surveySlug, factorName, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, factorGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factors
         * @summary Возвращает значение факторов по определенной команде (командам)
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugFactors(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsFactors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugFactors(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, factorGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/filter-values
         * @summary Получение доступных значений для фильтров
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugFilterValues(surveySlug: string, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsFilterValues>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugFilterValues(surveySlug, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/open-question-answers
         * @summary Получение ответов на открытый вопрос
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugOpenQuestionAnswers(surveySlug: string, questionId: number, teamIds?: Array<string>, factorGroup?: string, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugOpenQuestionAnswers(surveySlug, questionId, teamIds, factorGroup, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/open-questions
         * @summary Получение открытых вопросов
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {string} [factorName] Название фактора
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugOpenQuestions(surveySlug: string, teamIds?: Array<string>, factorName?: string, factorGroup?: string, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsQuestion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugOpenQuestions(surveySlug, teamIds, factorName, factorGroup, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/other-answers
         * @summary Возвращает варианты \"Другое\" по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} teamIds ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug: string, questionId: number, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsConstructorTextData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug, questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/priority_factors
         * @summary Возвращает топ факторов с которыми надо поработать
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugPriorityFactors(surveySlug: string, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsPriorityFactorsData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugPriorityFactors(surveySlug, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/timings-by-team
         * @summary Возвращает статистику прохождения опроса в разрезе команды (команд)
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugTimingsByTeam(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsTimingsByTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugTimingsByTeam(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/xlsx-reports/open-questions
         * @summary Возвращает XLSX файл с ответами на открытые вопросы
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugXlsxReportsOpenQuestions(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsFactors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugXlsxReportsOpenQuestions(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, factorGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/xlsx_reports/{report_id}/download
         * @summary Возвращает XLSX файл с данными отчета
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} reportId ID отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsSurveysSurveySlugXlsxReportsReportIdDownload(surveySlug: string, reportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsSurveysSurveySlugXlsxReportsReportIdDownload(surveySlug, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/analytics/teams/tree
         * @summary Получение дерева команд компании
         * @param {string} [surveySlug] Slug опроса (для разделения прав при просмотре аналитики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAnalyticsTeamsTree(surveySlug?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAnalyticsTeamsTree(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/company-historical-data
         * @summary Получение исторических значений факторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminCompanyHistoricalData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculatedCompanyHistoricalDatum>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminCompanyHistoricalData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/company-historical-data/{survey_slug}
         * @summary Получение исторических значений факторов
         * @param {number} surveySlug 
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {number} [offset] Оффсет
         * @param {number} [limit] Количество записей на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminCompanyHistoricalDataSurveySlug(surveySlug: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricalDataForSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminCompanyHistoricalDataSurveySlug(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/complex_reports
         * @summary Создание комплексного отчета
         * @param {string} [q] Строка для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminComplexReports(q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplexReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminComplexReports(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/complex_reports/analytics_kinds
         * @summary Список доступных analytics_kind для комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminComplexReportsAnalyticsKinds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsKind>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminComplexReportsAnalyticsKinds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/complex_reports/{id}
         * @summary Получение информации о комплексном отчете
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminComplexReportsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplexReportFull>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminComplexReportsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/complex_reports/{id}
         * @summary Получение списка опросов компании доступных для добавления в комплексный отчет
         * @param {number} id ID комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminComplexReportsIdSurveys(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyShort>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminComplexReportsIdSurveys(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/email_templates/{id}
         * @summary Шаблон письма
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminEmailTemplatesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminEmailTemplatesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/factor_business_unit
         * @summary Сравнение фактора по бизнес-юнитам
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {number} level Уровень команды
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminExpressSurveysAnalyticsSlugFactorBusinessUnit(slug: string, level: number, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminExpressSurveysAnalyticsSlugFactorBusinessUnit(slug, level, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/filter_values
         * @summary Получение данных для блока \"Верхнеуровневый фильтр по командам\"
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminExpressSurveysAnalyticsSlugFilterValues(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminExpressSurveysAnalyticsSlugFilterValues(slug, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/main_questions
         * @summary Данные для вкладки \"Факторы\" экспресс-опросов
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] UUID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminExpressSurveysAnalyticsSlugMainQuestions(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminExpressSurveysAnalyticsSlugMainQuestions(slug, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/main_results
         * @summary Данные для вкладки \"Основные результаты\" экспресс-опросов
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] UUID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminExpressSurveysAnalyticsSlugMainResults(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminExpressSurveysAnalyticsSlugMainResults(slug, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/main_results/business_units_comparison
         * @summary Данные для вкладки \"Основные результаты\" экспресс-опросов | Сравнение по бизнес-юнитам
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {number} level Выбранный уровень команд для сравнения по бизнес-юнитам
         * @param {Array<string>} [teamIds] UUIDs команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminExpressSurveysAnalyticsSlugMainResultsBusinessUnitsComparison(slug: string, level: number, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpressSurveysMainResultsEntityBusinessUnitsEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminExpressSurveysAnalyticsSlugMainResultsBusinessUnitsComparison(slug, level, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/nps_basic
         * @summary Получение статистики по eNPS/NPS
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminExpressSurveysAnalyticsSlugNpsBasic(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminExpressSurveysAnalyticsSlugNpsBasic(slug, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/nps_business_unit
         * @summary Сравнение eNPS/NPS факторов по бизнес-юнитам
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {number} level Уровень команды
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminExpressSurveysAnalyticsSlugNpsBusinessUnit(slug: string, level: number, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminExpressSurveysAnalyticsSlugNpsBusinessUnit(slug, level, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/open_questions/not_nps
         * @summary Получение статистики по открытым вопросам(без NPS)
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminExpressSurveysAnalyticsSlugOpenQuestionsNotNps(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminExpressSurveysAnalyticsSlugOpenQuestionsNotNps(slug, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/open_questions/nps
         * @summary Получение статистики по открытым вопросам(для NPS)
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminExpressSurveysAnalyticsSlugOpenQuestionsNps(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminExpressSurveysAnalyticsSlugOpenQuestionsNps(slug, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/external_surveys
         * @summary Список внешних опросов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminExternalSurveys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminExternalSurveysFull>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminExternalSurveys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/external_surveys/:id
         * @summary Получить внешний опрос
         * @param {number} id ID внешнего опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminExternalSurveysId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminExternalSurveysFull>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminExternalSurveysId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/factors
         * @summary Список факторов
         * @param {string} [surveySlug] Slug опроса куда добавляем факторы
         * @param {string} [questionType] Тип вопроса для фильтра
         * @param {number} [maxAnswers] Количество ответов для фильтра
         * @param {boolean} [questionRequired] Обязательность вопроса для фильтра
         * @param {boolean} [onlyFactors] Вернуть только факторы которые не привязаны к индексам
         * @param {Array<string>} [factorsGroupsNames] Названия групп факторов для фильтра
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminFactors(surveySlug?: string, questionType?: string, maxAnswers?: number, questionRequired?: boolean, onlyFactors?: boolean, factorsGroupsNames?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Factor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminFactors(surveySlug, questionType, maxAnswers, questionRequired, onlyFactors, factorsGroupsNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/factors_groups
         * @summary Список групп факторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminFactorsGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactorsGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminFactorsGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/factors/{id}
         * @summary Получить фактор
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminFactorsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Factor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminFactorsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/features/status
         * @summary Получение состояния feature флагов
         * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminFeaturesStatus(xAuthToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminFeaturesStatus(xAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/heatmaps/:id
         * @summary Получить статус тепловой карты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminHeatmapsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Heatmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminHeatmapsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/hr_practices
         * @summary Список HR практик
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {'all' | 'user' | 'tt'} [scope] Скоуп практик
         * @param {Array<string>} [tags] Тэги для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminHrPractices(offset?: number, limit?: number, scope?: 'all' | 'user' | 'tt', tags?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagsHrPractices>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminHrPractices(offset, limit, scope, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/hr_practices/{id}
         * @summary Список HR практик
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminHrPracticesId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HrPracticeFull>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminHrPracticesId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/image_templates
         * @summary Список шаблонов картинок
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminImageTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminImageTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/indicies
         * @summary Список индексов (групп факторов с пометкой индекс) для добавления в опрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminIndicies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactorsGroupAsIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminIndicies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/me
         * @summary Информация о текущем пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Me>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/meta_info
         * @summary Получить доступную метаинфу о пользователях для компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminMetaInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminMetaInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/pages
         * @summary Список страниц для данного опроса (с возможностью поиска)
         * @param {string} surveySlug Slug опроса
         * @param {string} [q] Шаблон для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminPages(surveySlug: string, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminPages(surveySlug, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/question_templates
         * @summary Список шаблонов вопросов
         * @param {number} [page] Страница
         * @param {number} [perPage] Количество записей на странице
         * @param {string} [tag] Тэг для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminQuestionTemplates(page?: number, perPage?: number, tag?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagsQuestionTemplates>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminQuestionTemplates(page, perPage, tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/roles
         * @summary Список ролей и абилок
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/surveys
         * @summary Список опросов с шаблонами для личного кабинета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveysTemplates>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/surveys/active/notification-reminders
         * @summary Получение списка отправленных уведомлений в течении 24х часов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveysActiveNotificationReminders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationReminder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveysActiveNotificationReminders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/surveys/drafts
         * @summary Список черновиков опросов для личного кабинета
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveysDrafts(offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveysListData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveysDrafts(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/surveys/{survey_slug}
         * @summary Получить опрос для редактирования
         * @param {string} surveySlug Идентификатор опроса
         * @param {string} [language] Язык опроса (перевода)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveysSurveySlug(surveySlug: string, language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveysSurveySlug(surveySlug, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/surveys/:survey_slug/final_page
         * @summary Получить страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {string} [language] Язык перевода
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveysSurveySlugFinalPage(surveySlug: string, language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSurveysFinalPages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveysSurveySlugFinalPage(surveySlug, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/surveys/:survey_slug/orgstructure_pages
         * @summary Получить настройки оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveysSurveySlugOrgstructurePages(surveySlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSurveysOrgstruturePageSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveysSurveySlugOrgstructurePages(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/surveys/{surveys_slug}/pptx/{pptx_id}/download
         * @summary Получение pptx опроса
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} pptxId ID презентации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveysSurveySlugPptxPptxIdDownload(surveySlug: string, pptxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveysSurveySlugPptxPptxIdDownload(surveySlug, pptxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/surveys/{surveys_slug}/pptx/{pptx_id}/status
         * @summary Возвращает информацию о статусе презентации
         * @param {number} surveySlug 
         * @param {number} pptxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveysSurveySlugPptxPptxIdStatus(surveySlug: number, pptxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pptx>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveysSurveySlugPptxPptxIdStatus(surveySlug, pptxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/surveys/{survey_slug}/root-team
         * @summary Получить корневую команду для опроса
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveysSurveySlugRootTeam(surveySlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgstructureTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveysSurveySlugRootTeam(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/surveys/{survey_slug}/timeline
         * @summary Получить таймлайн опросов компании
         * @param {string} surveySlug Идентификатор опроса
         * @param {string} startDate Начальная дата интервала
         * @param {string} endDate Конечная дата интервала
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveysSurveySlugTimeline(surveySlug: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyTimelineItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveysSurveySlugTimeline(surveySlug, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/surveys/trashed
         * @summary Список опросов в корзине
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveysTrashed(offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveysListData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveysTrashed(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/tags
         * @summary Получить список тегов с пагинацией, поиск по параметру q (если пустая строка в q, то отдаем все)
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 6)
         * @param {string} [q] Шаблон для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTags(offset?: number, limit?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagsIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTags(offset, limit, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/teams/
         * @summary Получение команд по совпадению имени (в будущем возможно по дефолту будет отдавать какие-то команды)
         * @param {string} name Совпадение, по которому искать команды
         * @param {string} [surveySlug] Slug опроса для аналитики которого ищем команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTeams(name: string, surveySlug?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTeams(name, surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/teams/children
         * @summary Получение детей для команд
         * @param {string} [surveySlug] 
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTeamsChildren(surveySlug?: string, teamIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTeamsChildren(surveySlug, teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/teams/{id}
         * @summary Получение данный команды по ID
         * @param {string} id ID команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTeamsId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgstructureTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTeamsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/teams/{id}/children
         * @summary Получение детей для команды
         * @param {string} id ID команды
         * @param {string} [surveySlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTeamsIdChildren(id: string, surveySlug?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTeamsIdChildren(id, surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/teams/{id}/parent
         * @summary Получение ID вышестоящей команды
         * @param {string} id ID команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTeamsIdParent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgstructureParent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTeamsIdParent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/teams/{id}/parents
         * @summary Получение дерево вышестоящих команд
         * @param {string} id ID команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTeamsIdParents(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgstructureParents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTeamsIdParents(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/teams/ids/merge
         * @summary Схлопывание team ids
         * @param {Array<string>} teamIds ID команд
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAdminTeamsIdsMerge(teamIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgstructureShortTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTeamsIdsMerge(teamIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/teams/tree
         * @summary Получение дерева команд компании
         * @param {string} [surveySlug] Slug опроса (для разделения прав при просмотре аналитики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTeamsTree(surveySlug?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTeamsTree(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/templates
         * @summary Список шаблонов опросов для личного кабинета
         * @param {number} [offset] Оффсет
         * @param {number} [limit] Количество записей на странице
         * @param {'all' | 'user' | 'tt'} [scope] Скоуп шаблонов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTemplates(offset?: number, limit?: number, scope?: 'all' | 'user' | 'tt', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyTemplatesIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTemplates(offset, limit, scope, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/user_groups
         * @summary Список групп пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUserGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUserGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /admin/user_groups/:id
         * @summary Полная информация о группе пользователей
         * @param {number} id ID группы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUserGroupsId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUserGroupsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * /admin/users
         * @summary Список пользователей компании
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {string} [q] Шаблон поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUsers(offset?: number, limit?: number, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUsers(offset, limit, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/analytics/analytics_templates/import
         * @summary Обновить шаблон аналитики
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {number} [templateId] ID импортируемого темплейта
         * @param {boolean} [_default] Является ли шаблон дефолтным
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsAnalyticsTemplatesImport(slug: string, templateId?: number, _default?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsAnalyticsTemplatesImport(slug, templateId, _default, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /analytics/flexible/:slug/additional_questions/comparison
         * @summary Сравнительные данные по индексу
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {string} [comparisonKind] Тип сравнения
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsFlexibleSlugAdditionalQuestionsComparison(slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAnalyticsFlexibleTabsAdditionalQuestionsComparison>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsFlexibleSlugAdditionalQuestionsComparison(slug, tabSlug, comparisonKind, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /analytics/flexible/:slug/additional_questions/main
         * @summary Основные данные по дополнительным вопросам
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsFlexibleSlugAdditionalQuestionsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAnalyticsFlexibleTabsAdditionalQuestionsMain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsFlexibleSlugAdditionalQuestionsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /analytics/flexible/:slug/main_results/main
         * @summary Основные данные для основных результатов
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsFlexibleSlugMainResultsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAnalyticsFlexibleTabsMainResultsMain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsFlexibleSlugMainResultsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /analytics/flexible/:slug/single_index/comparison
         * @summary Сравнительные данные по индексу
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {string} [comparisonKind] Тип сравнения
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsFlexibleSlugSingleIndexComparison(slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAnalyticsFlexibleTabsSingleIndexComparison>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsFlexibleSlugSingleIndexComparison(slug, tabSlug, comparisonKind, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /analytics/flexible/:slug/single_index/main
         * @summary Основные данные по индексу
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsFlexibleSlugSingleIndexMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAnalyticsFlexibleTabsSingleIndexMain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsFlexibleSlugSingleIndexMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /analytics/flexible/:slug/survey_participants/main
         * @summary Основные данные для участников исследования
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsFlexibleSlugSurveyParticipantsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAnalyticsFlexibleTabsSurveyPartipicantsMain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsFlexibleSlugSurveyParticipantsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /analytics/flexible/:slug/vertical_factors/comparison
         * @summary Сравнительные данные для вертикальных факторов
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {string} [comparisonKind] Тип сравнения
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsFlexibleSlugVerticalFactorsComparison(slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAnalyticsFlexibleTabsVerticalFactorsComparison>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsFlexibleSlugVerticalFactorsComparison(slug, tabSlug, comparisonKind, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /analytics/flexible/:slug/vertical_factors/main
         * @summary Основные данные для вертикальных факторов
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsFlexibleSlugVerticalFactorsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAnalyticsFlexibleTabsVerticalFactorsMain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsFlexibleSlugVerticalFactorsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/analytics/surveys/raw_data_reports/export
         * @summary Создает запрос на выгрузку .zip архива с отчётами
         * @param {PostAdminAnalyticsSurveysRawDataReportsExportRequest} postAdminAnalyticsSurveysRawDataReportsExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsSurveysRawDataReportsExport(postAdminAnalyticsSurveysRawDataReportsExportRequest: PostAdminAnalyticsSurveysRawDataReportsExportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsSurveysRawDataReportsSmall>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsSurveysRawDataReportsExport(postAdminAnalyticsSurveysRawDataReportsExportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/additional_questions
         * @summary Показывать ответы на вопросы, которых нет в нашем шаблоне
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest} [postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug: string, postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest?: PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsConstructorBlockData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug, postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/comments
         * @summary Возвращает комментарии по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsSurveysSurveySlugComments(surveySlug: string, questionId: number, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsConstructorTextData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsSurveysSurveySlugComments(surveySlug, questionId, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/factor-by-team
         * @summary Возвращает значение фактора в разрезе переданных команд
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug: string, factorName: string, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsFactorByTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug, factorName, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/other-answers
         * @summary Возвращает варианты \"Другое\" по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug: string, questionId: number, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsConstructorTextData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug, questionId, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/xlsx-reports-export
         * @summary Создает запрос на выгрузку xlsx файла
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {boolean} withChildren Вместе с дочерними подразделениями
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {Array<'index' | 'structure' | 'benchmarking' | 'answer_distribution'>} [engagement] Что показывать в вовлеченности
         * @param {Array<'index' | 'benchmarking' | 'answer_distribution'>} [satisfaction] Что показывать в удовлетворенности
         * @param {Array<'index' | 'groups'>} [enpsCompany] Что показывать в eNPS
         * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [baseFactors] Что показывать в базовых факторах
         * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [enchancingFactors] Что показывать в усиливающих факторах
         * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [employeeExperience] Что показывать в индексе опыта сотрудника
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminAnalyticsSurveysSurveySlugXlsxReportsExport(surveySlug: string, withChildren: boolean, teamIds?: Array<string>, jobPositions?: Array<string>, jobFunctions?: Array<string>, jobDurations?: Array<string>, jobPosition?: Array<string>, jobFunction?: Array<string>, jobDuration?: Array<string>, engagement?: Array<'index' | 'structure' | 'benchmarking' | 'answer_distribution'>, satisfaction?: Array<'index' | 'benchmarking' | 'answer_distribution'>, enpsCompany?: Array<'index' | 'groups'>, baseFactors?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, enchancingFactors?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, employeeExperience?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XLSXReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminAnalyticsSurveysSurveySlugXlsxReportsExport(surveySlug, withChildren, teamIds, jobPositions, jobFunctions, jobDurations, jobPosition, jobFunction, jobDuration, engagement, satisfaction, enpsCompany, baseFactors, enchancingFactors, employeeExperience, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/blocks
         * @summary Создание блока в странице опроса
         * @param {PostAdminBlocksRequest} postAdminBlocksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminBlocks(postAdminBlocksRequest: PostAdminBlocksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Block>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminBlocks(postAdminBlocksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/blocks/{id}/copy
         * @summary Копирование блока
         * @param {number} id ID блока для копирования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminBlocksIdCopy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Block>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminBlocksIdCopy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/branching_settings
         * @summary Обновить настройки ветвления для вопроса
         * @param {PostAdminBranchingSettingsRequest} postAdminBranchingSettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminBranchingSettings(postAdminBranchingSettingsRequest: PostAdminBranchingSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminBranchingSettings(postAdminBranchingSettingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/companies/import
         * @summary Импорт компании из оргструктуры
         * @param {PostAdminCompaniesImportRequest} postAdminCompaniesImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminCompaniesImport(postAdminCompaniesImportRequest: PostAdminCompaniesImportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminCompaniesImport(postAdminCompaniesImportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/complex_reports
         * @summary Создание комплексного отчета
         * @param {PostAdminComplexReports} adminComplexReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminComplexReports(adminComplexReports: PostAdminComplexReports, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplexReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminComplexReports(adminComplexReports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/email_templates
         * @summary Создание шаблона письма
         * @param {PostAdminEmailTemplatesRequest} postAdminEmailTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminEmailTemplates(postAdminEmailTemplatesRequest: PostAdminEmailTemplatesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminEmailTemplates(postAdminEmailTemplatesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/email_templates/{id}/test_email_message
         * @summary Отправить тестовое письмо
         * @param {string} id Идентификатор шаблона
         * @param {PostAdminEmailTemplatesIdTestEmailMessage} adminEmailTemplatesIdTestEmailMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminEmailTemplatesIdTestEmailMessage(id: string, adminEmailTemplatesIdTestEmailMessage: PostAdminEmailTemplatesIdTestEmailMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminEmailTemplatesIdTestEmailMessage(id, adminEmailTemplatesIdTestEmailMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/external_surveys
         * @summary Создать внешний опрос
         * @param {PostAdminExternalSurveys} adminExternalSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminExternalSurveys(adminExternalSurveys: PostAdminExternalSurveys, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminExternalSurveys(adminExternalSurveys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/factors
         * @summary Создать фактор
         * @param {PostAdminFactors} adminFactors 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminFactors(adminFactors: PostAdminFactors, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Factor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminFactors(adminFactors, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/factors_groups/:id/add_factors
         * @summary Список групп факторов
         * @param {number} id 
         * @param {PostAdminFactorsGroupsIdAddFactorsRequest} postAdminFactorsGroupsIdAddFactorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminFactorsGroupsIdAddFactors(id: number, postAdminFactorsGroupsIdAddFactorsRequest: PostAdminFactorsGroupsIdAddFactorsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactorsGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminFactorsGroupsIdAddFactors(id, postAdminFactorsGroupsIdAddFactorsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/factors/import
         * @summary Обновление фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminFactorsImport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminFactorsImport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/features/disable
         * @summary Выключение фичи
         * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
         * @param {string} featureName Название фичи
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminFeaturesDisable(xAuthToken: string, featureName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminFeaturesDisable(xAuthToken, featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/features/enable
         * @summary Включение фичи
         * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
         * @param {string} featureName Название фичи
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminFeaturesEnable(xAuthToken: string, featureName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminFeaturesEnable(xAuthToken, featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/heatmaps
         * @summary Создать тепловую карту
         * @param {PostAdminHeatmapsRequest} postAdminHeatmapsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminHeatmaps(postAdminHeatmapsRequest: PostAdminHeatmapsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Heatmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminHeatmaps(postAdminHeatmapsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/hr_practices
         * @summary Создать практику
         * @param {PostAdminHrPracticesRequest} postAdminHrPracticesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminHrPractices(postAdminHrPracticesRequest: PostAdminHrPracticesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HrPracticeFull>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminHrPractices(postAdminHrPracticesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/hr_practices/{id}/set_invisible
         * @summary Сделать практику невидимой для компании
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminHrPracticesIdSetInvisible(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminHrPracticesIdSetInvisible(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/hr_practices/{id}/set_visible
         * @summary Сделать практику видимой для компании
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminHrPracticesIdSetVisible(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminHrPracticesIdSetVisible(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/hr_practices/import
         * @summary Импортировать HR практики ТТ из файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminHrPracticesImport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminHrPracticesImport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/image_templates
         * @summary Добавление картинка в базу изображений
         * @param {PostAdminImageTemplatesRequest} postAdminImageTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminImageTemplates(postAdminImageTemplatesRequest: PostAdminImageTemplatesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminImageTemplates(postAdminImageTemplatesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/pages
         * @summary Создание страницы в опросе
         * @param {PostAdminPagesRequest} postAdminPagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminPages(postAdminPagesRequest: PostAdminPagesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminPages(postAdminPagesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/pages/{id}/duplicate
         * @summary Дублирование страницы
         * @param {number} id ID страницы для дублирования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminPagesIdDuplicate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminPagesIdDuplicate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/question_templates/import
         * @summary Импортировать шаблоны вопросов ТТ из файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminQuestionTemplatesImport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminQuestionTemplatesImport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/questions
         * @summary Создание вопроса
         * @param {PostAdminQuestionsRequest} [postAdminQuestionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminQuestions(postAdminQuestionsRequest?: PostAdminQuestionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Question>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminQuestions(postAdminQuestionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/roles/import
         * @summary Импорт ролей и абилок из файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminRolesImport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminRolesImport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/sudo_actions/add_to_sudo_admins
         * @summary Сделать пользователя системным администратором
         * @param {string} xAuthToken JWT токен для авторизации супер администратора
         * @param {PostAdminSudoActionsAddToSudoAdmins} adminSudoActionsAddToSudoAdmins 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSudoActionsAddToSudoAdmins(xAuthToken: string, adminSudoActionsAddToSudoAdmins: PostAdminSudoActionsAddToSudoAdmins, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Me>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSudoActionsAddToSudoAdmins(xAuthToken, adminSudoActionsAddToSudoAdmins, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/survey_meta_questions
         * @summary Задать настройки вопросов паспортички для опроса
         * @param {PostAdminSurveyMetaQuestions} adminSurveyMetaQuestions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveyMetaQuestions(adminSurveyMetaQuestions: PostAdminSurveyMetaQuestions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyMetaQuestion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveyMetaQuestions(adminSurveyMetaQuestions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/survey_requests
         * @summary Запросить опрос
         * @param {PostAdminSurveyRequestsRequest} postAdminSurveyRequestsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveyRequests(postAdminSurveyRequestsRequest: PostAdminSurveyRequestsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveyRequests(postAdminSurveyRequestsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys
         * @summary Создание опроса
         * @param {PostAdminSurveysRequest} postAdminSurveysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveys(postAdminSurveysRequest: PostAdminSurveysRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveys(postAdminSurveysRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys/active/notification-reminders
         * @summary Нотификация конкретной команды о том, что нужно пройти опрос
         * @param {PostAdminSurveysActiveNotificationRemindersRequest} [postAdminSurveysActiveNotificationRemindersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveysActiveNotificationReminders(postAdminSurveysActiveNotificationRemindersRequest?: PostAdminSurveysActiveNotificationRemindersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationReminder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveysActiveNotificationReminders(postAdminSurveysActiveNotificationRemindersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys/back_to_draft
         * @summary Вернуть несколько опросов из trashed в черновик
         * @param {PostAdminSurveysBackToDraftRequest} postAdminSurveysBackToDraftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveysBackToDraft(postAdminSurveysBackToDraftRequest: PostAdminSurveysBackToDraftRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveysBackToDraft(postAdminSurveysBackToDraftRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys/request
         * @summary Запросить опрос
         * @param {string} message Сообщение для создания опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveysRequest(message: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveysRequest(message, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys/{survey_slug}/back_to_draft
         * @summary Вернуть опрос (published/trashed -> draft) в статус черновик
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveysSurveySlugBackToDraft(surveySlug: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveysSurveySlugBackToDraft(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys/{survey_slug}/duplicate
         * @summary Дублировать опрос
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveysSurveySlugDuplicate(surveySlug: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveysSurveySlugDuplicate(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys/:survey_slug/fake_user_surveys
         * @summary “Восстановление” некачественных анкет из аналитики
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveysSurveySlugFakeUserSurveys(surveySlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveysSurveySlugFakeUserSurveys(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys/:survey_slug/orgstructure_pages
         * @summary Создание настроек страницы оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveysSurveySlugOrgstructurePages(surveySlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSurveysOrgstruturePageSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveysSurveySlugOrgstructurePages(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys/{surveys_slug}/pptx/pptx-export
         * @summary Создает запрос на выгрузку pptx файла
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveysSurveySlugPptxPptxExport(surveySlug: string, teamIds?: Array<string>, jobPositions?: Array<string>, jobFunctions?: Array<string>, jobDurations?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pptx>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveysSurveySlugPptxPptxExport(surveySlug, teamIds, jobPositions, jobFunctions, jobDurations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys/{survey_slug}/publish
         * @summary Опубликовать опрос(draft -> published)
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveysSurveySlugPublish(surveySlug: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveysSurveySlugPublish(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys/{survey_slug}/start_now
         * @summary Опубликовать и сразу запустить опрос (draft -> active)
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveysSurveySlugStartNow(surveySlug: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveysSurveySlugStartNow(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/surveys/{survey_slug}/stop_now
         * @summary Срочно остановить активный опрос
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSurveysSurveySlugStopNow(surveySlug: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSurveysSurveySlugStopNow(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/teams/ids/merge
         * @summary Схлопывание team ids
         * @param {GetAdminTeamsIdsMergeRequest} getAdminTeamsIdsMergeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminTeamsIdsMerge(getAdminTeamsIdsMergeRequest: GetAdminTeamsIdsMergeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgstructureShortTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminTeamsIdsMerge(getAdminTeamsIdsMergeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/teams/update_parents
         * @summary Обновление родительских связей в таблице teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminTeamsUpdateParents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminTeamsUpdateParents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/templates
         * @summary Создать шаблон опроса
         * @param {PostAdminTemplatesRequest} postAdminTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminTemplates(postAdminTemplatesRequest: PostAdminTemplatesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortSurveyTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminTemplates(postAdminTemplatesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/templates/import_tt
         * @summary Импортнуть шаблоны ТТ из файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminTemplatesImportTt(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminTemplatesImportTt(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/topmind_query/upsert
         * @summary Создает запрос на экспорт данных выбранной модели в TopMind
         * @param {PostAdminTopmindQueryUpsert} adminTopmindQueryUpsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminTopmindQueryUpsert(adminTopmindQueryUpsert: PostAdminTopmindQueryUpsert, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopmindQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminTopmindQueryUpsert(adminTopmindQueryUpsert, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /admin/user_groups
         * @summary Создать группу пользователей
         * @param {PostAdminUserGroups} adminUserGroups 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminUserGroups(adminUserGroups: PostAdminUserGroups, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminUserGroups(adminUserGroups, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/admin_area/surveys/{survey_slug}
         * @summary Обновление любых (даже запущенных опросов)
         * @param {number} surveySlug 
         * @param {PutAdminAdminAreaSurveysSurveySlugRequest} putAdminAdminAreaSurveysSurveySlugRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminAdminAreaSurveysSurveySlug(surveySlug: number, putAdminAdminAreaSurveysSurveySlugRequest: PutAdminAdminAreaSurveysSurveySlugRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminAdminAreaSurveysSurveySlug(surveySlug, putAdminAdminAreaSurveysSurveySlugRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/analytics/surveys/raw_data_reports/:report_id/update_status
         * @summary Меняет статус отчета
         * @param {number} reportId ID отчёта
         * @param {PutAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus} adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus(reportId: number, adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus: PutAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsSurveysRawDataReportsSmall>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus(reportId, adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/answers/{id}
         * @summary Обновление ответов
         * @param {number} id 
         * @param {PutAdminAnswersIdRequest} [putAdminAnswersIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminAnswersId(id: number, putAdminAnswersIdRequest?: PutAdminAnswersIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Answer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminAnswersId(id, putAdminAnswersIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/blocks/{id}
         * @summary Обновление блока
         * @param {number} id ID блока
         * @param {PutAdminBlocksIdRequest} [putAdminBlocksIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminBlocksId(id: number, putAdminBlocksIdRequest?: PutAdminBlocksIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Block>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminBlocksId(id, putAdminBlocksIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/complex_reports/{id}
         * @summary Обновление комплексного отчета
         * @param {number} id 
         * @param {PutAdminComplexReports} adminComplexReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminComplexReportsId(id: number, adminComplexReports: PutAdminComplexReports, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplexReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminComplexReportsId(id, adminComplexReports, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/email_templates/{id}
         * @summary Обновление шаблона письма
         * @param {string} id Идентификатор шаблона
         * @param {PutAdminEmailTemplates} adminEmailTemplates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminEmailTemplatesId(id: string, adminEmailTemplates: PutAdminEmailTemplates, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminEmailTemplatesId(id, adminEmailTemplates, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/external_surveys/:id
         * @summary Обновление внешнего опроса
         * @param {number} id ID внешнего опроса
         * @param {PutAdminExternalSurveys} adminExternalSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminExternalSurveysId(id: number, adminExternalSurveys: PutAdminExternalSurveys, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminExternalSurveysId(id, adminExternalSurveys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/factors/{id}
         * @summary Обновление фактора
         * @param {number} id Идентификатор фактора
         * @param {PutAdminFactors} adminFactors 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminFactorsId(id: number, adminFactors: PutAdminFactors, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Factor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminFactorsId(id, adminFactors, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/hr_practices/{id}
         * @summary Обновление практики
         * @param {string} id Идентификатор практики
         * @param {PutAdminHrPractices} adminHrPractices 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminHrPracticesId(id: string, adminHrPractices: PutAdminHrPractices, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HrPracticeFull>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminHrPracticesId(id, adminHrPractices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/pages/{id}
         * @summary Обновление страницы в опросе
         * @param {number} id ID страницы для обновления
         * @param {PutAdminPagesIdRequest} [putAdminPagesIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminPagesId(id: number, putAdminPagesIdRequest?: PutAdminPagesIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminPagesId(id, putAdminPagesIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/questions/{id}
         * @summary Обновление вопроса
         * @param {number} id ID вопроса
         * @param {PutAdminQuestionsIdRequest} [putAdminQuestionsIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminQuestionsId(id: number, putAdminQuestionsIdRequest?: PutAdminQuestionsIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Question>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminQuestionsId(id, putAdminQuestionsIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/surveys/{survey_slug}
         * @summary Обновление опроса
         * @param {string} surveySlug Идентификатор опроса
         * @param {PutAdminSurveys} adminSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminSurveysSurveySlug(surveySlug: string, adminSurveys: PutAdminSurveys, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminSurveysSurveySlug(surveySlug, adminSurveys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/surveys/:survey_slug/final_page
         * @summary Создать\\отредактировать страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminSurveysSurveySlugFinalPage(surveySlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSurveysFinalPages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminSurveysSurveySlugFinalPage(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/surveys/:survey_slug/orgstructure_pages
         * @summary Обновление настроек страницы оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {PutAdminSurveysSurveySlugOrgstructurePages} adminSurveysSurveySlugOrgstructurePages 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminSurveysSurveySlugOrgstructurePages(surveySlug: string, adminSurveysSurveySlugOrgstructurePages: PutAdminSurveysSurveySlugOrgstructurePages, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSurveysOrgstruturePageSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminSurveysSurveySlugOrgstructurePages(surveySlug, adminSurveysSurveySlugOrgstructurePages, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/surveys/:survey_slug/orgstructure_pages/orgstructure_blocks
         * @summary Обновление блока оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest} putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocks(surveySlug: string, putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest: PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgstructureBlock>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocks(surveySlug, putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/top_mind/export
         * @summary Создает запрос на экспорт данных выбранной модели в TopMind
         * @param {PutAdminTopMindExport} adminTopMindExport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminTopMindExport(adminTopMindExport: PutAdminTopMindExport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminTopMindExport(adminTopMindExport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/translations/blocks/{id}
         * @summary Обновление одного из переводов блока
         * @param {string} id Идентификатор блока
         * @param {PutAdminTranslationsBlocks} adminTranslationsBlocks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminTranslationsBlocksId(id: string, adminTranslationsBlocks: PutAdminTranslationsBlocks, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Block>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminTranslationsBlocksId(id, adminTranslationsBlocks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/translations/surveys/{survey_slug}
         * @summary Обновление переводов опроса
         * @param {string} surveySlug Идентификатор опроса
         * @param {PutAdminTranslationsSurveys} adminTranslationsSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminTranslationsSurveysSurveySlug(surveySlug: string, adminTranslationsSurveys: PutAdminTranslationsSurveys, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminTranslationsSurveysSurveySlug(surveySlug, adminTranslationsSurveys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/translations/surveys/:survey_slug/final_page
         * @summary Перевести страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {PutAdminTranslationsSurveysSurveySlugFinalPageRequest} putAdminTranslationsSurveysSurveySlugFinalPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminTranslationsSurveysSurveySlugFinalPage(surveySlug: string, putAdminTranslationsSurveysSurveySlugFinalPageRequest: PutAdminTranslationsSurveysSurveySlugFinalPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSurveysFinalPages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminTranslationsSurveysSurveySlugFinalPage(surveySlug, putAdminTranslationsSurveysSurveySlugFinalPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/translations/surveys/:survey_slug/orgstructure_pages
         * @summary Перевести страницы оргструктуры
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {PutAdminTranslationsSurveysSurveySlugOrgstructurePages} adminTranslationsSurveysSurveySlugOrgstructurePages 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminTranslationsSurveysSurveySlugOrgstructurePages(surveySlug: string, adminTranslationsSurveysSurveySlugOrgstructurePages: PutAdminTranslationsSurveysSurveySlugOrgstructurePages, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgstructurePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminTranslationsSurveysSurveySlugOrgstructurePages(surveySlug, adminTranslationsSurveysSurveySlugOrgstructurePages, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /admin/user_groups/:id
         * @summary Обновить группу пользователей
         * @param {number} id ID группы
         * @param {PutAdminUserGroups} adminUserGroups 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdminUserGroupsId(id: number, adminUserGroups: PutAdminUserGroups, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdminUserGroupsId(id, adminUserGroups, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * DELETE /admin/blocks/{id}
         * @summary Удаление блока
         * @param {number} id ID блока
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminBlocksId(id: number, options?: any): AxiosPromise<Block> {
            return localVarFp.deleteAdminBlocksId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/email_templates/{id}
         * @summary Удалить шаблон письма
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminEmailTemplatesId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminEmailTemplatesId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/email_templates/{id}/trash_image
         * @summary Удалить картинку шаблона
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminEmailTemplatesIdTrashImage(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminEmailTemplatesIdTrashImage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/external_surveys/:id
         * @summary Удалить внешний опрос
         * @param {number} id ID внешнего опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminExternalSurveysId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminExternalSurveysId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/factors/{id}
         * @summary Удалить фактор
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminFactorsId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminFactorsId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/hr_practices/{id}
         * @summary Удалить практику
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminHrPracticesId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminHrPracticesId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/hr_practices/{id}/trash_image
         * @summary Удалить картинку практики
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminHrPracticesIdTrashImage(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminHrPracticesIdTrashImage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/pages/{id}
         * @summary Удаление страницы в опросе
         * @param {number} id ID страницы для удаления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminPagesId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminPagesId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/questions/{id}
         * @summary Удалить вопрос
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminQuestionsId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminQuestionsId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/questions/{id}/picture_trash
         * @summary Удалить картинку вопроса
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminQuestionsIdPictureTrash(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminQuestionsIdPictureTrash(id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/surveys
         * @summary Удалить несколько опросов насовсем
         * @param {Array<string>} surveySlugs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveys(surveySlugs: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminSurveys(surveySlugs, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}
         * @summary Удалить опрос насовсем
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlug(surveySlug: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminSurveysSurveySlug(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}/async
         * @summary Удалить опрос насовсем асинхронно
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugAsync(surveySlug: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminSurveysSurveySlugAsync(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/surveys/:survey_slug/fake_user_surveys
         * @summary “Удаление” некачественных анкет из аналитики
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugFakeUserSurveys(surveySlug: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminSurveysSurveySlugFakeUserSurveys(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/surveys/:survey_slug/final_blocks/:id
         * @summary Удалить один из блоков страницы \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {string} id ID блока для удаления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugFinalBlocksId(surveySlug: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminSurveysSurveySlugFinalBlocksId(surveySlug, id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/surveys/:survey_slug/final_page
         * @summary Удалить страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugFinalPage(surveySlug: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminSurveysSurveySlugFinalPage(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}/image_trash
         * @summary Удалить картинку опроса
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugImageTrash(surveySlug: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminSurveysSurveySlugImageTrash(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/surveys/{surveys_slug}/trash
         * @summary Удалить опрос в корзину
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminSurveysSurveySlugTrash(surveySlug: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminSurveysSurveySlugTrash(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/templates/:id
         * @summary Удалить шаблона опроса компании
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminTemplatesId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminTemplatesId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * DELETE /admin/user_groups/:id
         * @summary Обновить группу пользователей
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminUserGroupsId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminUserGroupsId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/admin_area/surveys
         * @summary Получение списка опросов компании с разбивкой по статусам
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAdminAreaSurveys(options?: any): AxiosPromise<AdminAreaSurveysIndex> {
            return localVarFp.getAdminAdminAreaSurveys(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/analytics_templates
         * @summary Список шаблонов аналитики
         * @param {Array<string>} [slugs] Идентификаторы дефолтных темплейтов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsAnalyticsTemplates(slugs?: Array<string>, options?: any): AxiosPromise<AdminAnalyticsAnalyticsTemplatesFull> {
            return localVarFp.getAdminAnalyticsAnalyticsTemplates(slugs, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/company/factor-dynamic
         * @summary Динамика фактора на основании завершенных опросов
         * @param {string} [factorName] Название фактора
         * @param {Array<string>} [factorNames] Массив названий факторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsCompanyFactorDynamic(factorName?: string, factorNames?: Array<string>, options?: any): AxiosPromise<AnalyticsFactorDynamic> {
            return localVarFp.getAdminAnalyticsCompanyFactorDynamic(factorName, factorNames, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/dashboard
         * @summary Дэшборд по аналитике для активного опроса в рамках компании
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsDashboard(teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, options?: any): AxiosPromise<AnalyticsDashboard> {
            return localVarFp.getAdminAnalyticsDashboard(teamIds, jobFunctions, jobPositions, jobDurations, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/flexible/:slug/respondents/anonymous
         * @summary Проверка анонимности
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsFlexibleSlugRespondentsAnonymous(slug: string, teamIds?: Array<string>, options?: any): AxiosPromise<AdminAnalyticsFlexibleTabsRespondentsAnonymous> {
            return localVarFp.getAdminAnalyticsFlexibleSlugRespondentsAnonymous(slug, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /analytics/flexible/:slug/tabs
         * @summary Список вкладок для гибкой аналитики
         * @param {string} slug ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsFlexibleSlugTabs(slug: string, options?: any): AxiosPromise<AdminAnalyticsFlexibleTabs> {
            return localVarFp.getAdminAnalyticsFlexibleSlugTabs(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/questions/{question_id}/answers
         * @summary Получение ответов на открытые вопросы
         * @param {number} questionId ID вопроса
         * @param {string} [theme] Тема
         * @param {string} [sentiment] Сентимент
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 40)
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsQuestionsQuestionIdAnswers(questionId: number, theme?: string, sentiment?: string, offset?: number, limit?: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: any): AxiosPromise<AnalyticsQuestionsAnswers> {
            return localVarFp.getAdminAnalyticsQuestionsQuestionIdAnswers(questionId, theme, sentiment, offset, limit, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/questions/{question_id}/clustering
         * @summary Получение кластеризации открытого вопроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsQuestionsQuestionIdClustering(questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: any): AxiosPromise<AnalyticsQuestionsClustering> {
            return localVarFp.getAdminAnalyticsQuestionsQuestionIdClustering(questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/questions/{question_id}/comments
         * @summary Получение сентимента открытого вопроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsQuestionsQuestionIdComments(questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: any): AxiosPromise<AnalyticsQuestionsComments> {
            return localVarFp.getAdminAnalyticsQuestionsQuestionIdComments(questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/current
         * @summary Получить текущую активную команду для аналитики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysCurrent(options?: any): AxiosPromise<Survey> {
            return localVarFp.getAdminAnalyticsSurveysCurrent(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/raw_data_reports/:report_id/download
         * @summary Возвращает `.zip` файл со сформированными `.xlsx` отчетами, по одному файлу на каждый опрос
         * @param {number} reportId ID отчёта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysRawDataReportsReportIdDownload(reportId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getAdminAnalyticsSurveysRawDataReportsReportIdDownload(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/raw_data_reports/:report_id/status
         * @summary Возвращает информацию о статусе отчета
         * @param {number} reportId ID отчёта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysRawDataReportsReportIdStatus(reportId: number, options?: any): AxiosPromise<AnalyticsSurveysRawDataReportsSmall> {
            return localVarFp.getAdminAnalyticsSurveysRawDataReportsReportIdStatus(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{report_id}/report_status
         * @summary Возвращает информацию о статусе отчета
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysReportIdReportStatus(reportId: number, options?: any): AxiosPromise<XLSXReport> {
            return localVarFp.getAdminAnalyticsSurveysReportIdReportStatus(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{slug}
         * @summary Получить опрос по slug
         * @param {string} slug Slug идентификатор опроса или комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSlug(slug: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.getAdminAnalyticsSurveysSlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * /admin/analytics/surveys/{slug}/burnout
         * @summary Возвращает данные для аналитики опроса выгорания
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSlugBurnout(slug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: any): AxiosPromise<AnalyticsBurnoutData> {
            return localVarFp.getAdminAnalyticsSurveysSlugBurnout(slug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(axios, basePath));
        },
        /**
         * /admin/analytics/surveys/{survey_slug}/csi
         * @summary Возвращает данные для аналитики опроса сsi
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSlugCsi(slug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: any): AxiosPromise<AnalyticsCsiData> {
            return localVarFp.getAdminAnalyticsSurveysSlugCsi(slug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /user_surveys/{slug}/meta-filters
         * @summary Получение списка мета вопросов опроса (продвинутая паспортичка)
         * @param {string} slug Slug идентификатор опроса или комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSlugMetaFilters(slug: string, options?: any): AxiosPromise<AnalyticsMetaFiltersList> {
            return localVarFp.getAdminAnalyticsSurveysSlugMetaFilters(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/additional_questions
         * @summary Показывать ответы на вопросы, которых нет в нашем шаблоне
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: any): AxiosPromise<AnalyticsConstructorBlockData> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/comments
         * @summary Возвращает комментарии по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} teamIds ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugComments(surveySlug: string, questionId: number, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: any): AxiosPromise<AnalyticsConstructorTextData> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugComments(surveySlug, questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * /admin/analytics/surveys/{survey_slug}/constructor
         * @summary Возвращает данные для аналитики опросов из конструктора
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugConstructor(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: any): AxiosPromise<AnalyticsConstructorBlockData> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugConstructor(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/constructor-open-question-answers
         * @summary Возвращает ответы на открытые вопросы для опроса из конструктора по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugConstructorOpenQuestionAnswers(surveySlug: string, questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: any): AxiosPromise<AnalyticsConstructorTextData> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugConstructorOpenQuestionAnswers(surveySlug, questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factor-by-team
         * @summary Возвращает значение фактора в разрезе переданных команд
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {Array<string>} teamIds ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug: string, factorName: string, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: any): AxiosPromise<AnalyticsFactorByTeam> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug, factorName, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factor-groups
         * @summary Получение кол-ва групп в разрезе фактора
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugFactorGroups(surveySlug: string, factorName: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: any): AxiosPromise<AnalyticsFactorGroup> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugFactorGroups(surveySlug, factorName, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factor-questions
         * @summary Возвращает вопросы для фактора и ответы на них
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugFactorQuestions(surveySlug: string, factorName: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options?: any): AxiosPromise<AnalyticsQuestion> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugFactorQuestions(surveySlug, factorName, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, factorGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/factors
         * @summary Возвращает значение факторов по определенной команде (командам)
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugFactors(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options?: any): AxiosPromise<AnalyticsFactors> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugFactors(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, factorGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/filter-values
         * @summary Получение доступных значений для фильтров
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugFilterValues(surveySlug: string, teamIds?: Array<string>, options?: any): AxiosPromise<AnalyticsFilterValues> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugFilterValues(surveySlug, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/open-question-answers
         * @summary Получение ответов на открытый вопрос
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugOpenQuestionAnswers(surveySlug: string, questionId: number, teamIds?: Array<string>, factorGroup?: string, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: any): AxiosPromise<UserAnswer> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugOpenQuestionAnswers(surveySlug, questionId, teamIds, factorGroup, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/open-questions
         * @summary Получение открытых вопросов
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {string} [factorName] Название фактора
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugOpenQuestions(surveySlug: string, teamIds?: Array<string>, factorName?: string, factorGroup?: string, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: any): AxiosPromise<AnalyticsQuestion> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugOpenQuestions(surveySlug, teamIds, factorName, factorGroup, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/other-answers
         * @summary Возвращает варианты \"Другое\" по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {Array<string>} teamIds ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug: string, questionId: number, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: any): AxiosPromise<AnalyticsConstructorTextData> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug, questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/priority_factors
         * @summary Возвращает топ факторов с которыми надо поработать
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugPriorityFactors(surveySlug: string, teamIds?: Array<string>, options?: any): AxiosPromise<AnalyticsPriorityFactorsData> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugPriorityFactors(surveySlug, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/timings-by-team
         * @summary Возвращает статистику прохождения опроса в разрезе команды (команд)
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugTimingsByTeam(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: any): AxiosPromise<AnalyticsTimingsByTeam> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugTimingsByTeam(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/xlsx-reports/open-questions
         * @summary Возвращает XLSX файл с ответами на открытые вопросы
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {string} [factorGroup] Фильтрация по значению фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugXlsxReportsOpenQuestions(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options?: any): AxiosPromise<AnalyticsFactors> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugXlsxReportsOpenQuestions(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, factorGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/surveys/{survey_slug}/xlsx_reports/{report_id}/download
         * @summary Возвращает XLSX файл с данными отчета
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} reportId ID отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsSurveysSurveySlugXlsxReportsReportIdDownload(surveySlug: string, reportId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getAdminAnalyticsSurveysSurveySlugXlsxReportsReportIdDownload(surveySlug, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/analytics/teams/tree
         * @summary Получение дерева команд компании
         * @param {string} [surveySlug] Slug опроса (для разделения прав при просмотре аналитики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAnalyticsTeamsTree(surveySlug?: string, options?: any): AxiosPromise<Team> {
            return localVarFp.getAdminAnalyticsTeamsTree(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/company-historical-data
         * @summary Получение исторических значений факторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCompanyHistoricalData(options?: any): AxiosPromise<CalculatedCompanyHistoricalDatum> {
            return localVarFp.getAdminCompanyHistoricalData(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/company-historical-data/{survey_slug}
         * @summary Получение исторических значений факторов
         * @param {number} surveySlug 
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {number} [offset] Оффсет
         * @param {number} [limit] Количество записей на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCompanyHistoricalDataSurveySlug(surveySlug: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, offset?: number, limit?: number, options?: any): AxiosPromise<HistoricalDataForSurvey> {
            return localVarFp.getAdminCompanyHistoricalDataSurveySlug(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/complex_reports
         * @summary Создание комплексного отчета
         * @param {string} [q] Строка для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminComplexReports(q?: string, options?: any): AxiosPromise<ComplexReport> {
            return localVarFp.getAdminComplexReports(q, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/complex_reports/analytics_kinds
         * @summary Список доступных analytics_kind для комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminComplexReportsAnalyticsKinds(options?: any): AxiosPromise<AnalyticsKind> {
            return localVarFp.getAdminComplexReportsAnalyticsKinds(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/complex_reports/{id}
         * @summary Получение информации о комплексном отчете
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminComplexReportsId(id: number, options?: any): AxiosPromise<ComplexReportFull> {
            return localVarFp.getAdminComplexReportsId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/complex_reports/{id}
         * @summary Получение списка опросов компании доступных для добавления в комплексный отчет
         * @param {number} id ID комплексного отчета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminComplexReportsIdSurveys(id: number, options?: any): AxiosPromise<SurveyShort> {
            return localVarFp.getAdminComplexReportsIdSurveys(id, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/email_templates/{id}
         * @summary Шаблон письма
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEmailTemplatesId(id: number, options?: any): AxiosPromise<EmailTemplate> {
            return localVarFp.getAdminEmailTemplatesId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/factor_business_unit
         * @summary Сравнение фактора по бизнес-юнитам
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {number} level Уровень команды
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugFactorBusinessUnit(slug: string, level: number, teamIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.getAdminExpressSurveysAnalyticsSlugFactorBusinessUnit(slug, level, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/filter_values
         * @summary Получение данных для блока \"Верхнеуровневый фильтр по командам\"
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugFilterValues(slug: string, teamIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.getAdminExpressSurveysAnalyticsSlugFilterValues(slug, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/main_questions
         * @summary Данные для вкладки \"Факторы\" экспресс-опросов
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] UUID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugMainQuestions(slug: string, teamIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.getAdminExpressSurveysAnalyticsSlugMainQuestions(slug, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/main_results
         * @summary Данные для вкладки \"Основные результаты\" экспресс-опросов
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] UUID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugMainResults(slug: string, teamIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.getAdminExpressSurveysAnalyticsSlugMainResults(slug, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/main_results/business_units_comparison
         * @summary Данные для вкладки \"Основные результаты\" экспресс-опросов | Сравнение по бизнес-юнитам
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {number} level Выбранный уровень команд для сравнения по бизнес-юнитам
         * @param {Array<string>} [teamIds] UUIDs команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugMainResultsBusinessUnitsComparison(slug: string, level: number, teamIds?: Array<string>, options?: any): AxiosPromise<ExpressSurveysMainResultsEntityBusinessUnitsEntity> {
            return localVarFp.getAdminExpressSurveysAnalyticsSlugMainResultsBusinessUnitsComparison(slug, level, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/nps_basic
         * @summary Получение статистики по eNPS/NPS
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugNpsBasic(slug: string, teamIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.getAdminExpressSurveysAnalyticsSlugNpsBasic(slug, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/nps_business_unit
         * @summary Сравнение eNPS/NPS факторов по бизнес-юнитам
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {number} level Уровень команды
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugNpsBusinessUnit(slug: string, level: number, teamIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.getAdminExpressSurveysAnalyticsSlugNpsBusinessUnit(slug, level, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/open_questions/not_nps
         * @summary Получение статистики по открытым вопросам(без NPS)
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugOpenQuestionsNotNps(slug: string, teamIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.getAdminExpressSurveysAnalyticsSlugOpenQuestionsNotNps(slug, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/express_surveys/analytics/{slug}/open_questions/nps
         * @summary Получение статистики по открытым вопросам(для NPS)
         * @param {string} slug Slug опроса или комплексного отчета
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExpressSurveysAnalyticsSlugOpenQuestionsNps(slug: string, teamIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.getAdminExpressSurveysAnalyticsSlugOpenQuestionsNps(slug, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/external_surveys
         * @summary Список внешних опросов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExternalSurveys(options?: any): AxiosPromise<AdminExternalSurveysFull> {
            return localVarFp.getAdminExternalSurveys(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/external_surveys/:id
         * @summary Получить внешний опрос
         * @param {number} id ID внешнего опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminExternalSurveysId(id: number, options?: any): AxiosPromise<AdminExternalSurveysFull> {
            return localVarFp.getAdminExternalSurveysId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/factors
         * @summary Список факторов
         * @param {string} [surveySlug] Slug опроса куда добавляем факторы
         * @param {string} [questionType] Тип вопроса для фильтра
         * @param {number} [maxAnswers] Количество ответов для фильтра
         * @param {boolean} [questionRequired] Обязательность вопроса для фильтра
         * @param {boolean} [onlyFactors] Вернуть только факторы которые не привязаны к индексам
         * @param {Array<string>} [factorsGroupsNames] Названия групп факторов для фильтра
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFactors(surveySlug?: string, questionType?: string, maxAnswers?: number, questionRequired?: boolean, onlyFactors?: boolean, factorsGroupsNames?: Array<string>, options?: any): AxiosPromise<Factor> {
            return localVarFp.getAdminFactors(surveySlug, questionType, maxAnswers, questionRequired, onlyFactors, factorsGroupsNames, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/factors_groups
         * @summary Список групп факторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFactorsGroups(options?: any): AxiosPromise<FactorsGroup> {
            return localVarFp.getAdminFactorsGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/factors/{id}
         * @summary Получить фактор
         * @param {number} id Идентификатор фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFactorsId(id: number, options?: any): AxiosPromise<Factor> {
            return localVarFp.getAdminFactorsId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/features/status
         * @summary Получение состояния feature флагов
         * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFeaturesStatus(xAuthToken: string, options?: any): AxiosPromise<Feature> {
            return localVarFp.getAdminFeaturesStatus(xAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/heatmaps/:id
         * @summary Получить статус тепловой карты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminHeatmapsId(id: number, options?: any): AxiosPromise<Heatmap> {
            return localVarFp.getAdminHeatmapsId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/hr_practices
         * @summary Список HR практик
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {'all' | 'user' | 'tt'} [scope] Скоуп практик
         * @param {Array<string>} [tags] Тэги для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminHrPractices(offset?: number, limit?: number, scope?: 'all' | 'user' | 'tt', tags?: Array<string>, options?: any): AxiosPromise<TagsHrPractices> {
            return localVarFp.getAdminHrPractices(offset, limit, scope, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/hr_practices/{id}
         * @summary Список HR практик
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminHrPracticesId(id: number, options?: any): AxiosPromise<HrPracticeFull> {
            return localVarFp.getAdminHrPracticesId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/image_templates
         * @summary Список шаблонов картинок
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminImageTemplates(options?: any): AxiosPromise<ImageTemplate> {
            return localVarFp.getAdminImageTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/indicies
         * @summary Список индексов (групп факторов с пометкой индекс) для добавления в опрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminIndicies(options?: any): AxiosPromise<FactorsGroupAsIndex> {
            return localVarFp.getAdminIndicies(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/me
         * @summary Информация о текущем пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminMe(options?: any): AxiosPromise<Me> {
            return localVarFp.getAdminMe(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/meta_info
         * @summary Получить доступную метаинфу о пользователях для компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminMetaInfo(options?: any): AxiosPromise<MetaInfo> {
            return localVarFp.getAdminMetaInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/pages
         * @summary Список страниц для данного опроса (с возможностью поиска)
         * @param {string} surveySlug Slug опроса
         * @param {string} [q] Шаблон для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPages(surveySlug: string, q?: string, options?: any): AxiosPromise<ShortPage> {
            return localVarFp.getAdminPages(surveySlug, q, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/question_templates
         * @summary Список шаблонов вопросов
         * @param {number} [page] Страница
         * @param {number} [perPage] Количество записей на странице
         * @param {string} [tag] Тэг для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminQuestionTemplates(page?: number, perPage?: number, tag?: string, options?: any): AxiosPromise<TagsQuestionTemplates> {
            return localVarFp.getAdminQuestionTemplates(page, perPage, tag, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/roles
         * @summary Список ролей и абилок
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminRoles(options?: any): AxiosPromise<Role> {
            return localVarFp.getAdminRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/surveys
         * @summary Список опросов с шаблонами для личного кабинета
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveys(options?: any): AxiosPromise<SurveysTemplates> {
            return localVarFp.getAdminSurveys(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/surveys/active/notification-reminders
         * @summary Получение списка отправленных уведомлений в течении 24х часов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysActiveNotificationReminders(options?: any): AxiosPromise<NotificationReminder> {
            return localVarFp.getAdminSurveysActiveNotificationReminders(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/surveys/drafts
         * @summary Список черновиков опросов для личного кабинета
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysDrafts(offset?: number, limit?: number, options?: any): AxiosPromise<SurveysListData> {
            return localVarFp.getAdminSurveysDrafts(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/surveys/{survey_slug}
         * @summary Получить опрос для редактирования
         * @param {string} surveySlug Идентификатор опроса
         * @param {string} [language] Язык опроса (перевода)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlug(surveySlug: string, language?: string, options?: any): AxiosPromise<FullSurvey> {
            return localVarFp.getAdminSurveysSurveySlug(surveySlug, language, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/surveys/:survey_slug/final_page
         * @summary Получить страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {string} [language] Язык перевода
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugFinalPage(surveySlug: string, language?: string, options?: any): AxiosPromise<AdminSurveysFinalPages> {
            return localVarFp.getAdminSurveysSurveySlugFinalPage(surveySlug, language, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/surveys/:survey_slug/orgstructure_pages
         * @summary Получить настройки оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugOrgstructurePages(surveySlug: string, options?: any): AxiosPromise<AdminSurveysOrgstruturePageSettings> {
            return localVarFp.getAdminSurveysSurveySlugOrgstructurePages(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/surveys/{surveys_slug}/pptx/{pptx_id}/download
         * @summary Получение pptx опроса
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} pptxId ID презентации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugPptxPptxIdDownload(surveySlug: string, pptxId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getAdminSurveysSurveySlugPptxPptxIdDownload(surveySlug, pptxId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/surveys/{surveys_slug}/pptx/{pptx_id}/status
         * @summary Возвращает информацию о статусе презентации
         * @param {number} surveySlug 
         * @param {number} pptxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugPptxPptxIdStatus(surveySlug: number, pptxId: number, options?: any): AxiosPromise<Pptx> {
            return localVarFp.getAdminSurveysSurveySlugPptxPptxIdStatus(surveySlug, pptxId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/surveys/{survey_slug}/root-team
         * @summary Получить корневую команду для опроса
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugRootTeam(surveySlug: string, options?: any): AxiosPromise<OrgstructureTeam> {
            return localVarFp.getAdminSurveysSurveySlugRootTeam(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/surveys/{survey_slug}/timeline
         * @summary Получить таймлайн опросов компании
         * @param {string} surveySlug Идентификатор опроса
         * @param {string} startDate Начальная дата интервала
         * @param {string} endDate Конечная дата интервала
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysSurveySlugTimeline(surveySlug: string, startDate: string, endDate: string, options?: any): AxiosPromise<SurveyTimelineItem> {
            return localVarFp.getAdminSurveysSurveySlugTimeline(surveySlug, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/surveys/trashed
         * @summary Список опросов в корзине
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveysTrashed(offset?: number, limit?: number, options?: any): AxiosPromise<SurveysListData> {
            return localVarFp.getAdminSurveysTrashed(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/tags
         * @summary Получить список тегов с пагинацией, поиск по параметру q (если пустая строка в q, то отдаем все)
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 6)
         * @param {string} [q] Шаблон для поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTags(offset?: number, limit?: number, q?: string, options?: any): AxiosPromise<TagsIndex> {
            return localVarFp.getAdminTags(offset, limit, q, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/teams/
         * @summary Получение команд по совпадению имени (в будущем возможно по дефолту будет отдавать какие-то команды)
         * @param {string} name Совпадение, по которому искать команды
         * @param {string} [surveySlug] Slug опроса для аналитики которого ищем команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeams(name: string, surveySlug?: string, options?: any): AxiosPromise<Team> {
            return localVarFp.getAdminTeams(name, surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/teams/children
         * @summary Получение детей для команд
         * @param {string} [surveySlug] 
         * @param {Array<string>} [teamIds] ID команд
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsChildren(surveySlug?: string, teamIds?: Array<string>, options?: any): AxiosPromise<AnalyticsTeam> {
            return localVarFp.getAdminTeamsChildren(surveySlug, teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/teams/{id}
         * @summary Получение данный команды по ID
         * @param {string} id ID команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsId(id: string, options?: any): AxiosPromise<OrgstructureTeam> {
            return localVarFp.getAdminTeamsId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/teams/{id}/children
         * @summary Получение детей для команды
         * @param {string} id ID команды
         * @param {string} [surveySlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsIdChildren(id: string, surveySlug?: string, options?: any): AxiosPromise<AnalyticsTeam> {
            return localVarFp.getAdminTeamsIdChildren(id, surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/teams/{id}/parent
         * @summary Получение ID вышестоящей команды
         * @param {string} id ID команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsIdParent(id: string, options?: any): AxiosPromise<OrgstructureParent> {
            return localVarFp.getAdminTeamsIdParent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/teams/{id}/parents
         * @summary Получение дерево вышестоящих команд
         * @param {string} id ID команды
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsIdParents(id: string, options?: any): AxiosPromise<OrgstructureParents> {
            return localVarFp.getAdminTeamsIdParents(id, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/teams/ids/merge
         * @summary Схлопывание team ids
         * @param {Array<string>} teamIds ID команд
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAdminTeamsIdsMerge(teamIds: Array<string>, options?: any): AxiosPromise<OrgstructureShortTeam> {
            return localVarFp.getAdminTeamsIdsMerge(teamIds, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/teams/tree
         * @summary Получение дерева команд компании
         * @param {string} [surveySlug] Slug опроса (для разделения прав при просмотре аналитики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeamsTree(surveySlug?: string, options?: any): AxiosPromise<Team> {
            return localVarFp.getAdminTeamsTree(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/templates
         * @summary Список шаблонов опросов для личного кабинета
         * @param {number} [offset] Оффсет
         * @param {number} [limit] Количество записей на странице
         * @param {'all' | 'user' | 'tt'} [scope] Скоуп шаблонов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTemplates(offset?: number, limit?: number, scope?: 'all' | 'user' | 'tt', options?: any): AxiosPromise<SurveyTemplatesIndex> {
            return localVarFp.getAdminTemplates(offset, limit, scope, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/user_groups
         * @summary Список групп пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserGroups(options?: any): AxiosPromise<UserGroup> {
            return localVarFp.getAdminUserGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /admin/user_groups/:id
         * @summary Полная информация о группе пользователей
         * @param {number} id ID группы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserGroupsId(id: number, options?: any): AxiosPromise<UserGroup> {
            return localVarFp.getAdminUserGroupsId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * /admin/users
         * @summary Список пользователей компании
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {string} [q] Шаблон поиска
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUsers(offset?: number, limit?: number, q?: string, options?: any): AxiosPromise<UsersList> {
            return localVarFp.getAdminUsers(offset, limit, q, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/analytics/analytics_templates/import
         * @summary Обновить шаблон аналитики
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {number} [templateId] ID импортируемого темплейта
         * @param {boolean} [_default] Является ли шаблон дефолтным
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsAnalyticsTemplatesImport(slug: string, templateId?: number, _default?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.postAdminAnalyticsAnalyticsTemplatesImport(slug, templateId, _default, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /analytics/flexible/:slug/additional_questions/comparison
         * @summary Сравнительные данные по индексу
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {string} [comparisonKind] Тип сравнения
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugAdditionalQuestionsComparison(slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: any): AxiosPromise<AdminAnalyticsFlexibleTabsAdditionalQuestionsComparison> {
            return localVarFp.postAdminAnalyticsFlexibleSlugAdditionalQuestionsComparison(slug, tabSlug, comparisonKind, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /analytics/flexible/:slug/additional_questions/main
         * @summary Основные данные по дополнительным вопросам
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugAdditionalQuestionsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: any): AxiosPromise<AdminAnalyticsFlexibleTabsAdditionalQuestionsMain> {
            return localVarFp.postAdminAnalyticsFlexibleSlugAdditionalQuestionsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /analytics/flexible/:slug/main_results/main
         * @summary Основные данные для основных результатов
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugMainResultsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: any): AxiosPromise<AdminAnalyticsFlexibleTabsMainResultsMain> {
            return localVarFp.postAdminAnalyticsFlexibleSlugMainResultsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /analytics/flexible/:slug/single_index/comparison
         * @summary Сравнительные данные по индексу
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {string} [comparisonKind] Тип сравнения
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugSingleIndexComparison(slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: any): AxiosPromise<AdminAnalyticsFlexibleTabsSingleIndexComparison> {
            return localVarFp.postAdminAnalyticsFlexibleSlugSingleIndexComparison(slug, tabSlug, comparisonKind, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /analytics/flexible/:slug/single_index/main
         * @summary Основные данные по индексу
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugSingleIndexMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: any): AxiosPromise<AdminAnalyticsFlexibleTabsSingleIndexMain> {
            return localVarFp.postAdminAnalyticsFlexibleSlugSingleIndexMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /analytics/flexible/:slug/survey_participants/main
         * @summary Основные данные для участников исследования
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugSurveyParticipantsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: any): AxiosPromise<AdminAnalyticsFlexibleTabsSurveyPartipicantsMain> {
            return localVarFp.postAdminAnalyticsFlexibleSlugSurveyParticipantsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /analytics/flexible/:slug/vertical_factors/comparison
         * @summary Сравнительные данные для вертикальных факторов
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {string} [comparisonKind] Тип сравнения
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugVerticalFactorsComparison(slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: any): AxiosPromise<AdminAnalyticsFlexibleTabsVerticalFactorsComparison> {
            return localVarFp.postAdminAnalyticsFlexibleSlugVerticalFactorsComparison(slug, tabSlug, comparisonKind, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /analytics/flexible/:slug/vertical_factors/main
         * @summary Основные данные для вертикальных факторов
         * @param {string} slug Идентификатор опроса или комплексного отчета
         * @param {string} tabSlug Идентификатор таба
         * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsFlexibleSlugVerticalFactorsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: any): AxiosPromise<AdminAnalyticsFlexibleTabsVerticalFactorsMain> {
            return localVarFp.postAdminAnalyticsFlexibleSlugVerticalFactorsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/analytics/surveys/raw_data_reports/export
         * @summary Создает запрос на выгрузку .zip архива с отчётами
         * @param {PostAdminAnalyticsSurveysRawDataReportsExportRequest} postAdminAnalyticsSurveysRawDataReportsExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysRawDataReportsExport(postAdminAnalyticsSurveysRawDataReportsExportRequest: PostAdminAnalyticsSurveysRawDataReportsExportRequest, options?: any): AxiosPromise<AnalyticsSurveysRawDataReportsSmall> {
            return localVarFp.postAdminAnalyticsSurveysRawDataReportsExport(postAdminAnalyticsSurveysRawDataReportsExportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/additional_questions
         * @summary Показывать ответы на вопросы, которых нет в нашем шаблоне
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest} [postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug: string, postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest?: PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest, options?: any): AxiosPromise<AnalyticsConstructorBlockData> {
            return localVarFp.postAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug, postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/comments
         * @summary Возвращает комментарии по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysSurveySlugComments(surveySlug: string, questionId: number, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset?: number, limit?: number, options?: any): AxiosPromise<AnalyticsConstructorTextData> {
            return localVarFp.postAdminAnalyticsSurveysSurveySlugComments(surveySlug, questionId, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/factor-by-team
         * @summary Возвращает значение фактора в разрезе переданных команд
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {string} factorName Название фактора
         * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug: string, factorName: string, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, options?: any): AxiosPromise<AnalyticsFactorByTeam> {
            return localVarFp.postAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug, factorName, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/other-answers
         * @summary Возвращает варианты \"Другое\" по вопросу
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {number} questionId ID вопроса
         * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
         * @param {number} [offset] Offset (по умолчанию 0)
         * @param {number} [limit] Limit (по умолчанию 10)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug: string, questionId: number, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset?: number, limit?: number, options?: any): AxiosPromise<AnalyticsConstructorTextData> {
            return localVarFp.postAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug, questionId, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/analytics/surveys/{survey_slug}/xlsx-reports-export
         * @summary Создает запрос на выгрузку xlsx файла
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {boolean} withChildren Вместе с дочерними подразделениями
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {Array<string>} [jobPosition] Рабочие должности
         * @param {Array<string>} [jobFunction] Рабочие функции
         * @param {Array<string>} [jobDuration] Сроки работы
         * @param {Array<'index' | 'structure' | 'benchmarking' | 'answer_distribution'>} [engagement] Что показывать в вовлеченности
         * @param {Array<'index' | 'benchmarking' | 'answer_distribution'>} [satisfaction] Что показывать в удовлетворенности
         * @param {Array<'index' | 'groups'>} [enpsCompany] Что показывать в eNPS
         * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [baseFactors] Что показывать в базовых факторах
         * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [enchancingFactors] Что показывать в усиливающих факторах
         * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [employeeExperience] Что показывать в индексе опыта сотрудника
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminAnalyticsSurveysSurveySlugXlsxReportsExport(surveySlug: string, withChildren: boolean, teamIds?: Array<string>, jobPositions?: Array<string>, jobFunctions?: Array<string>, jobDurations?: Array<string>, jobPosition?: Array<string>, jobFunction?: Array<string>, jobDuration?: Array<string>, engagement?: Array<'index' | 'structure' | 'benchmarking' | 'answer_distribution'>, satisfaction?: Array<'index' | 'benchmarking' | 'answer_distribution'>, enpsCompany?: Array<'index' | 'groups'>, baseFactors?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, enchancingFactors?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, employeeExperience?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, options?: any): AxiosPromise<XLSXReport> {
            return localVarFp.postAdminAnalyticsSurveysSurveySlugXlsxReportsExport(surveySlug, withChildren, teamIds, jobPositions, jobFunctions, jobDurations, jobPosition, jobFunction, jobDuration, engagement, satisfaction, enpsCompany, baseFactors, enchancingFactors, employeeExperience, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/blocks
         * @summary Создание блока в странице опроса
         * @param {PostAdminBlocksRequest} postAdminBlocksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminBlocks(postAdminBlocksRequest: PostAdminBlocksRequest, options?: any): AxiosPromise<Block> {
            return localVarFp.postAdminBlocks(postAdminBlocksRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/blocks/{id}/copy
         * @summary Копирование блока
         * @param {number} id ID блока для копирования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminBlocksIdCopy(id: number, options?: any): AxiosPromise<Block> {
            return localVarFp.postAdminBlocksIdCopy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/branching_settings
         * @summary Обновить настройки ветвления для вопроса
         * @param {PostAdminBranchingSettingsRequest} postAdminBranchingSettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminBranchingSettings(postAdminBranchingSettingsRequest: PostAdminBranchingSettingsRequest, options?: any): AxiosPromise<FullSurvey> {
            return localVarFp.postAdminBranchingSettings(postAdminBranchingSettingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/companies/import
         * @summary Импорт компании из оргструктуры
         * @param {PostAdminCompaniesImportRequest} postAdminCompaniesImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminCompaniesImport(postAdminCompaniesImportRequest: PostAdminCompaniesImportRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postAdminCompaniesImport(postAdminCompaniesImportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/complex_reports
         * @summary Создание комплексного отчета
         * @param {PostAdminComplexReports} adminComplexReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminComplexReports(adminComplexReports: PostAdminComplexReports, options?: any): AxiosPromise<ComplexReport> {
            return localVarFp.postAdminComplexReports(adminComplexReports, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/email_templates
         * @summary Создание шаблона письма
         * @param {PostAdminEmailTemplatesRequest} postAdminEmailTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminEmailTemplates(postAdminEmailTemplatesRequest: PostAdminEmailTemplatesRequest, options?: any): AxiosPromise<EmailTemplate> {
            return localVarFp.postAdminEmailTemplates(postAdminEmailTemplatesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/email_templates/{id}/test_email_message
         * @summary Отправить тестовое письмо
         * @param {string} id Идентификатор шаблона
         * @param {PostAdminEmailTemplatesIdTestEmailMessage} adminEmailTemplatesIdTestEmailMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminEmailTemplatesIdTestEmailMessage(id: string, adminEmailTemplatesIdTestEmailMessage: PostAdminEmailTemplatesIdTestEmailMessage, options?: any): AxiosPromise<void> {
            return localVarFp.postAdminEmailTemplatesIdTestEmailMessage(id, adminEmailTemplatesIdTestEmailMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/external_surveys
         * @summary Создать внешний опрос
         * @param {PostAdminExternalSurveys} adminExternalSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminExternalSurveys(adminExternalSurveys: PostAdminExternalSurveys, options?: any): AxiosPromise<void> {
            return localVarFp.postAdminExternalSurveys(adminExternalSurveys, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/factors
         * @summary Создать фактор
         * @param {PostAdminFactors} adminFactors 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminFactors(adminFactors: PostAdminFactors, options?: any): AxiosPromise<Factor> {
            return localVarFp.postAdminFactors(adminFactors, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/factors_groups/:id/add_factors
         * @summary Список групп факторов
         * @param {number} id 
         * @param {PostAdminFactorsGroupsIdAddFactorsRequest} postAdminFactorsGroupsIdAddFactorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminFactorsGroupsIdAddFactors(id: number, postAdminFactorsGroupsIdAddFactorsRequest: PostAdminFactorsGroupsIdAddFactorsRequest, options?: any): AxiosPromise<FactorsGroup> {
            return localVarFp.postAdminFactorsGroupsIdAddFactors(id, postAdminFactorsGroupsIdAddFactorsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/factors/import
         * @summary Обновление фактора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminFactorsImport(options?: any): AxiosPromise<void> {
            return localVarFp.postAdminFactorsImport(options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/features/disable
         * @summary Выключение фичи
         * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
         * @param {string} featureName Название фичи
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminFeaturesDisable(xAuthToken: string, featureName: string, options?: any): AxiosPromise<Feature> {
            return localVarFp.postAdminFeaturesDisable(xAuthToken, featureName, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/features/enable
         * @summary Включение фичи
         * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
         * @param {string} featureName Название фичи
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminFeaturesEnable(xAuthToken: string, featureName: string, options?: any): AxiosPromise<Feature> {
            return localVarFp.postAdminFeaturesEnable(xAuthToken, featureName, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/heatmaps
         * @summary Создать тепловую карту
         * @param {PostAdminHeatmapsRequest} postAdminHeatmapsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminHeatmaps(postAdminHeatmapsRequest: PostAdminHeatmapsRequest, options?: any): AxiosPromise<Heatmap> {
            return localVarFp.postAdminHeatmaps(postAdminHeatmapsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/hr_practices
         * @summary Создать практику
         * @param {PostAdminHrPracticesRequest} postAdminHrPracticesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminHrPractices(postAdminHrPracticesRequest: PostAdminHrPracticesRequest, options?: any): AxiosPromise<HrPracticeFull> {
            return localVarFp.postAdminHrPractices(postAdminHrPracticesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/hr_practices/{id}/set_invisible
         * @summary Сделать практику невидимой для компании
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminHrPracticesIdSetInvisible(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.postAdminHrPracticesIdSetInvisible(id, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/hr_practices/{id}/set_visible
         * @summary Сделать практику видимой для компании
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminHrPracticesIdSetVisible(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.postAdminHrPracticesIdSetVisible(id, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/hr_practices/import
         * @summary Импортировать HR практики ТТ из файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminHrPracticesImport(options?: any): AxiosPromise<void> {
            return localVarFp.postAdminHrPracticesImport(options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/image_templates
         * @summary Добавление картинка в базу изображений
         * @param {PostAdminImageTemplatesRequest} postAdminImageTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminImageTemplates(postAdminImageTemplatesRequest: PostAdminImageTemplatesRequest, options?: any): AxiosPromise<ImageTemplate> {
            return localVarFp.postAdminImageTemplates(postAdminImageTemplatesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/pages
         * @summary Создание страницы в опросе
         * @param {PostAdminPagesRequest} postAdminPagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminPages(postAdminPagesRequest: PostAdminPagesRequest, options?: any): AxiosPromise<Page> {
            return localVarFp.postAdminPages(postAdminPagesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/pages/{id}/duplicate
         * @summary Дублирование страницы
         * @param {number} id ID страницы для дублирования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminPagesIdDuplicate(id: number, options?: any): AxiosPromise<Page> {
            return localVarFp.postAdminPagesIdDuplicate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/question_templates/import
         * @summary Импортировать шаблоны вопросов ТТ из файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminQuestionTemplatesImport(options?: any): AxiosPromise<void> {
            return localVarFp.postAdminQuestionTemplatesImport(options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/questions
         * @summary Создание вопроса
         * @param {PostAdminQuestionsRequest} [postAdminQuestionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminQuestions(postAdminQuestionsRequest?: PostAdminQuestionsRequest, options?: any): AxiosPromise<Question> {
            return localVarFp.postAdminQuestions(postAdminQuestionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/roles/import
         * @summary Импорт ролей и абилок из файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminRolesImport(options?: any): AxiosPromise<Role> {
            return localVarFp.postAdminRolesImport(options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/sudo_actions/add_to_sudo_admins
         * @summary Сделать пользователя системным администратором
         * @param {string} xAuthToken JWT токен для авторизации супер администратора
         * @param {PostAdminSudoActionsAddToSudoAdmins} adminSudoActionsAddToSudoAdmins 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSudoActionsAddToSudoAdmins(xAuthToken: string, adminSudoActionsAddToSudoAdmins: PostAdminSudoActionsAddToSudoAdmins, options?: any): AxiosPromise<Me> {
            return localVarFp.postAdminSudoActionsAddToSudoAdmins(xAuthToken, adminSudoActionsAddToSudoAdmins, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/survey_meta_questions
         * @summary Задать настройки вопросов паспортички для опроса
         * @param {PostAdminSurveyMetaQuestions} adminSurveyMetaQuestions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveyMetaQuestions(adminSurveyMetaQuestions: PostAdminSurveyMetaQuestions, options?: any): AxiosPromise<SurveyMetaQuestion> {
            return localVarFp.postAdminSurveyMetaQuestions(adminSurveyMetaQuestions, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/survey_requests
         * @summary Запросить опрос
         * @param {PostAdminSurveyRequestsRequest} postAdminSurveyRequestsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveyRequests(postAdminSurveyRequestsRequest: PostAdminSurveyRequestsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postAdminSurveyRequests(postAdminSurveyRequestsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys
         * @summary Создание опроса
         * @param {PostAdminSurveysRequest} postAdminSurveysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveys(postAdminSurveysRequest: PostAdminSurveysRequest, options?: any): AxiosPromise<FullSurvey> {
            return localVarFp.postAdminSurveys(postAdminSurveysRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys/active/notification-reminders
         * @summary Нотификация конкретной команды о том, что нужно пройти опрос
         * @param {PostAdminSurveysActiveNotificationRemindersRequest} [postAdminSurveysActiveNotificationRemindersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysActiveNotificationReminders(postAdminSurveysActiveNotificationRemindersRequest?: PostAdminSurveysActiveNotificationRemindersRequest, options?: any): AxiosPromise<NotificationReminder> {
            return localVarFp.postAdminSurveysActiveNotificationReminders(postAdminSurveysActiveNotificationRemindersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys/back_to_draft
         * @summary Вернуть несколько опросов из trashed в черновик
         * @param {PostAdminSurveysBackToDraftRequest} postAdminSurveysBackToDraftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysBackToDraft(postAdminSurveysBackToDraftRequest: PostAdminSurveysBackToDraftRequest, options?: any): AxiosPromise<FullSurvey> {
            return localVarFp.postAdminSurveysBackToDraft(postAdminSurveysBackToDraftRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys/request
         * @summary Запросить опрос
         * @param {string} message Сообщение для создания опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysRequest(message: string, options?: any): AxiosPromise<void> {
            return localVarFp.postAdminSurveysRequest(message, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys/{survey_slug}/back_to_draft
         * @summary Вернуть опрос (published/trashed -> draft) в статус черновик
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugBackToDraft(surveySlug: number, options?: any): AxiosPromise<FullSurvey> {
            return localVarFp.postAdminSurveysSurveySlugBackToDraft(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys/{survey_slug}/duplicate
         * @summary Дублировать опрос
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugDuplicate(surveySlug: number, options?: any): AxiosPromise<FullSurvey> {
            return localVarFp.postAdminSurveysSurveySlugDuplicate(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys/:survey_slug/fake_user_surveys
         * @summary “Восстановление” некачественных анкет из аналитики
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugFakeUserSurveys(surveySlug: string, options?: any): AxiosPromise<void> {
            return localVarFp.postAdminSurveysSurveySlugFakeUserSurveys(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys/:survey_slug/orgstructure_pages
         * @summary Создание настроек страницы оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugOrgstructurePages(surveySlug: string, options?: any): AxiosPromise<AdminSurveysOrgstruturePageSettings> {
            return localVarFp.postAdminSurveysSurveySlugOrgstructurePages(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys/{surveys_slug}/pptx/pptx-export
         * @summary Создает запрос на выгрузку pptx файла
         * @param {string} surveySlug Slug идентификатор опроса
         * @param {Array<string>} [teamIds] ID команд
         * @param {Array<string>} [jobPositions] Рабочие должности
         * @param {Array<string>} [jobFunctions] Рабочие функции
         * @param {Array<string>} [jobDurations] Сроки работы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugPptxPptxExport(surveySlug: string, teamIds?: Array<string>, jobPositions?: Array<string>, jobFunctions?: Array<string>, jobDurations?: Array<string>, options?: any): AxiosPromise<Pptx> {
            return localVarFp.postAdminSurveysSurveySlugPptxPptxExport(surveySlug, teamIds, jobPositions, jobFunctions, jobDurations, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys/{survey_slug}/publish
         * @summary Опубликовать опрос(draft -> published)
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugPublish(surveySlug: number, options?: any): AxiosPromise<FullSurvey> {
            return localVarFp.postAdminSurveysSurveySlugPublish(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys/{survey_slug}/start_now
         * @summary Опубликовать и сразу запустить опрос (draft -> active)
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugStartNow(surveySlug: number, options?: any): AxiosPromise<FullSurvey> {
            return localVarFp.postAdminSurveysSurveySlugStartNow(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/surveys/{survey_slug}/stop_now
         * @summary Срочно остановить активный опрос
         * @param {number} surveySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSurveysSurveySlugStopNow(surveySlug: number, options?: any): AxiosPromise<FullSurvey> {
            return localVarFp.postAdminSurveysSurveySlugStopNow(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/teams/ids/merge
         * @summary Схлопывание team ids
         * @param {GetAdminTeamsIdsMergeRequest} getAdminTeamsIdsMergeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminTeamsIdsMerge(getAdminTeamsIdsMergeRequest: GetAdminTeamsIdsMergeRequest, options?: any): AxiosPromise<OrgstructureShortTeam> {
            return localVarFp.postAdminTeamsIdsMerge(getAdminTeamsIdsMergeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/teams/update_parents
         * @summary Обновление родительских связей в таблице teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminTeamsUpdateParents(options?: any): AxiosPromise<void> {
            return localVarFp.postAdminTeamsUpdateParents(options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/templates
         * @summary Создать шаблон опроса
         * @param {PostAdminTemplatesRequest} postAdminTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminTemplates(postAdminTemplatesRequest: PostAdminTemplatesRequest, options?: any): AxiosPromise<ShortSurveyTemplate> {
            return localVarFp.postAdminTemplates(postAdminTemplatesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/templates/import_tt
         * @summary Импортнуть шаблоны ТТ из файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminTemplatesImportTt(options?: any): AxiosPromise<void> {
            return localVarFp.postAdminTemplatesImportTt(options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/topmind_query/upsert
         * @summary Создает запрос на экспорт данных выбранной модели в TopMind
         * @param {PostAdminTopmindQueryUpsert} adminTopmindQueryUpsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminTopmindQueryUpsert(adminTopmindQueryUpsert: PostAdminTopmindQueryUpsert, options?: any): AxiosPromise<TopmindQuery> {
            return localVarFp.postAdminTopmindQueryUpsert(adminTopmindQueryUpsert, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /admin/user_groups
         * @summary Создать группу пользователей
         * @param {PostAdminUserGroups} adminUserGroups 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminUserGroups(adminUserGroups: PostAdminUserGroups, options?: any): AxiosPromise<UserGroup> {
            return localVarFp.postAdminUserGroups(adminUserGroups, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/admin_area/surveys/{survey_slug}
         * @summary Обновление любых (даже запущенных опросов)
         * @param {number} surveySlug 
         * @param {PutAdminAdminAreaSurveysSurveySlugRequest} putAdminAdminAreaSurveysSurveySlugRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAdminAreaSurveysSurveySlug(surveySlug: number, putAdminAdminAreaSurveysSurveySlugRequest: PutAdminAdminAreaSurveysSurveySlugRequest, options?: any): AxiosPromise<FullSurvey> {
            return localVarFp.putAdminAdminAreaSurveysSurveySlug(surveySlug, putAdminAdminAreaSurveysSurveySlugRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/analytics/surveys/raw_data_reports/:report_id/update_status
         * @summary Меняет статус отчета
         * @param {number} reportId ID отчёта
         * @param {PutAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus} adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus(reportId: number, adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus: PutAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus, options?: any): AxiosPromise<AnalyticsSurveysRawDataReportsSmall> {
            return localVarFp.putAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus(reportId, adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/answers/{id}
         * @summary Обновление ответов
         * @param {number} id 
         * @param {PutAdminAnswersIdRequest} [putAdminAnswersIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminAnswersId(id: number, putAdminAnswersIdRequest?: PutAdminAnswersIdRequest, options?: any): AxiosPromise<Answer> {
            return localVarFp.putAdminAnswersId(id, putAdminAnswersIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/blocks/{id}
         * @summary Обновление блока
         * @param {number} id ID блока
         * @param {PutAdminBlocksIdRequest} [putAdminBlocksIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminBlocksId(id: number, putAdminBlocksIdRequest?: PutAdminBlocksIdRequest, options?: any): AxiosPromise<Block> {
            return localVarFp.putAdminBlocksId(id, putAdminBlocksIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/complex_reports/{id}
         * @summary Обновление комплексного отчета
         * @param {number} id 
         * @param {PutAdminComplexReports} adminComplexReports 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminComplexReportsId(id: number, adminComplexReports: PutAdminComplexReports, options?: any): AxiosPromise<ComplexReport> {
            return localVarFp.putAdminComplexReportsId(id, adminComplexReports, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/email_templates/{id}
         * @summary Обновление шаблона письма
         * @param {string} id Идентификатор шаблона
         * @param {PutAdminEmailTemplates} adminEmailTemplates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminEmailTemplatesId(id: string, adminEmailTemplates: PutAdminEmailTemplates, options?: any): AxiosPromise<EmailTemplate> {
            return localVarFp.putAdminEmailTemplatesId(id, adminEmailTemplates, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/external_surveys/:id
         * @summary Обновление внешнего опроса
         * @param {number} id ID внешнего опроса
         * @param {PutAdminExternalSurveys} adminExternalSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminExternalSurveysId(id: number, adminExternalSurveys: PutAdminExternalSurveys, options?: any): AxiosPromise<void> {
            return localVarFp.putAdminExternalSurveysId(id, adminExternalSurveys, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/factors/{id}
         * @summary Обновление фактора
         * @param {number} id Идентификатор фактора
         * @param {PutAdminFactors} adminFactors 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminFactorsId(id: number, adminFactors: PutAdminFactors, options?: any): AxiosPromise<Factor> {
            return localVarFp.putAdminFactorsId(id, adminFactors, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/hr_practices/{id}
         * @summary Обновление практики
         * @param {string} id Идентификатор практики
         * @param {PutAdminHrPractices} adminHrPractices 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminHrPracticesId(id: string, adminHrPractices: PutAdminHrPractices, options?: any): AxiosPromise<HrPracticeFull> {
            return localVarFp.putAdminHrPracticesId(id, adminHrPractices, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/pages/{id}
         * @summary Обновление страницы в опросе
         * @param {number} id ID страницы для обновления
         * @param {PutAdminPagesIdRequest} [putAdminPagesIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminPagesId(id: number, putAdminPagesIdRequest?: PutAdminPagesIdRequest, options?: any): AxiosPromise<Page> {
            return localVarFp.putAdminPagesId(id, putAdminPagesIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/questions/{id}
         * @summary Обновление вопроса
         * @param {number} id ID вопроса
         * @param {PutAdminQuestionsIdRequest} [putAdminQuestionsIdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminQuestionsId(id: number, putAdminQuestionsIdRequest?: PutAdminQuestionsIdRequest, options?: any): AxiosPromise<Question> {
            return localVarFp.putAdminQuestionsId(id, putAdminQuestionsIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/surveys/{survey_slug}
         * @summary Обновление опроса
         * @param {string} surveySlug Идентификатор опроса
         * @param {PutAdminSurveys} adminSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminSurveysSurveySlug(surveySlug: string, adminSurveys: PutAdminSurveys, options?: any): AxiosPromise<FullSurvey> {
            return localVarFp.putAdminSurveysSurveySlug(surveySlug, adminSurveys, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/surveys/:survey_slug/final_page
         * @summary Создать\\отредактировать страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminSurveysSurveySlugFinalPage(surveySlug: string, options?: any): AxiosPromise<AdminSurveysFinalPages> {
            return localVarFp.putAdminSurveysSurveySlugFinalPage(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/surveys/:survey_slug/orgstructure_pages
         * @summary Обновление настроек страницы оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {PutAdminSurveysSurveySlugOrgstructurePages} adminSurveysSurveySlugOrgstructurePages 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminSurveysSurveySlugOrgstructurePages(surveySlug: string, adminSurveysSurveySlugOrgstructurePages: PutAdminSurveysSurveySlugOrgstructurePages, options?: any): AxiosPromise<AdminSurveysOrgstruturePageSettings> {
            return localVarFp.putAdminSurveysSurveySlugOrgstructurePages(surveySlug, adminSurveysSurveySlugOrgstructurePages, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/surveys/:survey_slug/orgstructure_pages/orgstructure_blocks
         * @summary Обновление блока оргструктуры
         * @param {string} surveySlug Slug опроса
         * @param {PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest} putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocks(surveySlug: string, putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest: PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest, options?: any): AxiosPromise<OrgstructureBlock> {
            return localVarFp.putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocks(surveySlug, putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/top_mind/export
         * @summary Создает запрос на экспорт данных выбранной модели в TopMind
         * @param {PutAdminTopMindExport} adminTopMindExport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminTopMindExport(adminTopMindExport: PutAdminTopMindExport, options?: any): AxiosPromise<void> {
            return localVarFp.putAdminTopMindExport(adminTopMindExport, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/translations/blocks/{id}
         * @summary Обновление одного из переводов блока
         * @param {string} id Идентификатор блока
         * @param {PutAdminTranslationsBlocks} adminTranslationsBlocks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminTranslationsBlocksId(id: string, adminTranslationsBlocks: PutAdminTranslationsBlocks, options?: any): AxiosPromise<Block> {
            return localVarFp.putAdminTranslationsBlocksId(id, adminTranslationsBlocks, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/translations/surveys/{survey_slug}
         * @summary Обновление переводов опроса
         * @param {string} surveySlug Идентификатор опроса
         * @param {PutAdminTranslationsSurveys} adminTranslationsSurveys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminTranslationsSurveysSurveySlug(surveySlug: string, adminTranslationsSurveys: PutAdminTranslationsSurveys, options?: any): AxiosPromise<Survey> {
            return localVarFp.putAdminTranslationsSurveysSurveySlug(surveySlug, adminTranslationsSurveys, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/translations/surveys/:survey_slug/final_page
         * @summary Перевести страницу \"Спасибо\"
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {PutAdminTranslationsSurveysSurveySlugFinalPageRequest} putAdminTranslationsSurveysSurveySlugFinalPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminTranslationsSurveysSurveySlugFinalPage(surveySlug: string, putAdminTranslationsSurveysSurveySlugFinalPageRequest: PutAdminTranslationsSurveysSurveySlugFinalPageRequest, options?: any): AxiosPromise<AdminSurveysFinalPages> {
            return localVarFp.putAdminTranslationsSurveysSurveySlugFinalPage(surveySlug, putAdminTranslationsSurveysSurveySlugFinalPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/translations/surveys/:survey_slug/orgstructure_pages
         * @summary Перевести страницы оргструктуры
         * @param {string} surveySlug Slug-идетнификатор опроса
         * @param {PutAdminTranslationsSurveysSurveySlugOrgstructurePages} adminTranslationsSurveysSurveySlugOrgstructurePages 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminTranslationsSurveysSurveySlugOrgstructurePages(surveySlug: string, adminTranslationsSurveysSurveySlugOrgstructurePages: PutAdminTranslationsSurveysSurveySlugOrgstructurePages, options?: any): AxiosPromise<OrgstructurePage> {
            return localVarFp.putAdminTranslationsSurveysSurveySlugOrgstructurePages(surveySlug, adminTranslationsSurveysSurveySlugOrgstructurePages, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /admin/user_groups/:id
         * @summary Обновить группу пользователей
         * @param {number} id ID группы
         * @param {PutAdminUserGroups} adminUserGroups 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdminUserGroupsId(id: number, adminUserGroups: PutAdminUserGroups, options?: any): AxiosPromise<UserGroup> {
            return localVarFp.putAdminUserGroupsId(id, adminUserGroups, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * DELETE /admin/blocks/{id}
     * @summary Удаление блока
     * @param {number} id ID блока
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminBlocksId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminBlocksId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/email_templates/{id}
     * @summary Удалить шаблон письма
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminEmailTemplatesId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminEmailTemplatesId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/email_templates/{id}/trash_image
     * @summary Удалить картинку шаблона
     * @param {number} id Идентификатор фактора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminEmailTemplatesIdTrashImage(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminEmailTemplatesIdTrashImage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/external_surveys/:id
     * @summary Удалить внешний опрос
     * @param {number} id ID внешнего опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminExternalSurveysId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminExternalSurveysId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/factors/{id}
     * @summary Удалить фактор
     * @param {number} id Идентификатор фактора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminFactorsId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminFactorsId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/hr_practices/{id}
     * @summary Удалить практику
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminHrPracticesId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminHrPracticesId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/hr_practices/{id}/trash_image
     * @summary Удалить картинку практики
     * @param {number} id Идентификатор фактора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminHrPracticesIdTrashImage(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminHrPracticesIdTrashImage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/pages/{id}
     * @summary Удаление страницы в опросе
     * @param {number} id ID страницы для удаления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminPagesId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminPagesId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/questions/{id}
     * @summary Удалить вопрос
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminQuestionsId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminQuestionsId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/questions/{id}/picture_trash
     * @summary Удалить картинку вопроса
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminQuestionsIdPictureTrash(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminQuestionsIdPictureTrash(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/surveys
     * @summary Удалить несколько опросов насовсем
     * @param {Array<string>} surveySlugs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminSurveys(surveySlugs: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminSurveys(surveySlugs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/surveys/{surveys_slug}
     * @summary Удалить опрос насовсем
     * @param {number} surveySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminSurveysSurveySlug(surveySlug: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminSurveysSurveySlug(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/surveys/{surveys_slug}/async
     * @summary Удалить опрос насовсем асинхронно
     * @param {number} surveySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminSurveysSurveySlugAsync(surveySlug: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminSurveysSurveySlugAsync(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/surveys/:survey_slug/fake_user_surveys
     * @summary “Удаление” некачественных анкет из аналитики
     * @param {string} surveySlug Slug опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminSurveysSurveySlugFakeUserSurveys(surveySlug: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminSurveysSurveySlugFakeUserSurveys(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/surveys/:survey_slug/final_blocks/:id
     * @summary Удалить один из блоков страницы \"Спасибо\"
     * @param {string} surveySlug Slug-идетнификатор опроса
     * @param {string} id ID блока для удаления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminSurveysSurveySlugFinalBlocksId(surveySlug: string, id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminSurveysSurveySlugFinalBlocksId(surveySlug, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/surveys/:survey_slug/final_page
     * @summary Удалить страницу \"Спасибо\"
     * @param {string} surveySlug Slug-идетнификатор опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminSurveysSurveySlugFinalPage(surveySlug: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminSurveysSurveySlugFinalPage(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/surveys/{surveys_slug}/image_trash
     * @summary Удалить картинку опроса
     * @param {number} surveySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminSurveysSurveySlugImageTrash(surveySlug: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminSurveysSurveySlugImageTrash(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/surveys/{surveys_slug}/trash
     * @summary Удалить опрос в корзину
     * @param {number} surveySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminSurveysSurveySlugTrash(surveySlug: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminSurveysSurveySlugTrash(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/templates/:id
     * @summary Удалить шаблона опроса компании
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminTemplatesId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminTemplatesId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DELETE /admin/user_groups/:id
     * @summary Обновить группу пользователей
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdminUserGroupsId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteAdminUserGroupsId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/admin_area/surveys
     * @summary Получение списка опросов компании с разбивкой по статусам
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAdminAreaSurveys(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAdminAreaSurveys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/analytics_templates
     * @summary Список шаблонов аналитики
     * @param {Array<string>} [slugs] Идентификаторы дефолтных темплейтов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsAnalyticsTemplates(slugs?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsAnalyticsTemplates(slugs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/company/factor-dynamic
     * @summary Динамика фактора на основании завершенных опросов
     * @param {string} [factorName] Название фактора
     * @param {Array<string>} [factorNames] Массив названий факторов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsCompanyFactorDynamic(factorName?: string, factorNames?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsCompanyFactorDynamic(factorName, factorNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/dashboard
     * @summary Дэшборд по аналитике для активного опроса в рамках компании
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsDashboard(teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsDashboard(teamIds, jobFunctions, jobPositions, jobDurations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/flexible/:slug/respondents/anonymous
     * @summary Проверка анонимности
     * @param {string} slug Идентификатор опроса или комплексного отчета
     * @param {Array<string>} [teamIds] ID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsFlexibleSlugRespondentsAnonymous(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsFlexibleSlugRespondentsAnonymous(slug, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /analytics/flexible/:slug/tabs
     * @summary Список вкладок для гибкой аналитики
     * @param {string} slug ID опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsFlexibleSlugTabs(slug: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsFlexibleSlugTabs(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/questions/{question_id}/answers
     * @summary Получение ответов на открытые вопросы
     * @param {number} questionId ID вопроса
     * @param {string} [theme] Тема
     * @param {string} [sentiment] Сентимент
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 40)
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsQuestionsQuestionIdAnswers(questionId: number, theme?: string, sentiment?: string, offset?: number, limit?: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsQuestionsQuestionIdAnswers(questionId, theme, sentiment, offset, limit, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/questions/{question_id}/clustering
     * @summary Получение кластеризации открытого вопроса
     * @param {number} questionId ID вопроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsQuestionsQuestionIdClustering(questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsQuestionsQuestionIdClustering(questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/questions/{question_id}/comments
     * @summary Получение сентимента открытого вопроса
     * @param {number} questionId ID вопроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsQuestionsQuestionIdComments(questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsQuestionsQuestionIdComments(questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/current
     * @summary Получить текущую активную команду для аналитики
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysCurrent(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysCurrent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/raw_data_reports/:report_id/download
     * @summary Возвращает `.zip` файл со сформированными `.xlsx` отчетами, по одному файлу на каждый опрос
     * @param {number} reportId ID отчёта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysRawDataReportsReportIdDownload(reportId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysRawDataReportsReportIdDownload(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/raw_data_reports/:report_id/status
     * @summary Возвращает информацию о статусе отчета
     * @param {number} reportId ID отчёта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysRawDataReportsReportIdStatus(reportId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysRawDataReportsReportIdStatus(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{report_id}/report_status
     * @summary Возвращает информацию о статусе отчета
     * @param {number} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysReportIdReportStatus(reportId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysReportIdReportStatus(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{slug}
     * @summary Получить опрос по slug
     * @param {string} slug Slug идентификатор опроса или комплексного отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSlug(slug: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * /admin/analytics/surveys/{slug}/burnout
     * @summary Возвращает данные для аналитики опроса выгорания
     * @param {string} slug Slug опроса или комплексного отчета
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSlugBurnout(slug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSlugBurnout(slug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * /admin/analytics/surveys/{survey_slug}/csi
     * @summary Возвращает данные для аналитики опроса сsi
     * @param {string} slug Slug опроса или комплексного отчета
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSlugCsi(slug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSlugCsi(slug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /user_surveys/{slug}/meta-filters
     * @summary Получение списка мета вопросов опроса (продвинутая паспортичка)
     * @param {string} slug Slug идентификатор опроса или комплексного отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSlugMetaFilters(slug: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSlugMetaFilters(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/additional_questions
     * @summary Показывать ответы на вопросы, которых нет в нашем шаблоне
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/comments
     * @summary Возвращает комментарии по вопросу
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {number} questionId ID вопроса
     * @param {Array<string>} teamIds ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugComments(surveySlug: string, questionId: number, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugComments(surveySlug, questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * /admin/analytics/surveys/{survey_slug}/constructor
     * @summary Возвращает данные для аналитики опросов из конструктора
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugConstructor(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugConstructor(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/constructor-open-question-answers
     * @summary Возвращает ответы на открытые вопросы для опроса из конструктора по вопросу
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {number} questionId ID вопроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugConstructorOpenQuestionAnswers(surveySlug: string, questionId: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugConstructorOpenQuestionAnswers(surveySlug, questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/factor-by-team
     * @summary Возвращает значение фактора в разрезе переданных команд
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {string} factorName Название фактора
     * @param {Array<string>} teamIds ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug: string, factorName: string, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug, factorName, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/factor-groups
     * @summary Получение кол-ва групп в разрезе фактора
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {string} factorName Название фактора
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugFactorGroups(surveySlug: string, factorName: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugFactorGroups(surveySlug, factorName, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/factor-questions
     * @summary Возвращает вопросы для фактора и ответы на них
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {string} factorName Название фактора
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {string} [factorGroup] Фильтрация по значению фактора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugFactorQuestions(surveySlug: string, factorName: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugFactorQuestions(surveySlug, factorName, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, factorGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/factors
     * @summary Возвращает значение факторов по определенной команде (командам)
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {string} [factorGroup] Фильтрация по значению фактора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugFactors(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugFactors(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, factorGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/filter-values
     * @summary Получение доступных значений для фильтров
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugFilterValues(surveySlug: string, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugFilterValues(surveySlug, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/open-question-answers
     * @summary Получение ответов на открытый вопрос
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {number} questionId ID вопроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {string} [factorGroup] Фильтрация по значению фактора
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugOpenQuestionAnswers(surveySlug: string, questionId: number, teamIds?: Array<string>, factorGroup?: string, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugOpenQuestionAnswers(surveySlug, questionId, teamIds, factorGroup, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/open-questions
     * @summary Получение открытых вопросов
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {string} [factorName] Название фактора
     * @param {string} [factorGroup] Фильтрация по значению фактора
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugOpenQuestions(surveySlug: string, teamIds?: Array<string>, factorName?: string, factorGroup?: string, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugOpenQuestions(surveySlug, teamIds, factorName, factorGroup, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/other-answers
     * @summary Возвращает варианты \"Другое\" по вопросу
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {number} questionId ID вопроса
     * @param {Array<string>} teamIds ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 10)
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug: string, questionId: number, teamIds: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug, questionId, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/priority_factors
     * @summary Возвращает топ факторов с которыми надо поработать
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugPriorityFactors(surveySlug: string, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugPriorityFactors(surveySlug, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/timings-by-team
     * @summary Возвращает статистику прохождения опроса в разрезе команды (команд)
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugTimingsByTeam(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugTimingsByTeam(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/xlsx-reports/open-questions
     * @summary Возвращает XLSX файл с ответами на открытые вопросы
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {string} [factorGroup] Фильтрация по значению фактора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugXlsxReportsOpenQuestions(surveySlug: string, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, jobFunction?: Array<string>, jobPosition?: Array<string>, jobDuration?: Array<string>, factorGroup?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugXlsxReportsOpenQuestions(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, jobFunction, jobPosition, jobDuration, factorGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/surveys/{survey_slug}/xlsx_reports/{report_id}/download
     * @summary Возвращает XLSX файл с данными отчета
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {number} reportId ID отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsSurveysSurveySlugXlsxReportsReportIdDownload(surveySlug: string, reportId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsSurveysSurveySlugXlsxReportsReportIdDownload(surveySlug, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/analytics/teams/tree
     * @summary Получение дерева команд компании
     * @param {string} [surveySlug] Slug опроса (для разделения прав при просмотре аналитики
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminAnalyticsTeamsTree(surveySlug?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminAnalyticsTeamsTree(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/company-historical-data
     * @summary Получение исторических значений факторов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminCompanyHistoricalData(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminCompanyHistoricalData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/company-historical-data/{survey_slug}
     * @summary Получение исторических значений факторов
     * @param {number} surveySlug 
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {number} [offset] Оффсет
     * @param {number} [limit] Количество записей на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminCompanyHistoricalDataSurveySlug(surveySlug: number, teamIds?: Array<string>, jobFunctions?: Array<string>, jobPositions?: Array<string>, jobDurations?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminCompanyHistoricalDataSurveySlug(surveySlug, teamIds, jobFunctions, jobPositions, jobDurations, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/complex_reports
     * @summary Создание комплексного отчета
     * @param {string} [q] Строка для поиска
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminComplexReports(q?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminComplexReports(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/complex_reports/analytics_kinds
     * @summary Список доступных analytics_kind для комплексного отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminComplexReportsAnalyticsKinds(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminComplexReportsAnalyticsKinds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/complex_reports/{id}
     * @summary Получение информации о комплексном отчете
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminComplexReportsId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminComplexReportsId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/complex_reports/{id}
     * @summary Получение списка опросов компании доступных для добавления в комплексный отчет
     * @param {number} id ID комплексного отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminComplexReportsIdSurveys(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminComplexReportsIdSurveys(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/email_templates/{id}
     * @summary Шаблон письма
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminEmailTemplatesId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminEmailTemplatesId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/express_surveys/analytics/{slug}/factor_business_unit
     * @summary Сравнение фактора по бизнес-юнитам
     * @param {string} slug Slug опроса или комплексного отчета
     * @param {number} level Уровень команды
     * @param {Array<string>} [teamIds] ID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminExpressSurveysAnalyticsSlugFactorBusinessUnit(slug: string, level: number, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminExpressSurveysAnalyticsSlugFactorBusinessUnit(slug, level, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/express_surveys/analytics/{slug}/filter_values
     * @summary Получение данных для блока \"Верхнеуровневый фильтр по командам\"
     * @param {string} slug Slug опроса или комплексного отчета
     * @param {Array<string>} [teamIds] ID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminExpressSurveysAnalyticsSlugFilterValues(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminExpressSurveysAnalyticsSlugFilterValues(slug, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/express_surveys/analytics/{slug}/main_questions
     * @summary Данные для вкладки \"Факторы\" экспресс-опросов
     * @param {string} slug Slug опроса или комплексного отчета
     * @param {Array<string>} [teamIds] UUID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminExpressSurveysAnalyticsSlugMainQuestions(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminExpressSurveysAnalyticsSlugMainQuestions(slug, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/express_surveys/analytics/{slug}/main_results
     * @summary Данные для вкладки \"Основные результаты\" экспресс-опросов
     * @param {string} slug Slug опроса или комплексного отчета
     * @param {Array<string>} [teamIds] UUID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminExpressSurveysAnalyticsSlugMainResults(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminExpressSurveysAnalyticsSlugMainResults(slug, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/express_surveys/analytics/{slug}/main_results/business_units_comparison
     * @summary Данные для вкладки \"Основные результаты\" экспресс-опросов | Сравнение по бизнес-юнитам
     * @param {string} slug Slug опроса или комплексного отчета
     * @param {number} level Выбранный уровень команд для сравнения по бизнес-юнитам
     * @param {Array<string>} [teamIds] UUIDs команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminExpressSurveysAnalyticsSlugMainResultsBusinessUnitsComparison(slug: string, level: number, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminExpressSurveysAnalyticsSlugMainResultsBusinessUnitsComparison(slug, level, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/express_surveys/analytics/{slug}/nps_basic
     * @summary Получение статистики по eNPS/NPS
     * @param {string} slug Slug опроса или комплексного отчета
     * @param {Array<string>} [teamIds] ID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminExpressSurveysAnalyticsSlugNpsBasic(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminExpressSurveysAnalyticsSlugNpsBasic(slug, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/express_surveys/analytics/{slug}/nps_business_unit
     * @summary Сравнение eNPS/NPS факторов по бизнес-юнитам
     * @param {string} slug Slug опроса или комплексного отчета
     * @param {number} level Уровень команды
     * @param {Array<string>} [teamIds] ID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminExpressSurveysAnalyticsSlugNpsBusinessUnit(slug: string, level: number, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminExpressSurveysAnalyticsSlugNpsBusinessUnit(slug, level, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/express_surveys/analytics/{slug}/open_questions/not_nps
     * @summary Получение статистики по открытым вопросам(без NPS)
     * @param {string} slug Slug опроса или комплексного отчета
     * @param {Array<string>} [teamIds] ID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminExpressSurveysAnalyticsSlugOpenQuestionsNotNps(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminExpressSurveysAnalyticsSlugOpenQuestionsNotNps(slug, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/express_surveys/analytics/{slug}/open_questions/nps
     * @summary Получение статистики по открытым вопросам(для NPS)
     * @param {string} slug Slug опроса или комплексного отчета
     * @param {Array<string>} [teamIds] ID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminExpressSurveysAnalyticsSlugOpenQuestionsNps(slug: string, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminExpressSurveysAnalyticsSlugOpenQuestionsNps(slug, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/external_surveys
     * @summary Список внешних опросов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminExternalSurveys(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminExternalSurveys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/external_surveys/:id
     * @summary Получить внешний опрос
     * @param {number} id ID внешнего опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminExternalSurveysId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminExternalSurveysId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/factors
     * @summary Список факторов
     * @param {string} [surveySlug] Slug опроса куда добавляем факторы
     * @param {string} [questionType] Тип вопроса для фильтра
     * @param {number} [maxAnswers] Количество ответов для фильтра
     * @param {boolean} [questionRequired] Обязательность вопроса для фильтра
     * @param {boolean} [onlyFactors] Вернуть только факторы которые не привязаны к индексам
     * @param {Array<string>} [factorsGroupsNames] Названия групп факторов для фильтра
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminFactors(surveySlug?: string, questionType?: string, maxAnswers?: number, questionRequired?: boolean, onlyFactors?: boolean, factorsGroupsNames?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminFactors(surveySlug, questionType, maxAnswers, questionRequired, onlyFactors, factorsGroupsNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/factors_groups
     * @summary Список групп факторов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminFactorsGroups(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminFactorsGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/factors/{id}
     * @summary Получить фактор
     * @param {number} id Идентификатор фактора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminFactorsId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminFactorsId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/features/status
     * @summary Получение состояния feature флагов
     * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminFeaturesStatus(xAuthToken: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminFeaturesStatus(xAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/heatmaps/:id
     * @summary Получить статус тепловой карты
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminHeatmapsId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminHeatmapsId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/hr_practices
     * @summary Список HR практик
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 10)
     * @param {'all' | 'user' | 'tt'} [scope] Скоуп практик
     * @param {Array<string>} [tags] Тэги для поиска
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminHrPractices(offset?: number, limit?: number, scope?: 'all' | 'user' | 'tt', tags?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminHrPractices(offset, limit, scope, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/hr_practices/{id}
     * @summary Список HR практик
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminHrPracticesId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminHrPracticesId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/image_templates
     * @summary Список шаблонов картинок
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminImageTemplates(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminImageTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/indicies
     * @summary Список индексов (групп факторов с пометкой индекс) для добавления в опрос
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminIndicies(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminIndicies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/me
     * @summary Информация о текущем пользователе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminMe(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/meta_info
     * @summary Получить доступную метаинфу о пользователях для компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminMetaInfo(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminMetaInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/pages
     * @summary Список страниц для данного опроса (с возможностью поиска)
     * @param {string} surveySlug Slug опроса
     * @param {string} [q] Шаблон для поиска
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminPages(surveySlug: string, q?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminPages(surveySlug, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/question_templates
     * @summary Список шаблонов вопросов
     * @param {number} [page] Страница
     * @param {number} [perPage] Количество записей на странице
     * @param {string} [tag] Тэг для поиска
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminQuestionTemplates(page?: number, perPage?: number, tag?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminQuestionTemplates(page, perPage, tag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/roles
     * @summary Список ролей и абилок
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminRoles(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/surveys
     * @summary Список опросов с шаблонами для личного кабинета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminSurveys(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminSurveys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/surveys/active/notification-reminders
     * @summary Получение списка отправленных уведомлений в течении 24х часов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminSurveysActiveNotificationReminders(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminSurveysActiveNotificationReminders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/surveys/drafts
     * @summary Список черновиков опросов для личного кабинета
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminSurveysDrafts(offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminSurveysDrafts(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/surveys/{survey_slug}
     * @summary Получить опрос для редактирования
     * @param {string} surveySlug Идентификатор опроса
     * @param {string} [language] Язык опроса (перевода)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminSurveysSurveySlug(surveySlug: string, language?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminSurveysSurveySlug(surveySlug, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/surveys/:survey_slug/final_page
     * @summary Получить страницу \"Спасибо\"
     * @param {string} surveySlug Slug-идетнификатор опроса
     * @param {string} [language] Язык перевода
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminSurveysSurveySlugFinalPage(surveySlug: string, language?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminSurveysSurveySlugFinalPage(surveySlug, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/surveys/:survey_slug/orgstructure_pages
     * @summary Получить настройки оргструктуры
     * @param {string} surveySlug Slug опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminSurveysSurveySlugOrgstructurePages(surveySlug: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminSurveysSurveySlugOrgstructurePages(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/surveys/{surveys_slug}/pptx/{pptx_id}/download
     * @summary Получение pptx опроса
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {number} pptxId ID презентации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminSurveysSurveySlugPptxPptxIdDownload(surveySlug: string, pptxId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminSurveysSurveySlugPptxPptxIdDownload(surveySlug, pptxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/surveys/{surveys_slug}/pptx/{pptx_id}/status
     * @summary Возвращает информацию о статусе презентации
     * @param {number} surveySlug 
     * @param {number} pptxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminSurveysSurveySlugPptxPptxIdStatus(surveySlug: number, pptxId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminSurveysSurveySlugPptxPptxIdStatus(surveySlug, pptxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/surveys/{survey_slug}/root-team
     * @summary Получить корневую команду для опроса
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminSurveysSurveySlugRootTeam(surveySlug: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminSurveysSurveySlugRootTeam(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/surveys/{survey_slug}/timeline
     * @summary Получить таймлайн опросов компании
     * @param {string} surveySlug Идентификатор опроса
     * @param {string} startDate Начальная дата интервала
     * @param {string} endDate Конечная дата интервала
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminSurveysSurveySlugTimeline(surveySlug: string, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminSurveysSurveySlugTimeline(surveySlug, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/surveys/trashed
     * @summary Список опросов в корзине
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminSurveysTrashed(offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminSurveysTrashed(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/tags
     * @summary Получить список тегов с пагинацией, поиск по параметру q (если пустая строка в q, то отдаем все)
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 6)
     * @param {string} [q] Шаблон для поиска
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminTags(offset?: number, limit?: number, q?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminTags(offset, limit, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/teams/
     * @summary Получение команд по совпадению имени (в будущем возможно по дефолту будет отдавать какие-то команды)
     * @param {string} name Совпадение, по которому искать команды
     * @param {string} [surveySlug] Slug опроса для аналитики которого ищем команды
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminTeams(name: string, surveySlug?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminTeams(name, surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/teams/children
     * @summary Получение детей для команд
     * @param {string} [surveySlug] 
     * @param {Array<string>} [teamIds] ID команд
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminTeamsChildren(surveySlug?: string, teamIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminTeamsChildren(surveySlug, teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/teams/{id}
     * @summary Получение данный команды по ID
     * @param {string} id ID команды
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminTeamsId(id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminTeamsId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/teams/{id}/children
     * @summary Получение детей для команды
     * @param {string} id ID команды
     * @param {string} [surveySlug] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminTeamsIdChildren(id: string, surveySlug?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminTeamsIdChildren(id, surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/teams/{id}/parent
     * @summary Получение ID вышестоящей команды
     * @param {string} id ID команды
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminTeamsIdParent(id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminTeamsIdParent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/teams/{id}/parents
     * @summary Получение дерево вышестоящих команд
     * @param {string} id ID команды
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminTeamsIdParents(id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminTeamsIdParents(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/teams/ids/merge
     * @summary Схлопывание team ids
     * @param {Array<string>} teamIds ID команд
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminTeamsIdsMerge(teamIds: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminTeamsIdsMerge(teamIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/teams/tree
     * @summary Получение дерева команд компании
     * @param {string} [surveySlug] Slug опроса (для разделения прав при просмотре аналитики
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminTeamsTree(surveySlug?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminTeamsTree(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/templates
     * @summary Список шаблонов опросов для личного кабинета
     * @param {number} [offset] Оффсет
     * @param {number} [limit] Количество записей на странице
     * @param {'all' | 'user' | 'tt'} [scope] Скоуп шаблонов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminTemplates(offset?: number, limit?: number, scope?: 'all' | 'user' | 'tt', options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminTemplates(offset, limit, scope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/user_groups
     * @summary Список групп пользователей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminUserGroups(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminUserGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /admin/user_groups/:id
     * @summary Полная информация о группе пользователей
     * @param {number} id ID группы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminUserGroupsId(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminUserGroupsId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * /admin/users
     * @summary Список пользователей компании
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 10)
     * @param {string} [q] Шаблон поиска
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminUsers(offset?: number, limit?: number, q?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminUsers(offset, limit, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/analytics/analytics_templates/import
     * @summary Обновить шаблон аналитики
     * @param {string} slug Идентификатор опроса или комплексного отчета
     * @param {number} [templateId] ID импортируемого темплейта
     * @param {boolean} [_default] Является ли шаблон дефолтным
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsAnalyticsTemplatesImport(slug: string, templateId?: number, _default?: boolean, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsAnalyticsTemplatesImport(slug, templateId, _default, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /analytics/flexible/:slug/additional_questions/comparison
     * @summary Сравнительные данные по индексу
     * @param {string} slug Идентификатор опроса или комплексного отчета
     * @param {string} tabSlug Идентификатор таба
     * @param {string} [comparisonKind] Тип сравнения
     * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsFlexibleSlugAdditionalQuestionsComparison(slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsFlexibleSlugAdditionalQuestionsComparison(slug, tabSlug, comparisonKind, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /analytics/flexible/:slug/additional_questions/main
     * @summary Основные данные по дополнительным вопросам
     * @param {string} slug Идентификатор опроса или комплексного отчета
     * @param {string} tabSlug Идентификатор таба
     * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsFlexibleSlugAdditionalQuestionsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsFlexibleSlugAdditionalQuestionsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /analytics/flexible/:slug/main_results/main
     * @summary Основные данные для основных результатов
     * @param {string} slug Идентификатор опроса или комплексного отчета
     * @param {string} tabSlug Идентификатор таба
     * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsFlexibleSlugMainResultsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsFlexibleSlugMainResultsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /analytics/flexible/:slug/single_index/comparison
     * @summary Сравнительные данные по индексу
     * @param {string} slug Идентификатор опроса или комплексного отчета
     * @param {string} tabSlug Идентификатор таба
     * @param {string} [comparisonKind] Тип сравнения
     * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsFlexibleSlugSingleIndexComparison(slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsFlexibleSlugSingleIndexComparison(slug, tabSlug, comparisonKind, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /analytics/flexible/:slug/single_index/main
     * @summary Основные данные по индексу
     * @param {string} slug Идентификатор опроса или комплексного отчета
     * @param {string} tabSlug Идентификатор таба
     * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsFlexibleSlugSingleIndexMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsFlexibleSlugSingleIndexMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /analytics/flexible/:slug/survey_participants/main
     * @summary Основные данные для участников исследования
     * @param {string} slug Идентификатор опроса или комплексного отчета
     * @param {string} tabSlug Идентификатор таба
     * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsFlexibleSlugSurveyParticipantsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsFlexibleSlugSurveyParticipantsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /analytics/flexible/:slug/vertical_factors/comparison
     * @summary Сравнительные данные для вертикальных факторов
     * @param {string} slug Идентификатор опроса или комплексного отчета
     * @param {string} tabSlug Идентификатор таба
     * @param {string} [comparisonKind] Тип сравнения
     * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsFlexibleSlugVerticalFactorsComparison(slug: string, tabSlug: string, comparisonKind?: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsFlexibleSlugVerticalFactorsComparison(slug, tabSlug, comparisonKind, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /analytics/flexible/:slug/vertical_factors/main
     * @summary Основные данные для вертикальных факторов
     * @param {string} slug Идентификатор опроса или комплексного отчета
     * @param {string} tabSlug Идентификатор таба
     * @param {PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest} [postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsFlexibleSlugVerticalFactorsMain(slug: string, tabSlug: string, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest?: PostAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsFlexibleSlugVerticalFactorsMain(slug, tabSlug, postAdminAnalyticsFlexibleSlugSingleIndexComparisonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/analytics/surveys/raw_data_reports/export
     * @summary Создает запрос на выгрузку .zip архива с отчётами
     * @param {PostAdminAnalyticsSurveysRawDataReportsExportRequest} postAdminAnalyticsSurveysRawDataReportsExportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsSurveysRawDataReportsExport(postAdminAnalyticsSurveysRawDataReportsExportRequest: PostAdminAnalyticsSurveysRawDataReportsExportRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsSurveysRawDataReportsExport(postAdminAnalyticsSurveysRawDataReportsExportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/analytics/surveys/{survey_slug}/additional_questions
     * @summary Показывать ответы на вопросы, которых нет в нашем шаблоне
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest} [postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug: string, postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest?: PostAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsSurveysSurveySlugAdditionalQuestions(surveySlug, postAdminAnalyticsSurveysSurveySlugAdditionalQuestionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/analytics/surveys/{survey_slug}/comments
     * @summary Возвращает комментарии по вопросу
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {number} questionId ID вопроса
     * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsSurveysSurveySlugComments(surveySlug: string, questionId: number, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsSurveysSurveySlugComments(surveySlug, questionId, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/analytics/surveys/{survey_slug}/factor-by-team
     * @summary Возвращает значение фактора в разрезе переданных команд
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {string} factorName Название фактора
     * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug: string, factorName: string, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsSurveysSurveySlugFactorByTeam(surveySlug, factorName, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/analytics/surveys/{survey_slug}/other-answers
     * @summary Возвращает варианты \"Другое\" по вопросу
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {number} questionId ID вопроса
     * @param {PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest} postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest 
     * @param {number} [offset] Offset (по умолчанию 0)
     * @param {number} [limit] Limit (по умолчанию 10)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug: string, questionId: number, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest: PostAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsSurveysSurveySlugOtherAnswers(surveySlug, questionId, postAdminAnalyticsSurveysSurveySlugOtherAnswersRequest, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/analytics/surveys/{survey_slug}/xlsx-reports-export
     * @summary Создает запрос на выгрузку xlsx файла
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {boolean} withChildren Вместе с дочерними подразделениями
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {Array<string>} [jobPosition] Рабочие должности
     * @param {Array<string>} [jobFunction] Рабочие функции
     * @param {Array<string>} [jobDuration] Сроки работы
     * @param {Array<'index' | 'structure' | 'benchmarking' | 'answer_distribution'>} [engagement] Что показывать в вовлеченности
     * @param {Array<'index' | 'benchmarking' | 'answer_distribution'>} [satisfaction] Что показывать в удовлетворенности
     * @param {Array<'index' | 'groups'>} [enpsCompany] Что показывать в eNPS
     * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [baseFactors] Что показывать в базовых факторах
     * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [enchancingFactors] Что показывать в усиливающих факторах
     * @param {Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>} [employeeExperience] Что показывать в индексе опыта сотрудника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminAnalyticsSurveysSurveySlugXlsxReportsExport(surveySlug: string, withChildren: boolean, teamIds?: Array<string>, jobPositions?: Array<string>, jobFunctions?: Array<string>, jobDurations?: Array<string>, jobPosition?: Array<string>, jobFunction?: Array<string>, jobDuration?: Array<string>, engagement?: Array<'index' | 'structure' | 'benchmarking' | 'answer_distribution'>, satisfaction?: Array<'index' | 'benchmarking' | 'answer_distribution'>, enpsCompany?: Array<'index' | 'groups'>, baseFactors?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, enchancingFactors?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, employeeExperience?: Array<'values' | 'uninvolved' | 'involved' | 'highly_involved' | 'critic' | 'neutral' | 'supporter'>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminAnalyticsSurveysSurveySlugXlsxReportsExport(surveySlug, withChildren, teamIds, jobPositions, jobFunctions, jobDurations, jobPosition, jobFunction, jobDuration, engagement, satisfaction, enpsCompany, baseFactors, enchancingFactors, employeeExperience, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/blocks
     * @summary Создание блока в странице опроса
     * @param {PostAdminBlocksRequest} postAdminBlocksRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminBlocks(postAdminBlocksRequest: PostAdminBlocksRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminBlocks(postAdminBlocksRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/blocks/{id}/copy
     * @summary Копирование блока
     * @param {number} id ID блока для копирования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminBlocksIdCopy(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminBlocksIdCopy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/branching_settings
     * @summary Обновить настройки ветвления для вопроса
     * @param {PostAdminBranchingSettingsRequest} postAdminBranchingSettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminBranchingSettings(postAdminBranchingSettingsRequest: PostAdminBranchingSettingsRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminBranchingSettings(postAdminBranchingSettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/companies/import
     * @summary Импорт компании из оргструктуры
     * @param {PostAdminCompaniesImportRequest} postAdminCompaniesImportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminCompaniesImport(postAdminCompaniesImportRequest: PostAdminCompaniesImportRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminCompaniesImport(postAdminCompaniesImportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/complex_reports
     * @summary Создание комплексного отчета
     * @param {PostAdminComplexReports} adminComplexReports 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminComplexReports(adminComplexReports: PostAdminComplexReports, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminComplexReports(adminComplexReports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/email_templates
     * @summary Создание шаблона письма
     * @param {PostAdminEmailTemplatesRequest} postAdminEmailTemplatesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminEmailTemplates(postAdminEmailTemplatesRequest: PostAdminEmailTemplatesRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminEmailTemplates(postAdminEmailTemplatesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/email_templates/{id}/test_email_message
     * @summary Отправить тестовое письмо
     * @param {string} id Идентификатор шаблона
     * @param {PostAdminEmailTemplatesIdTestEmailMessage} adminEmailTemplatesIdTestEmailMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminEmailTemplatesIdTestEmailMessage(id: string, adminEmailTemplatesIdTestEmailMessage: PostAdminEmailTemplatesIdTestEmailMessage, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminEmailTemplatesIdTestEmailMessage(id, adminEmailTemplatesIdTestEmailMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/external_surveys
     * @summary Создать внешний опрос
     * @param {PostAdminExternalSurveys} adminExternalSurveys 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminExternalSurveys(adminExternalSurveys: PostAdminExternalSurveys, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminExternalSurveys(adminExternalSurveys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/factors
     * @summary Создать фактор
     * @param {PostAdminFactors} adminFactors 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminFactors(adminFactors: PostAdminFactors, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminFactors(adminFactors, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/factors_groups/:id/add_factors
     * @summary Список групп факторов
     * @param {number} id 
     * @param {PostAdminFactorsGroupsIdAddFactorsRequest} postAdminFactorsGroupsIdAddFactorsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminFactorsGroupsIdAddFactors(id: number, postAdminFactorsGroupsIdAddFactorsRequest: PostAdminFactorsGroupsIdAddFactorsRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminFactorsGroupsIdAddFactors(id, postAdminFactorsGroupsIdAddFactorsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/factors/import
     * @summary Обновление фактора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminFactorsImport(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminFactorsImport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/features/disable
     * @summary Выключение фичи
     * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
     * @param {string} featureName Название фичи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminFeaturesDisable(xAuthToken: string, featureName: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminFeaturesDisable(xAuthToken, featureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/features/enable
     * @summary Включение фичи
     * @param {string} xAuthToken JWT токен для авторизации администратора feature флагов
     * @param {string} featureName Название фичи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminFeaturesEnable(xAuthToken: string, featureName: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminFeaturesEnable(xAuthToken, featureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/heatmaps
     * @summary Создать тепловую карту
     * @param {PostAdminHeatmapsRequest} postAdminHeatmapsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminHeatmaps(postAdminHeatmapsRequest: PostAdminHeatmapsRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminHeatmaps(postAdminHeatmapsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/hr_practices
     * @summary Создать практику
     * @param {PostAdminHrPracticesRequest} postAdminHrPracticesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminHrPractices(postAdminHrPracticesRequest: PostAdminHrPracticesRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminHrPractices(postAdminHrPracticesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/hr_practices/{id}/set_invisible
     * @summary Сделать практику невидимой для компании
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminHrPracticesIdSetInvisible(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminHrPracticesIdSetInvisible(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/hr_practices/{id}/set_visible
     * @summary Сделать практику видимой для компании
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminHrPracticesIdSetVisible(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminHrPracticesIdSetVisible(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/hr_practices/import
     * @summary Импортировать HR практики ТТ из файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminHrPracticesImport(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminHrPracticesImport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/image_templates
     * @summary Добавление картинка в базу изображений
     * @param {PostAdminImageTemplatesRequest} postAdminImageTemplatesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminImageTemplates(postAdminImageTemplatesRequest: PostAdminImageTemplatesRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminImageTemplates(postAdminImageTemplatesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/pages
     * @summary Создание страницы в опросе
     * @param {PostAdminPagesRequest} postAdminPagesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminPages(postAdminPagesRequest: PostAdminPagesRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminPages(postAdminPagesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/pages/{id}/duplicate
     * @summary Дублирование страницы
     * @param {number} id ID страницы для дублирования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminPagesIdDuplicate(id: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminPagesIdDuplicate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/question_templates/import
     * @summary Импортировать шаблоны вопросов ТТ из файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminQuestionTemplatesImport(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminQuestionTemplatesImport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/questions
     * @summary Создание вопроса
     * @param {PostAdminQuestionsRequest} [postAdminQuestionsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminQuestions(postAdminQuestionsRequest?: PostAdminQuestionsRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminQuestions(postAdminQuestionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/roles/import
     * @summary Импорт ролей и абилок из файлов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminRolesImport(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminRolesImport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/sudo_actions/add_to_sudo_admins
     * @summary Сделать пользователя системным администратором
     * @param {string} xAuthToken JWT токен для авторизации супер администратора
     * @param {PostAdminSudoActionsAddToSudoAdmins} adminSudoActionsAddToSudoAdmins 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSudoActionsAddToSudoAdmins(xAuthToken: string, adminSudoActionsAddToSudoAdmins: PostAdminSudoActionsAddToSudoAdmins, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSudoActionsAddToSudoAdmins(xAuthToken, adminSudoActionsAddToSudoAdmins, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/survey_meta_questions
     * @summary Задать настройки вопросов паспортички для опроса
     * @param {PostAdminSurveyMetaQuestions} adminSurveyMetaQuestions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveyMetaQuestions(adminSurveyMetaQuestions: PostAdminSurveyMetaQuestions, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveyMetaQuestions(adminSurveyMetaQuestions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/survey_requests
     * @summary Запросить опрос
     * @param {PostAdminSurveyRequestsRequest} postAdminSurveyRequestsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveyRequests(postAdminSurveyRequestsRequest: PostAdminSurveyRequestsRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveyRequests(postAdminSurveyRequestsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys
     * @summary Создание опроса
     * @param {PostAdminSurveysRequest} postAdminSurveysRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveys(postAdminSurveysRequest: PostAdminSurveysRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveys(postAdminSurveysRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys/active/notification-reminders
     * @summary Нотификация конкретной команды о том, что нужно пройти опрос
     * @param {PostAdminSurveysActiveNotificationRemindersRequest} [postAdminSurveysActiveNotificationRemindersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveysActiveNotificationReminders(postAdminSurveysActiveNotificationRemindersRequest?: PostAdminSurveysActiveNotificationRemindersRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveysActiveNotificationReminders(postAdminSurveysActiveNotificationRemindersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys/back_to_draft
     * @summary Вернуть несколько опросов из trashed в черновик
     * @param {PostAdminSurveysBackToDraftRequest} postAdminSurveysBackToDraftRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveysBackToDraft(postAdminSurveysBackToDraftRequest: PostAdminSurveysBackToDraftRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveysBackToDraft(postAdminSurveysBackToDraftRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys/request
     * @summary Запросить опрос
     * @param {string} message Сообщение для создания опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveysRequest(message: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveysRequest(message, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys/{survey_slug}/back_to_draft
     * @summary Вернуть опрос (published/trashed -> draft) в статус черновик
     * @param {number} surveySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveysSurveySlugBackToDraft(surveySlug: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveysSurveySlugBackToDraft(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys/{survey_slug}/duplicate
     * @summary Дублировать опрос
     * @param {number} surveySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveysSurveySlugDuplicate(surveySlug: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveysSurveySlugDuplicate(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys/:survey_slug/fake_user_surveys
     * @summary “Восстановление” некачественных анкет из аналитики
     * @param {string} surveySlug Slug опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveysSurveySlugFakeUserSurveys(surveySlug: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveysSurveySlugFakeUserSurveys(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys/:survey_slug/orgstructure_pages
     * @summary Создание настроек страницы оргструктуры
     * @param {string} surveySlug Slug опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveysSurveySlugOrgstructurePages(surveySlug: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveysSurveySlugOrgstructurePages(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys/{surveys_slug}/pptx/pptx-export
     * @summary Создает запрос на выгрузку pptx файла
     * @param {string} surveySlug Slug идентификатор опроса
     * @param {Array<string>} [teamIds] ID команд
     * @param {Array<string>} [jobPositions] Рабочие должности
     * @param {Array<string>} [jobFunctions] Рабочие функции
     * @param {Array<string>} [jobDurations] Сроки работы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveysSurveySlugPptxPptxExport(surveySlug: string, teamIds?: Array<string>, jobPositions?: Array<string>, jobFunctions?: Array<string>, jobDurations?: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveysSurveySlugPptxPptxExport(surveySlug, teamIds, jobPositions, jobFunctions, jobDurations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys/{survey_slug}/publish
     * @summary Опубликовать опрос(draft -> published)
     * @param {number} surveySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveysSurveySlugPublish(surveySlug: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveysSurveySlugPublish(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys/{survey_slug}/start_now
     * @summary Опубликовать и сразу запустить опрос (draft -> active)
     * @param {number} surveySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveysSurveySlugStartNow(surveySlug: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveysSurveySlugStartNow(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/surveys/{survey_slug}/stop_now
     * @summary Срочно остановить активный опрос
     * @param {number} surveySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminSurveysSurveySlugStopNow(surveySlug: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminSurveysSurveySlugStopNow(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/teams/ids/merge
     * @summary Схлопывание team ids
     * @param {GetAdminTeamsIdsMergeRequest} getAdminTeamsIdsMergeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminTeamsIdsMerge(getAdminTeamsIdsMergeRequest: GetAdminTeamsIdsMergeRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminTeamsIdsMerge(getAdminTeamsIdsMergeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/teams/update_parents
     * @summary Обновление родительских связей в таблице teams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminTeamsUpdateParents(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminTeamsUpdateParents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/templates
     * @summary Создать шаблон опроса
     * @param {PostAdminTemplatesRequest} postAdminTemplatesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminTemplates(postAdminTemplatesRequest: PostAdminTemplatesRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminTemplates(postAdminTemplatesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/templates/import_tt
     * @summary Импортнуть шаблоны ТТ из файлов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminTemplatesImportTt(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminTemplatesImportTt(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/topmind_query/upsert
     * @summary Создает запрос на экспорт данных выбранной модели в TopMind
     * @param {PostAdminTopmindQueryUpsert} adminTopmindQueryUpsert 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminTopmindQueryUpsert(adminTopmindQueryUpsert: PostAdminTopmindQueryUpsert, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminTopmindQueryUpsert(adminTopmindQueryUpsert, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /admin/user_groups
     * @summary Создать группу пользователей
     * @param {PostAdminUserGroups} adminUserGroups 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public postAdminUserGroups(adminUserGroups: PostAdminUserGroups, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).postAdminUserGroups(adminUserGroups, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/admin_area/surveys/{survey_slug}
     * @summary Обновление любых (даже запущенных опросов)
     * @param {number} surveySlug 
     * @param {PutAdminAdminAreaSurveysSurveySlugRequest} putAdminAdminAreaSurveysSurveySlugRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminAdminAreaSurveysSurveySlug(surveySlug: number, putAdminAdminAreaSurveysSurveySlugRequest: PutAdminAdminAreaSurveysSurveySlugRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminAdminAreaSurveysSurveySlug(surveySlug, putAdminAdminAreaSurveysSurveySlugRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/analytics/surveys/raw_data_reports/:report_id/update_status
     * @summary Меняет статус отчета
     * @param {number} reportId ID отчёта
     * @param {PutAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus} adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus(reportId: number, adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus: PutAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminAnalyticsSurveysRawDataReportsReportIdUpdateStatus(reportId, adminAnalyticsSurveysRawDataReportsReportIdUpdateStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/answers/{id}
     * @summary Обновление ответов
     * @param {number} id 
     * @param {PutAdminAnswersIdRequest} [putAdminAnswersIdRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminAnswersId(id: number, putAdminAnswersIdRequest?: PutAdminAnswersIdRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminAnswersId(id, putAdminAnswersIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/blocks/{id}
     * @summary Обновление блока
     * @param {number} id ID блока
     * @param {PutAdminBlocksIdRequest} [putAdminBlocksIdRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminBlocksId(id: number, putAdminBlocksIdRequest?: PutAdminBlocksIdRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminBlocksId(id, putAdminBlocksIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/complex_reports/{id}
     * @summary Обновление комплексного отчета
     * @param {number} id 
     * @param {PutAdminComplexReports} adminComplexReports 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminComplexReportsId(id: number, adminComplexReports: PutAdminComplexReports, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminComplexReportsId(id, adminComplexReports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/email_templates/{id}
     * @summary Обновление шаблона письма
     * @param {string} id Идентификатор шаблона
     * @param {PutAdminEmailTemplates} adminEmailTemplates 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminEmailTemplatesId(id: string, adminEmailTemplates: PutAdminEmailTemplates, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminEmailTemplatesId(id, adminEmailTemplates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/external_surveys/:id
     * @summary Обновление внешнего опроса
     * @param {number} id ID внешнего опроса
     * @param {PutAdminExternalSurveys} adminExternalSurveys 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminExternalSurveysId(id: number, adminExternalSurveys: PutAdminExternalSurveys, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminExternalSurveysId(id, adminExternalSurveys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/factors/{id}
     * @summary Обновление фактора
     * @param {number} id Идентификатор фактора
     * @param {PutAdminFactors} adminFactors 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminFactorsId(id: number, adminFactors: PutAdminFactors, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminFactorsId(id, adminFactors, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/hr_practices/{id}
     * @summary Обновление практики
     * @param {string} id Идентификатор практики
     * @param {PutAdminHrPractices} adminHrPractices 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminHrPracticesId(id: string, adminHrPractices: PutAdminHrPractices, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminHrPracticesId(id, adminHrPractices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/pages/{id}
     * @summary Обновление страницы в опросе
     * @param {number} id ID страницы для обновления
     * @param {PutAdminPagesIdRequest} [putAdminPagesIdRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminPagesId(id: number, putAdminPagesIdRequest?: PutAdminPagesIdRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminPagesId(id, putAdminPagesIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/questions/{id}
     * @summary Обновление вопроса
     * @param {number} id ID вопроса
     * @param {PutAdminQuestionsIdRequest} [putAdminQuestionsIdRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminQuestionsId(id: number, putAdminQuestionsIdRequest?: PutAdminQuestionsIdRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminQuestionsId(id, putAdminQuestionsIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/surveys/{survey_slug}
     * @summary Обновление опроса
     * @param {string} surveySlug Идентификатор опроса
     * @param {PutAdminSurveys} adminSurveys 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminSurveysSurveySlug(surveySlug: string, adminSurveys: PutAdminSurveys, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminSurveysSurveySlug(surveySlug, adminSurveys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/surveys/:survey_slug/final_page
     * @summary Создать\\отредактировать страницу \"Спасибо\"
     * @param {string} surveySlug Slug-идетнификатор опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminSurveysSurveySlugFinalPage(surveySlug: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminSurveysSurveySlugFinalPage(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/surveys/:survey_slug/orgstructure_pages
     * @summary Обновление настроек страницы оргструктуры
     * @param {string} surveySlug Slug опроса
     * @param {PutAdminSurveysSurveySlugOrgstructurePages} adminSurveysSurveySlugOrgstructurePages 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminSurveysSurveySlugOrgstructurePages(surveySlug: string, adminSurveysSurveySlugOrgstructurePages: PutAdminSurveysSurveySlugOrgstructurePages, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminSurveysSurveySlugOrgstructurePages(surveySlug, adminSurveysSurveySlugOrgstructurePages, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/surveys/:survey_slug/orgstructure_pages/orgstructure_blocks
     * @summary Обновление блока оргструктуры
     * @param {string} surveySlug Slug опроса
     * @param {PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest} putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocks(surveySlug: string, putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest: PutAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocks(surveySlug, putAdminSurveysSurveySlugOrgstructurePagesOrgstructureBlocksRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/top_mind/export
     * @summary Создает запрос на экспорт данных выбранной модели в TopMind
     * @param {PutAdminTopMindExport} adminTopMindExport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminTopMindExport(adminTopMindExport: PutAdminTopMindExport, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminTopMindExport(adminTopMindExport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/translations/blocks/{id}
     * @summary Обновление одного из переводов блока
     * @param {string} id Идентификатор блока
     * @param {PutAdminTranslationsBlocks} adminTranslationsBlocks 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminTranslationsBlocksId(id: string, adminTranslationsBlocks: PutAdminTranslationsBlocks, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminTranslationsBlocksId(id, adminTranslationsBlocks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/translations/surveys/{survey_slug}
     * @summary Обновление переводов опроса
     * @param {string} surveySlug Идентификатор опроса
     * @param {PutAdminTranslationsSurveys} adminTranslationsSurveys 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminTranslationsSurveysSurveySlug(surveySlug: string, adminTranslationsSurveys: PutAdminTranslationsSurveys, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminTranslationsSurveysSurveySlug(surveySlug, adminTranslationsSurveys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/translations/surveys/:survey_slug/final_page
     * @summary Перевести страницу \"Спасибо\"
     * @param {string} surveySlug Slug-идетнификатор опроса
     * @param {PutAdminTranslationsSurveysSurveySlugFinalPageRequest} putAdminTranslationsSurveysSurveySlugFinalPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminTranslationsSurveysSurveySlugFinalPage(surveySlug: string, putAdminTranslationsSurveysSurveySlugFinalPageRequest: PutAdminTranslationsSurveysSurveySlugFinalPageRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminTranslationsSurveysSurveySlugFinalPage(surveySlug, putAdminTranslationsSurveysSurveySlugFinalPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/translations/surveys/:survey_slug/orgstructure_pages
     * @summary Перевести страницы оргструктуры
     * @param {string} surveySlug Slug-идетнификатор опроса
     * @param {PutAdminTranslationsSurveysSurveySlugOrgstructurePages} adminTranslationsSurveysSurveySlugOrgstructurePages 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminTranslationsSurveysSurveySlugOrgstructurePages(surveySlug: string, adminTranslationsSurveysSurveySlugOrgstructurePages: PutAdminTranslationsSurveysSurveySlugOrgstructurePages, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminTranslationsSurveysSurveySlugOrgstructurePages(surveySlug, adminTranslationsSurveysSurveySlugOrgstructurePages, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /admin/user_groups/:id
     * @summary Обновить группу пользователей
     * @param {number} id ID группы
     * @param {PutAdminUserGroups} adminUserGroups 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public putAdminUserGroupsId(id: number, adminUserGroups: PutAdminUserGroups, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).putAdminUserGroupsId(id, adminUserGroups, options).then((request) => request(this.axios, this.basePath));
    }
}


