// eslint-disable-next-line import/extensions,import/no-unresolved
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';
import VuetifyColors from 'vuetify/lib/util/colors';
import TTColors from './tt.colors';

const theme: VuetifyThemeVariant = {
  // TODO: удалить VuetifyColors
  ...(VuetifyColors as unknown as VuetifyThemeVariant),
  ...TTColors,

  primary: TTColors['tt-dark-blue'],
  secondary: TTColors['tt-light-mono-100'],
  alt: TTColors['tt-light-mono-8'],
  accent: TTColors['tt-dark-blue'],
  error: TTColors['tt-dark-red'],
  warning: TTColors['tt-dark-yellow'],
  info: TTColors['tt-dark-blue'],
  success: TTColors['tt-dark-green'],
  // NOTE: не поддерживает ParsedThemeItem, только String :"(
  anchor: TTColors['tt-dark-blue'].base,

  white: TTColors['tt-light-mono-0'],
};

export default theme;
