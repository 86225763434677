import {
  EntitiesProfileShow,
  CompanyShow,
  EntitiesProfileChiefs,
  EntitiesProfileTeamsWithPeople,
} from '@/services/api/profile';

export interface ProfileState {
  companies: CompanyShow[];
  company: CompanyShow | null;
  profile: EntitiesProfileShow | null;
  licenses: string[];
  chiefs: EntitiesProfileChiefs[];
  teams: EntitiesProfileTeamsWithPeople[];
}

export default (): ProfileState => ({
  profile: null,
  company: null,
  companies: [],
  licenses: [],
  chiefs: [],
  teams: [],
});
