function getDomainFromURL(location: Location) {
  return new URL(location.toString()).hostname.split('.').slice(-2).join('.');
}

function getDomainForCookies(location = window.location) {
  /**
   * Note: Получаем текущий домен.
   * Устанавливаем куки всегда на текущий домен и для всех сабдоменов.
   * Точку добавляем, чтобы куки распространялись на все сабдомены
   * Точку не добавляем для localhost
   * */
  let domain = `.${getDomainFromURL(location)}`;
  domain = domain.startsWith('.localhost') ? domain.slice(1) : domain;
  return domain;
}

export { getDomainFromURL, getDomainForCookies };
