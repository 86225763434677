import { Store } from 'vuex';

import { TOGGLE_SNACK, TOGGLE_LOADING, TOGGLE_NAV_DRAWER } from './mutationTypes';
import modules from './modules';
import plugins from './plugins';

import { RootState } from './types';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

function createStore() {
  return new Store<RootState>({
    strict: !IS_PRODUCTION,
    plugins,
    modules,

    // TODO: refactor use modules
    state: {
      loading: false,
      navDrawer: false,
      snackbar: {
        value: false,
        message: '',
        color: '',
        timeout: 3000,
      },
    },

    actions: {},

    mutations: {
      [TOGGLE_LOADING](state, payload) {
        state.loading = payload;
      },

      [TOGGLE_NAV_DRAWER](state, payload) {
        state.navDrawer = payload;
      },

      [TOGGLE_SNACK](state, payload) {
        const { snackbar } = state;
        snackbar.value = !snackbar.value;
        snackbar.message = payload.message || '';
        snackbar.color = payload.color || '';
      },
    },

    getters: {},
  });
}

export default createStore;
