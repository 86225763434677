import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

if (['production'].includes(process.env.NODE_ENV) && (process.env.VUE_APP_T8H_ENABLE?.toLowerCase() === 'true')) {
  Sentry.init({
    dsn: 'https://c707cbc56e158e4aff58685c1085daa9@err.t8h.io/17',
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
        logErrors: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1,
  });
}
