export enum SurveySettingNames {
  POSSIBLE_EXPERTS_STAGE = 'possible_experts_stage',
  LIMIT_CUSTOM_SCHEDULE = 'possible_custom_schedule',
  INITIALIZE_ONCE_STAGE = 'initialize_once_stage',
  INITIALIZE_TWICE_STAGE = 'initialize_twice_stage',
  LIMIT_SURVEYEES_ON_STAGE = 'limit_surveyees_on_stage',
  USE_FAVORITE = 'use_favorite',
  POSSIBLE_EXPERTS_STAGE_MANAGEMENT = 'possible_experts_stage_management',
  POSSIBLE_CHOOSE_EXPERTS = 'possible_choose_experts',
  POSSIBLE_CUSTOM_SCHEDULE = 'possible_custom_schedule',
}

export enum SurveySettingValueType {
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
}
