
import Vue from 'vue';

export default Vue.extend({
  name: 'TTChip',
  model: {
    event: 'change',
  },
  props: {
    dataTest: {
      type: String,
      default: 'tt-chip',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },

    counter: {
      type: [Number, String],
      default: undefined,
    },
    ripple: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
  },
});
