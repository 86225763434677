import state, { EngagementInfoState } from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export const getStoreConfig = ({ initialState = {} }: { initialState?: Partial<EngagementInfoState> } = {}) => ({
  namespaced: true,
  state: { ...state(), ...initialState },
  mutations,
  getters,
  actions,
});

export default getStoreConfig();
