// eslint-disable-next-line import/extensions,import/no-unresolved
import { IconsOptions } from 'vuetify/types/services/icons';

const iconOptions: IconsOptions = {
  iconfont: 'fa',
  values: {
    complete: 'fal fa-check',
    cancel: 'fal fa-times-circle',
    close: 'fal fa-times',
    delete: 'fal fa-times-circle',
    clear: 'fal fa-times-circle',
    success: 'fal fa-check-circle',
    successAlert: 'fa fa-check-circle',
    info: 'fal fa-info-circle',
    infoAlert: 'fa fa-info-circle',
    warning: 'fal fa-exclamation',
    warningAlert: 'fa fa-exclamation-circle',
    error: 'fal fa-exclamation-triangle',
    errorAlert: 'fa fa-exclamation-circle',
    prev: 'fal fa-chevron-left',
    arrowLeft: 'fal fa-arrow-left',
    questionCircle: 'fal fa-question-circle',
    arrowCircleDown: 'fas fa-arrow-circle-down',
    arrowCircleUp: 'fas fa-arrow-circle-up',
    next: 'fal fa-chevron-right',
    checkboxOn: 'fa fa-check-square',
    checkboxOff: 'fal fa-square',
    checkboxIndeterminate: 'fas fa-minus-square',
    delimiter: 'fal fa-circle',
    sort: 'fal fa-sort-amount-up',
    search: 'fal fa-search',
    expand: 'fal fa-chevron-down',
    menu: 'fal fa-bars',
    subgroup: 'fal fa-caret-down',
    dropdown: 'fal fa-angle-down',
    ttRadioOn: 'fas fa-circle',
    radioOn: 'far fa-dot-circle',
    radioOff: 'far fa-circle',
    edit: 'fal fa-edit',
    ratingEmpty: 'far fa-star',
    ratingFull: 'fal fa-star',
    ratingHalf: 'fal fa-star-half',
    loading: 'fal fa-sync',
    first: 'fal fa-step-backward',
    last: 'fal fa-step-forward',
    unfold: 'fal fa-arrows-alt-v',
    file: 'fal fa-paperclip',
    plus: 'fal fa-plus',
    minus: 'fal fa-minus',
    signOut: 'fal fa-sign-out',
    columns: 'fal fa-columns',
    analytics: 'fal fa-analytics',
    chartPie: 'fal fa-chart-pie',
    filledCircle: 'fas fa-circle',
    clock: 'fal fa-clock',
    bell: 'fal fa-bell',
    check: 'fas fa-check',
    frown: 'fas fa-frown',
    smile: 'fas fa-smile',
    laugh: 'fas fa-laugh',
    angleLeft: 'fal fa-angle-left',
    angleRight: 'fal fa-angle-right',
    fire: 'far fa-fire',
    fireAlt: 'fas fa-fire-alt',
    gem: 'fas fa-gem',
    mediumM: 'fab fa-medium-m',
    square: 'fas fa-square',
    fileExcel: 'fal fa-file-excel',
    infoCircleSolid: 'fas fa-info-circle',
    userTie: 'far fa-user-tie',
    userTieLight: 'fal fa-user-tie',
    signal: 'far fa-signal',
    award: 'far fa-award',
    rocketLaunch: 'far fa-rocket',
    commentAltCheck: 'far fa-comment-alt-check',
    magic: 'fal fa-magic',
    route: 'fal fa-route',
    usersClass: 'fal fa-users-class',
    userFriends: 'fal fa-user-friends',
    thumbsUp: 'fal fa-thumbs-up',
    handshake: 'fal fa-handshake',
    projectDiagram: 'fal fa-project-diagram',
    users: 'far fa-users',
    usersLight: 'fal fa-users',
    building: 'fal fa-building',
    briefcase: 'far fa-briefcase',
    briefcaseLight: 'fal fa-briefcase',
    bookReader: 'far fa-book-reader',
    heart: 'far fa-heart',
    piggyBank: 'far fa-piggy-bank',
    wallet: 'far fa-wallet',
    leaf: 'far fa-leaf',
    hospital: 'far fa-hospital',
    userShield: 'far fa-user-shield',
    desktop: 'far fa-desktop',
    star: 'far fa-star',
    starLight: 'fal fa-star',
    starHalfLight: 'fal fa-star-half',
    exclamationCircle: 'fas fa-exclamation-circle',
    checkCircle: 'fas fa-check-circle',
    calendarAlt: 'fal fa-calendar-alt',
    lock: 'fas fa-lock-alt',
    angleUp: 'fal fa-angle-up',
    externalLink: 'fas fa-external-link-alt',
    searchMinus: 'fal fa-search-minus',
    searchPlus: 'fal fa-search-plus',
  },
};

export default iconOptions;
