/* tslint:disable */
/* eslint-disable */
/**
 * Public endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Auth_ConfirmTotp model
 * @export
 * @interface AuthConfirmTotp
 */
 export interface AuthConfirmTotp {
    /**
     * Jwt токен для аутентификации
     * @type {string}
     * @memberof AuthConfirmTotp
     */
    jwt?: string;
}
/**
 * Auth_Show model
 * @export
 * @interface AuthShow
 */
export interface AuthShow {
    /**
     * 
     * @type {string}
     * @memberof AuthShow
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthShow
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthShow
     */
    hint?: string;
}
/**
 * Company_Index model
 * @export
 * @interface CompanyIndex
 */
export interface CompanyIndex {
    /**
     * 
     * @type {CompanyShow}
     * @memberof CompanyIndex
     */
    companies?: CompanyShow;
}
/**
 * Company::Show model
 * @export
 * @interface CompanyShow
 */
export interface CompanyShow {
    /**
     * Название компании
     * @type {string}
     * @memberof CompanyShow
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyShow
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyShow
     */
    id?: string;
}
/**
 * ContentProgress_Batch model
 * @export
 * @interface ContentProgressBatch
 */
export interface ContentProgressBatch {
    /**
     * 
     * @type {Array<ContentProgressShow>}
     * @memberof ContentProgressBatch
     */
    contentProgressBatch?: Array<ContentProgressShow>;
}
/**
 * 
 * @export
 * @interface ContentProgressShow
 */
export interface ContentProgressShow {
    /**
     * ID контента
     * @type {string}
     * @memberof ContentProgressShow
     */
    contentId?: string;
    /**
     * Прогресс по контенту
     * @type {number}
     * @memberof ContentProgressShow
     */
    progress?: number;
    /**
     * Просмотрен контент или нет
     * @type {boolean}
     * @memberof ContentProgressShow
     */
    isFinished?: boolean;
    /**
     * Время обновления прогресса
     * @type {string}
     * @memberof ContentProgressShow
     */
    touchedAt?: string;
}
/**
 * Entities::Company::Teams model
 * @export
 * @interface EntitiesCompanyTeams
 */
export interface EntitiesCompanyTeams {
    /**
     * UUID команды
     * @type {string}
     * @memberof EntitiesCompanyTeams
     */
    id: string;
    /**
     * Название команды
     * @type {string}
     * @memberof EntitiesCompanyTeams
     */
    name: string;
}
/**
 * Entities::Profile::Apps model
 * @export
 * @interface EntitiesProfileApps
 */
export interface EntitiesProfileApps {
    /**
     * Массив названий приложений (модулей)
     * @type {string}
     * @memberof EntitiesProfileApps
     */
    appName?: string;
}
/**
 * Entities::Profile::Chiefs model
 * @export
 * @interface EntitiesProfileChiefs
 */
export interface EntitiesProfileChiefs {
    /**
     * id пользователя
     * @type {number}
     * @memberof EntitiesProfileChiefs
     */
    personId?: number;
    /**
     * Должность
     * @type {string}
     * @memberof EntitiesProfileChiefs
     */
    positionName?: string;
    /**
     * 
     * @type {EntitiesProfileTeamPeopleName}
     * @memberof EntitiesProfileChiefs
     */
    name?: EntitiesProfileTeamPeopleName;
}
/**
 * Entities::Profile::Redirect model
 * @export
 * @interface EntitiesProfileRedirect
 */
export interface EntitiesProfileRedirect {
    /**
     * 
     * @type {string}
     * @memberof EntitiesProfileRedirect
     */
    url?: string;
}
/**
 * Entities::Profile::Show model
 * @export
 * @interface EntitiesProfileShow
 */
export interface EntitiesProfileShow {
    /**
     * День рождения
     * @type {string}
     * @memberof EntitiesProfileShow
     */
    dob?: string;
    /**
     * Пол. https://ru.wikipedia.org/wiki/ISO_5218
     * @type {string}
     * @memberof EntitiesProfileShow
     */
    gender?: string;
    /**
     * 
     * @type {EntitiesProfileShowName}
     * @memberof EntitiesProfileShow
     */
    name?: EntitiesProfileShowName;
    /**
     * Название позиции
     * @type {string}
     * @memberof EntitiesProfileShow
     */
    positionName?: string;
    /**
     * id позиции
     * @type {string}
     * @memberof EntitiesProfileShow
     */
    positionId?: string;
    /**
     * Название команды
     * @type {string}
     * @memberof EntitiesProfileShow
     */
    teamName?: string;
    /**
     * Краткое название команды
     * @type {string}
     * @memberof EntitiesProfileShow
     */
    teamDisplayName?: string;
    /**
     * id команды
     * @type {string}
     * @memberof EntitiesProfileShow
     */
    teamId?: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof EntitiesProfileShow
     */
    phoneNumber?: string;
    /**
     * Email
     * @type {string}
     * @memberof EntitiesProfileShow
     */
    email?: string;
    /**
     * 
     * @type {Array<RoleShow>}
     * @memberof EntitiesProfileShow
     */
    roles?: Array<RoleShow>;
    /**
     * Профессия
     * @type {string}
     * @memberof EntitiesProfileShow
     */
    profession: string;
}
/**
 * ФИО
 * @export
 * @interface EntitiesProfileShowName
 */
export interface EntitiesProfileShowName {
    /**
     * 
     * @type {string}
     * @memberof EntitiesProfileShowName
     */
    first?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesProfileShowName
     */
    middle?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesProfileShowName
     */
    last?: string;
}
/**
 * 
 * @export
 * @interface EntitiesProfileTeamPeople
 */
export interface EntitiesProfileTeamPeople {
    /**
     * id пользователя
     * @type {number}
     * @memberof EntitiesProfileTeamPeople
     */
    personId?: number;
    /**
     * Должность
     * @type {string}
     * @memberof EntitiesProfileTeamPeople
     */
    positionName?: string;
    /**
     * 
     * @type {EntitiesProfileTeamPeopleName}
     * @memberof EntitiesProfileTeamPeople
     */
    name?: EntitiesProfileTeamPeopleName;
    /**
     * Является ли пользователем руководителем в команде
     * @type {boolean}
     * @memberof EntitiesProfileTeamPeople
     */
    head?: boolean;
}
/**
 * 
 * @export
 * @interface EntitiesProfileTeamPeopleName
 */
export interface EntitiesProfileTeamPeopleName {
    /**
     * 
     * @type {string}
     * @memberof EntitiesProfileTeamPeopleName
     */
    first?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesProfileTeamPeopleName
     */
    middle?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesProfileTeamPeopleName
     */
    last?: string;
}
/**
 * Entities::Profile::TeamsWithPeople model
 * @export
 * @interface EntitiesProfileTeamsWithPeople
 */
export interface EntitiesProfileTeamsWithPeople {
    /**
     * 
     * @type {Array<ProfileTeamsWithPeopleTeamWithPeople>}
     * @memberof EntitiesProfileTeamsWithPeople
     */
    teamsWithPeople?: Array<ProfileTeamsWithPeopleTeamWithPeople>;
}
/**
 * Entities::Scopes::Index model
 * @export
 * @interface EntitiesScopesIndex
 */
export interface EntitiesScopesIndex {
    /**
     * 
     * @type {Array<ScopesIndexScope>}
     * @memberof EntitiesScopesIndex
     */
    scopes?: Array<ScopesIndexScope>;
}
/**
 * Entities::Search::Scheme model
 * @export
 * @interface EntitiesSearchScheme
 */
export interface EntitiesSearchScheme {
    /**
     * UUID компании
     * @type {string}
     * @memberof EntitiesSearchScheme
     */
    companyId?: string;
    /**
     * JSON-схема компании
     * @type {string}
     * @memberof EntitiesSearchScheme
     */
    schema?: string;
}
/**
 * Entities::V2::Profile::Scheme model
 * @export
 * @interface EntitiesV2ProfileScheme
 */
export interface EntitiesV2ProfileScheme {
    /**
     * View JSON-схемы профиля
     * @type {string}
     * @memberof EntitiesV2ProfileScheme
     */
    view?: string;
}
/**
 * Entities::V2::Profile::SchemeDs model
 * @export
 * @interface EntitiesV2ProfileSchemeDs
 */
export interface EntitiesV2ProfileSchemeDs {
    /**
     * Объект с данными по DS
     * @type {object}
     * @memberof EntitiesV2ProfileSchemeDs
     */
    data?: object;
}
/**
 * Invitation_Show model
 * @export
 * @interface InvitationShow
 */
export interface InvitationShow {
    /**
     * 
     * @type {string}
     * @memberof InvitationShow
     */
    id?: string;
}
/**
 * IsAdmin model
 * @export
 * @interface IsAdmin
 */
export interface IsAdmin {
    /**
     * 
     * @type {boolean}
     * @memberof IsAdmin
     */
    response?: boolean;
}
/**
 * POST /auth/email
 * @export
 * @interface PostAuthEmail
 */
export interface PostAuthEmail {
    /**
     * Email пользователя
     * @type {string}
     * @memberof PostAuthEmail
     */
    email: string;
    /**
     * Код аутентификации, полученный из auth/email/request
     * @type {string}
     * @memberof PostAuthEmail
     */
    code: string;
}
/**
 * POST /auth/email/request
 * @export
 * @interface PostAuthEmailRequest
 */
export interface PostAuthEmailRequest {
    /**
     * Email пользователя
     * @type {string}
     * @memberof PostAuthEmailRequest
     */
    email: string;
}
/**
 * POST /auth/login
 * @export
 * @interface PostAuthLogin
 */
export interface PostAuthLogin {
    /**
     * Allowed login types are: LOGIN, EMAIL and PHONE
     * @type {string}
     * @memberof PostAuthLogin
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof PostAuthLogin
     */
    password: string;
}
/**
 * POST /auth/phone
 * @export
 * @interface PostAuthPhone
 */
export interface PostAuthPhone {
    /**
     * Телефон пользователя в любом формате
     * @type {string}
     * @memberof PostAuthPhone
     */
    phone: string;
    /**
     * Код аутентификации, полученный из auth/phone/request
     * @type {string}
     * @memberof PostAuthPhone
     */
    code: string;
}
/**
 * POST /auth/phone/request
 * @export
 * @interface PostAuthPhoneRequest
 */
export interface PostAuthPhoneRequest {
    /**
     * User phone in any format
     * @type {string}
     * @memberof PostAuthPhoneRequest
     */
    phone: string;
}
/**
 * POST /auth/sap/:sap_id
 * @export
 * @interface PostAuthSap
 */
export interface PostAuthSap {
    /**
     * 
     * @type {string}
     * @memberof PostAuthSap
     */
    ticket: string;
}
/**
 * POST /profile/change_password
 * @export
 * @interface PostProfileChangePassword
 */
export interface PostProfileChangePassword {
    /**
     * 
     * @type {string}
     * @memberof PostProfileChangePassword
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof PostProfileChangePassword
     */
    newPassword: string;
}
/**
 * POST /profile/redirect
 * @export
 * @interface PostProfileRedirect
 */
export interface PostProfileRedirect {
    /**
     * 
     * @type {string}
     * @memberof PostProfileRedirect
     */
    service: string;
}
/**
 * POST /users/check_auth
 * @export
 * @interface PostUsersCheckAuth
 */
export interface PostUsersCheckAuth {
    /**
     * 
     * @type {string}
     * @memberof PostUsersCheckAuth
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof PostUsersCheckAuth
     */
    type: PostUsersCheckAuthType;
}

/**
 * POST /v2/auth/totp
 * @export
 * @interface PostV2AuthTotp
 */
export interface PostV2AuthTotp {
    /**
     * Ключ подтверждения TOTP. Генерируется на основе секрета пользователя, указанного при активации TOTP.
     * @type {string}
     * @memberof PostV2AuthTotp
     */
    key: string;
}
/**
 * 
 * @export
 * @interface ProfileTeamsWithPeopleTeamWithPeople
 */
export interface ProfileTeamsWithPeopleTeamWithPeople {
    /**
     * 
     * @type {string}
     * @memberof ProfileTeamsWithPeopleTeamWithPeople
     */
    id?: string;
    /**
     * Название команды.
     * @type {string}
     * @memberof ProfileTeamsWithPeopleTeamWithPeople
     */
    name?: string;
    /**
     * Краткое название команды.
     * @type {string}
     * @memberof ProfileTeamsWithPeopleTeamWithPeople
     */
    displayName?: string;
    /**
     * Тип команды (стандартов нет, справочник определяется внешним приложением).
     * @type {string}
     * @memberof ProfileTeamsWithPeopleTeamWithPeople
     */
    teamType?: string;
    /**
     * 
     * @type {Array<EntitiesProfileTeamPeople>}
     * @memberof ProfileTeamsWithPeopleTeamWithPeople
     */
    people?: Array<EntitiesProfileTeamPeople>;
}
/**
 * PUT /content_progress
 * @export
 * @interface PutContentProgress
 */
export interface PutContentProgress {
    /**
     * 
     * @type {string}
     * @memberof PutContentProgress
     */
    contentId: string;
    /**
     * 
     * @type {number}
     * @memberof PutContentProgress
     */
    progress: number;
    /**
     * 
     * @type {string}
     * @memberof PutContentProgress
     */
    touchedAt: string;
}
/**
 * PUT /content_progress/batch
 * @export
 * @interface PutContentProgressBatch
 */
export interface PutContentProgressBatch {
    /**
     * 
     * @type {Array<PutContentProgressBatchContentProgressBatch>}
     * @memberof PutContentProgressBatch
     */
    contentProgressBatch: Array<PutContentProgressBatchContentProgressBatch>;
}
/**
 * 
 * @export
 * @interface PutContentProgressBatchContentProgressBatch
 */
export interface PutContentProgressBatchContentProgressBatch {
    /**
     * 
     * @type {string}
     * @memberof PutContentProgressBatchContentProgressBatch
     */
    contentId: string;
    /**
     * 
     * @type {number}
     * @memberof PutContentProgressBatchContentProgressBatch
     */
    progress: number;
    /**
     * 
     * @type {string}
     * @memberof PutContentProgressBatchContentProgressBatch
     */
    touchedAt: string;
}
/**
 * 
 * @export
 * @interface RoleShow
 */
export interface RoleShow {
    /**
     * Роль пользователя
     * @type {string}
     * @memberof RoleShow
     */
    role?: RoleShowRole;
}

/**
 * 
 * @export
 * @interface ScopesIndexScope
 */
export interface ScopesIndexScope {
    /**
     * Ключ скоупа
     * @type {string}
     * @memberof ScopesIndexScope
     */
    key?: string;
}
/**
 * User_SearchInCompany model
 * @export
 * @interface UserSearchInCompany
 */
export interface UserSearchInCompany {
    /**
     * 
     * @type {Array<UserSearchInCompanyUser>}
     * @memberof UserSearchInCompany
     */
    users?: Array<UserSearchInCompanyUser>;
}
/**
 * 
 * @export
 * @interface UserSearchInCompanyUser
 */
export interface UserSearchInCompanyUser {
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    spName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    teamName?: string;
}
/**
 * User::Show model
 * @export
 * @interface UserShow
 */
export interface UserShow {
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    teamDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    teamName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    staffPositionName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    chiefFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    chiefLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    chiefMiddleName?: string;
}
/**
 * 
 * @export
 * @interface PostProfileAvatarRequest
 */
export interface PostProfileAvatarRequest {
    /**
     * 
     * @type {any}
     * @memberof PostProfileAvatarRequest
     */
    file: any;
}
/**
 * POST /profile/change_password
 * @export
 * @interface PostProfileChangePassword
 */
export interface PostProfileChangePassword {
    /**
     * 
     * @type {string}
     * @memberof PostProfileChangePassword
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof PostProfileChangePassword
     */
    newPassword: string;
}
/**
 * POST /profile/redirect
 * @export
 * @interface PostProfileRedirect
 */
export interface PostProfileRedirect {
    /**
     * 
     * @type {string}
     * @memberof PostProfileRedirect
     */
    service: string;
}
/**
 * POST /users/check_auth
 * @export
 * @interface PostUsersCheckAuth
 */
export interface PostUsersCheckAuth {
    /**
     * 
     * @type {string}
     * @memberof PostUsersCheckAuth
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof PostUsersCheckAuth
     */
    type: PostUsersCheckAuthType;
}

export const PostUsersCheckAuthType = {
    EMAIL: 'email',
    PHONE: 'phone'
} as const;

export type PostUsersCheckAuthType = typeof PostUsersCheckAuthType[keyof typeof PostUsersCheckAuthType];

/**
 * POST /v2/auth/totp
 * @export
 * @interface PostV2AuthTotp
 */
export interface PostV2AuthTotp {
    /**
     * Ключ подтверждения TOTP. Генерируется на основе секрета пользователя, указанного при активации TOTP.
     * @type {string}
     * @memberof PostV2AuthTotp
     */
    key: string;
}
/**
 * 
 * @export
 * @interface ProfileTeamsWithPeopleTeamWithPeople
 */
export interface ProfileTeamsWithPeopleTeamWithPeople {
    /**
     * 
     * @type {string}
     * @memberof ProfileTeamsWithPeopleTeamWithPeople
     */
    id?: string;
    /**
     * Название команды.
     * @type {string}
     * @memberof ProfileTeamsWithPeopleTeamWithPeople
     */
    name?: string;
    /**
     * Краткое название команды.
     * @type {string}
     * @memberof ProfileTeamsWithPeopleTeamWithPeople
     */
    displayName?: string;
    /**
     * Тип команды (стандартов нет, справочник определяется внешним приложением).
     * @type {string}
     * @memberof ProfileTeamsWithPeopleTeamWithPeople
     */
    teamType?: string;
    /**
     * 
     * @type {Array<EntitiesProfileTeamPeople>}
     * @memberof ProfileTeamsWithPeopleTeamWithPeople
     */
    people?: Array<EntitiesProfileTeamPeople>;
}
/**
 * PUT /content_progress
 * @export
 * @interface PutContentProgress
 */
export interface PutContentProgress {
    /**
     * 
     * @type {string}
     * @memberof PutContentProgress
     */
    contentId: string;
    /**
     * 
     * @type {number}
     * @memberof PutContentProgress
     */
    progress: number;
    /**
     * 
     * @type {string}
     * @memberof PutContentProgress
     */
    touchedAt: string;
}
/**
 * PUT /content_progress/batch
 * @export
 * @interface PutContentProgressBatch
 */
export interface PutContentProgressBatch {
    /**
     * 
     * @type {Array<PutContentProgressBatchContentProgressBatchInner>}
     * @memberof PutContentProgressBatch
     */
    contentProgressBatch: Array<PutContentProgressBatchContentProgressBatchInner>;
}
/**
 * 
 * @export
 * @interface PutContentProgressBatchContentProgressBatchInner
 */
export interface PutContentProgressBatchContentProgressBatchInner {
    /**
     * 
     * @type {string}
     * @memberof PutContentProgressBatchContentProgressBatchInner
     */
    contentId: string;
    /**
     * 
     * @type {number}
     * @memberof PutContentProgressBatchContentProgressBatchInner
     */
    progress: number;
    /**
     * 
     * @type {string}
     * @memberof PutContentProgressBatchContentProgressBatchInner
     */
    touchedAt: string;
}
/**
 * 
 * @export
 * @interface RoleShow
 */
export interface RoleShow {
    /**
     * Роль пользователя
     * @type {string}
     * @memberof RoleShow
     */
    'role'?: RoleShowRole;
}

export const RoleShowRole = {
    ADMIN: 'admin',
    USER: 'user',
    CHIEF: 'chief'
} as const;

export type RoleShowRole = typeof RoleShowRole[keyof typeof RoleShowRole];

/**
 * 
 * @export
 * @interface ScopesIndexScope
 */
export interface ScopesIndexScope {
    /**
     * Ключ скоупа
     * @type {string}
     * @memberof ScopesIndexScope
     */
    key?: string;
}
/**
 * User_SearchInCompany model
 * @export
 * @interface UserSearchInCompany
 */
export interface UserSearchInCompany {
    /**
     * 
     * @type {Array<UserSearchInCompanyUser>}
     * @memberof UserSearchInCompany
     */
    users?: Array<UserSearchInCompanyUser>;
}
/**
 * 
 * @export
 * @interface UserSearchInCompanyUser
 */
export interface UserSearchInCompanyUser {
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    spName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSearchInCompanyUser
     */
    teamName?: string;
}
/**
 * User::Show model
 * @export
 * @interface UserShow
 */
export interface UserShow {
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    teamDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    teamName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    staffPositionName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    chiefFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    chiefLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShow
     */
    chiefMiddleName?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * POST /auth/email
         * @summary Вход по коду из email
         * @param {PostAuthEmail} authEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthEmail: async (authEmail: PostAuthEmail, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authEmail' is not null or undefined
            assertParamExists('postAuthEmail', 'authEmail', authEmail)
            const localVarPath = `/auth/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /auth/email/request
         * @summary Запросить код подтверждения аутентификации на email
         * @param {PostAuthEmailRequest} authEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthEmailRequest: async (authEmailRequest: PostAuthEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authEmailRequest' is not null or undefined
            assertParamExists('postAuthEmailRequest', 'authEmailRequest', authEmailRequest)
            const localVarPath = `/auth/email/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /auth/login
         * @summary Authenticates user by login and password
         * @param {PostAuthLogin} authLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthLogin: async (authLogin: PostAuthLogin, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authLogin' is not null or undefined
            assertParamExists('postAuthLogin', 'authLogin', authLogin)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /auth/phone
         * @summary Login with code
         * @param {PostAuthPhone} authPhone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthPhone: async (authPhone: PostAuthPhone, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authPhone' is not null or undefined
            assertParamExists('postAuthPhone', 'authPhone', authPhone)
            const localVarPath = `/auth/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authPhone, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /auth/phone/request
         * @summary Request authentication code
         * @param {PostAuthPhoneRequest} authPhoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthPhoneRequest: async (authPhoneRequest: PostAuthPhoneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authPhoneRequest' is not null or undefined
            assertParamExists('postAuthPhoneRequest', 'authPhoneRequest', authPhoneRequest)
            const localVarPath = `/auth/phone/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authPhoneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /auth/sap/:sap_id
         * @summary Authenticates user by SAP assertion ticket
         * @param {string} sapId 
         * @param {PostAuthSap} authSap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthSapSapId: async (sapId: string, authSap: PostAuthSap, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sapId' is not null or undefined
            assertParamExists('postAuthSapSapId', 'sapId', sapId)
            // verify required parameter 'authSap' is not null or undefined
            assertParamExists('postAuthSapSapId', 'authSap', authSap)
            const localVarPath = `/auth/sap/{sap_id}`
                .replace(`{${"sap_id"}}`, encodeURIComponent(String(sapId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authSap, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * POST /auth/email
         * @summary Вход по коду из email
         * @param {PostAuthEmail} authEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthEmail(authEmail: PostAuthEmail, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthEmail(authEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /auth/email/request
         * @summary Запросить код подтверждения аутентификации на email
         * @param {PostAuthEmailRequest} authEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthEmailRequest(authEmailRequest: PostAuthEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthEmailRequest(authEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /auth/login
         * @summary Authenticates user by login and password
         * @param {PostAuthLogin} authLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthLogin(authLogin: PostAuthLogin, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthLogin(authLogin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /auth/phone
         * @summary Login with code
         * @param {PostAuthPhone} authPhone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthPhone(authPhone: PostAuthPhone, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthPhone(authPhone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /auth/phone/request
         * @summary Request authentication code
         * @param {PostAuthPhoneRequest} authPhoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthPhoneRequest(authPhoneRequest: PostAuthPhoneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthPhoneRequest(authPhoneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /auth/sap/:sap_id
         * @summary Authenticates user by SAP assertion ticket
         * @param {string} sapId 
         * @param {PostAuthSap} authSap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthSapSapId(sapId: string, authSap: PostAuthSap, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthSapSapId(sapId, authSap, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * POST /auth/email
         * @summary Вход по коду из email
         * @param {PostAuthEmail} authEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthEmail(authEmail: PostAuthEmail, options?: any): AxiosPromise<AuthShow> {
            return localVarFp.postAuthEmail(authEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /auth/email/request
         * @summary Запросить код подтверждения аутентификации на email
         * @param {PostAuthEmailRequest} authEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthEmailRequest(authEmailRequest: PostAuthEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postAuthEmailRequest(authEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /auth/login
         * @summary Authenticates user by login and password
         * @param {PostAuthLogin} authLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthLogin(authLogin: PostAuthLogin, options?: any): AxiosPromise<AuthShow> {
            return localVarFp.postAuthLogin(authLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /auth/phone
         * @summary Login with code
         * @param {PostAuthPhone} authPhone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthPhone(authPhone: PostAuthPhone, options?: any): AxiosPromise<AuthShow> {
            return localVarFp.postAuthPhone(authPhone, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /auth/phone/request
         * @summary Request authentication code
         * @param {PostAuthPhoneRequest} authPhoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthPhoneRequest(authPhoneRequest: PostAuthPhoneRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postAuthPhoneRequest(authPhoneRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /auth/sap/:sap_id
         * @summary Authenticates user by SAP assertion ticket
         * @param {string} sapId 
         * @param {PostAuthSap} authSap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthSapSapId(sapId: string, authSap: PostAuthSap, options?: any): AxiosPromise<AuthShow> {
            return localVarFp.postAuthSapSapId(sapId, authSap, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * POST /auth/email
     * @summary Вход по коду из email
     * @param {PostAuthEmail} authEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAuthEmail(authEmail: PostAuthEmail, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAuthEmail(authEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /auth/email/request
     * @summary Запросить код подтверждения аутентификации на email
     * @param {PostAuthEmailRequest} authEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAuthEmailRequest(authEmailRequest: PostAuthEmailRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAuthEmailRequest(authEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /auth/login
     * @summary Authenticates user by login and password
     * @param {PostAuthLogin} authLogin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAuthLogin(authLogin: PostAuthLogin, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAuthLogin(authLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /auth/phone
     * @summary Login with code
     * @param {PostAuthPhone} authPhone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAuthPhone(authPhone: PostAuthPhone, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAuthPhone(authPhone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /auth/phone/request
     * @summary Request authentication code
     * @param {PostAuthPhoneRequest} authPhoneRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAuthPhoneRequest(authPhoneRequest: PostAuthPhoneRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAuthPhoneRequest(authPhoneRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /auth/sap/:sap_id
     * @summary Authenticates user by SAP assertion ticket
     * @param {string} sapId 
     * @param {PostAuthSap} authSap 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAuthSapSapId(sapId: string, authSap: PostAuthSap, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).postAuthSapSapId(sapId, authSap, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * GET /company
         * @summary View company profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /company/invitation
         * @summary Get company invitation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyInvitation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /company/teams
         * @summary Get a list of all teams of a company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTeams: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * GET /company
         * @summary View company profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /company/invitation
         * @summary Get company invitation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyInvitation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyInvitation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /company/teams
         * @summary Get a list of all teams of a company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyTeams(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntitiesCompanyTeams>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyTeams(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * GET /company
         * @summary View company profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(options?: any): AxiosPromise<CompanyShow> {
            return localVarFp.getCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /company/invitation
         * @summary Get company invitation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyInvitation(options?: any): AxiosPromise<InvitationShow> {
            return localVarFp.getCompanyInvitation(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /company/teams
         * @summary Get a list of all teams of a company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTeams(options?: any): AxiosPromise<Array<EntitiesCompanyTeams>> {
            return localVarFp.getCompanyTeams(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * GET /company
     * @summary View company profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompany(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /company/invitation
     * @summary Get company invitation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanyInvitation(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompanyInvitation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /company/teams
     * @summary Get a list of all teams of a company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanyTeams(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompanyTeams(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContentProgressApi - axios parameter creator
 * @export
 */
export const ContentProgressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get /content_progress/batch
         * @summary Get batch of content_progress
         * @param {Array<string>} contentIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentProgressBatch: async (contentIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentIds' is not null or undefined
            assertParamExists('getContentProgressBatch', 'contentIds', contentIds)
            const localVarPath = `/content_progress/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (contentIds) {
                localVarFormParams.append('content_ids', contentIds.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /content_progress
         * @summary Create or update content_progress
         * @param {PutContentProgress} contentProgress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContentProgress: async (contentProgress: PutContentProgress, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentProgress' is not null or undefined
            assertParamExists('putContentProgress', 'contentProgress', contentProgress)
            const localVarPath = `/content_progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentProgress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /content_progress/batch
         * @summary Create or update batch of content_progress
         * @param {PutContentProgressBatch} contentProgressBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContentProgressBatch: async (contentProgressBatch: PutContentProgressBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentProgressBatch' is not null or undefined
            assertParamExists('putContentProgressBatch', 'contentProgressBatch', contentProgressBatch)
            const localVarPath = `/content_progress/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentProgressBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentProgressApi - functional programming interface
 * @export
 */
export const ContentProgressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentProgressApiAxiosParamCreator(configuration)
    return {
        /**
         * Get /content_progress/batch
         * @summary Get batch of content_progress
         * @param {Array<string>} contentIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentProgressBatch(contentIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentProgressBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentProgressBatch(contentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /content_progress
         * @summary Create or update content_progress
         * @param {PutContentProgress} contentProgress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContentProgress(contentProgress: PutContentProgress, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContentProgress(contentProgress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /content_progress/batch
         * @summary Create or update batch of content_progress
         * @param {PutContentProgressBatch} contentProgressBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContentProgressBatch(contentProgressBatch: PutContentProgressBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContentProgressBatch(contentProgressBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentProgressApi - factory interface
 * @export
 */
export const ContentProgressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentProgressApiFp(configuration)
    return {
        /**
         * Get /content_progress/batch
         * @summary Get batch of content_progress
         * @param {Array<string>} contentIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentProgressBatch(contentIds: Array<string>, options?: any): AxiosPromise<ContentProgressBatch> {
            return localVarFp.getContentProgressBatch(contentIds, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /content_progress
         * @summary Create or update content_progress
         * @param {PutContentProgress} contentProgress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContentProgress(contentProgress: PutContentProgress, options?: any): AxiosPromise<void> {
            return localVarFp.putContentProgress(contentProgress, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /content_progress/batch
         * @summary Create or update batch of content_progress
         * @param {PutContentProgressBatch} contentProgressBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContentProgressBatch(contentProgressBatch: PutContentProgressBatch, options?: any): AxiosPromise<void> {
            return localVarFp.putContentProgressBatch(contentProgressBatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentProgressApi - object-oriented interface
 * @export
 * @class ContentProgressApi
 * @extends {BaseAPI}
 */
export class ContentProgressApi extends BaseAPI {
    /**
     * Get /content_progress/batch
     * @summary Get batch of content_progress
     * @param {Array<string>} contentIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentProgressApi
     */
    public getContentProgressBatch(contentIds: Array<string>, options?: AxiosRequestConfig) {
        return ContentProgressApiFp(this.configuration).getContentProgressBatch(contentIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /content_progress
     * @summary Create or update content_progress
     * @param {PutContentProgress} contentProgress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentProgressApi
     */
    public putContentProgress(contentProgress: PutContentProgress, options?: AxiosRequestConfig) {
        return ContentProgressApiFp(this.configuration).putContentProgress(contentProgress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /content_progress/batch
     * @summary Create or update batch of content_progress
     * @param {PutContentProgressBatch} contentProgressBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentProgressApi
     */
    public putContentProgressBatch(contentProgressBatch: PutContentProgressBatch, options?: AxiosRequestConfig) {
        return ContentProgressApiFp(this.configuration).putContentProgressBatch(contentProgressBatch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IsAdminApi - axios parameter creator
 * @export
 */
export const IsAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * GET /is_admin
         * @summary Является ли пользователь администратором компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIsAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/is_admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IsAdminApi - functional programming interface
 * @export
 */
export const IsAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IsAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * GET /is_admin
         * @summary Является ли пользователь администратором компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIsAdmin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IsAdmin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIsAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IsAdminApi - factory interface
 * @export
 */
export const IsAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IsAdminApiFp(configuration)
    return {
        /**
         * GET /is_admin
         * @summary Является ли пользователь администратором компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIsAdmin(options?: any): AxiosPromise<IsAdmin> {
            return localVarFp.getIsAdmin(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IsAdminApi - object-oriented interface
 * @export
 * @class IsAdminApi
 * @extends {BaseAPI}
 */
export class IsAdminApi extends BaseAPI {
    /**
     * GET /is_admin
     * @summary Является ли пользователь администратором компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IsAdminApi
     */
    public getIsAdmin(options?: AxiosRequestConfig) {
        return IsAdminApiFp(this.configuration).getIsAdmin(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * GET /profile
         * @summary Получить сводную информацию по профилю пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /profile/apps
         * @summary Возвращает список названий приложений, к которым у компании или аккаунта есть лицензии
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileApps: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /profile/chiefs
         * @summary Возвращает руководителей текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileChiefs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/chiefs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /profile/companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Является результатом /profile/teams + /profile/team_people/{team_id}
         * @summary Возвращает команды с пользователями данного пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileTeamsWithPeople: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/teams_with_people`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /profile/avatar
         * @param {PostProfileAvatarRequest} postProfileAvatarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProfileAvatar: async (postProfileAvatarRequest: PostProfileAvatarRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postProfileAvatarRequest' is not null or undefined
            assertParamExists('postProfileAvatar', 'postProfileAvatarRequest', postProfileAvatarRequest)
            const localVarPath = `/profile/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProfileAvatarRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /profile/change_password
         * @summary Change user password
         * @param {PostProfileChangePassword} profileChangePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProfileChangePassword: async (profileChangePassword: PostProfileChangePassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileChangePassword' is not null or undefined
            assertParamExists('postProfileChangePassword', 'profileChangePassword', profileChangePassword)
            const localVarPath = `/profile/change_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileChangePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /profile/redirect
         * @summary Cross authentication endpoint
         * @param {PostProfileRedirect} profileRedirect 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProfileRedirect: async (profileRedirect: PostProfileRedirect, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileRedirect' is not null or undefined
            assertParamExists('postProfileRedirect', 'profileRedirect', profileRedirect)
            const localVarPath = `/profile/redirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileRedirect, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PUT /profile
         * @summary Upsert profile meta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * GET /profile
         * @summary Получить сводную информацию по профилю пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesProfileShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /profile/apps
         * @summary Возвращает список названий приложений, к которым у компании или аккаунта есть лицензии
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileApps(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntitiesProfileApps>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileApps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /profile/chiefs
         * @summary Возвращает руководителей текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileChiefs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntitiesProfileChiefs>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileChiefs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /profile/companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Является результатом /profile/teams + /profile/team_people/{team_id}
         * @summary Возвращает команды с пользователями данного пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileTeamsWithPeople(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesProfileTeamsWithPeople>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileTeamsWithPeople(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /profile/avatar
         * @param {PostProfileAvatarRequest} postProfileAvatarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProfileAvatar(postProfileAvatarRequest: PostProfileAvatarRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProfileAvatar(postProfileAvatarRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /profile/change_password
         * @summary Change user password
         * @param {PostProfileChangePassword} profileChangePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProfileChangePassword(profileChangePassword: PostProfileChangePassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProfileChangePassword(profileChangePassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /profile/redirect
         * @summary Cross authentication endpoint
         * @param {PostProfileRedirect} profileRedirect 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProfileRedirect(profileRedirect: PostProfileRedirect, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesProfileRedirect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProfileRedirect(profileRedirect, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * PUT /profile
         * @summary Upsert profile meta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * GET /profile
         * @summary Получить сводную информацию по профилю пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<EntitiesProfileShow> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /profile/apps
         * @summary Возвращает список названий приложений, к которым у компании или аккаунта есть лицензии
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileApps(options?: any): AxiosPromise<Array<EntitiesProfileApps>> {
            return localVarFp.getProfileApps(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /profile/chiefs
         * @summary Возвращает руководителей текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileChiefs(options?: any): AxiosPromise<Array<EntitiesProfileChiefs>> {
            return localVarFp.getProfileChiefs(options).then((request) => request(axios, basePath));
        },
        /**
         * GET /profile/companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileCompanies(options?: any): AxiosPromise<CompanyIndex> {
            return localVarFp.getProfileCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * Является результатом /profile/teams + /profile/team_people/{team_id}
         * @summary Возвращает команды с пользователями данного пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileTeamsWithPeople(options?: any): AxiosPromise<EntitiesProfileTeamsWithPeople> {
            return localVarFp.getProfileTeamsWithPeople(options).then((request) => request(axios, basePath));
        },
        /**
         * POST /profile/avatar
         * @param {PostProfileAvatarRequest} postProfileAvatarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProfileAvatar(postProfileAvatarRequest: PostProfileAvatarRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postProfileAvatar(postProfileAvatarRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /profile/change_password
         * @summary Change user password
         * @param {PostProfileChangePassword} profileChangePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProfileChangePassword(profileChangePassword: PostProfileChangePassword, options?: any): AxiosPromise<void> {
            return localVarFp.postProfileChangePassword(profileChangePassword, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /profile/redirect
         * @summary Cross authentication endpoint
         * @param {PostProfileRedirect} profileRedirect 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProfileRedirect(profileRedirect: PostProfileRedirect, options?: any): AxiosPromise<EntitiesProfileRedirect> {
            return localVarFp.postProfileRedirect(profileRedirect, options).then((request) => request(axios, basePath));
        },
        /**
         * PUT /profile
         * @summary Upsert profile meta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProfile(options?: any): AxiosPromise<void> {
            return localVarFp.putProfile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * GET /profile
     * @summary Получить сводную информацию по профилю пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfile(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /profile/apps
     * @summary Возвращает список названий приложений, к которым у компании или аккаунта есть лицензии
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfileApps(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).getProfileApps(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /profile/chiefs
     * @summary Возвращает руководителей текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfileChiefs(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).getProfileChiefs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /profile/companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfileCompanies(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).getProfileCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Является результатом /profile/teams + /profile/team_people/{team_id}
     * @summary Возвращает команды с пользователями данного пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfileTeamsWithPeople(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).getProfileTeamsWithPeople(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /profile/avatar
     * @param {PostProfileAvatarRequest} postProfileAvatarRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public postProfileAvatar(postProfileAvatarRequest: PostProfileAvatarRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).postProfileAvatar(postProfileAvatarRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /profile/change_password
     * @summary Change user password
     * @param {PostProfileChangePassword} profileChangePassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public postProfileChangePassword(profileChangePassword: PostProfileChangePassword, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).postProfileChangePassword(profileChangePassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /profile/redirect
     * @summary Cross authentication endpoint
     * @param {PostProfileRedirect} profileRedirect 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public postProfileRedirect(profileRedirect: PostProfileRedirect, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).postProfileRedirect(profileRedirect, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PUT /profile
     * @summary Upsert profile meta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public putProfile(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).putProfile(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScopesApi - axios parameter creator
 * @export
 */
export const ScopesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * GET /scopes
         * @summary Скоупы внешних приложений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scopes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScopesApi - functional programming interface
 * @export
 */
export const ScopesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScopesApiAxiosParamCreator(configuration)
    return {
        /**
         * GET /scopes
         * @summary Скоупы внешних приложений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScopes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesScopesIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScopes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScopesApi - factory interface
 * @export
 */
export const ScopesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScopesApiFp(configuration)
    return {
        /**
         * GET /scopes
         * @summary Скоупы внешних приложений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopes(options?: any): AxiosPromise<EntitiesScopesIndex> {
            return localVarFp.getScopes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScopesApi - object-oriented interface
 * @export
 * @class ScopesApi
 * @extends {BaseAPI}
 */
export class ScopesApi extends BaseAPI {
    /**
     * GET /scopes
     * @summary Скоупы внешних приложений
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScopesApi
     */
    public getScopes(options?: AxiosRequestConfig) {
        return ScopesApiFp(this.configuration).getScopes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * GET /search/scheme
         * @summary Search company`s scheme
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchScheme: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/scheme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * GET /search/scheme
         * @summary Search company`s scheme
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchScheme(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesSearchScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchScheme(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * GET /search/scheme
         * @summary Search company`s scheme
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchScheme(options?: any): AxiosPromise<EntitiesSearchScheme> {
            return localVarFp.getSearchScheme(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * GET /search/scheme
     * @summary Search company`s scheme
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public getSearchScheme(options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).getSearchScheme(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * optional fields: `middle_name`, `email`, `phone`, `team_display_name`, `staff_position_name`, `chief_first_name`, `chief_last_name`, `chief_middle_name`
         * @summary Information about a user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUsersId', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 204 - login isn\'t taken; 409 - login is taken, code: AU001
         * @summary Check login is taken or not
         * @param {PostUsersCheckAuth} usersCheckAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsersCheckAuth: async (usersCheckAuth: PostUsersCheckAuth, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersCheckAuth' is not null or undefined
            assertParamExists('postUsersCheckAuth', 'usersCheckAuth', usersCheckAuth)
            const localVarPath = `/users/check_auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersCheckAuth, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *         Структура данных в индексе компаний может отличатся от компании к компании.         Поэтому каждое поле в теле запроса будет обработано, как поле по которому будет         произведен поиск.         Для поиска вызывается RPC.         /twirp/tt.core.tools.index.v1.CompanyIndexAPI/SearchUserInCompanyIndex         За подробностями читать описание SearchUserInCompanyIndex.          Если в индексе содержатся документы вида:         {           \"document\": {             \"series\": \"1234\",             \"number\": \"567890\"           }         }          То запрос на поиск пользователя по этим документам будет выглядеть         следующим образом:         {           \"document.series\": \"3333\",           \"document.number\": \"777777\"         } 
         * @summary Поиск пользователя по данным из индекса текущей компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsersSearchInCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/search_in_company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * optional fields: `middle_name`, `email`, `phone`, `team_display_name`, `staff_position_name`, `chief_first_name`, `chief_last_name`, `chief_middle_name`
         * @summary Information about a user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 204 - login isn\'t taken; 409 - login is taken, code: AU001
         * @summary Check login is taken or not
         * @param {PostUsersCheckAuth} usersCheckAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUsersCheckAuth(usersCheckAuth: PostUsersCheckAuth, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUsersCheckAuth(usersCheckAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *         Структура данных в индексе компаний может отличатся от компании к компании.         Поэтому каждое поле в теле запроса будет обработано, как поле по которому будет         произведен поиск.         Для поиска вызывается RPC.         /twirp/tt.core.tools.index.v1.CompanyIndexAPI/SearchUserInCompanyIndex         За подробностями читать описание SearchUserInCompanyIndex.          Если в индексе содержатся документы вида:         {           \"document\": {             \"series\": \"1234\",             \"number\": \"567890\"           }         }          То запрос на поиск пользователя по этим документам будет выглядеть         следующим образом:         {           \"document.series\": \"3333\",           \"document.number\": \"777777\"         } 
         * @summary Поиск пользователя по данным из индекса текущей компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUsersSearchInCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchInCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUsersSearchInCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * optional fields: `middle_name`, `email`, `phone`, `team_display_name`, `staff_position_name`, `chief_first_name`, `chief_last_name`, `chief_middle_name`
         * @summary Information about a user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersId(id: string, options?: any): AxiosPromise<UserShow> {
            return localVarFp.getUsersId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 204 - login isn\'t taken; 409 - login is taken, code: AU001
         * @summary Check login is taken or not
         * @param {PostUsersCheckAuth} usersCheckAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsersCheckAuth(usersCheckAuth: PostUsersCheckAuth, options?: any): AxiosPromise<void> {
            return localVarFp.postUsersCheckAuth(usersCheckAuth, options).then((request) => request(axios, basePath));
        },
        /**
         *         Структура данных в индексе компаний может отличатся от компании к компании.         Поэтому каждое поле в теле запроса будет обработано, как поле по которому будет         произведен поиск.         Для поиска вызывается RPC.         /twirp/tt.core.tools.index.v1.CompanyIndexAPI/SearchUserInCompanyIndex         За подробностями читать описание SearchUserInCompanyIndex.          Если в индексе содержатся документы вида:         {           \"document\": {             \"series\": \"1234\",             \"number\": \"567890\"           }         }          То запрос на поиск пользователя по этим документам будет выглядеть         следующим образом:         {           \"document.series\": \"3333\",           \"document.number\": \"777777\"         } 
         * @summary Поиск пользователя по данным из индекса текущей компании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsersSearchInCompany(options?: any): AxiosPromise<UserSearchInCompany> {
            return localVarFp.postUsersSearchInCompany(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * optional fields: `middle_name`, `email`, `phone`, `team_display_name`, `staff_position_name`, `chief_first_name`, `chief_last_name`, `chief_middle_name`
     * @summary Information about a user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersId(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 204 - login isn\'t taken; 409 - login is taken, code: AU001
     * @summary Check login is taken or not
     * @param {PostUsersCheckAuth} usersCheckAuth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postUsersCheckAuth(usersCheckAuth: PostUsersCheckAuth, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).postUsersCheckAuth(usersCheckAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *         Структура данных в индексе компаний может отличатся от компании к компании.         Поэтому каждое поле в теле запроса будет обработано, как поле по которому будет         произведен поиск.         Для поиска вызывается RPC.         /twirp/tt.core.tools.index.v1.CompanyIndexAPI/SearchUserInCompanyIndex         За подробностями читать описание SearchUserInCompanyIndex.          Если в индексе содержатся документы вида:         {           \"document\": {             \"series\": \"1234\",             \"number\": \"567890\"           }         }          То запрос на поиск пользователя по этим документам будет выглядеть         следующим образом:         {           \"document.series\": \"3333\",           \"document.number\": \"777777\"         } 
     * @summary Поиск пользователя по данным из индекса текущей компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postUsersSearchInCompany(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).postUsersSearchInCompany(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V2Api - axios parameter creator
 * @export
 */
export const V2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *            View схемы ищется по параметрам <current_company> + <role>.           В зависимости от <role> может быть выдана одна из следующих view:           - админская           - руководителя           - пользователя.           Параметр <role> вычисляется следующим образом:           - администратор, если <current_user> является админом           - руководитель, если <current_user> является руководителем для <user_id>           - пользователь, если <current_user> равен <user_id>            Для задания view схемы профиля нужно использовать RPC вызов:           /twirp/tt.m9t.profile.ProfileSchemaAPI/CreateProfileSchema         
         * @summary Получение view схемы страницы профиля
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2ProfileUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getV2ProfileUserId', 'userId', userId)
            const localVarPath = `/v2/profile/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET /v2/profile/:user_id/ds/:ds
         * @summary Получение DS блока для схемы профиля
         * @param {string} userId 
         * @param {string} ds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2ProfileUserIdDsDs: async (userId: string, ds: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getV2ProfileUserIdDsDs', 'userId', userId)
            // verify required parameter 'ds' is not null or undefined
            assertParamExists('getV2ProfileUserIdDsDs', 'ds', ds)
            const localVarPath = `/v2/profile/{user_id}/ds/{ds}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"ds"}}`, encodeURIComponent(String(ds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST /v2/auth/totp
         * @summary Подтверждение аутентификации вторым фактором
         * @param {PostV2AuthTotp} v2AuthTotp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2AuthTotp: async (v2AuthTotp: PostV2AuthTotp, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'v2AuthTotp' is not null or undefined
            assertParamExists('postV2AuthTotp', 'v2AuthTotp', v2AuthTotp)
            const localVarPath = `/v2/auth/totp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v2AuthTotp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V2Api - functional programming interface
 * @export
 */
export const V2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V2ApiAxiosParamCreator(configuration)
    return {
        /**
         *            View схемы ищется по параметрам <current_company> + <role>.           В зависимости от <role> может быть выдана одна из следующих view:           - админская           - руководителя           - пользователя.           Параметр <role> вычисляется следующим образом:           - администратор, если <current_user> является админом           - руководитель, если <current_user> является руководителем для <user_id>           - пользователь, если <current_user> равен <user_id>            Для задания view схемы профиля нужно использовать RPC вызов:           /twirp/tt.m9t.profile.ProfileSchemaAPI/CreateProfileSchema         
         * @summary Получение view схемы страницы профиля
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV2ProfileUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesV2ProfileScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV2ProfileUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GET /v2/profile/:user_id/ds/:ds
         * @summary Получение DS блока для схемы профиля
         * @param {string} userId 
         * @param {string} ds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV2ProfileUserIdDsDs(userId: string, ds: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesV2ProfileSchemeDs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV2ProfileUserIdDsDs(userId, ds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST /v2/auth/totp
         * @summary Подтверждение аутентификации вторым фактором
         * @param {PostV2AuthTotp} v2AuthTotp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV2AuthTotp(v2AuthTotp: PostV2AuthTotp, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthConfirmTotp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV2AuthTotp(v2AuthTotp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V2Api - factory interface
 * @export
 */
export const V2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V2ApiFp(configuration)
    return {
        /**
         *            View схемы ищется по параметрам <current_company> + <role>.           В зависимости от <role> может быть выдана одна из следующих view:           - админская           - руководителя           - пользователя.           Параметр <role> вычисляется следующим образом:           - администратор, если <current_user> является админом           - руководитель, если <current_user> является руководителем для <user_id>           - пользователь, если <current_user> равен <user_id>            Для задания view схемы профиля нужно использовать RPC вызов:           /twirp/tt.m9t.profile.ProfileSchemaAPI/CreateProfileSchema         
         * @summary Получение view схемы страницы профиля
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2ProfileUserId(userId: string, options?: any): AxiosPromise<EntitiesV2ProfileScheme> {
            return localVarFp.getV2ProfileUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET /v2/profile/:user_id/ds/:ds
         * @summary Получение DS блока для схемы профиля
         * @param {string} userId 
         * @param {string} ds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2ProfileUserIdDsDs(userId: string, ds: string, options?: any): AxiosPromise<EntitiesV2ProfileSchemeDs> {
            return localVarFp.getV2ProfileUserIdDsDs(userId, ds, options).then((request) => request(axios, basePath));
        },
        /**
         * POST /v2/auth/totp
         * @summary Подтверждение аутентификации вторым фактором
         * @param {PostV2AuthTotp} v2AuthTotp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2AuthTotp(v2AuthTotp: PostV2AuthTotp, options?: any): AxiosPromise<AuthConfirmTotp> {
            return localVarFp.postV2AuthTotp(v2AuthTotp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V2Api - object-oriented interface
 * @export
 * @class V2Api
 * @extends {BaseAPI}
 */
export class V2Api extends BaseAPI {
    /**
     *            View схемы ищется по параметрам <current_company> + <role>.           В зависимости от <role> может быть выдана одна из следующих view:           - админская           - руководителя           - пользователя.           Параметр <role> вычисляется следующим образом:           - администратор, если <current_user> является админом           - руководитель, если <current_user> является руководителем для <user_id>           - пользователь, если <current_user> равен <user_id>            Для задания view схемы профиля нужно использовать RPC вызов:           /twirp/tt.m9t.profile.ProfileSchemaAPI/CreateProfileSchema         
     * @summary Получение view схемы страницы профиля
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public getV2ProfileUserId(userId: string, options?: AxiosRequestConfig) {
        return V2ApiFp(this.configuration).getV2ProfileUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET /v2/profile/:user_id/ds/:ds
     * @summary Получение DS блока для схемы профиля
     * @param {string} userId 
     * @param {string} ds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public getV2ProfileUserIdDsDs(userId: string, ds: string, options?: AxiosRequestConfig) {
        return V2ApiFp(this.configuration).getV2ProfileUserIdDsDs(userId, ds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST /v2/auth/totp
     * @summary Подтверждение аутентификации вторым фактором
     * @param {PostV2AuthTotp} v2AuthTotp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V2Api
     */
    public postV2AuthTotp(v2AuthTotp: PostV2AuthTotp, options?: AxiosRequestConfig) {
        return V2ApiFp(this.configuration).postV2AuthTotp(v2AuthTotp, options).then((request) => request(this.axios, this.basePath));
    }
}


